import { createGlobalStyle } from 'styled-components';

export const ApplicationTheme = {
  primaryColor: '#ff9b41',
  secondaryColor: '#ff9b41',
  primaryShadowColor: '#FAA51D4F',
  primaryHoverColor: '#e0883a',
  secondaryHoverColor: '#f9ede4',
  sidebarItemBg: '#fcefe7',
  sidebarItemHover: '#fac69b',
  iconsColor: '#ff9b41',
  primaryBGColor: '#f7f6f6',
  primaryTextColor: '#000',
  secondaryTextColor: '#747474',
  bordersColor: '#c1c1c1',
};

const GlobalStyle = createGlobalStyle`
  body {
    //rsuite colors
    --rs-picker-value: ${(props) => props.theme.primaryColor};
    --rs-text-link-hover: ${(props) => `${props.theme.secondaryHoverColor}`};
    --rs-state-hover-bg: ${(props) => `${props.theme.secondaryHoverColor}`};
    --rs-listbox-option-selected-text: ${(props) => props.theme.secondaryColor};
    --rs-listbox-option-hover-text: ${(props) => props.theme.primaryColor};
    --rs-input-focus-border: ${(props) => props.theme.primaryColor};
    --rs-calendar-cell-selected-hover-bg: ${(props) => props.theme.secondaryColor};
    --rs-picker-count-bg: ${(props) => props.theme.secondaryColor};
    --rs-picker-count-text: #fff;
    --rs-calendar-today-bg: ${(props) => props.theme.secondaryColor};
    --rs-calendar-today-text: #fff;
    --rs-calendar-range-bg: rgba(204, 233, 255, 0.5);
    --rs-calendar-time-unit-bg: #f7f7fa;
    --rs-calendar-date-selected-text: #fff;
    --rs-text-active: ${(props) => props.theme.primaryColor};
    --rs-bg-active: ${(props) => props.theme.primaryColor};

   
    --rs-primary-700: ${(props) => props.theme.secondaryColor};
    --rs-primary-800: ${(props) => props.theme.primaryColor};
    --rs-primary-900: ${(props) => props.theme.primaryColor};

    --rs-toggle-checked-bg: #afd136;
    --rs-toggle-checked-thumb: #fff;
    --rs-toggle-checked-hover-bg: #869d2e;
    --rs-toggle-checked-disabled-bg: #cce9ff;
    --rs-toggle-checked-disabled-thumb: #9e9d9d;
    --rs-toggle-bg: #9e9d9d;

    --rs-menuitem-active-bg: #fef4e2;
    --rs-menuitem-active-text: ${(props) => props.theme.secondaryColor};


    --rs-radio-marker: #fff;
    --rs-radio-border: #d9d9d9;
    --rs-radio-checked-bg: ${(props) => props.theme.primaryColor};
    --rs-radio-disabled-bg: #f7f7fa;

    --rs-btn-ghost-border: ${(props) => props.theme.primaryColor};
    --rs-btn-ghost-text: ${(props) => props.theme.secondaryColor};
    --rs-btn-ghost-hover-border: ${(props) => props.theme.primaryColor};
    --rs-btn-ghost-hover-text: ${(props) => props.theme.primaryColor}; 
    --rs-btn-ghost-active-border: ${(props) => props.theme.secondaryColor};
    --rs-btn-ghost-active-text: ${(props) => props.theme.primaryColor};

    --rs-loader-rotor: ${(props) => props.theme.primaryColor};
  }
`;

export default GlobalStyle;
