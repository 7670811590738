import React, { useCallback, useState } from 'react';
import { Loader } from 'rsuite';
import styled from 'styled-components';
import craigslistIco from '../../../assets/craigslistIco.png';
import FBLogo from '../../../assets/FB_logo_text.png';
import CustomButton from '../../../components/Buttons/CustomButton';
import CustomSelect from '../../../components/inputs/CustomSelect';
import SettingsLayout from '../../../components/SettingsLayout';
import { useGetExtensionDealer } from '../../../hooks/getExtensionHook';
import Pages from '../../../Router/pages';
import { UnitRequests } from '../../../store/unit/requests';

const tabs = [
  { label: 'Craigslist', key: 'craigslist' },
  { label: 'Facebook', key: 'facebook' },
] as const;

export enum ExtensionFiles {
  'x32' = 'x32',
  'x64' = 'x64',
  'mac' = 'mac',
}

function SettingsIntegrations() {
  const [activeTab, setActiveTab] = useState<typeof tabs[number]['key']>('craigslist');
  const renderTab = useCallback(() => {
    switch (activeTab) {
      case 'facebook':
        return <FacebookTab />;
      default:
        return <CraigslistTab />;
    }
  }, [activeTab]);

  return (
    <SettingsLayout navigateOnBack={Pages.inventoryManager} headerTitle="settings">
      <Container>
        <div className="tabs">
          {tabs.map((tab) => (
            <div
              className={`tab-item ${tab.key === activeTab && 'active'}`}
              key={tab.key}
              onClick={() => {
                setActiveTab(tab.key);
              }}
            >
              {tab.label}
            </div>
          ))}
        </div>
        {renderTab()}
      </Container>
    </SettingsLayout>

  );
}
export default SettingsIntegrations;

function CraigslistTab() {
  const isConnected = useGetExtensionDealer();

  return (
    <CraigslistTabContainer>
      <img src={craigslistIco} alt="craigslistIco" />
      <div className="button-wrap">
        <CustomButton className={`${isConnected && 'connected'}`}>
          <a
            target="_blank"
            href="https://chrome.google.com/webstore/detail/trailer-funnel/flngphflebohjiiofdadkncllfjpnocd?gclid=CjwKCAjw6p-oBhAYEiwAgg2PgshTtJ-gkZvgW-BE5eVmb2JHWYkImwL-WbLyyvT8TzEU9GMFCTS5hxoCoAcQAvD_BwE"
            rel="noreferrer"
          >
            Download
          </a>
        </CustomButton>
      </div>
    </CraigslistTabContainer>
  );
}
function FacebookTab() {
  const isConnected = useGetExtensionDealer();
  const options = [
    { value: ExtensionFiles.x32, label: 'Windows x32' },
    { value: ExtensionFiles.x64, label: 'Windows x64' },
    { value: ExtensionFiles.mac, label: 'macOS' },
  ];
type ExtensionFileOption = {value: keyof typeof ExtensionFiles, label: string}
const [fileType, setFileType] = useState<ExtensionFileOption>(options[0]);
const [isLoading, setIsLoading] = useState<boolean>(false);
const handleDownload = () => {
  setIsLoading(true);
  UnitRequests.fbAppDownload(fileType?.value).finally(() => {
    setIsLoading(false);
  });
};

return (
  <CraigslistTabContainer>
    {isLoading
    && (
    <div className="loader-wrapper">
      <Loader center size="lg" color="red" />
    </div>
    )}
    <img src={FBLogo} alt="FBLogo" />
    {/* <div className="download-wrap">
      <CustomSelect
        options={options}
        value={fileType}
        onChange={(val: ExtensionFileOption) => setFileType(val)}
      />
      <div className="button-wrap">
        <CustomButton className={`${isConnected && 'connected'}`} disabled={isLoading} onClick={handleDownload}>Download</CustomButton>
      </div>
    </div> */}
  </CraigslistTabContainer>
);
}

const CraigslistTabContainer = styled.div`
  text-align: start;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .loader-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
  }
    img {
      width: 150px;
      object-fit: contain;
    }
  .download-wrap {
    .select-wrap {
      min-width: 200px;
    } 
  }
  .button-wrap {
    width: 129px;
    margin-top: 10px;
    a {
      text-decoration: none;
      color: inherit;
    }
    .connected {
      border: 2px solid ${(props) => props.theme.primaryColor};
      color: ${(props) => props.theme.primaryColor};
      background-color: #fff;
    }
  }
  .select-container {
    .select-wrap {
        @media screen and (max-width: 768px) {
          min-width: 100px;
        }
    }
  }
`;

const Container = styled.div`
  padding-top: 100px;
  font-size: 14px;
  color: ${(props) => props.theme.primaryTextColor};
  background-color: ${(props) => props.theme.primaryBGColor};
  padding-left: 45px;
    @media screen and (max-width: 768px) {
      padding-left: 10px;
    }
  .tabs {
   display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f3f3f3;
    border-bottom: 4px solid #fff;
   .tab-item {
     height: 39px;
     padding-left: 10px;
     padding-right: 10px;
     display: flex;
     align-items:center;
     justify-content:center;
     cursor: pointer;
     user-select: none;
     background-color: #ffffff;
     margin-left: 5px;
     &.active {
       color: #fff;
       background-color: ${(props) => props.theme.secondaryColor};
       font-weight: bold;
     }
   }
 }
  .content {
    padding-left: 23px;
    padding-right: 40px;
    text-align: start;
  }
`;
