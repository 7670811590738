import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import Calendar, { CalendarProps } from 'rsuite/Calendar';
import styled from 'styled-components';
import CustomButton from '../Buttons/CustomButton';

type CustomCalendarProps = CalendarProps & {
    date?: Date
    setDate?: (date: Date) => void
}

function CustomCalendar({ date, setDate, ...props }: CustomCalendarProps) {
  const [calendarDate, setCalendarDate] = useState<Date>(new Date());
  const handleMonthView = () => { // simulate click on month to open year selection dropdown
    const button = document.getElementsByClassName('rs-calendar-header-title  rs-calendar-header-title-date  rs-btn  rs-btn-subtle  rs-btn-sm')[0] as HTMLElement;
    button.click();
  };
  useEffect(() => {
    if (date) {
      setCalendarDate(date);
    }
  }, [date]);

  const handleNextMonth = () => {
    const oldDate = moment(calendarDate);
    const newDate = moment(oldDate.add(1, 'month')).toDate();
    setCalendarDate(newDate);
  };
  const handlePrevMonth = () => {
    const oldDate = moment(calendarDate);
    const newDate = moment(oldDate.subtract(1, 'month')).toDate();
    if (moment(newDate).get('months') >= moment().get('months')) {
      setCalendarDate(newDate);
    } else {
      setCalendarDate((prevState) => prevState);
    }
  };

  return (
    <Container className="calendar-component-wrapper">
      <div className="custom-header">
        <div className="day">{calendarDate?.toLocaleString('en-us', { weekday: 'long' })}</div>
        <div className="header-control">
          <div className="month-wrap">
            <CustomButton variant="text" wrapperClassname="button left" onClick={handlePrevMonth}>
              <StyledArrow className={`${moment() > moment(calendarDate) && 'disabled'}`} />
            </CustomButton>
            <span onClick={handleMonthView} className="month">
              {calendarDate?.toLocaleString('en-us', { month: 'long' })}
            </span>
            <CustomButton variant="text" wrapperClassname="button right" onClick={handleNextMonth}><StyledArrow /></CustomButton>
          </div>
          <div className="day-wrap">
            {calendarDate?.toLocaleString('en-us', { day: 'numeric' })}
          </div>
          <div className="year-wrap">
            {calendarDate?.toLocaleString('en-us', { year: 'numeric' })}
          </div>
        </div>
      </div>
      <Calendar
        compact
        value={calendarDate}
        locale={{
          sunday: 'S',
          monday: 'M',
          tuesday: 'T',
          wednesday: 'W',
          thursday: 'T',
          friday: 'F',
          saturday: 'S',
          formattedMonthPattern: 'MMMM',
          hours: 'Hours',
          minutes: 'Minutes',
          seconds: 'Seconds',
        }}
        onSelect={(selected) => {
          setCalendarDate(selected);
          setDate?.(selected);
        }}
        renderCell={(cellDate) => {
          const isSameDate = moment(cellDate).startOf('day').valueOf() === moment(date).startOf('day').valueOf();
          return <div className={`${date && isSameDate && 'active'}`}> </div>;
        }}
        onChange={(selected) => {
          setCalendarDate(selected);
          setDate?.(selected);
        }}
        {...props}
      />
    </Container>
  );
}

export default CustomCalendar;
const StyledArrow = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' viewBox='0 0 24 24' width='100%25' fill='%23ffffff' preserveAspectRatio='none'%3E%3Cpath d='M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z'/%3E%3C/svg%3E");
  width: 32px;
  height: 32px;
  margin-top: 4px;
  margin-right: 4px;

  &.disabled {
    cursor: not-allowed;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' viewBox='0 0 24 24' width='100%25' fill='%23696969FF' preserveAspectRatio='none'%3E%3Cpath d='M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z'/%3E%3C/svg%3E");
  }
`;
const Container = styled.div`
    width: 14rem;
      @media screen and (max-width: 1200px) {
        max-width: 337px; 
      }
    .rs-calendar-header {
     display: none;
    }
  .custom-header {
    .day {
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      vertical-align: center;
      background-color: ${(props) => props.theme.secondaryColor};
      color: #ffffff;
    }
    .header-control {
      background-color: #9e9d9d;
      height: 149px;
      color: #fff;
      .month-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: #fff;
        .button {
          width: 32px;
          &.left {
            transform: rotate(90deg);
          }
          &.right {
            transform: rotate(-90deg);
          }
        }
        .month {
          cursor: pointer;
        }
      }
      .day-wrap {
        font-size: 60px;
        line-height: initial;
      } 
      .year-wrap {
        font-size: 18px;
        line-height: initial;
      }
    }
  }
  .rs-calendar-table-cell-content {
    box-shadow: none;
    position: relative;
    .active {
      box-shadow: inset 0 0 0 1px #ed7419;
      width: 100%;
      position: absolute;
      height: 100%;
      left: 0;
    }
  }
  .rs-calendar-panel .rs-calendar-month-dropdown {
    top: 0;
  }
  .rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content  {
    height: 40px;
  }
`;
