import styled from 'styled-components';
import { ButtonHTMLAttributes } from 'react';

interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    children?: JSX.Element | JSX.Element[] | string
    variant?: 'primary' | 'outlined-box' | 'text'
    wrapperClassname?: string
}

function CustomButton({
  children, type, variant = 'primary', className, wrapperClassname, ...props
}: CustomButtonProps) {
  return (
    <StyledWrapper className={wrapperClassname}>
      <StyledButton
        type={type}
        className={`${className} ${variant}`}
        {...props}
      >
        {children}
      </StyledButton>
    </StyledWrapper>
  );
}

export default CustomButton;

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledButton = styled.button`
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  border-radius: 20px;
  text-align: center;
  text-decoration: none;
  border: 2px solid transparent;
  width: 100%;
  transition: all ease 0.2s;

  &.primary {
    color: #fff;
    text-transform: uppercase;
    background-color: ${(props) => props.theme.primaryColor};
    border-radius: 36px;
    outline: none;
    box-shadow: 0 5px 5px ${(props) => props.theme.primaryColor};
    transition: all ease 0.2s;
    :hover {
      background: ${(props) => props.theme.primaryHoverColor};
      border: 2px solid transparent;
    }
  }

  &.text {
    background-color: inherit;
    text-transform: inherit;
    text-decoration: underline;
    color: #6a707c;
    padding: 0;
    outline: none;
    border: none;
    font-size: 10px;
    transition: all ease 0.2s;
    :hover {
      background: inherit;
      font-weight: bold;
    }
  }

  &.outlined-box {
    border: 0.5px solid #6a707c;
    border-radius: 0;
    background-color: #fff;
    transition: all ease 0.2s;
    :hover {
      background: inherit;
      border: 2px solid black;
      font-weight: bold;
    }
  }

  :disabled {
    background: gray;
    box-shadow: 0 5px 5px gray;
  }
`;
