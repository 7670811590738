import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppMessageContext } from '../../appMessagesContext';
import squareEdit from '../../assets/square-with-pencil.png';
import CustomButton from '../../components/Buttons/CustomButton';
import CustomTable from '../../components/CustomTable';
import DeleteModal from '../../components/DeleteModal';
import BinSvg from '../../components/SvgElements/BinSvg';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import { DeleteRoleRequest, GetRoles } from '../../store/dealers/actions';

function RolesTable() {
  const navigate = useNavigateHook();
  const { showMessage } = useAppMessageContext();
  const { data: roles, refetch: refetchTable, isFetching } = GetRoles();
  const [showModal, setShowModal] = useState<{id: number} | boolean>(false);
  const deleteRoleRequest = DeleteRoleRequest();

  const handleModalAccept = ({ id }: {id: number}) => {
    deleteRoleRequest.mutate({ id });
    setShowModal(false);
  };
  useEffect(() => {
    if (deleteRoleRequest.isSuccess) {
      showMessage({ type: 'success', message: 'Role deleted successfully' });
      deleteRoleRequest.reset();
      refetchTable();
    }
  }, [deleteRoleRequest.isSuccess]);
  const modalText = () => (
    <div>
      <h3>
        Permanently Delete
      </h3>
      <div className="text">
        Are you sure you would like
        <br />
        to Permanently Delete this
        <br />
        Role?
      </div>
    </div>
  );
  return (
    <Container>
      <div className="head">
        <div className="title">
          Roles
        </div>
      </div>
      <div className="control">
        <CustomButton className="add-new-btn" onClick={() => navigate(`${Pages.rolePurePage}/null`)}>Add new Role</CustomButton>
      </div>
      <CustomTable
        loading={isFetching}
        data={roles.map((dataRow) => ({
          ...dataRow,
          action: <ActionsCell
            onEditClick={() => navigate(`${Pages.rolePurePage}/${dataRow.id}`)}
            onRemoveClick={() => setShowModal({ id: dataRow.id })}
          />,
        }))}
        columns={columns}
        selectable={false}
        hideSelectHeader
        displayTotal={false}
        fillHeight={false}
        autoHeight
        fullHeightContainer={false}
        displayPagination={false}
      />
      <DeleteModal
        openObj={showModal}
        onApproveClose={handleModalAccept}
        onClose={() => setShowModal(false)}
        mainContent={modalText()}
      />
    </Container>
  );
}
export default RolesTable;
type ActionsCellProps = {
  onEditClick: () => void
  onRemoveClick: () => void
}
function ActionsCell({ onEditClick, onRemoveClick }: ActionsCellProps) {
  return (
    <StyledActionCell>
      <button type="button" onClick={onEditClick}>
        <img src={squareEdit} alt="edit" />
      </button>
      <button type="button" className="remove-bin" onClick={onRemoveClick}>
        <BinSvg fill="#747474" stroke="#747474" />
      </button>
    </StyledActionCell>
  );
}

const StyledActionCell = styled.div`
  display: flex;
  align-items: center;
  .dots {
    margin-top: 12px;
    > div {
      width: 24px;
    }
   }
  .remove-bin {
    height: 20px;
    margin-top: 5px;
  }
`;

const columns = [
  {
    label: 'ROLE ID',
    key: 'id',
    width: 120,
  },
  // {
  //   label: 'NAME',
  //   key: 'name',
  // },
  {
    label: 'NAME',
    key: 'display_name',
  },
  {
    label: 'DESCRIPTION',
    key: 'description',
  },
  {
    label: 'GUARD NAME',
    key: 'guard_name',
  },
  {
    label: '',
    key: 'action',
    width: 120,
  },
];

const Container = styled.div`
  padding-right: 20px;
  padding-top: 33px;
  .head {
    .title {
      color: ${(props) => props.theme.secondaryTextColor};
      font-size: 20px;
      font-weight: bold;
    }
    .control {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      .buttons-container {
        display: flex;
        .remove-btn-container {
          margin-right: 20px;
        }
      }
    }
    .create-button {
      .primary {
        text-transform: unset;
        border-radius: 4px;
      }
    }
  }
  .control {
    text-align: end;
    margin-top: 10px;
    margin-right: 20px;
    .primary {
      border-radius: 4px;
    }
    .add-new-btn {
      max-width: 150px;
    }
  }
`;
