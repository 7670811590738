import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Modal } from 'rsuite';
import styled from 'styled-components';
import { useAppMessageContext } from '../../../../../appMessagesContext';
import clearIco from '../../../../../assets/clear.png';
import CustomButton from '../../../../../components/Buttons/CustomButton';
import CustomInput from '../../../../../components/inputs/CustomInput';
import ErrorSVG from '../../../../../components/SvgElements/ErrorSvg';
import { ApplicationTheme } from '../../../../../globalStyles';
import { CSVDuplicate, CSVSaveDuplicatesRequest } from '../../../../../store/settings/actions';

export type CSVDuplicateModalForm = {
 stock: {
   old: string
   new: string
 }[]
}

type CSVDuplicateModalProps = {
    open: boolean
    onClose: () => void
    duplicates: CSVDuplicate | null
}
function CSVDuplicateModal({
  open, onClose, duplicates,
}: CSVDuplicateModalProps) {
  const saveDuplicates = CSVSaveDuplicatesRequest();
  const { showMessage } = useAppMessageContext();
  const handleSubmit = (data: CSVDuplicateModalForm) => {
    if (duplicates?.csv_file.id) {
      saveDuplicates.mutate({ data: { stock_numbers: data.stock }, id: duplicates?.csv_file.id });
    }
  };

  const formik = useFormik<CSVDuplicateModalForm>({
    initialValues: {
      stock: [{ old: '', new: '' }],
    },
    onSubmit: handleSubmit,
  });

  const {
    setFieldValue, values: formikValues, errors, touched,
  } = formik;
  useEffect(() => {
    if (open) {
      const initialValues: CSVDuplicateModalForm['stock'] = (duplicates?.duplicated_stock_numbers || []).map((unit) => ({
        old: unit,
        new: '',
      }));
      setFieldValue('stock', initialValues);
    }
  }, [open]);

  useEffect(() => {
    if (saveDuplicates.isSuccess) {
      showMessage({ type: 'success', message: 'CSV Uploaded successfully' });
      saveDuplicates.reset();
      onClose();
    }
  }, [saveDuplicates.isSuccess]);
  return (
    <Modal
      open={!!open}
      onClose={onClose}
      size="md"
    >
      <StyledModalHeader>
        <button type="button" onClick={onClose}>
          <img src={clearIco} alt="clear" />
        </button>
      </StyledModalHeader>
      <Modal.Body>
        <ModalContainer>
          <div className="error-svg">
            <ErrorSVG fill={ApplicationTheme.secondaryColor} />
          </div>
          <div className="title">
            Error
          </div>
          <div className="info">
            These units already exists, please type a new &ldquo;Stock #&ldquo; to save them
          </div>
          <div className="table">
            <table>
              <thead>
                <tr className="header">
                  <th style={{
                    width: '10%',
                  }}
                  >
                    Row
                  </th>
                  <th style={{
                    width: '30%',
                  }}
                  >
                    Unit Stock #
                  </th>
                  <th style={{
                    width: '60%',
                  }}
                  >
                    New Stock #
                  </th>
                </tr>
              </thead>
              <tbody>
                {duplicates?.duplicated_stock_numbers?.map((stockNumber, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={`${index}_${stockNumber}`}>
                    <td>{index + 2}</td>
                    <td>{stockNumber}</td>
                    <td>
                      <CustomInput
                        value={formikValues.stock[index]?.new || ''}
                        onChange={(value) => {
                          const tempValues = [...formikValues.stock];
                          tempValues[index].new = value;
                          tempValues[index].old = stockNumber;
                          setFieldValue('stock', tempValues);
                        }}
                        error={errors.stock?.[index] && touched?.stock?.[index] ? (errors.stock?.[index] as CSVDuplicateModalForm['stock'])[0].new : saveDuplicates?.error?.response?.data.errors?.[`stock_numbers.${index}.new`]?.[0]}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="button-wrap">
              <CustomButton
                type="button"
                onClick={formik.submitForm}
              >
                Save
              </CustomButton>
            </div>
          </div>
        </ModalContainer>
      </Modal.Body>
    </Modal>
  );
}

export default CSVDuplicateModal;

const StyledModalHeader = styled.div`
display: flex;
justify-content: flex-end;
  button {
    padding: 0;
    margin: 0;
    background: transparent;
  }
  img {
    cursor: pointer;
    transition: all ease 0.2s;
    :hover {
      transform: scale(1.2);
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h3 {
    margin-top: 10px;
  }
  .text {
    margin-top: 11px;
    font-size: 18px;
    color: ${(props) => props.theme.secondaryTextColor};
  }
  .archive-btn {
    width: 121px;
    height: 35px;
    margin-top: 32px;
  }
  img {
    width: 52px;
    height: 52px;
  }
  .error-svg {
    width: 123px;
  }
  .title {
    font-weight: bold;
    font-size: 24px;
    color: ${(props) => props.theme.primaryTextColor};
  }
  .info {
    font-size: 18px;
    color: ${(props) => props.theme.secondaryTextColor};
  }
  .table {
    width: 100%;
    margin-top: 10px;
    text-align: start;
    table {
      width: 100%;
    }
    th {
      text-align: start;
      color: ${(props) => props.theme.secondaryTextColor};
      padding-left: 5px;      
    }
    tr {
      border-bottom: solid thin;
      td {
        height: 48px;
        padding-bottom: 15px;
      }
      :not(.header) {
        td:not(:last-child) {
          padding-left: 5px;
        }
      :not(:nth-child(2n)) {
          background-color: #f3f3f3;
        }
      }
    }
  }
  .button-wrap {
    width: 200px;
    margin: 0 auto;
    margin-top: 20px;
  }
`;
