import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Pages from '../../Router/pages';
import AdminHeader from './AdminHeader';
import AdminSideBar, { AdminSideBarItem } from './AdminSideBar';

function AdminLayout() {
  const sideBarItems: AdminSideBarItem = [
    {
      label: 'Resources',
      subCategory: [
        { label: 'Dealers', link: Pages.dealersPage, key: 'dealers' },
        { label: 'Feeds', link: Pages.feedsPage, key: 'feeds' },
        { label: 'Showrooms', link: Pages.showroomsPage, key: 'showrooms' },
        { label: 'Users', link: Pages.adminUsersPage, key: 'users' },
        { label: 'Roles', link: Pages.rolesPage, key: 'roles' },
      ],
    },
  ];

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  }, []);

  return (
    <StyledLayout>
      <AdminHeader />
      <Content>
        <AdminSideBar
          sideBarItems={sideBarItems}
          defaultCollapsed={['Resources']}
        />
        <div className="outlet-wrap">
          <Outlet />
        </div>
      </Content>
    </StyledLayout>
  );
}

export default AdminLayout;

const Content = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.primaryBGColor};
  .outlet-wrap {
    width: 100%;
    text-align: start;
  }
`;
const StyledLayout = styled.div`
background: #f5f5f5;    
min-height: 100vh;
`;
