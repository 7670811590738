import axios, { AxiosResponse } from 'axios';
import { axiosInstance } from '../../config/axios';
import { ForgotPasswordData } from '../../pages/Login';
import { LoginFormData } from '../../pages/Login/loginForm';
import { RegistrationFormData } from '../../pages/Registration/registrationForm';
import { ResetPasswordFormData } from '../../pages/ResetPassword/resetPasswordForm';
import { MeType } from './types';

export const AuthRequests = {
  async Registration(data: RegistrationFormData) {
    return axiosInstance.post('/users', {
      ...data,
      password_confirmation: data.passwordConfirmation,
    });
  },
  async Login(data: LoginFormData) {
    return axiosInstance.post('/login', data);
  },
  async ResetPassword(data: ResetPasswordFormData) {
    return axiosInstance.post('/resetPassword', data);
  },
  async ForgotPassword(data: ForgotPasswordData) {
    return axiosInstance.post('/forgotPassword', data);
  },
  async AdminLogin(data: LoginFormData) {
    return axiosInstance.post('/admin/login', data);
  },
  async Logout() {
    return axiosInstance.post('/logout');
  },
  async Me(): Promise<AxiosResponse<MeType>> {
    return axiosInstance.get('/me');
  },
  async Notifications() {
    return axiosInstance.get('/notifications');
  },
  async NotificationsDelete(ids: {ids: string[]}) {
    return axiosInstance.delete('/notifications', { data: ids });
  },
  async MarkNotificationsRead(ids: {ids: string[]}) {
    return axiosInstance.post('/notifications/markAsRead', ids);
  },
  async MfaCode(id: number, url: string) {
    return axiosInstance.get(url + id);
  },
};
