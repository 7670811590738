import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Dropdown, Popover, Whisper } from 'rsuite';
import Drawer from 'rsuite/Drawer';
import { OverlayTriggerProps } from 'rsuite/esm/Overlay/OverlayTrigger';
import styled from 'styled-components';
import NoticeIcon from '@rsuite/icons/Notice';
import { useQuery } from '@tanstack/react-query';
import { queryClient } from '../../../App';
import logoIco from '../../../assets/Logo.png';
import BillingSvg from '../../../components/SvgElements/BillingSvg';
import InventorySvg from '../../../components/SvgElements/InventorySvg';
import LogoutSvg from '../../../components/SvgElements/LogoutSvg';
import SchedulerSvg from '../../../components/SvgElements/SchedulerSvg';
import SettingsSvg from '../../../components/SvgElements/SettingsSvg';
import { ApplicationTheme } from '../../../globalStyles';
import useResize from '../../../hooks';
import { useGetSelectedDealer } from '../../../hooks/getDealersHook';
import useNavigateHook from '../../../hooks/useNavigateHook';
import Pages from '../../../Router/pages';
import {
  deleteNotificationsRequest,
  getMeRequest,
  getNotificationsRequest,
  LogoutRequest,
  markNotificationsReadRequest,
  NotificationT,
} from '../../../store/auth/actions';
import {
  BillingRoles, SchedulerRoles, SettingRoles, SupperAdminRoles,
} from '../../../store/constants';
import { RequestKeys } from '../../../store/keys';
import { PendingUnitRequest } from '../../../store/types';
import { getUnit } from '../../../store/unit/actions';
import { formDate, roleHasAccess } from '../../../helpers';
import BinSvg from '../../../components/SvgElements/BinSvg';

export type CombinedNotification = {
  updated_at: string
} & (NotificationT | PendingUnitRequest)

function MainLayoutHeader({ title }: {title: string}) {
  const logoutRequest = LogoutRequest();
  const params = useParams<{id: string}>();
  const selectedDealer = useGetSelectedDealer();
  const navigate = useNavigateHook();
  const { isMobile } = useResize();
  const [openSidebar, setOpenSidebar] = useState<boolean>(false);
  const displayStock = (title === 'Edit Unit' && isMobile);
  const { responseData: unit } = getUnit({ id: params.id || '', dlRequest: true }, !displayStock);
  const handleLogOut = () => {
    logoutRequest.mutate();
  };
  const user = getMeRequest()?.data?.data;
  const { respData: notificationsList, refetch: refetchNotifications } = getNotificationsRequest();
  const deleteNotificationsReq = deleteNotificationsRequest();
  const readNotifications = markNotificationsReadRequest();
  const location = window.location?.href;
  type NavigationItem = {
    label: string
    icon: JSX.Element
    active: boolean
    hide?: boolean
    to: string
    disabled: boolean
  }
  useEffect(() => {
    if (readNotifications?.isSuccess) {
      refetchNotifications();
      readNotifications.reset();
    }
  }, [readNotifications.isSuccess]);
  const handleNotificationStatus = () => {
    const unreadedIDs = notificationsList?.filter((notification) => !notification?.read_at)?.map((notic) => notic?.id);
    if (unreadedIDs?.length) {
      readNotifications.mutate({ ids: unreadedIDs });
    }
    queryClient.setQueryData<PendingUnitRequest[]>(
      [RequestKeys.PENDING_REQUESTS],
      (oldData) => {
        const tempArr = [...(oldData || [])];

        return tempArr?.map((request) => ({
          ...request,
          readed: true,
        }));
      },
    );
  };
  const requestsList = useQuery<readonly PendingUnitRequest[]>({
    queryKey: [RequestKeys.PENDING_REQUESTS],
  })?.data;
  const unreadedNotification = !!requestsList?.filter((req) => !req?.readed)?.length
      || notificationsList?.filter((notic) => !notic?.read_at)?.length || '';

  const combinedNotifications: CombinedNotification[] = useMemo(() => {
    const mergedNotifications: CombinedNotification[] = [...(requestsList || []), ...(notificationsList || [])];
    return mergedNotifications?.sort((a: CombinedNotification, b: CombinedNotification) => (a.updated_at < b.updated_at ? 1 : -1));
  }, [requestsList, notificationsList]);
  useEffect(() => {
    const pendingRequests = requestsList?.filter((req) => req?.status === 'PENDING');
    const fireMessage = (ev: BeforeUnloadEvent) => {
      ev.preventDefault();
      // eslint-disable-next-line no-param-reassign,no-return-assign
      return ev.returnValue = 'Are you sure you want to close?';
    };
    if (pendingRequests?.length) {
      window.addEventListener('beforeunload', fireMessage);
    } else {
      window.removeEventListener('beforeunload', fireMessage);
    }
    return () => {
      window.removeEventListener('beforeunload', fireMessage);
    };
  }, [requestsList]);
  const navigationItems: NavigationItem[] = [
    {
      label: 'INVENTORY',
      icon: <InventorySvg />,
      active: location.includes(Pages.inventoryManager) || location.includes('unit'),
      to: Pages.inventoryManager,
      disabled: false,
    },
    {
      label: 'SCHEDULER',
      icon: <SchedulerSvg />,
      active: location.includes('scheduler'),
      to: Pages.SchedulerSettingPure,
      // hide: !user?.role.some((role) => SchedulerRoles.includes(role)),
      disabled: !roleHasAccess(selectedDealer?.role || user?.role, SchedulerRoles),
    },
    {
      label: 'BILLING', icon: <BillingSvg />, active: location.includes('billing'), to: Pages.billingAddFunds, hide: !roleHasAccess(user?.role, BillingRoles), disabled: false,
    },
    {
      label: 'SETTINGS', icon: <SettingsSvg />, active: location.includes('settings'), to: Pages.LocationSetting, hide: !roleHasAccess(user?.role, SettingRoles), disabled: false,
    },
  ];
  const handleNotificationClick = (request: PendingUnitRequest) => {
    if (!request?.isNewUnit) {
      navigate(`${Pages.editUnitPure}/${request?.unitID}`);
    } else {
      navigate(`${Pages.addUnitPure}/${request?.unitID}`);
    }
  };

  useEffect(() => {
    if (deleteNotificationsReq?.isSuccess) {
      refetchNotifications();
      deleteNotificationsReq.reset();
    }
  }, [deleteNotificationsReq?.isSuccess]);
  const handleDeleteNotification = (id: string) => {
    deleteNotificationsReq.mutate({ ids: [id] });
  };
  const handleDeleteLocalNotification = (id: string | number | undefined) => {
    queryClient.setQueryData<PendingUnitRequest[]>(
      [RequestKeys.PENDING_REQUESTS],
      (oldData) => oldData?.filter((request) => request.unitID !== id),
    );
  };
  const renderNotificationMenu: OverlayTriggerProps['speaker'] = ({
    left, top, className,
  }, ref) => {
    const formMessage = (request: PendingUnitRequest) => {
      if (request.isNewUnit) {
        switch (request.status) {
          case 'SUCCESS':
            return `Unit #${request.unitID} created successfully, images/files are being processed, will be available shortly`;
          case 'ERROR':
            return 'Unit creation has failed';
          default:
            return 'Unit is creating';
        }
      } else {
        switch (request.status) {
          case 'SUCCESS':
            return `Unit #${request.unitID} updated successfully, images/files are being processed, will be available shortly.`;
          case 'ERROR':
            return `Unit #${request.unitID} update failed`;
          default:
            return `Unit #${request.unitID} is updating`;
        }
      }
    };

    return (
      <StyledPopover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu>
          {combinedNotifications?.map((notic: CombinedNotification) => {
            if ('isLocalNotification' in notic) {
              return (
                <Dropdown.Item key={notic.unitID + notic.status} onClick={() => handleNotificationClick(notic)}>
                  <StyledNotification className={notic.status}>
                    <div className="notification-message">
                      {formMessage(notic)}
                      <div className="time-wrap">
                        <span>
                          {formDate(notic?.updated_at)?.date}
                        </span>
                        <span>
                          {formDate(notic?.updated_at)?.time.trim()}
                        </span>
                      </div>
                    </div>
                    <span
                      className="bin-svg"
                      onClick={(event) => {
                        event?.stopPropagation();
                        handleDeleteLocalNotification(notic?.unitID);
                      }}
                    >
                      <BinSvg />
                    </span>
                  </StyledNotification>
                </Dropdown.Item>
              );
            }
            return (
              <Dropdown.Item key={notic?.id}>
                <StyledNotification className="INFO">
                  <div className="notification-message">
                    CSV
                    {' '}
                    {notic?.message}
                    <div className="time-wrap">
                      <span>
                        {formDate(notic?.updated_at)?.date}
                      </span>
                      <span>
                        {formDate(notic?.updated_at)?.time.trim()}
                      </span>
                    </div>
                  </div>
                  <span className="bin-svg" onClick={() => handleDeleteNotification(notic?.id)}>
                    <BinSvg />
                  </span>
                </StyledNotification>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </StyledPopover>
    );
  };
  return (
    <StyledHeader>
      <div
        className="menu-group"
        onClick={() => {
          setOpenSidebar(true);
        }}
      >
        <img src={logoIco} alt="logo" className="logo" />
        {' '}
        <div className="label">
          MENU
        </div>
      </div>
      <Drawer
        open={openSidebar}
        onClose={() => setOpenSidebar(false)}
        size="xs"
        placement="left"
        className="navigation"
      >
        <DrawerContainer>
          <div>
            <div className="title">
              {(roleHasAccess(user?.role, SupperAdminRoles) && !selectedDealer?.code) ? 'TF admin' : selectedDealer?.dealership_name || user?.dealership_name}
            </div>
            <div className="email">
              {selectedDealer?.primary_email || user?.email}
            </div>
          </div>
          <div className="navigation-section">
            {!isMobile
                && (
                <ul>
                  {navigationItems.map((item) => (
                    <Link
                      to={item.to}
                      key={item.label}
                      style={{
                        display: item.hide ? 'none' : 'unset',
                      }}
                      onClick={(event) => {
                        if (item?.disabled) {
                          event?.preventDefault();
                        }
                      }}
                    >
                      <div
                        className={`navigation-item ${item.active && 'active'} ${item.disabled && 'disabled'}`}
                      >
                        <div className="svg-wrap">
                          {item.icon}
                        </div>
                        {item.label}
                      </div>
                    </Link>
                  ))}
                </ul>
                )}
            <div>
              <div
                className="navigation-item"
                onClick={handleLogOut}
              >
                <div className="svg-wrap">
                  <LogoutSvg />
                </div>
                Logout
              </div>
              {(user?.code || selectedDealer?.code)
              && (
              <div className="dealer-id">
                Your dealership ID:
                <span className="code">
                  {user?.code || selectedDealer?.code}
                </span>
              </div>
              )}
            </div>
          </div>
        </DrawerContainer>
      </Drawer>
      <div className="title">
        {displayStock
          ? (
            <span className="stock-header">
              STOCK:
              {unit?.stock}
            </span>
          )
          : (
            <span className="main-header">
              {title}
            </span>
          )}
      </div>
      <StyledNotificationIco>
        <Whisper placement="autoHorizontalStart" trigger="click" speaker={renderNotificationMenu} onOpen={handleNotificationStatus}>
          <div>
            {unreadedNotification
            && <div className="notification-dot" />}
            <NoticeIcon color={ApplicationTheme.primaryColor} width={25} height={25} />
          </div>
        </Whisper>
      </StyledNotificationIco>
    </StyledHeader>
  );
}
export default MainLayoutHeader;

const StyledPopover = styled(Popover)`
  width: 270px;
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  .rs-dropdown-menu {
    padding: 0;
  }
  .rs-dropdown-item {
    padding: 0;
    margin-bottom: 4px;
  }
`;

const StyledNotificationIco = styled.div`
  position: relative;
.notification-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: 17px;
  right: 0;
  border-radius: 50%;
  background-color: ${(props) => props.theme.primaryColor};;
  border: 1px solid #fff ;
}
`;

const StyledNotification = styled.div`
  padding-left: 10px;
  margin-top: 4px;
  position: relative;
  .notification-message {
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: pre-wrap;
    display: flex;
    align-items: center;
    .time-wrap {
      min-width: 66px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-left: 1px solid #606060;
      padding-left: 4px;
      padding-right: 4px;
      margin-left: 2px;
    }
  }
  .bin-svg {
    position: absolute;
    top: 10px;
    right: 0;
    display: none;
    svg {
      width: 20px;
      height: 20px;  
    }
  }
  &:hover {
    .bin-svg {
      display: block;
    }
  }
  &.PENDING {
    background-color: #ffd783;
    border: 1px solid #ffa834;
    border-left: 3px solid #ffa834;
  } 
  &.ERROR {
    background-color: #ff8a84;
    border: 1px solid #ff3823;
    border-left: 3px solid #ff3823;
  } 
  &.SUCCESS {
    background-color: #cbe8ba;
    border: 1px solid #92d36e;
    border-left: 3px solid #629c44;
  }
  &.INFO {
    background-color: #cddbff;
    border: 1px solid #031e66;
    border-left: 3px solid #1257fc;
  }
`;

const DrawerContainer = styled(Drawer.Body)`
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
  .title {
    color: #232323;
    font-size: 18px;
    margin-top: 76px;
    font-weight: bold;
  }
  .email {
    color: #7a7a7a;
    font-size: 14px;
  }
  .navigation-section {
    width: 100%;
    margin-top: 52px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    a {
      text-decoration: none;
    }
    ul {
      list-style: none;
      padding-left: 0;
    }
  }
  .dealer-id {
    margin-left: 10px;
    color: #7a7a7a;
    font-size: 14px;
    .code {
      font-weight: bold;
    }
  }
  .dealer-id, .navigation-item {
    padding-left: 15%;
  }
  .navigation-item {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    color: #7a7a7a;
    text-transform: uppercase;
    &.disabled {
      cursor: not-allowed;
      color: #adadad;
      &:hover {
        color: #7a7a7a;
        svg {
          fill: ${(props) => props.theme.bordersColor}!important;
        }  
      }
    }
    .svg-wrap {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background-color: transparent;
      svg {
        width: 20px;
        height: 20px;
        fill: #c0c0c0;
      }
    }
    transition: all ease 0.2s;
    &:hover {
      color: #070707;
      .svg-wrap {
        svg {
          fill: ${(props) => props.theme.sidebarItemHover};
        }
      }
    }
    &.active {
      color: #070707;
      background-color: #fff;
      .svg-wrap {
        background-color: ${(props) => props.theme.sidebarItemBg};
        svg {
          fill: ${(props) => props.theme.sidebarItemHover};
        }
      }
    }
  }

`;

const StyledHeader = styled.header`
  position: sticky;
  overflow-anchor: none;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 40px 0 20px;
  background-color: ${(props) => props.theme.primaryTextColor};
  height: 82px;
  font-size: 24px;
  font-weight: bold;
  .menu-group {
    display: flex;
    align-items: center;
    .label {
      margin-left: 18px;
      margin-top: 2px;
      color: #fff;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  .logo {
    cursor: pointer;
    height: 44px;
    aspect-ratio: 1/0;
  }
   .title {
    margin: 0 auto;
    text-transform: uppercase;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
     .main-header {
       margin-left: -86px;
       letter-spacing: 1.1px;   
     }
   }
  .profile-wrapper {
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    .location {
      &.hidden {
        display: none;        
      }
    }
  }
  .profile-ico {
    width: 45px;
    height: 45px;
  }
  @media screen and (max-width: 768px) {
    height: 46px;
    padding: 8px;
    .title {
      font-size: 14px;
      font-weight: bold;
    }
    .profile-ico {
      width: 30px;
      height: 30px;
    }
    .logo {
      width: 34px;
      height: 21px;
      aspect-ratio: 1/0;
    }
    .profile-wrapper {
      .location {
        display: none;
      }
    }
  }
`;
