import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppMessageContext } from '../../appMessagesContext';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import {
  CreateRole, GetAvailablePermissions, GetPermissionsByRole, GetRoleById, UpdateRole,
} from '../../store/dealers/actions';
import RoleForm, { RoleFormData } from './RoleForm';

function RolePage() {
  const params = useParams<{id?: string}>();
  const navigate = useNavigateHook();
  const { showMessage } = useAppMessageContext();
  const { data: roleData, isFetching: roleIsFetching } = GetRoleById(Number(params.id));
  const { data: permissions } = GetAvailablePermissions();
  const { data: rolePermissions, isFetching: rolePermissionsFetching } = GetPermissionsByRole(Number(params.id));
  const activeRoleIds = rolePermissions.map((role) => role.id);

  const createRoleReq = CreateRole();
  const updateRoleReq = UpdateRole();

  const handleFormSubmit = (data: RoleFormData) => {
    if (Number(params.id)) {
      updateRoleReq.mutate({ data, id: Number(params.id) });
    } else {
      createRoleReq.mutate({ data });
    }
  };
  useEffect(() => {
    if (createRoleReq.isSuccess) {
      showMessage({ type: 'success', message: 'Role saved successfully' });
      createRoleReq.reset();
      navigate(Pages.rolesPage);
    }
    if (updateRoleReq.isSuccess) {
      showMessage({ type: 'success', message: 'Role updated successfully' });
      updateRoleReq.reset();
      navigate(Pages.rolesPage);
    }
    if (createRoleReq.isError || updateRoleReq.isError) {
      showMessage({ type: 'error', message: createRoleReq.error?.response?.data?.message || updateRoleReq.error?.response?.data?.message || '' });
      createRoleReq.reset();
      updateRoleReq.reset();
    }
  }, [createRoleReq.isSuccess, updateRoleReq.isSuccess, createRoleReq.isError, updateRoleReq.isError]);
  const errorsFromResponse = createRoleReq.error?.response?.data.errors || updateRoleReq.error?.response?.data.errors;

  if (roleIsFetching || rolePermissionsFetching) {
    return null;
  }
  return (
    <Container>
      <div className="header">
        <h4>
          Role Details
        </h4>
      </div>
      <RoleForm roleData={roleData} permissions={permissions} activeRoleIds={activeRoleIds} onSubmit={handleFormSubmit} errorsProps={errorsFromResponse} />
    </Container>
  );
}
export default RolePage;

const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`;
