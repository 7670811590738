import { useReducer, useState } from 'react';
import styled from 'styled-components';
import CustomTable from '../../../components/CustomTable';
import SettingsLayout from '../../../components/SettingsLayout';
import { useGetSelectedDealer } from '../../../hooks/getDealersHook';
import useNavigateHook from '../../../hooks/useNavigateHook';
import Pages from '../../../Router/pages';
import { getMeRequest } from '../../../store/auth/actions';
import { BillingTransactionsData, getBillingTransactionsRequest } from '../../../store/settings/actions';
import { billingSections, BillingSideBar } from '../AddFund';
import { ItemsPaginationParams } from '../../../store/unit/actions';

function BillingHistory() {
  const navigate = useNavigateHook();
  const [activeSection, setActiveSection] = useState<typeof billingSections[number]['key']>(billingSections.find((item) => window.location.pathname.includes(item.key))?.key || 'history');
  const user = getMeRequest()?.data?.data;
  const selectedDealer = useGetSelectedDealer();
  const [params, setParams] = useReducer(
    (state: ItemsPaginationParams, newState: Partial<ItemsPaginationParams>) => ({
      ...state,
      ...newState,
    }),
    {
      page: 1,
      ...(selectedDealer?.id ? { dealer_id: [selectedDealer?.id] } : { dealer_id: undefined }),
    },
  );
  const { responseData: transactionsData, data: transactionsMetaData, isLoading } = getBillingTransactionsRequest(
    {
      page: params?.page,
      dealer_id: (user?.dealer_id ? String(user?.dealer_id) : undefined) || (selectedDealer?.id ? String(selectedDealer?.id) : undefined),
    },
  );
  const handleSidebarItemClick = (item: BillingSideBar) => {
    navigate(`${Pages.billing}/${item.key}`);
    setActiveSection(item.key);
  };
  const handlePageChange = (newPage: number) => {
    setParams({
      page: newPage,
    });
  };
  const defaultSideBarItems = billingSections.map((section) => ({
    label: section.label, onClick: handleSidebarItemClick, key: section.key, page: Pages.billing,
  }));
  return (
    <SettingsLayout navigateOnBack={Pages.inventoryManager} sideBarItems={defaultSideBarItems} headerTitle="Billing" activeSectionProp={activeSection}>
      <ContentContainer>
        <div className="history-header">Billing</div>
        <div className="main-content">
          <CustomTable
            data={transactionsData.map((dataRow: BillingTransactionsData) => ({
              ...dataRow,
              amount: <Price amount={dataRow.amount} />,
              remain_balance: <Price amount={dataRow.remaining_balance} />,
              status: <StatusCell status={dataRow.status} />,
              date: <DatedCell createdAt={dataRow.created_at} />,
            }))}
            loading={isLoading}
            page={transactionsMetaData?.data?.current_page}
            totalPages={transactionsMetaData?.data?.last_page}
            totalItems={transactionsMetaData?.data?.total}
            columns={columns}
            onPageChange={handlePageChange}
            hideSelectHeader
            displayPagination
            displayTotal={false}
            fillHeight
            selectable={false}
          />
        </div>
      </ContentContainer>
    </SettingsLayout>
  );
}

export default BillingHistory;
function DatedCell({ createdAt }: {createdAt: BillingTransactionsData['created_at']}) {
  const today = new Date(createdAt);
  const formatedDate = today.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
  return (
    <div>
      {formatedDate}
    </div>
  );
}

function Price({ amount }: {amount: BillingTransactionsData['amount'] | BillingTransactionsData['remaining_balance']}) {
  const calculatedBalance = Number(amount) ? Number(amount) / 100 : '';
  return (
    <div>
      {calculatedBalance && '$'}
      {calculatedBalance}
    </div>
  );
}
function StatusCell({ status }: {status: BillingTransactionsData['status']}) {
  return (
    <StyledStatusCell className={status}>
      {status}
    </StyledStatusCell>
  );
}

const StyledStatusCell = styled.div`
    border-radius: 8px;
    min-width: 80px;
    font-size: 14px;
    padding: 3px;
    padding-left: 8px;
    padding-right: 8px;
    &.Success {
      background-color: #d4f8be;
      color: #48c200;
    }
    &.Debit {
      background-color: #c9f1fd;
      color: #3d8af7;
    }
`;

const ContentContainer = styled.div`
  color: #232323;
  padding: 20px;
  background-color: #ffffff;
  text-align: start;
  margin-right: 20px;
  padding-left: 40px;
  .pagination-wrapper {
    background-color: #ffffff;
  }
  .history-header {
    font-size: 24px;
    border-bottom: 1px solid ${(props) => props.theme.secondaryColor};
    padding-bottom: 10px;
  }
  .main-content {
    margin-top: 30px;
    //max-width: 1000px;
    #table-wrapper {
      margin: 0;
      .rs-table-cell-content {
        font-weight: normal;
      }
    }  
  }
  .rs-table-body-row-wrapper {
    max-height: 70vh;
  }
`;

const columns = [
  {
    label: 'AMOUNT',
    key: 'amount',
    width: 80,
  },
  {
    label: '',
    key: 'status',
    width: 120,
  },
  {
    label: 'DESCRIPTION',
    key: 'description',
  },
  {
    label: 'DEBIT REMAINING BALANCE',
    key: 'remain_balance',
    width: 220,
  },
  {
    label: 'DATE',
    key: 'date',
    width: 180,
  },
];
