import { axiosInstance } from '../../config/axios';
import { AdminUsersFromData } from '../../pages/AdminUserCreation/AdminUserCreationForm';
import { ProviderFromData } from '../../pages/ProviderCreation/ProviderCreationForm';
import { CreateUpdateDealerRequestData, GetDealersParams, RoleCreationRequest } from './actions';

export const DealerRequests = {
  async CreateDealer(data: CreateUpdateDealerRequestData) {
    return axiosInstance.post('/dealers', data);
  },
  async UpdateDealer(data: CreateUpdateDealerRequestData, id: string) {
    return axiosInstance.put(`dealers/${id}`, data);
  },
  async GetDealers(params: GetDealersParams) {
    return axiosInstance.get('/dealers', {
      params,
    });
  },
  async GetDealerById(id: string | number) {
    return axiosInstance.get(`/dealers/${id}`);
  },
  async GetDealer(id: string) {
    return axiosInstance.get(`dealers/${id}`);
  },
  async DeleteDealer(id: string) {
    return axiosInstance.delete(`/dealers/${id}`);
  },
  async DeleteDealers(ids: number[]) {
    return axiosInstance.delete('/dealers/bulk', { data: { ids } });
  },
  async feedUpdate(id: number) {
    return axiosInstance.get(`/dealers/${id}/feedUpdate`);
  },
  async GetAdminUsers() {
    return axiosInstance.get('/admin/users');
  },
  async GetAdminUser(id: string) {
    return axiosInstance.get(`/admin/users/${id}`);
  },
  async CreateAdminUser(data: AdminUsersFromData) {
    return axiosInstance.post('/admin/users', data);
  },
  async UpdateAdminUser(data: Partial<AdminUsersFromData>, id: string | number) {
    return axiosInstance.put(`/admin/users/${id}`, data);
  },
  async DeleteAdminUsers(ids: number[]) {
    return axiosInstance.delete('/admin/users/bulk', { data: { ids } });
  },
  async GetAvailablePermissions() {
    return axiosInstance.get('/roles/availablePermissions');
  },
  async GetRoles() {
    return axiosInstance.get('/roles');
  },
  async GetRoleById(id?: string | number) {
    return axiosInstance.get(`/roles/${id}`);
  },
  async GetPermissionsByRole(id?: string | number) {
    return axiosInstance.get(`/roles/${id}/permissions`);
  },
  async CreateRole(data: RoleCreationRequest) {
    return axiosInstance.post('/roles', data);
  },
  async UpdateRole(data: RoleCreationRequest, id: string | number) {
    return axiosInstance.put(`/roles/${id}`, data);
  },
  async DeleteRole(id: number) {
    return axiosInstance.delete(`/roles/${id}`);
  },
  async GetProviders(params?: {dealer_id?: string, limit?: number}) {
    return axiosInstance.get('/admin/providers', { params });
  },
  async GetProvider(id: string) {
    return axiosInstance.get(`/admin/providers/${id}`);
  },
  async CreateProvider(data: ProviderFromData) {
    return axiosInstance.post('/admin/providers', data);
  },
  async UpdateProvider(data: ProviderFromData, id: string) {
    return axiosInstance.put(`/admin/providers/${id}`, data);
  },
  async DeleteProvider(id: number) {
    return axiosInstance.delete(`admin/providers/${id}`);
  },
};
