import { ApplicationTheme } from '../../../globalStyles';

interface SVGProps {
    fill?: string
    stroke?: string
}

function PrintSvg(props: SVGProps): JSX.Element {
  const { fill, stroke } = props;
  return (
    <svg
      fill={fill || ApplicationTheme.secondaryColor}
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      viewBox="0 0 40 40"
      width="100%"
      preserveAspectRatio="none"
    >
      <path stroke={stroke} d="M6 9L6 3C6 1.9 6.89 1 8 1L23 1C24.11 1 25 1.89 25 3L25 9 6 9 6 9ZM5 25L3.99 25C2.34 25 1 23.66 1 22L1 13C1 11.34 2.34 10 3.99 10L27.01 10C28.66 10 30 11.34 30 13L30 22C30 23.66 28.66 25 27.01 25L26 25 26 20 5 20 5 25 5 25 5 25ZM6 21L6 29C6 30.1 6.89 31 8 31L23 31C24.11 31 25 30.11 25 29L25 21 6 21 6 21ZM24 16C24.55 16 25 15.55 25 15 25 14.45 24.55 14 24 14 23.45 14 23 14.45 23 15 23 15.55 23.45 16 24 16L24 16ZM8 24L8 25 23 25 23 24 8 24 8 24ZM8 27L8 28 23 28 23 27 8 27 8 27Z" />
    </svg>
  );
}

export default PrintSvg;
