import React, { useMemo } from 'react';
import styled from 'styled-components';
import CustomSelect, { SelectValue } from '../../../../components/inputs/CustomSelect';
import CustomInput from '../../../../components/inputs/CustomInput';
import { convertInFtToTotalIn, convertInToInFt, formSelectOptions } from '../../../../helpers';
import {
  CreateUnitReqError, ItemAttribute, ItemAttributesResponse, ItemSpecificAttribute, ItemType,
} from '../../../../store/unit/actions';
import { DetailsFormInputKey, DetailsFormInputs } from '../Details';
import { AttributeFormInputs } from './types';

export type AttributesInputs = AttributeFormInputs & {
    overallLengthTotal: string
    overallLengthIn: string
    overallLengthFt: string
    minWidthTotal: string
    minWidthIn: string
    minWidthFt: string
    minLengthTotal: string
    minLengthIn: string
    minLengthFt: string
    sideWallHeightTotal: string
    sideWallHeightIn: string
    sideWallHeightFt: string

    minHeightTotal: string
    minHeightIn: string
    minHeightFt: string
}

interface AttributesProps {
    initialValues: AttributesInputs
    onInputChange: (key: string, value: string | SelectValue | number) => void
    inventoryType: ItemType | null
    itemAttributes: ItemAttributesResponse[]
    specificAttributes?: Record<string, ItemSpecificAttribute[]>
    requestErrors?: CreateUnitReqError
    detailsInitialValues: DetailsFormInputs
    onDetailInputChange: (key: DetailsFormInputKey, value: string | SelectValue | number) => void
}

function Attributes({
  initialValues, onInputChange, inventoryType, itemAttributes, specificAttributes, requestErrors, detailsInitialValues, onDetailInputChange,
}: AttributesProps) {
  const handleChange = (value: SelectValue | string, controlInput: string) => {
    onInputChange(controlInput, value);
  };

  const boardedInputs = [
    'overall_length',
    'min_width',
    'min_length',
    'min_height',
    'side_wall_height',
  ];

  const attributeInputs = useMemo(() => {
    const rentalInputs = ['monthly_rate', 'weekly_rate', 'daily_rate', 'is_rental'];
    let attributesByInventory = [];
    attributesByInventory = itemAttributes.find((attribute) => attribute.name === inventoryType?.name)?.attributes
      .filter((attributeInput) => !boardedInputs.includes(attributeInput.name))
        || [];
    attributesByInventory = attributesByInventory.filter((input) => !rentalInputs.includes(input.name));
    return (inventoryType?.name ? attributesByInventory : []);
  }, [inventoryType, itemAttributes, initialValues.is_rental]);

  const renderInput = (input: ItemAttribute) => {
    const type = Array.isArray(input?.options) ? 'select' : 'input';
    const numericInputs = ['Mileage', 'Black Water Capacity', 'Fresh Water Capacity', 'Gray Water Capacity', 'monthly_rate', 'weekly_rate', 'daily_rate'];
    switch (type) {
      case 'select':
        return (
          <CustomSelect
            label={input.alias}
            className="select-input"
            required={false}
            options={formSelectOptions([...(input?.options || [])])}
            value={initialValues[input.name] as SelectValue}
            onChange={(value: SelectValue) => handleChange(value, input.name)}
            placeholder={' '}
            isClearable
            error={requestErrors?.[(`attributes.${input.name}`)]?.[0]}
          />
        );
      default:
        return (
          <CustomInput
            label={input.alias}
            wrapperClass="input-wrapper"
            value={initialValues[input.name] as string || ''}
            onChange={(value: string) => handleChange(value, input.name)}
            {...(numericInputs.includes(input.name) ? { pattern: 'decinumeric' } : null)}
            error={requestErrors?.[(`attributes.${input.name}`)]?.[0]}
          />
        );
    }
  };

  const setCalculatedCapacity = ({ gvwr, weight }: {gvwr: string, weight: string}) => {
    const calculatedCapacity = Number(gvwr) - Number(weight);
    if (calculatedCapacity > 0) {
      onDetailInputChange('payloadCapacity', calculatedCapacity);
    } else {
      onDetailInputChange('payloadCapacity', '');
    }
  };

  const displaySideWallHeight = inventoryType?.name === 'Trailer or Truck Bed';
  return (
    <AttributesSectionContainer>
      <div className={`attributes-section-content ${!attributeInputs.length && 'empty'}`}>
        <div className="left-row">
          {attributeInputs.map((input: ItemAttribute, index: number) => {
            if (index <= 8 || index % 2) {
              return (
                <React.Fragment key={input.id}>
                  {renderInput(input)}
                </React.Fragment>
              );
            }
            return null;
          })}
          <div className="specific-attributes-section ">
            {Object.entries(specificAttributes || {}).map(([vehicleType, attributes]) => (
              <div key={vehicleType}>
                {attributes.map((attributeGroup) => Object.entries(attributeGroup).map(([groupName, groupAttributes]) => (
                  <div key={`${vehicleType}-${groupName}`}>
                    <div className="section-title">{groupName}</div>
                    {groupAttributes.map((attribute: {
                                      id: number,
                                      name: string,
                                      alias: string,
                                      options: string[] | null
                                  }) => (
                                    <React.Fragment key={attribute.name}>
                                      {renderInput(attribute)}
                                    </React.Fragment>
                    ))}
                  </div>
                )))}
              </div>
            ))}
          </div>
        </div>
        <div className="right-row">
          <div className="dimensions-section">
            <div />
            <div className="col">Inches</div>
            <div />
            <div className="col">Feet</div>
            <div className="col">Inches</div>
            <div className="row">Length</div>
            <CustomInput
              label=""
              wrapperClass="input-wrapper"
              value={initialValues.overallLengthTotal as string}
              onChange={(value) => {
                const convertedIn = convertInToInFt(+value);
                onInputChange('overallLengthTotal', value);
                onInputChange('overallLengthIn', convertedIn.inches || '');
                onInputChange('overallLengthFt', convertedIn.feet || '');
              }}
              pattern="decinumeric"
            />
            <div className="or">OR</div>
            <CustomInput
              label=""
              wrapperClass="input-wrapper ft"
              value={initialValues.overallLengthFt as string}
              onChange={(value) => {
                onInputChange('overallLengthTotal', convertInFtToTotalIn(+initialValues.overallLengthIn, +value) || '');
                onInputChange('overallLengthFt', value);
              }}
              pattern="decinumeric"
            />
            <CustomInput
              label=""
              wrapperClass="input-wrapper in"
              value={initialValues.overallLengthIn as string}
              onChange={(value) => {
                onInputChange('overallLengthTotal', convertInFtToTotalIn(+value, +initialValues.overallLengthFt) || '');
                onInputChange('overallLengthIn', value);
              }}
              pattern="decinumeric"
            />
            <div className="row">Width</div>
            <CustomInput
              label=""
              wrapperClass="input-wrapper"
              value={initialValues.minWidthTotal as string}
              onChange={(value) => {
                const convertedIn = convertInToInFt(+value);
                onInputChange('minWidthTotal', value);
                onInputChange('minWidthIn', convertedIn.inches || '');
                onInputChange('minWidthFt', convertedIn.feet || '');
              }}
              pattern="decinumeric"
            />
            <div className="or">OR</div>
            <CustomInput
              label=""
              wrapperClass="input-wrapper ft"
              value={initialValues.minWidthFt as string}
              onChange={(value) => {
                onInputChange('minWidthTotal', convertInFtToTotalIn(+initialValues.minWidthIn, +value) || '');
                onInputChange('minWidthFt', value);
              }}
              pattern="decinumeric"
            />
            <CustomInput
              label=""
              wrapperClass="input-wrapper in"
              value={initialValues.minWidthIn as string}
              onChange={(value) => {
                onInputChange('minWidthTotal', convertInFtToTotalIn(+value, +initialValues.minWidthFt) || '');
                onInputChange('minWidthIn', value);
              }}
              pattern="decinumeric"
            />
            {inventoryType?.name !== 'Trailer or Truck Bed'
                      && (
                      <>
                        <div className="row">Min Length</div>
                        <CustomInput
                          label=""
                          wrapperClass="input-wrapper"
                          value={initialValues.minLengthTotal as string}
                          onChange={(value) => {
                            const convertedIn = convertInToInFt(+value);
                            onInputChange('minLengthTotal', value);
                            onInputChange('minLengthIn', convertedIn.inches || '');
                            onInputChange('minLengthFt', convertedIn.feet || '');
                          }}
                          pattern="decinumeric"
                        />
                        <div className="or">OR</div>
                        <CustomInput
                          label=""
                          wrapperClass="input-wrapper ft"
                          value={initialValues.minLengthFt as string}
                          onChange={(value) => {
                            onInputChange('minLengthTotal', convertInFtToTotalIn(+initialValues.minLengthIn, +value) || '');
                            onInputChange('minLengthFt', value);
                          }}
                          pattern="decinumeric"
                        />
                        <CustomInput
                          label=""
                          wrapperClass="input-wrapper in"
                          value={initialValues.minLengthIn as string}
                          onChange={(value) => {
                            onInputChange('minLengthTotal', convertInFtToTotalIn(+value, +initialValues.minLengthFt) || '');
                            onInputChange('minLengthIn', value);
                          }}
                          pattern="decinumeric"
                        />
                      </>
                      )}
            <div className="row">Height</div>
            <CustomInput
              label=""
              wrapperClass="input-wrapper"
              value={initialValues.minHeightTotal as string}
              onChange={(value) => {
                const convertedIn = convertInToInFt(+value);
                onInputChange('minHeightTotal', value);
                onInputChange('minHeightIn', convertedIn.inches || '');
                onInputChange('minHeightFt', convertedIn.feet || '');
              }}
              pattern="decinumeric"
            />
            <div className="or">OR</div>
            <CustomInput
              label=""
              wrapperClass="input-wrapper ft"
              value={initialValues.minHeightFt as string}
              onChange={(value) => {
                onInputChange('minHeightTotal', convertInFtToTotalIn(+(initialValues.minHeightIn), +value) || '');
                onInputChange('minHeightFt', value);
              }}
              pattern="decinumeric"
            />
            <CustomInput
              label=""
              wrapperClass="input-wrapper in"
              value={initialValues.minHeightIn as string}
              onChange={(value) => {
                onInputChange('minHeightTotal', convertInFtToTotalIn(+value, +initialValues.minHeightFt) || '');
                onInputChange('minHeightIn', value);
              }}
              pattern="decinumeric"
            />
            {displaySideWallHeight
                      && (
                      <>
                        {' '}
                        <div className="row">Side Wall Height</div>
                        <CustomInput
                          label=""
                          wrapperClass="input-wrapper"
                          value={initialValues.sideWallHeightTotal as string}
                          onChange={(value) => {
                            const convertedIn = convertInToInFt(+value);
                            onInputChange('sideWallHeightTotal', value);
                            onInputChange('sideWallHeightIn', convertedIn.inches || '');
                            onInputChange('sideWallHeightFt', convertedIn.feet || '');
                          }}
                          pattern="decinumeric"
                        />
                        <div className="or">OR</div>
                        <CustomInput
                          label=""
                          wrapperClass="input-wrapper ft"
                          value={initialValues.sideWallHeightFt as string}
                          onChange={(value) => {
                            onInputChange('sideWallHeightTotal', convertInFtToTotalIn(+(initialValues.sideWallHeightIn), +value) || '');
                            onInputChange('sideWallHeightFt', value);
                          }}
                          pattern="decinumeric"
                        />
                        <CustomInput
                          label=""
                          wrapperClass="input-wrapper in"
                          value={initialValues.sideWallHeightIn as string}
                          onChange={(value) => {
                            onInputChange('sideWallHeightTotal', convertInFtToTotalIn(+value, +initialValues.sideWallHeightFt) || '');
                            onInputChange('sideWallHeightIn', value);
                          }}
                          pattern="decinumeric"
                        />
                      </>
                      )}
            <div className="row">Floor Height</div>
            <CustomInput
              label=""
              wrapperClass="input-wrapper"
              value={detailsInitialValues.florHeightTotal}
              onChange={(value) => {
                const convertedIn = convertInToInFt(+value);
                onDetailInputChange('florHeightTotal', value);
                onDetailInputChange('florHeightIn', convertedIn.inches || '');
                onDetailInputChange('florHeightFt', convertedIn.feet || '');
              }}
              pattern="decinumeric"
            />
            <div className="or">OR</div>
            <CustomInput
              label=""
              wrapperClass="input-wrapper ft"
              value={detailsInitialValues.florHeightFt}
              onChange={(value) => {
                onDetailInputChange('florHeightTotal', convertInFtToTotalIn(+detailsInitialValues.florHeightIn, +value) || '');
                onDetailInputChange('florHeightFt', value);
              }}
              pattern="decinumeric"
            />
            <CustomInput
              label=""
              wrapperClass="input-wrapper in"
              value={detailsInitialValues.florHeightIn}
              onChange={(value) => {
                onDetailInputChange('florHeightTotal', convertInFtToTotalIn(+value, +detailsInitialValues.florHeightFt) || '');
                onDetailInputChange('florHeightIn', value);
              }}
              pattern="decinumeric"
            />
            <div className="row">Floor Length</div>
            <CustomInput
              label=""
              wrapperClass="input-wrapper"
              value={detailsInitialValues.florLengthTotal}
              onChange={(value) => {
                const convertedIn = convertInToInFt(+value);
                onDetailInputChange('florLengthTotal', value);
                onDetailInputChange('florLengthIn', convertedIn.inches || '');
                onDetailInputChange('florLengthFt', convertedIn.feet || '');
              }}
              pattern="decinumeric"
            />
            <div className="or">OR</div>
            <CustomInput
              label=""
              wrapperClass="input-wrapper ft"
              value={detailsInitialValues.florLengthFt}
              onChange={(value) => {
                onDetailInputChange('florLengthTotal', convertInFtToTotalIn(+detailsInitialValues.florLengthIn, +value) || '');
                onDetailInputChange('florLengthFt', value);
              }}
              pattern="decinumeric"
            />
            <CustomInput
              label=""
              wrapperClass="input-wrapper in"
              value={detailsInitialValues.florLengthIn}
              onChange={(value) => {
                onDetailInputChange('florLengthTotal', convertInFtToTotalIn(+value, +detailsInitialValues.florLengthFt) || '');
                onDetailInputChange('florLengthIn', value);
              }}
              pattern="decinumeric"
            />
            <div className="row">Floor Width</div>
            <CustomInput
              label=""
              wrapperClass="input-wrapper"
              value={detailsInitialValues.florWidthTotal}
              onChange={(value) => {
                const convertedIn = convertInToInFt(+value);
                onDetailInputChange('florWidthTotal', value);
                onDetailInputChange('florWidthIn', convertedIn.inches || '');
                onDetailInputChange('florWidthFt', convertedIn.feet || '');
              }}
              pattern="decinumeric"
            />
            <div className="or">OR</div>
            <CustomInput
              label=""
              wrapperClass="input-wrapper ft"
              value={detailsInitialValues.florWidthFt}
              onChange={(value) => {
                onDetailInputChange('florWidthTotal', convertInFtToTotalIn(+detailsInitialValues.florWidthIn, +value) || '');
                onDetailInputChange('florWidthFt', value);
              }}
              pattern="decinumeric"
            />
            <CustomInput
              label=""
              wrapperClass="input-wrapper in"
              value={detailsInitialValues.florWidthIn}
              onChange={(value) => {
                onDetailInputChange('florWidthTotal', convertInFtToTotalIn(+value, +detailsInitialValues.florWidthFt) || '');
                onDetailInputChange('florWidthIn', value);
              }}
              pattern="decinumeric"
            />
          </div>
          <div className="bg">
            <CustomInput
              label="GVWR"
              wrapperClass="input-wrapper"
              value={detailsInitialValues.gvwr}
              onChange={(value) => {
                onDetailInputChange('gvwr', value);
                setCalculatedCapacity({ gvwr: value, weight: detailsInitialValues.weight });
              }}
              pattern="numeric"
            />
            <CustomInput
              label="Payload Capacity"
              wrapperClass="input-wrapper"
              value={detailsInitialValues.payloadCapacity}
              onChange={(value) => onDetailInputChange('payloadCapacity', value)}
              pattern="numeric"
            />
            <CustomInput
              label="Weight"
              wrapperClass="input-wrapper"
              value={detailsInitialValues.weight}
              onChange={(value) => {
                onDetailInputChange('weight', value);
                setCalculatedCapacity({ gvwr: detailsInitialValues.gvwr, weight: value });
              }}
              pattern="numeric"
            />
            {(inventoryType?.name && inventoryType?.name !== 'Equipment')
                      && (
                      <CustomInput
                        label="Axle Capacity"
                        wrapperClass="input-wrapper axle-capacity"
                        value={detailsInitialValues.axleCapacity}
                        onChange={(value) => onDetailInputChange('axleCapacity', value)}
                        pattern="numeric"
                      />
                      )}
          </div>

          <div className="inputs-section">
            {attributeInputs.map((input: ItemAttribute, index: number) => {
              if (index > 8 && index % 2 === 0) {
                return (
                  <React.Fragment key={input.id}>
                    {renderInput(input)}
                  </React.Fragment>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </AttributesSectionContainer>
  );
}

export default Attributes;

const AttributesSectionContainer = styled.div`
    display: flex;
    justify-content: center;

    .attributes-section-content {
        .select-container {
            .select-wrap {
                width: 100%;
            }
        }

        .input-wrapper {
            input {
                padding-left: 20px;
            }
        }
    }
    
    .specific-attributes-section {
        div.section-title {
            margin-bottom: 0px;
            margin-top: 20px;
        }
        div.input-wrapper {
            padding: 10px 0 10px 0;
        }
    }

    .dimensions-section {
        display: grid;
        align-items: center;
        background-color: #fef4e2;
        border-radius: 8px;
        padding: 8px;
        margin: 8px;
        @media screen and (max-width: 768px) {
            grid-template-columns: 28% 23% 9% 18% 20%;
    }
      grid-template-columns: 28% 23% 9% 17% 17%;
    .row {
      margin-top: 15px; 
      text-align: end;
      margin-right: 10px;
      color: ${(props) => props.theme.primaryTextColor};
    }
    font-weight: normal;
    .row,.or{
      font-size: 12px;
    }
    .or {
      color: ${(props) => props.theme.primaryColor}
      margin-left: 7px;
      margin-top: 16px
    }
    .col {
      margin-bottom: -6px;
      font-size: 8px;
      text-transform: uppercase;
      color: ${(props) => props.theme.primaryTextColor};
    }
    .input-wrapper {
      margin-top: 8px;
      padding: 0;
    }
    input {
      width: 55px;
      padding: 8px;
      border: none;
    }
  }

  @media screen and (min-width: 767px) {
    width: 100%;
  display: block;  
    .dimensions-section {
      margin-top: 2px;
      margin-left: 0;
      .row {
        font-size: 13px;
      }
      .col {
        margin-top: 5px;
        font-size: 10px;
      }
      input {
        width: 100%;
        border: none;
      }
      .ft {
        margin-left: 10px!important;
      } 
      .in {
        margin-left: 10px!important;
      }
      .input-wrapper {
        input {
          padding: 8px;
        }  
      }
      .select-container,
      .input-wrapper {
        justify-items: start;
        display: block!important;
        margin-left: 5px;
        label {
          justify-self: end;
        }
      }
    }
  }
  .bg {
    background-color: #fef4e2;
    border-radius: 8px;
    padding: 8px;
    margin: 8px;
    align-items: center;
    margin-left: 0;
    .input-wrapper {
      padding: 0;
      max-width: 500px;
      > div {
        width: 100%;
        max-width: 300px;
        flex: 4;
      }
      input {
        width: 100%;
      }
      label {
        text-align: end;
        flex: 2;
        white-space: nowrap;
      }
    }
    input {
      width: 198px;
      border: none;
    }
    gap: 10px;
    display: grid;
    grid-template-columns: 3fr 4fr;
    .input-wrapper {
      display: grid;
      grid-template-columns: clamp(40px, 45%, 110px) clamp(40px, 50%, 200px);
      align-items: center;
      gap: 12px;
    }
  }

`;
