import { useEffect, useState } from 'react';
import { Editor, EditorCore } from '@toast-ui/editor';
import styled from 'styled-components';
import '@toast-ui/editor/dist/toastui-editor.css';

interface MarkdownProps {
  onChange?: (val: string) => void
  initialVal?: string
}

function Markdown({ onChange, initialVal }: MarkdownProps) {
  const [value, setValue] = useState(initialVal || ' ');
  const [editor, setEditor] = useState<EditorCore>();

  useEffect(() => {
    const elementToRenderIn = document.querySelector('#editor') as HTMLDivElement;
    if (elementToRenderIn) {
      const newEditor = new Editor({
        el: elementToRenderIn,
        previewStyle: 'vertical',
        height: '406px',
        autofocus: false,
        events: {
          change: () => {
            onChange?.(newEditor?.getMarkdown());
          },
        },
        initialValue: value,
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol'],
          ['table', 'link'],
        ],
      });
      setEditor(newEditor);
    }
  }, [value]);
  useEffect(() => {
    if (editor && editor.getMarkdown() !== initialVal && initialVal) {
      setValue(initialVal);
    }
  }, [initialVal]);
  return (
    <MarkdownWrapper>
      <div id="editor" />
    </MarkdownWrapper>
  );
}

export default Markdown;

const MarkdownWrapper = styled.div`
  @media screen and (max-width: 768px) {
    max-width: 90vw;
    margin-left: 10px;
  }
    max-width: 59vw;
  width: 100%;
  #editor {
    width: 90%;
    overflow: auto;
    text-align: left;
  }
`;
