import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppMessageContext } from '../../appMessagesContext';
import BackSvg from '../../assets/back-arrow.png';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import {
  CreateProvider, GetProvider, UpdateProvider,
} from '../../store/dealers/actions';
import ProviderCreationForm, { ProviderFromData } from './ProviderCreationForm';

function ProviderCreation() {
  const navigate = useNavigateHook();
  const params = useParams<{ id: string }>();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const dealerId = Object.fromEntries(urlSearchParams.entries()).dealer_id ? Number(Object.fromEntries(urlSearchParams.entries()).dealer_id) : 1;
  const { showMessage } = useAppMessageContext();
  const { data: providersData, isFetching: providersLoading } = GetProvider(params.id || '');
  const createProviderReq = CreateProvider();
  const updateProviderReq = UpdateProvider();

  const handleFormSubmit = (data: ProviderFromData) => {
    const formData: ProviderFromData = {
      ...data,
    };
    if (params.id) {
      updateProviderReq.mutate({ data: formData, id: params.id });
    } else {
      createProviderReq.mutate({ data: formData });
    }
  };

  useEffect(() => {
    if (createProviderReq.isSuccess) {
      showMessage({ type: 'success', message: 'Provider Created successfully' });
      createProviderReq.reset();
      navigate(`${Pages.providersPagePure}/${createProviderReq.data?.data?.dealer_id}`);
    }
    if (updateProviderReq.isSuccess) {
      showMessage({ type: 'success', message: 'Provider Updated successfully' });
      updateProviderReq.reset();
      navigate(`${Pages.providersPagePure}/${updateProviderReq.data?.data?.dealer_id}`);
    }
  }, [createProviderReq.isSuccess, updateProviderReq.isSuccess]);

  const formInitial: ProviderFromData = {
    name: providersData?.name || '',
    email: providersData?.email || '',
    dealer_id: providersData?.dealer_id || dealerId || '',
  };

  if (providersLoading) {
    return null;
  }

  return (
    <StyledContainer>
      <div className="back-btn">
        <button type="button" className="back" onClick={() => navigate(-1)}>
          <img src={BackSvg} alt="back" />
          <span>Back</span>
        </button>
      </div>
      <ProviderCreationForm
        onSubmit={handleFormSubmit}
        errorsProps={createProviderReq?.error?.response?.data.errors || updateProviderReq?.error?.response?.data.errors}
        editProviderMode={!!params.id}
        editableProviderValues={formInitial}
      />
    </StyledContainer>
  );
}

export default ProviderCreation;

const StyledContainer = styled.div`
    width: 100%;
    .back-btn {
      margin-left: 61px;
      margin-top: 10px;
    }
`;
