import { useFormik } from 'formik';
import styled from 'styled-components';
import CustomButton from '../../components/Buttons/CustomButton';
import CustomInput from '../../components/inputs/CustomInput';
import signupFormSchema from '../../validation/registrationFormSchema';
import { RegistrationErrorsType } from './index';

export interface RegistrationFormData {
    name: string
    email: string
    password: string
    passwordConfirmation: string
}

interface RegistrationFormProps {
    handleSubmit: (data: RegistrationFormData) => void
    loading: boolean
    errorsProps?: RegistrationErrorsType
}
function RegistrationForm({ handleSubmit, loading, errorsProps }: RegistrationFormProps) {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: signupFormSchema,
    onSubmit: handleSubmit,
  });
  const {
    setFieldValue, values: formikValues, errors, touched,
  } = formik;

  const nameError = errors.name && touched.name ? errors.name : errorsProps?.name;
  const emailError = errors.email && touched.email ? errors.email : errorsProps?.email;
  const passwordError = errors.password && touched.password ? errors.password : errorsProps?.password;
  const passwordConfirmationError = errors.passwordConfirmation && touched.passwordConfirmation ? errors.passwordConfirmation : errorsProps?.password_confirmation;

  return (
    <form onSubmit={(event) => {
      event.preventDefault();
      formik.submitForm();
    }}
    >
      <InputsContainer>
        <CustomInput
          label="Your Dealership's Name"
          name="name"
          wrapperClass="input-wrapper"
          value={formikValues.name}
          onChange={(value) => setFieldValue('name', value)}
          error={nameError}
          data-testid="name"
        />
        <CustomInput
          label="Your Email"
          name="email"
          wrapperClass="input-wrapper"
          value={formikValues.email}
          onChange={(value) => setFieldValue('email', value)}
          error={emailError}
          data-testid="email"
        />
        <CustomInput
          label="Your Password"
          name="password"
          wrapperClass="input-wrapper"
          type="password"
          value={formikValues.password}
          onChange={(value) => setFieldValue('password', value)}
          error={passwordError}
          data-testid="password"
        />
        <CustomInput
          label="Confirm Password"
          name="passwordConfirmation"
          wrapperClass="input-wrapper"
          type="password"
          value={formikValues.passwordConfirmation}
          onChange={(value) => setFieldValue('passwordConfirmation', value)}
          error={passwordConfirmationError}
          data-testid="confirmation"
        />
        <PrimaryButtonWrapper>
          <CustomButton type="submit" disabled={loading}>create account</CustomButton>
        </PrimaryButtonWrapper>
      </InputsContainer>
    </form>
  );
}

export default RegistrationForm;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -2px;
  width: 280px;
  form {
    padding: 0;
    margin: 0;
    border: none;
  }
  label {
    font-size: 12px;
  }
  .input-wrapper {
    width: 283px;
    margin-top: 20px;
  }
  input {
    border-radius: 4px;
    width: 100%;
  }
 
`;

const PrimaryButtonWrapper = styled.div`
  margin-top: 44px;
  button {
    width: 202px;
    height: 44px;
    font-size: 16px;
  }
`;
