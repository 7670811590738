import PlaceholderComponent from '../../components/PlaceholderComponent';

function Feeds() {
  return (
    <div>
      <PlaceholderComponent />
    </div>
  );
}

export default Feeds;
