import * as Y from 'yup';

export const clProfileFormSchema = Y.object().shape({
  name: Y.string().required('This field is required').max(32),
  location_city: Y.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Y.object().required('This field is required'); // schema for object
      case 'string':
        return Y.string().required('This field is required');
      default:
        return Y.mixed();
    }
  }),
  address: Y.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Y.object().required('This field is required'); // schema for object
      case 'string':
        return Y.string().required('This field is required');
      default:
        return Y.mixed();
    }
  }),
});
