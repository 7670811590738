import styled from 'styled-components';
import MainLayoutHeader from '../../InventoryManager/Header';
import SchedulerSection from '../../SettingsSections/SchedulerSection';

function Scheduler() {
  return (
    <ScheduleContainer>
      <MainLayoutHeader title="scheduler" />
      <div className="main-content">
        <SchedulerSection />
      </div>
    </ScheduleContainer>

  );
}
export default Scheduler;

const ScheduleContainer = styled.div`
    .main-content {
      padding-left: 90px;
      padding-right: 3vw;
      @media screen and (max-width: 768px) {
        padding-left: 10px;
      }
  }
`;
