import { ApplicationTheme } from '../../../globalStyles';

interface SVGProps {
    fill?: string
    stroke?: string
}

function BinSvg(props: SVGProps): JSX.Element {
  const { fill, stroke } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      viewBox="0 0 24 24"
      width="100%"
      preserveAspectRatio="none"
      fill={fill || ApplicationTheme.secondaryColor}
    >
      <path stroke={stroke} d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z" />
    </svg>
  );
}

export default BinSvg;
