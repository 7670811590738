import * as Y from 'yup';

const mainLocationRules = {
  main_location: Y.object({
    name: Y.string().required('This field is required'),
    contact_first_name: Y.string().required('This field is required'),
    contact_last_name: Y.string().required('This field is required'),
    phone_number: Y.string().required('This field is required'),
    email: Y.lazy((val) => (Array.isArray(val) ? Y.array().of(Y.string().required('This field is required')) : Y.string().required('This field is required'))),
    street_address: Y.string().required('This field is required'),
    city: Y.string().required('This field is required'),
    state: Y.lazy((value) => {
      switch (typeof value) {
        case 'object':
          return Y.object().required('This field is required'); // schema for object
        case 'string':
          return Y.string().required('This field is required');
        default:
          return Y.mixed(); // here you can decide what is the default
      }
    }),
    zip_code: Y.string().required('This field is required'),
    county: Y.string().required('This field is required'),
  }),
};
const locationsRules = {
  locations: Y.array().of(
    Y.object({
      name: Y.string().required('This field is required'),
      contact_first_name: Y.string().required('This field is required'),
      contact_last_name: Y.string().required('This field is required'),
      phone_number: Y.string().required('This field is required'),
      email: Y.lazy((val) => (Array.isArray(val) ? Y.array().of(Y.string().required('This field is required')) : Y.string().required('This field is required'))),
      street_address: Y.string().required('This field is required'),
      city: Y.string().required('This field is required'),
      state: Y.lazy((value) => {
        switch (typeof value) {
          case 'object':
            return Y.object().required('This field is required'); // schema for object
          case 'string':
            return Y.string().required('This field is required');
          default:
            return Y.mixed(); // here you can decide what is the default
        }
      }),
      zip_code: Y.string().required('This field is required'),
      county: Y.string().required('This field is required'),
    }),
  ),
};
const createDealerFormSchema = Y.object().shape({
  dealer_data: Y.object({
    dealership_name: Y.string().required('This field is required'),
    primary_email: Y.string().required('This field is required'),
    password: Y.string().required('This field is required'),
    first_name: Y.string().required('This field is required'),
    last_name: Y.string().required('This field is required'),
    phone_number: Y.string().required('This field is required'),
  }),
  ...mainLocationRules,
  ...locationsRules,
});

export const updateDealerFormSchema = Y.object().shape({
  dealer_data: Y.object({
    dealership_name: Y.string().required('This field is required'),
    primary_email: Y.string().required('This field is required'),
    first_name: Y.string().required('This field is required'),
    last_name: Y.string().required('This field is required'),
    phone_number: Y.string().required('This field is required'),
  }),
  ...mainLocationRules,
  ...locationsRules,
});

export default createDealerFormSchema;
