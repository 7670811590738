import { Message } from 'rsuite';
import styled from 'styled-components';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import WarningRoundIcon from '@rsuite/icons/WarningRound';
import RemindFillIcon from '@rsuite/icons/RemindFill';
import InfoRoundIcon from '@rsuite/icons/InfoRound';
import { AppMessageType } from '../../appMessagesContext';

function SystemMessage({ type = 'info', message, ...props }: AppMessageType) {
  const getIcon = () => {
    switch (type) {
      case 'success':
        return <CheckRoundIcon width={24} height={24} />;
      case 'error':
        return <WarningRoundIcon width={24} height={24} />;
      case 'warning':
        return <RemindFillIcon width={24} height={24} />;
      default:
        return <InfoRoundIcon width={24} height={24} />;
    }
  };

  return (
    <StyledMessage type={type} closable duration={props.duration || 3000}>
      <div className="container">
        <div className="icon-side">
          {getIcon()}
        </div>
        <div className="message-container">
          <div className="message-title">
            {type}
          </div>
          <div className="message">
            {message}
          </div>
        </div>
      </div>
    </StyledMessage>
  );
}

export default SystemMessage;

const getColor = (type: AppMessageType['type']) => {
  switch (type) {
    case 'success':
      return '#afd136';
    case 'error':
      return '#ff3823';
    case 'warning':
      return '#ffcc00';
    default:
      return '#45b3ff';
  }
};

const StyledMessage = styled(Message)`
  background-color: #fff; 
  .rs-message-container {
    padding: 0;
    width: 330px;
  }
  border: ${(props) => `1px solid ${getColor(props.type)}`};
  .container {
    display: flex;
  }
  .icon-side {
    background-color: ${(props) => getColor(props.type)};
    width: 46px;
    min-height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: #fff;
      stroke: ${(props) => getColor(props.type)};
    }
  }
  .message-container {
    margin-left: 11px;
    margin-top: 9px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    .message-title {
      color: ${(props) => getColor(props.type)};
      text-transform: capitalize;
    }
    .message {
      color: ${(props) => props.theme.secondaryTextColor};
    }
  }
`;
