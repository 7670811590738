import * as Y from 'yup';

const signupFormSchema = Y.object().shape({
  name: Y.string().required('This field is required')
    .min(2, 'Name must be at least 2 characters')
    .max(255, 'Name must not be more than 255 characters'),
  email: Y.string().required('This field is required').email().min(9)
    .max(255),
  password: Y.string()
    .required('No password provided.')
    .min(8, 'Password must be at least 8 characters')
    .max(255, 'Password must not be more than 255 characters'),
  passwordConfirmation: Y.string()
    .required('No confirm password provided.')
    .oneOf([Y.ref('password')], 'Passwords must match'),
});

export default signupFormSchema;
