import React from 'react';
import { UnitPrintType } from '../../store/unit/actions';
import {
  CategoryCell,
  PrimeImageCell,
  LocationCell,
  PriceCell,
  SalesPriceCell,
} from '../../pages/InventoryManager/UnintsTableCells';

export interface ItemTableProps {
    data: UnitPrintType[];
}
function PrintableItemTable({
  data,
}: ItemTableProps) {
  return (
    <table className="printableTable">
      <thead>
        <tr>
          <th>Primary Image</th>
          <th>Stock #</th>
          <th>Year</th>
          <th>Manufacturer</th>
          <th>Category</th>
          <th>Model</th>
          <th>Status</th>
          <th>Price</th>
          <th>Sales Price</th>
          <th>Location</th>
        </tr>
      </thead>
      <tbody>
        {data.map((dataRow: UnitPrintType) => (
          <tr key={dataRow.id} className="printableRow">
            <td><PrimeImageCell item={dataRow} /></td>
            <td>{dataRow.stock}</td>
            <td>{dataRow.year}</td>
            <td>{dataRow.manufacturer}</td>
            <td><CategoryCell item={dataRow} /></td>
            <td>{dataRow.model_name}</td>
            <td>{dataRow.status}</td>
            <td><PriceCell item={dataRow} /></td>
            <td><SalesPriceCell item={dataRow} /></td>
            <td><LocationCell item={dataRow} /></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default PrintableItemTable;
