import React from 'react';
import styled from 'styled-components';
import { queryClient } from '../../../../App';
import FileUploader, { RenderImageT } from '../../../../components/FileUploader';
import CustomSelect, { SelectValue } from '../../../../components/inputs/CustomSelect';
import { formSelectOptions } from '../../../../helpers';
import { RequestKeys } from '../../../../store/keys';
import { FileFromResponse, UnitUserOverlaySetting } from '../../../../store/unit/actions';
import { QuickEditForm } from '../../../InventoryManager/QuickEditModal';

export type ImagesFormInputs = {
    overlaySetting: SelectValue
    files: RenderImageT[]
    filesFromResponse: FileFromResponse[]
}

interface ImagesProps {
    onChange: (files: RenderImageT[]) => void
    onDeleteAll: () => void
    onSelectChange: (value: SelectValue) => void
    initialValue: ImagesFormInputs
    overlaySettings?: UnitUserOverlaySetting
    downloadBtn?: boolean
}
function Images({
  onChange, onSelectChange, initialValue, overlaySettings, onDeleteAll, downloadBtn,
}: ImagesProps) {
  const imageSelectOptions = formSelectOptions(['All', 'None', 'Primary']);
  const quickEditData = queryClient.getQueryData<QuickEditForm>([RequestKeys.QUICK_EDIT_DATA]);

  return (
    <StyledImagesContainer>
      <div className="select-wrapper">
        <CustomSelect
          label="Overlay Setting"
          className="select-input"
          options={imageSelectOptions}
          value={initialValue.overlaySetting}
          onChange={(val) => onSelectChange(val)}
          placeholder={' '}
        />
      </div>
      <div className="uploader">
        <FileUploader
          title="Add Images"
          subtitle="or drag and drop images within this box"
          fileType={'image/*'}
          acceptFiles={{
            'image/jpeg': [],
            'image/png': [],
            'image/gif': [],
            'image/heic': [],
            'image/heif': [],
          }}
          onPreviewChange={onChange}
          onDeleteAll={onDeleteAll}
          filesFromResponse={initialValue.filesFromResponse}
          initialPreviewFiles={quickEditData?.images?.files || initialValue?.files}
          downloadAllBtn={downloadBtn}
          overlaySettings={overlaySettings ? {
            ...overlaySettings,
            setting: initialValue?.overlaySetting?.value ? String(initialValue?.overlaySetting?.value) : 'None',
          } : undefined}
        />
        <div className="supported-files">
          Supported File Types: .jpeg, .jpg, .png, .gif, .heic, .heif
          {' '}
          <br />
          <i>Bigger images might take some time to load after adding, please be patient.</i>
        </div>
      </div>
    </StyledImagesContainer>
  );
}

export default Images;

const StyledImagesContainer = styled.div`
  margin-top: 12px;
.select-wrapper {
  max-width: 350px;
 label {
   font-size: 13px;
   white-space: nowrap;
   font-weight: normal;
 } 
  .select-wrap {
    width: 195px;
  }
}
  .uploader {
    margin-top: 33px;
    margin-bottom: 22px;
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: 768px) {
      .outlined-wrap {
        max-width: 323px;
      }
    }
    .supported-files {
      margin-top: 10px;
      font-size: 12px;
      text-align: start;
      color: ${(props) => props.theme.primaryColor};
    }
  }
`;
