import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppMessageContext } from '../../../../appMessagesContext';
import CustomButton from '../../../../components/Buttons/CustomButton';
import DeleteModal from '../../../../components/DeleteModal';
import CustomInput from '../../../../components/inputs/CustomInput';
import BinSvg from '../../../../components/SvgElements/BinSvg';
import { useGetSelectedDealer } from '../../../../hooks/getDealersHook';
import { getMeRequest } from '../../../../store/auth/actions';
import { DealerPasswordChange } from '../../../../store/constants';
import { CreateUserRequestI, UpdateUserRequest } from '../../../../store/settings/actions';
import changePasswordFormSchema from '../../../../validation/changePasswordFormSchema';
import { roleHasAccess } from '../../../../helpers';

interface ChangePasswordForm {
  password: string
  passwordConfirmation: string
}
interface UserSectionHeaderProps {
    showDelete?: boolean
    errorsProps?: ChangePasswordForm
    onModalAccept: () => void
    primaryEmail: string
}
function UserSectionHeader({
  showDelete, errorsProps, onModalAccept, primaryEmail,
}: UserSectionHeaderProps) {
  const { showMessage } = useAppMessageContext();

  const [resetPasswordMode, setResetPasswordMode] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const updateUserRequest = UpdateUserRequest();

  const user = getMeRequest()?.data?.data;
  const selectedDealer = useGetSelectedDealer();

  const handleSubmit = (data: ChangePasswordForm) => {
    const formData: CreateUserRequestI = {
      email: primaryEmail,
      password: data.password,
      password_confirmation: data.passwordConfirmation,
      is_dealer_admin: true,
      access_billing: true,
      access_scheduler: true,
      fb_profiles: false,
      dl_customer_id: '',
    };
    if (user?.id || selectedDealer?.id) {
      updateUserRequest.mutate({ data: formData, id: selectedDealer?.id || user?.id || 1 });
    }
    setResetPasswordMode(false);
  };
  useEffect(() => {
    if (updateUserRequest.isError) {
      showMessage({ type: 'error', message: updateUserRequest.error?.response?.data?.message || 'Something went wrong' });
      updateUserRequest.reset();
    }
    if (updateUserRequest.isSuccess) {
      showMessage({ type: 'success', message: 'Password changed successfully' });
      updateUserRequest.reset();
    }
  }, [updateUserRequest]);
  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: changePasswordFormSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });
  const {
    setFieldValue, values: formikValues, errors, touched,
  } = formik;

  const handleInputChange = (key: keyof ChangePasswordForm, value: string) => {
    setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    if (resetPasswordMode) {
      formik.submitForm();
      if (errors.passwordConfirmation === 'Passwords must match') {
        showMessage({ type: 'error', message: 'Passwords do not match' });
      }
    }
  };

  const handleModalAccept = () => {
    setShowModal(false);
    onModalAccept();
  };

  const passwordError = errors.password && touched.password ? errors.password : errorsProps?.password;
  const passwordConfirmationError = errors.passwordConfirmation && touched.passwordConfirmation ? errors.passwordConfirmation : errorsProps?.passwordConfirmation;

  const modalText = () => (
    <div>
      <h3>
        Permanently Delete
      </h3>
      <div className="text">
        Are you sure you would like
        <br />
        to Permanently Delete these
        <br />
        User(s)?
      </div>
    </div>
  );

  return (
    <Container className="header">
      <div className="email-part">
        <div className="primary-email">
          {`Primary Email: ${primaryEmail}`}
        </div>
        {!resetPasswordMode && roleHasAccess(user?.role, DealerPasswordChange)
        && (
        <div className="reset-button-wrap">
          <CustomButton variant="text" onClick={() => setResetPasswordMode(true)}>RESET PASSWORD</CustomButton>
        </div>
        )}
      </div>
      {resetPasswordMode
            && (
            <div className="inputs">
              <div>
                <CustomInput
                  placeholder="New Password"
                  type="password"
                  value={formikValues.password}
                  onChange={(val) => handleInputChange('password', val)}
                  error={passwordError}
                />
              </div>
              <div>
                <CustomInput
                  placeholder="Confirm Password"
                  type="password"
                  value={formikValues.passwordConfirmation}
                  onChange={(val) => handleInputChange('passwordConfirmation', val)}
                  error={passwordConfirmationError}
                />
              </div>
            </div>
            )}
      <div className="buttons-container">
        {showDelete
              && (
              <IconWrapper className="remove-ico" onClick={() => setShowModal(true)}>
                <BinSvg />
              </IconWrapper>
              )}
        {resetPasswordMode
        && (
        <div className="save-btn">
          <CustomButton variant="primary" onClick={handleSaveClick}>SAVE</CustomButton>
        </div>
        )}
      </div>
      <DeleteModal
        openObj={showModal}
        onApproveClose={handleModalAccept}
        onClose={() => setShowModal(false)}
        mainContent={modalText()}
      />
    </Container>

  );
}

export default UserSectionHeader;

const Container = styled.div`
    display: flex;
    align-items: center;
    padding-top: 22px; 
    padding-left: 28px;
    padding-right: 40px;
    justify-content: space-between;
      @media screen and (max-width: 768px) {
          flex-direction: column;
          padding-left: 0!important;
      }
    .email-part {
      display: flex;
      align-items: center;
      .primary-email {
        min-height: 43px;
        font-weight: bold;
        font-size: 14px;
        color: #232323;
        background-color: #ffffff;
        padding: 12px;
        border-radius: 8px;
      }
      .reset-button-wrap {
        margin-left: 12px;
        button {
          color: ${(props) => props.theme.primaryColor};
          text-decoration: unset;
          font-weight: bold;
          font-size: 10px;
        }
      }
    }
    .inputs {
      display: flex;
      align-items: center;
      gap: 16px;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-top: 10px;
      }
    }
    .buttons-container {
      display: flex;
      align-items: center;
      gap: 29px;
      @media screen and (max-width: 768px) {
        margin-top: 10px;
        flex-direction: column;
        width: 100%;
          .remove-ico {
              align-self: baseline;
          }
       }
    }
    .save-btn {
      width: 100px;
    }

`;

const IconWrapper = styled.div`
  &.remove-ico {
    border-radius: 50%;
    width: 61px;
    height: 61px;
    display: flex;
    flex: initial;
    margin-left: 12px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.primaryColor};
    img {
      height: 28px;
      width: 28px;
    }
    transition: all ease 0.2s;
    :hover {
      border: 2px solid ${(props) => props.theme.primaryColor};
    }
    @media screen and (max-width: 767px) {
      order: 3;
      width: 31px;
      height: 31px;
    }
  }
  svg {
    height: 26px;
    width: 26px;
  }
`;
