import { useFormik } from 'formik';
import { useEffect } from 'react';
import { Loader, Modal } from 'rsuite';
import styled from 'styled-components';
import CopyIcon from '@rsuite/icons/Copy';
import { useAppMessageContext } from '../../../appMessagesContext';
import clearIco from '../../../assets/clear.png';
import CustomButton from '../../../components/Buttons/CustomButton';
import CustomInput from '../../../components/inputs/CustomInput';
import { ApplicationTheme } from '../../../globalStyles';
import { stockInputPattern } from '../../../helpers/regexPatterns';
import { CloneUnitRequest, UnitType } from '../../../store/unit/actions';
import { duplicateUnitFormSchema } from '../../../validation/duplicateUnitFormSchema';

export type InventoryManagerDeleteModalProps = {
  showModal: UnitType | null
  onModalClose: (val: boolean) => void
  onSuccessDuplicate: () => void
}
type DuplicateUnitForm = {
  newStock: string
  oldStock: string
}
function InventoryManagerDuplicateUnitModal({
  onModalClose, showModal, onSuccessDuplicate,
}: InventoryManagerDeleteModalProps) {
  const cloneUnitRequest = CloneUnitRequest();
  const { showMessage } = useAppMessageContext();
  const handleSubmit = (data: DuplicateUnitForm) => {
    if (showModal?.id) {
      cloneUnitRequest.mutate({ id: showModal?.id, data: { stock: data.newStock } });
    }
  };

  useEffect(() => {
    if (cloneUnitRequest.isSuccess) {
      showMessage({ type: 'success', message: 'Unit duplicated successfully' });
      cloneUnitRequest.reset();
      onSuccessDuplicate();
    }
    if (cloneUnitRequest.isError) {
      showMessage({ type: 'error', message: cloneUnitRequest.error?.response?.data.message || 'Error duplicating unit' });
      cloneUnitRequest.reset();
      onModalClose(false);
    }
  }, [cloneUnitRequest.isSuccess, cloneUnitRequest.isError]);

  const formik = useFormik<DuplicateUnitForm>({
    initialValues: {
      newStock: '',
      oldStock: showModal?.stock || '',
    },
    onSubmit: handleSubmit,
    validationSchema: duplicateUnitFormSchema,
    enableReinitialize: true,
  });

  const {
    setFieldValue, values: formikValues, errors, touched,
  } = formik;

  return (
    <Modal
      open={!!showModal}
      onClose={() => onModalClose(false)}
    >
      <StyledModalHeader>
        <button type="button" onClick={() => onModalClose(false)}>
          <img src={clearIco} alt="clear" />
        </button>
      </StyledModalHeader>
      <Modal.Body>
        <ModalContainer>
          <CopyIcon width={52} height={52} color={ApplicationTheme.primaryColor} />
          <h3>
            Duplicate
          </h3>
          <div className="text">
            Are you sure you would like
            {' '}
            to
            {' '}
            Duplicate
            {' '}
            Unit with
            {' '}
            <span className="stock-label">Stock #:</span>
            <span className="stock-value">{showModal?.stock}</span>
            ?
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="form">
              <CustomInput
                wrapperClass="stock-input"
                label="New Stock #"
                value={formikValues.newStock}
                onChange={(value) => setFieldValue('newStock', value)}
                error={errors.newStock && touched?.newStock ? errors.newStock : cloneUnitRequest?.error?.response?.data.errors.stock}
                pattern={stockInputPattern}
              />
            </div>
            <CustomButton
              wrapperClassname="archive-btn"
              variant="primary"
              onClick={formik.submitForm}
              type="submit"
              disabled={cloneUnitRequest?.isPending}
            >
              <div>
                Duplicate
                {cloneUnitRequest?.isPending
                && <Loader center />}
              </div>
            </CustomButton>
          </form>
        </ModalContainer>
      </Modal.Body>
    </Modal>
  );
}

export default InventoryManagerDuplicateUnitModal;

const StyledModalHeader = styled.div`
display: flex;
justify-content: flex-end;
  button {
    padding: 0;
    margin: 0;
    background: transparent;
  }
  img {
    cursor: pointer;
    transition: all ease 0.2s;
    :hover {
      transform: scale(1.2);
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    margin-top: 10px;
  }
  .text {
    margin-top: 11px;
    margin-left: 20px;
    font-size: 18px;
    color: ${(props) => props.theme.secondaryTextColor};
  }
  .archive-btn {
    width: 121px;
    height: 35px;
    margin-top: 32px;
  }
  .stock-label {
    font-weight: bold;
  }
  .stock-value {
    margin-left: 5px;
    margin-right: 5px;
  }
  .stock-input {
    text-align: start;
    align-items: flex-start;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
