import { Toggle } from 'rsuite';
import styled from 'styled-components';
import PlusRoundIcon from '@rsuite/icons/PlusRound';
import {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import TextArea from '../../../../components/inputs/TextArea';
import CustomSelect, { SelectOption, SelectValue } from '../../../../components/inputs/CustomSelect';
import { ApplicationTheme } from '../../../../globalStyles';
import { formSelectOptions } from '../../../../helpers';
import { SelectedDealer } from '../../../../hooks/getDealersHook';
import { DealerType } from '../../../../store/dealers/actions';
import { MeType } from '../../../../store/auth/types';

export const websiteSelectOptions = formSelectOptions(['Yes', 'No']);
export type WebsiteFormInputs = {
  is_active: boolean
  racing_junk: boolean
  is_featured: SelectValue
  is_on_special: SelectValue
  custom_status: SelectValue
  video_embed_code: string[]
  racing_junk_slots?: string | null
}
export type WebsiteInputKeys = keyof WebsiteFormInputs
interface WebsiteProps {
  selectedDealer: SelectedDealer | DealerType | MeType | undefined
  initialValues: WebsiteFormInputs
  onInputChange: (key: WebsiteInputKeys, value: WebsiteFormInputs['video_embed_code'] | SelectValue | boolean) => void
  customStatusOptions: SelectOption[]
}
export type WebsiteRefHandle = {
  removeEmptyEmbedCodes: () => void;
};
const WebsiteConfiguration = forwardRef<WebsiteRefHandle, WebsiteProps>(({
  selectedDealer, initialValues, onInputChange, customStatusOptions,
}:WebsiteProps, ref) => {
  const [active, setActive] = useState(initialValues.is_active);
  const [racingJunk, setRacingJunk] = useState(initialValues.racing_junk);
  const [racingJunkSlots, setRacingJunkSlots] = useState<number>(selectedDealer?.rj_slots_used
    ? Number(selectedDealer?.rj_slots_used?.split('/')[0])
    : 0);
  const [embedCodes, setEmbedCodes] = useState<string[]>(
    initialValues.video_embed_code.length ? initialValues.video_embed_code : [''],
  );
  useImperativeHandle(ref, () => ({
    removeEmptyEmbedCodes() {
      let filteredCodes = initialValues.video_embed_code.filter((code: string | null) => code?.trim());
      if (filteredCodes.length === 0 && !filteredCodes[0]) {
        filteredCodes = [''];
      }
      setEmbedCodes(filteredCodes);
      onInputChange('video_embed_code', filteredCodes);
    },
  }));
  const formRJSlots = (slots: WebsiteFormInputs['racing_junk_slots']) => (slots ? {
    taken: Number(slots.split('/')[0]),
    total: Number(slots.split('/')[1]),
  } : null);
  useEffect(() => {
    const taken = formRJSlots(selectedDealer?.rj_slots_used)?.taken;
    if (taken) {
      setRacingJunkSlots(taken);
    }
  }, [selectedDealer?.rj_slots_used, initialValues.racing_junk_slots]);
  const customStatusOptionsWithEmptyOption = [{ value: '', label: 'None' }, ...customStatusOptions];
  return (
    <SectionContainer>
      <div className="wrap">
        <ToggleInput>
          <span className="toggle-label">{active ? 'Active' : 'Inactive'}</span>
          <Toggle
            id="is_active"
            arial-label="Switch"
            onChange={(val: boolean) => {
              setActive(val);
              onInputChange('is_active', val);
            }}
            checked={active}
          />
          <span className="toggle-description">
            When active your unit will be pushed to your Website and any active Classifieds integration
          </span>
        </ToggleInput>
        { !!selectedDealer?.rj_integration && (
        <ToggleInput className="racing-junk">
          <span className="toggle-label">Racing Junk</span>
          <Toggle
            id="is_active"
            arial-label="Switch"
            onChange={(val: boolean) => {
              setRacingJunk(val);
              onInputChange('racing_junk', val);
              if (val) {
                setRacingJunkSlots((prevState) => prevState + 1);
              } else {
                setRacingJunkSlots((prevState) => prevState - 1);
              }
            }}
            checked={racingJunk}
            disabled={(racingJunkSlots === formRJSlots(selectedDealer?.rj_slots_used)?.total) && !racingJunk}
          />
          {selectedDealer?.rj_slots_used
            ? (
              <span className="toggle-description">
                {racingJunkSlots}
                /
                {formRJSlots(selectedDealer?.rj_slots_used)?.total}
                {' '}
                slots
              </span>
            )
            : null}
        </ToggleInput>
        ) }
        <CustomSelect
          label="Is Featured"
          className="select-input"
          options={websiteSelectOptions}
          defaultValue={websiteSelectOptions[1]}
          value={initialValues.is_featured}
          onChange={(val) => onInputChange('is_featured', val)}
        />
        <CustomSelect
          label="Is on Special"
          className="select-input"
          options={websiteSelectOptions}
          defaultValue={websiteSelectOptions[1]}
          value={initialValues.is_on_special}
          onChange={(val) => onInputChange('is_on_special', val)}
        />
        <CustomSelect
          label="Custom Status"
          className="select-input"
          options={customStatusOptionsWithEmptyOption.filter(Boolean)}
          value={initialValues.custom_status || { value: '', label: 'None' }}
          onChange={(val) => onInputChange('custom_status', val)}
          placeholder={' '}
        />
        {embedCodes.map((num, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="embed-code" key={`${num}text-area${index}`}>
            <TextArea
              label={index === 0 ? 'Video Embed Code' : ''}
              rows={5}
              value={initialValues.video_embed_code[index]}
              onChange={(e) => {
                const temCodes = [...initialValues.video_embed_code];
                temCodes[index] = e.target.value;
                onInputChange('video_embed_code', temCodes);
              }}
            />
          </div>
        ))}

        <div
          className="add-code"
          onClick={() => {
            setEmbedCodes([...embedCodes, '']);
          }}
        >
          <PlusRoundIcon color={ApplicationTheme.primaryColor} />
          <span>Add Another Embed Code</span>
        </div>
        {/* <div className="info"> */}
        {/*  <div> */}
        {/*    <InfoRoundIcon color="#ffdd17" /> */}
        {/*  </div> */}
        {/*  <span>Once you have added a Classifieds integration your configurations will be displayed here</span> */}
        {/* </div> */}
      </div>
    </SectionContainer>
  );
});
WebsiteConfiguration.displayName = 'WebsiteConfiguration';
export default WebsiteConfiguration;

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 13px;
  width:  100%;
  .wrap {
    width: 100%;
  }
    .embed-code {
      margin-top: 10px;
      .text-area-wrapper {
        display: flex;
        max-width: 500px;
        grid-template-columns: 2fr 6fr;
        label {
          margin-top: 10px; 
          flex: 20%;
          text-align: end;
        }
        textarea {
          flex: 40%
        } 
      }
      @media screen and (max-width: 768px) {
        .text-area-wrapper {
          display: grid;
          grid-template-columns: 2fr 4fr;
          padding-right: 14px;
          margin-left: 10px;
        }
      }
    }
  .add-code {
    cursor: pointer;
    margin-top: 10px;
    font-size: 10px;
    color: ${(props) => props.theme.primaryColor};
    span {
      margin-left: 10px;
    }
  }
  .info {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-size: 12px; 
    font-weight: normal;
    text-align: start;
    span {
      margin-left: 10px;
    }
  }
  @media screen and (min-width: 768px) {
 width: 100%;
    justify-content: flex-start;
    .select-container {
      max-width: 543px;
    }
  .select-container,
  .text-area-wrapper{
    display: grid;
    grid-template-columns: 2fr 6fr; 
    justify-items: start;
    margin-top: 20px;
    label {
      width: 100%;
      text-align: end;
      font-size: 13px;
      font-weight: normal;   
      color: ${(props) => props.theme.primaryTextColor};
    }
    > div {
      margin-left: 12px;
      max-width: 195px;
    }
  }
    .embed-code {
      .text-area-wrapper {
        display: grid;
        grid-template-columns: 2.1fr 12fr;
        width: 100%;
        max-width: 932px;
        textarea {
          margin-left: 10px;
          width: 100%;
          height: 103px;
        }
      }
    }
    .add-code {
      text-align: start;
      font-size: 13px;
      margin-left: 151px;
      margin-top: 16px;
      margin-bottom: 9px;
      span {
        margin-left: 5px;
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
}

`;

const ToggleInput = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 12px;
  text-align: initial;
  &.racing-junk {
    margin-left: inherit;
    margin-top: 10px;
  }
  .toggle-label {
    margin-right: 7px;
  }
  .rs-toggle {
    margin-left: 4px;
  }
  .toggle-description {
    font-size: 8px;
    font-style: italic;
    margin-left: 16px;
  }
  margin: 0 auto;
  width: fit-content;

  @media screen and (min-width: 768px) {
    margin-left: 100px;
    &.racing-junk {
      margin-left: 73px;
    }
    color: ${(props) => props.theme.primaryTextColor};
    .toggle-label {
      font-size: 13px;
      min-width: 45px;
    }
    .toggle-description {
      font-size: 10px;
      max-width: 213px;
    }


  }
`;
