import { Modal } from 'rsuite';
import styled from 'styled-components';
import clearIco from '../../../../../assets/clear.png';
import ErrorSVG from '../../../../../components/SvgElements/ErrorSvg';
import { ApplicationTheme } from '../../../../../globalStyles';
import { CSVErrorMessagesKeys, CSVErrors } from '../../../../../store/settings/actions';

type CSVErrorsModalProps = {
    open: boolean
    onClose: () => void
    errors?: CSVErrors[] | null
}
function CSVErrorsModal({
  open, onClose, errors,
}: CSVErrorsModalProps) {
  return (
    <Modal
      open={!!open}
      onClose={onClose}
      size="md"
    >
      <StyledModalHeader>
        <button type="button" onClick={onClose}>
          <img src={clearIco} alt="clear" />
        </button>
      </StyledModalHeader>
      <Modal.Body>
        <ModalContainer>
          <div className="error-svg">
            <ErrorSVG fill={ApplicationTheme.secondaryColor} />
          </div>
          <div className="title">
            Error
          </div>
          <div className="info">
            Please review the CSV and correct the following error(s)
          </div>
          <div className="table">
            <table>
              <thead>
                <tr className="header">
                  <th style={{
                    width: '10%',
                  }}
                  >
                    Row
                  </th>
                  <th>Column</th>
                  <th style={{
                    width: '80%',
                  }}
                  >
                    Error
                  </th>
                </tr>
              </thead>
              <tbody>
                {errors?.map((errorRow, index) => Object.keys(errorRow.error_messages).map((key: keyof CSVErrorMessagesKeys) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={`${index}_${key}`}>
                    <td>{index + 2}</td>
                    <td>{key.includes('.') ? key.split('.')[1] : key}</td>
                    <td>{errorRow.error_messages?.[key]}</td>
                  </tr>
                )))}
              </tbody>
            </table>
          </div>
        </ModalContainer>
      </Modal.Body>
    </Modal>
  );
}

export default CSVErrorsModal;

const StyledModalHeader = styled.div`
display: flex;
justify-content: flex-end;
  button {
    padding: 0;
    margin: 0;
    background: transparent;
  }
  img {
    cursor: pointer;
    transition: all ease 0.2s;
    :hover {
      transform: scale(1.2);
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h3 {
    margin-top: 10px;
  }
  .text {
    margin-top: 11px;
    font-size: 18px;
    color: ${(props) => props.theme.secondaryTextColor};
  }
  .archive-btn {
    width: 121px;
    height: 35px;
    margin-top: 32px;
  }
  img {
    width: 52px;
    height: 52px;
  }
  .error-svg {
    width: 123px;
  }
  .title {
    font-weight: bold;
    font-size: 24px;
    color: ${(props) => props.theme.primaryTextColor};
  }
  .info {
    font-size: 18px;
    color: ${(props) => props.theme.secondaryTextColor};
  }
  .table {
    width: 100%;
    margin-top: 10px;
    text-align: start;
    table {
      width: 100%;
    }
    th {
      text-align: start;
      color: ${(props) => props.theme.secondaryTextColor};
    }
    tr {
      border-bottom: solid thin;
      td {
        height: 48px;
      }
      :not(.header) {
        td:not(:last-child) {
          text-align: center;
        }
      :not(:nth-child(2n)) {
          background-color: #f3f3f3;
        }
      }
    }
  }
`;
