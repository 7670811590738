import { useState } from 'react';
import { Modal } from 'rsuite';
import styled from 'styled-components';
import { ExportModes } from '../../store/unit/types';
import CustomButton from '../Buttons/CustomButton';
import CustomRadio from '../CustomRadio';

type InventoryManagerDeleteModalProps = {
    showModel: boolean
    onClose: () => void
    handleExport: (made: ExportModes) => void
}
function ExportInventoryModal({ onClose, showModel, handleExport }: InventoryManagerDeleteModalProps) {
  const [exportMode, setExportMode] = useState<ExportModes>('active');

  const onExportClick = () => {
    handleExport(exportMode);
  };

  return (
    <Modal
      open={showModel}
      onClose={() => onClose()}
    >
      <StyledModalHeader />
      <Modal.Body>
        <ModalContainer>

          <h3>
            Download Inventory
          </h3>
          <div className="buttons">
            <div className="radio-wrapper">
              <CustomRadio
                label="active items"
                value="active"
                id="active"
                onChange={(value: ExportModes) => setExportMode(value)}
                checked={exportMode === 'active'}
              />
            </div>
            <div className="radio-wrapper">
              <CustomRadio
                label="active + archived"
                value="all"
                id="all"
                onChange={(value: ExportModes) => setExportMode(value)}
                checked={exportMode === 'all'}
              />
            </div>
            <div className="radio-wrapper">
              <CustomRadio
                label="archived"
                value="archived"
                id="archived"
                onChange={(value: ExportModes) => setExportMode(value)}
                checked={exportMode === 'archived'}
              />
            </div>
          </div>
          <CustomButton
            wrapperClassname="archive-btn"
            variant="primary"
            onClick={onExportClick}
          >
            Download
          </CustomButton>
        </ModalContainer>
      </Modal.Body>
    </Modal>
  );
}

export default ExportInventoryModal;

const StyledModalHeader = styled.div`
display: flex;
justify-content: flex-end;
  button {
    padding: 0;
    margin: 0;
    background: transparent;
  }
  img {
    cursor: pointer;
    transition: all ease 0.2s;
    :hover {
      transform: scale(1.2);
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    margin-top: 10px;
  }
  .archive-btn {
    width: 121px;
    height: 35px;
    margin-top: 32px;
  }
  .buttons {
    margin-top: 11px;
    font-size: 18px;
    color: ${(props) => props.theme.secondaryTextColor};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .radio-wrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .rs-radio-wrapper::after {
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
  .rs-radio-wrapper::before, .rs-radio-wrapper::after, .rs-radio-wrapper .rs-radio-inner::before, .rs-radio-wrapper .rs-radio-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }
`;
