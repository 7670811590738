import { useState, useEffect } from 'react';

const useResize = (): { isMobile: boolean, clientWidth: number } => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [clientWidth, setClientWidth] = useState<number>(document.documentElement.clientWidth);
  useEffect(() => {
    function handleResize() {
      setIsMobile(document.documentElement.clientWidth < 768);
      setClientWidth(document.documentElement.clientWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile, clientWidth };
};

export default useResize;
