import { useCallback, useState } from 'react';
import styled from 'styled-components';
import HistoryTab from './HistoryTab';
import ImportTab from './ImportTab';

const tabs = [
  { label: 'Import CSV', key: 'import' },
  { label: 'History', key: 'history' },
] as const;
function CSVSection() {
  const [activeTab, setActiveTab] = useState<typeof tabs[number]['key']>('import');

  const renderTab = useCallback(() => {
    switch (activeTab) {
      case 'import':
        return <ImportTab />;
      default:
        return <HistoryTab />;
    }
  }, [activeTab]);

  return (
    <Container>
      <div className="header">
        {tabs.map((tab) => (
          <div
            className={`header-item ${tab.key === activeTab && 'active'}`}
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      {renderTab()}
    </Container>
  );
}

export default CSVSection;

const Container = styled.div`
  padding-top: 83px;
  font-size: 14px;
  color: ${(props) => props.theme.primaryTextColor};
  background-color: ${(props) => props.theme.primaryBGColor};
  .required {
    color: ${(props) => props.theme.primaryColor};
    margin-left: 2px;
  }
  .header {
   display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f3f3f3;
   .header-item {
     height: 39px;
     padding-left: 10px;
     padding-right: 10px;
     display: flex;
     align-items:center;
     justify-content:center;
     cursor: pointer;
     user-select: none;
     &.active {
       color: ${(props) => props.theme.primaryColor};
       background-color: #fff;
       font-weight: bold;
     }
   }
 }
  .content {
    padding-left: 23px;
    padding-right: 40px;
    text-align: start;
    .section-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 26px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }      
      .label {
        font-weight: bold;
        font-size: 25px;
        color: #444444;
      }
      .download-buttons {
        display: flex;
        gap: 10px;
        flex-direction: column;
      }
      .download-btn {
        button {
          font-size: 16px;
          text-transform: initial;
        }
      }
    }
    .text {
      font-weight: normal;
      margin-top: 18px;
      font-size: 16px;
      max-width: 744px;
      .click-here {
        text-decoration: underline;
        cursor: pointer;
        transition: all ease 0.2s;
        :hover {
          font-weight: bold;
        }
      }
    }
    .input-label {
      margin-top: 47px;
    }
  }
  .dropzone-container {
    position: relative;
    max-width: 752px;
    .loader {
      position: absolute;
      right: 5%;
      top: 5%;
    }
  }  
    
  .dropzone {
    border: 0.5px dashed ${(props) => props.theme.primaryColor};
    background-color: #fef4e2;
    border-radius: 4px;
    height: 193px;
    max-width: 752px;
    @media screen and (max-width: 768px) {
      min-width: 100px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    .custom-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #1d2343;
      font-size: 12px;
      img {
        width: 70px;
      }
      .label {
        font-weight: bold;
      }
      .button-wrap {
        button {
          border-radius: 3px;
          font-size: 14px;
          text-transform: unset;
        }
      }
    }
  }
  .download-btn {
    a {
      text-decoration: none;
      color: inherit;
    }
  }
`;
