const Pages = {
  home: '/',
  login: '/login',
  resetPassword: '/reset-password/:token/:email',
  resetPasswordClear: '/reset-password',
  adminLogin: '/admin-login',
  craigslistLogin: '/craigslist-login',
  registration: '/registration',
  inventoryManager: '/inventory-manager',
  editUnit: '/edit-unit/:id',
  editUnitPure: '/edit-unit',
  addUnit: '/add-unit/:id',
  addUnitPure: '/add-unit',
  settings: '/settings',
  settingsWithParams: '/settings/:section',
  location: '/location',
  LocationSetting: '/settings/location',
  CSVSetting: '/settings/csv',
  ImagePlaceholderSetting: '/settings/image-placeholder',
  SchedulerSetting: '/scheduler',
  SchedulerProfileFromSetting: '/scheduler/profile-form/:mode/:id',
  SchedulerProfileFromSettingPure: '/scheduler/profile-form',
  SchedulerFbProfileFromSetting: '/scheduler/fb-profile-form/:mode/:id',
  SchedulerFbProfileFromSettingPure: '/scheduler/fb-profile-form',
  SchedulerSettingTabs: '/scheduler/:type/:tab',
  SchedulerSettingPure: '/scheduler/',
  IntegrationsSetting: '/settings/integrations',
  OverlaySetting: '/settings/overlay',
  UsersSetting: '/settings/users',
  editLocation: '/location/:id',
  adminDashboard: '/admin-dashboard/:section',
  adminDashboardPure: '/admin-dashboard',
  dealerCreation: '/admin-dashboard/dealer',
  dealerEdit: '/admin-dashboard/dealer/:id',
  dealersPage: '/admin-dashboard/dealers',
  providersPage: '/admin-dashboard/providers/:id',
  providersPagePure: '/admin-dashboard/providers',
  providerCreation: '/admin-dashboard/provider',
  providerEdit: '/admin-dashboard/provider/:id',
  adminUsersPage: '/admin-dashboard/users',
  adminUserCreation: '/admin-dashboard/user',
  adminUserEdit: '/admin-dashboard/user/:id',
  feedsPage: '/admin-dashboard/feeds',
  showroomsPage: '/admin-dashboard/showrooms',
  rolesPage: '/admin-dashboard/roles',
  rolePage: '/admin-dashboard/roles/:id',
  rolePurePage: '/admin-dashboard/roles',
  billing: '/billing',
  billingWithParams: '/settings/:section',
  billingAddFunds: '/billing/add-funds',
  billingHistory: '/billing/history',
};

export default Pages;
