import axios, {
  AxiosError, AxiosResponse, InternalAxiosRequestConfig,
} from 'axios';
import { clearLocalStorage, getLocalStorage } from '../helpers';
import Pages from '../Router/pages';
import { LocalStorageKeys } from '../store/keys';

export type AxiosResponseInterface<RespData, ErrorData = Record<string, never>> = AxiosResponse<RespData> | (AxiosError<ErrorData>);

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
};

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers,
});

const setAuthHeader = (config: InternalAxiosRequestConfig, token: string) => {
  const newConfig = { ...config };
  newConfig.headers.Authorization = `Bearer ${token}`;
  return newConfig;
};

axiosInstance.interceptors.request.use((config) => {
  const token = getLocalStorage(LocalStorageKeys.TOKEN);
  return token ? setAuthHeader(config, token) : config;
});

const nonLogoutExceptions = [
  'ftp credentials invalid',
  'ksl ftp credentials invalid',
];

axiosInstance.interceptors.response.use((response: AxiosResponse) => response, (error) => {
  if (error.response?.status === 401) {
    // The nonLogoutExceptions condition will handle invalid credential issues related with non auth requests to avoid changing the backend.
    if ((window.location.pathname !== Pages.login && window.location.pathname !== Pages.adminLogin) && !nonLogoutExceptions.includes(error?.response?.data?.message.toLowerCase())) {
      clearLocalStorage();
      if (!window.location.pathname.includes(Pages.login) && !window.location.pathname.includes(Pages.adminLogin) && !window.location.pathname.includes(Pages.resetPasswordClear)) {
        window.location.href = Pages.login;
      }
    }
    if (error?.response?.status === 429) {
      clearLocalStorage();
    }
  }
  throw error;
});
