import moment from 'moment';
import { SoldUnitRequest, UnitType } from '../store/unit/actions';

export const useSoldUnit = () => {
  const soldReq = SoldUnitRequest();
  return (updatedUnit?: UnitType) => {
    const pastSchedules = updatedUnit?.dealer_schedules?.filter((schedule) => (moment(schedule.schedule_date_time).diff(moment()) < 0) && schedule.status === 'Successful');
    const clSchedules = pastSchedules?.filter((schedule) => schedule.type === 'craigslist');
    if (clSchedules?.length) {
      soldReq.mutate(clSchedules?.map((scheduler) => scheduler.id));
    }
    const urgentScheduleIds = clSchedules?.filter((schedule) => schedule.status === 'Successful' || schedule.status === 'Urgent' || schedule?.is_urgent).map((schedule) => schedule?.id);
    if (urgentScheduleIds) {
      window.postMessage({ type: 'STATUS', text: urgentScheduleIds }, '*');
    }
  };
};
