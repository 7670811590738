import { Checkbox } from 'rsuite';
import styled from 'styled-components';

function CustomCheckbox({ ...props }) {
  return (
    <StyledCheckbox
      {...props}
    />
  );
}

export default CustomCheckbox;

const StyledCheckbox = styled(Checkbox)`
  &.rs-checkbox-inner::before {
    border-color: ${(props) => props.theme.primaryColor};
  }
  &.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before {
    background-color: ${(props) => props.theme.primaryColor};
  }
  &.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    background-color: ${(props) => props.theme.primaryColor};
  }
`;
