import iro from '@jaames/iro';
import React, {
  createRef, MutableRefObject, RefObject, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { stringToHex } from '../../helpers';

interface IroColorPicker {
    on: (action: string, callback: (color: { hexString: string }) => void) => void;
    color: {
        hexString: string;
        set: (value: string) => void;
        setState: (state: string) => void;
    }
}

export interface ColorPickerProps {
    className?: string;
    value?: string;
    label?: string;
    onChange?: (color: string) => void;
}

function ColorPicker({
  className,
  value = '#6cbc63',
  label,
  onChange,
  ...props
}: ColorPickerProps) {
  const colorPicker: MutableRefObject<IroColorPicker | null> = useRef<IroColorPicker | null>(null);
  const el: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();
  const pickerContainer: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

  const [inputColor, setInputColor] = useState<string>(value);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!el.current) {
      return;
    }
    if (!colorPicker.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      colorPicker.current = new iro.ColorPicker(el.current, { color: inputColor, height: 152, width: 156 });
      if (!colorPicker.current) {
        return;
      }
      colorPicker.current.on('color:change', (color: { hexString: string }) => {
        onChange?.(color.hexString);
        setInputColor(color.hexString);
      });
    } else if (inputColor !== colorPicker.current.color.hexString) {
      colorPicker.current.color.set(inputColor);
    }
  }, []);

  useEffect(() => { // hide on click outside
    function handleClickOutside(event: MouseEvent) {
      const target = event?.target as HTMLDivElement;
      if (pickerContainer.current && !pickerContainer.current.contains(target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [el, pickerContainer]);

  return (
    <PickerContainer className={className}>
      <PickerPopup className={`${isOpen && 'show'}`}>
        <div className="container" ref={pickerContainer}>
          <div ref={el} />
          <div className="color-text-input">
            <input
              value={inputColor}
              maxLength={7}
              onChange={(event) => {
                if (event.target.value.startsWith('#')) {
                  setInputColor(event.target.value);
                } else {
                  setInputColor(`#${event.target.value}`);
                }
              }}
              onBlur={(event) => {
                const color = stringToHex(event.target.value);
                setInputColor(color);
                colorPicker?.current?.color.set(color);
              }}
              {...props}
            />
          </div>
        </div>
      </PickerPopup>
      <label htmlFor="color-picker">{label}</label>
      <StyledInput
        type="color"
        id="html5colorpicker"
        value={inputColor}
        onClick={(event) => {
          setIsOpen(!isOpen);
          event.preventDefault();
        }}
        onChange={(event) => {
          setInputColor(event.target.value);
        }}
      />
    </PickerContainer>
  );
}

export default ColorPicker;

const PickerContainer = styled.div`
position: relative;
display: flex;
align-items: center;
  label {
    margin-right: 10px;
    color: #232323;
  }
`;

const StyledInput = styled.input`
  padding: 5px 9px 7px 12px; 
  border-radius: 4px;
  border-style: solid;
  height: 39px;
  font-size: 14px;
  border-width: 0.5px;
  outline-color: #d2cece;
  border-color: #d2cece;
  color:${(props) => props.theme.primaryTextColor};
  background-color: #fff;
  ::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
    padding: 0;
  }
  ::-webkit-color-swatch-wrapper {
    padding: 0;
    background-color: #fff;
  }
  &:focus {
    outline-color: ${(props) => props.theme.primaryColor};
  }
`;

const PickerPopup = styled.div`
    display: none;
    z-index: 100;
  &.show {
    display: block;
    position: absolute;
    bottom: 40px;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
    padding: 30px;
    width: 256px;
    height: 310px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.29);
    .color-text-input {
      margin-top: 22px;
      input {
        padding: 8px;
        border-radius: 4px;
        border-style: solid;
        height: 39px;
        font-size: 14px;
        border-width: 0.5px;
        outline-color: #d2cece;
        border-color: #d2cece;
        color:${(props) => props.theme.primaryTextColor};
        &:focus {
          outline-color: ${(props) => props.theme.primaryColor};
        }
      }
    }
  }
`;
