import * as Y from 'yup';

export const createAdminUserFormSchema = Y.object().shape({
  first_name: Y.string().required('This field is required'),
  last_name: Y.string().required('This field is required'),
  email: Y.string().email().required('This field is required'),
  password: Y.string().required('This field is required'),
  password_confirmation: Y.string()
    .required('No confirm password provided.')
    .oneOf([Y.ref('password')], 'Passwords must match'),
  department: Y.string().required('This field is required'),
});

export const updateAdminUserFormSchema = Y.object().shape({
  first_name: Y.string().required('This field is required'),
  last_name: Y.string().required('This field is required'),
  email: Y.string().email().required('This field is required'),
  password: Y.string()
    .oneOf([Y.ref('password_confirmation')], 'Passwords must match'),
  password_confirmation: Y.string()
    .oneOf([Y.ref('password')], 'Passwords must match'),
  department: Y.string().required('This field is required'),
});
