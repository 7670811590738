import {
  PaymentElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { FormEvent } from 'react';
import { useAppMessageContext } from '../../../appMessagesContext';
import Pages from '../../../Router/pages';

function Stripe() {
  const { showMessage } = useAppMessageContext();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${Pages.billingAddFunds}`,
      },
    });

    if (error.message) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      showMessage({ type: 'error', message: error?.message });
    }
    return null;
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button type="submit">Submit</button>
    </form>
  );
}

export default Stripe;
