import PlusRoundIcon from '@rsuite/icons/PlusRound';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppMessageContext } from '../../../appMessagesContext';
import editIco from '../../../assets/edit.png';
import CustomButton from '../../../components/Buttons/CustomButton';
import CustomRadio from '../../../components/CustomRadio';
import CustomTable from '../../../components/CustomTable';
import DeleteModal from '../../../components/DeleteModal';
import BinSvg from '../../../components/SvgElements/BinSvg';
import useNavigateHook from '../../../hooks/useNavigateHook';
import Pages from '../../../Router/pages';
import {
  DeleteLocationRequest,
  UpdateLocationRequest,
  UpdateMainLocationRequest,
} from '../../../store/settings/actions';
import { LocationsI } from '../../../store/settings/requests';

interface LocationSectionProps {
    locationData: LocationsI[]
    refetch: () => void
    loading?: boolean
}
function LocationSection({ locationData, refetch, loading }: LocationSectionProps) {
  const { showMessage } = useAppMessageContext();
  const navigate = useNavigateHook();
  const updateLocationRequest = UpdateLocationRequest();
  const deleteLocationRequest = DeleteLocationRequest();
  const updateMainLocationRequest = UpdateMainLocationRequest();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const handleMainLocationChange = (data: LocationsI) => {
    updateMainLocationRequest.mutate({ id: String(data.id) || '' });
  };

  useEffect(() => {
    if (updateLocationRequest.isSuccess || updateMainLocationRequest.isSuccess) {
      refetch();
    }
    if (deleteLocationRequest.isSuccess && !deleteLocationRequest.isPending) {
      refetch();
      showMessage({ type: 'success', message: 'Location(s) Deleted successfully' });
    }
  }, [updateLocationRequest.isSuccess, deleteLocationRequest.isSuccess, deleteLocationRequest.isPending, updateMainLocationRequest.isSuccess]);

  const modalText = () => (
    <div>
      <h3>
        Permanently Delete
      </h3>
      <div className="text">
        Are you sure you would like
        <br />
        to Permanently Delete these
        <br />
        Location(s)?
      </div>
    </div>
  );
  const handleModalAccept = () => {
    setShowModal(false);
    deleteLocationRequest.mutate({ ids: selectedItems });
  };

  return (
    <>
      <SectionHeader className="button-container">
        <div>
          {selectedItems.length
            ? (
              <IconWrapper className="remove-ico" onClick={() => setShowModal(true)}>
                <BinSvg />
              </IconWrapper>
            ) : null}
        </div>
        <CustomButton
          variant="primary"
          className="add-button"
          wrapperClassname="button-wrapper"
          onClick={() => navigate(Pages.location)}
        >
          <PlusRoundIcon />
          <span>Add location</span>
        </CustomButton>
      </SectionHeader>
      <div className="table-wrapper">
        <CustomTable
          loading={!!loading}
          data={locationData.map((dataRow: LocationsI) => ({
            ...dataRow,
            is_main: <LocationCell item={dataRow} onChange={() => handleMainLocationChange(dataRow)} />,
            actionButtons: <ActionCell item={dataRow} />,
            street_address: <AddressCell item={dataRow} />,
            hideSelect: dataRow.is_main,
          }))}
          columns={columns}
          onItemSelect={(selected) => {
            setSelectedItems(selected);
          }}
          selectable
          showHeader
          displayTotal={false}
          displayPagination={false}
          hideSelectHeader
        />
      </div>
      <DeleteModal
        openObj={showModal}
        onApproveClose={handleModalAccept}
        onClose={() => setShowModal(false)}
        mainContent={modalText()}
      />
    </>
  );
}

export default LocationSection;

function AddressCell({ item }: {item: LocationsI}) {
  return (
    <div>
      {item?.street_address}
      {', '}
      {item?.county}
      {', '}
      {item?.city}
      {', '}
      {item.state}
      {', '}
      {item?.zip_code}
    </div>
  );
}

function ActionCell({ item }: {item: LocationsI}) {
  const navigate = useNavigateHook();
  return (
    <ActionButtonsContainer>
      <IconWrapper onClick={() => {
        navigate(`${Pages.location}/${item.id}`);
      }}
      >
        <img src={editIco} alt="edit" />
      </IconWrapper>
    </ActionButtonsContainer>
  );
}

interface LocationCellProps {
    item: LocationsI
    onChange: (checked: boolean) => void
}
function LocationCell({ item, onChange }: LocationCellProps) {
  return (
    <LocationCellContainer>
      <CustomRadio
        onChange={(value, checked) => {
          onChange(checked);
        }}
        checked={item.is_main}
        value="is_main"
        id="is_main"
      />
    </LocationCellContainer>
  );
}

const LocationCellContainer = styled.div`
  width: 100px;
`;
const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 13px;
  justify-content: flex-end;
  width: 60px;
`;

const SectionHeader = styled.div`
    display: flex;
    margin-top: 22px;
    justify-content: end;
    margin-right: 40px;
    padding-left: 40px;
    .button-wrapper {
      max-width: 190px;
    }
    .add-button {
      display: flex;
      align-items: center;
      svg {
        width: 23px;
        height: 23px;
        margin-left: 6px;
      }
      span {
        margin-left: 8px;
      }
    }
    .remove-ico {
      width: 61px;
      height: 61px;
      margin-right: 40px;
      img {
        width: 34px;
        height: 34px;
      } 
    }
  
`;
const IconWrapper = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.primaryColor};
  transition: all ease 0.2s;
  :hover {
    border: 2px solid ${(props) => props.theme.primaryColor};
  }
  svg {
    width: 34px;
    height: 34px;
  }
`;

const columns = [
  {
    label: 'Main Location',
    key: 'is_main',
    width: 125,
  },
  {
    label: 'Location Name',
    key: 'name',
  },
  {
    label: 'Location ID',
    key: 'id',
  },
  {
    label: 'Phone',
    key: 'phone_number',
  },
  {
    label: 'Address',
    key: 'street_address',
  },
  {
    label: '',
    key: 'actionButtons',
    width: 120,
  },
];
