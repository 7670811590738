import { useFormik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import CustomButton from '../../../../../components/Buttons/CustomButton';
import CustomInput from '../../../../../components/inputs/CustomInput';
import CustomSelect, { SelectValue } from '../../../../../components/inputs/CustomSelect';
import TextArea from '../../../../../components/inputs/TextArea';
import { formSelectOptions } from '../../../../../helpers';
import { useGetSelectedDealer } from '../../../../../hooks/getDealersHook';
import { getMeRequest } from '../../../../../store/auth/actions';
import {
  CreateCLProfileRequestData,
  getCLProfileOptionsRequest, getLocationsRequest,
} from '../../../../../store/settings/actions';
import { LocationsI } from '../../../../../store/settings/requests';
import { LocationFilters } from '../../../../../store/unit/actions';
import { clProfileFormSchema } from '../../../../../validation/clProfileFormSchema';

export type ProfileTabFormT = {
  name: string
  location_city: SelectValue
  location_district: SelectValue
  contact_name: string
  phone_number: string
  address: LocationsI | null
  category: SelectValue
  condition: SelectValue
  description: string
}

type CLProfileFormProps = {
  initialValueProps?: ProfileTabFormT
  onSubmit: (data: ProfileTabFormT) => void
  requestErrors?: CreateCLProfileRequestData
}

function ProfileTabForm({ initialValueProps, onSubmit, requestErrors }: CLProfileFormProps) {
  const { responseData: options } = getCLProfileOptionsRequest();
  const selectedDealer = useGetSelectedDealer();
  const user = getMeRequest()?.data?.data;
  const locationsData = getLocationsRequest(
    { dealer_id: (user?.dealer_id ? String(user?.dealer_id) : undefined) || (selectedDealer?.id ? String(selectedDealer?.id) : undefined) },
  )?.data?.data || [];

  const initialValues: ProfileTabFormT = initialValueProps || {
    name: '',
    location_city: null,
    location_district: null,
    contact_name: '',
    phone_number: '',
    address: null,
    category: null,
    condition: null,
    description: '',
  };

  const formik = useFormik<ProfileTabFormT>({
    initialValues,
    onSubmit,
    validationSchema: clProfileFormSchema,
    validate: (values) => {
      if (options.location_districts[formikValues?.location_city?.value || '']?.length && !values.location_district) {
        return {
          location_district: 'This field is required',
        };
      }
      return {};
    },
    enableReinitialize: true,
  });

  const {
    setFieldValue, values: formikValues, errors, touched,
  } = formik;

  const handleInputChange = (key: keyof ProfileTabFormT, value: string | SelectValue) => {
    setFieldValue(key, value);
  };

  const addressLabel = (data: LocationsI) => (
    <div>
      {data.name
      && (
      <span>
        {data.name}
        ,
        {' '}
        {data.city}
        ,
        {' '}
        {data.county}
        ,
        {' '}
        {data.state}
        ,
        {' '}
        {data.zip_code}
      </span>
      )}
    </div>
  );

  const valueContainerStyles = {
    paddingLeft: '4px',
  };

  return (
    <Container>
      <div className="title">
        Profile
      </div>
      <form onSubmit={(e) => {
        e.preventDefault();
        formik.submitForm();
      }}
      >
        <div className="name-input">
          <div className="input-wrapper">
            <CustomInput
              wrapperClass="text-input"
              label="Profile Name"
              required
              value={formikValues.name}
              onChange={(value) => handleInputChange('name', value)}
              maxLength={250}
              error={errors.name && touched?.name ? errors.name : requestErrors?.name}
            />
          </div>
        </div>
        <div className="inputs-row">
          <div className="input-wrapper">
            <CustomSelect
              className="select-input"
              label="Location City"
              required
              value={formikValues.location_city}
              options={formSelectOptions(options.location_cities)}
              onChange={(value) => {
                handleInputChange('location_city', value);
                handleInputChange('location_district', null);
              }}
              valueContainerStyles={valueContainerStyles}
              error={errors.location_city && touched?.location_city ? errors.location_city : requestErrors?.location_city}
            />
          </div>
          <div className="input-wrapper">
            <CustomSelect
              className="select-input location-district"
              label="Location District"
              required={!!options.location_districts[formikValues?.location_city?.value || '']?.length}
              disabled={!options.location_districts[formikValues?.location_city?.value || '']?.length}
              value={formikValues.location_district}
              options={formSelectOptions(options.location_districts[formikValues?.location_city?.value || ''] || [])}
              onChange={(value) => handleInputChange('location_district', value)}
              valueContainerStyles={valueContainerStyles}
              error={errors.location_district && touched?.location_district ? errors.location_district : requestErrors?.location_district}
            />
          </div>
        </div>
        <div className="single-input-row">
          <div className="input-wrapper">
            <CustomSelect
              className="select-input"
              label="Address"
              required
              value={{
                ...formikValues.address,
                value: formikValues.address?.name,
                label: formikValues.address?.name,
              }}
              options={locationsData.map((el) => ({
                ...el,
                value: el.name,
                label: el.name,
              }))}
              onChange={(value) => handleInputChange('address', value)}
              formatOptionLabel={(data) => addressLabel(data as unknown as LocationsI)}
              isOptionSelected={(data: LocationFilters) => data.id === formikValues.address?.id}
              valueContainerStyles={valueContainerStyles}
              error={errors.address && touched?.address ? errors.address : requestErrors?.location_id as string}
            />
          </div>
        </div>
        <div className="textfield-row">
          <TextArea
            label="Description"
            value={formikValues.description}
            maxLength={250}
            onChange={(e) => handleInputChange('description', e.target.value)}
          />
        </div>
        <div className="save-row">
          <CustomButton className="save-btn">Save</CustomButton>
        </div>
      </form>
    </Container>
  );
}

export default ProfileTabForm;

const Container = styled.div`
  margin-top: 17px;
 .title {
   color: ${(props) => props.theme.primaryColor};
   font-size: 25px;
   font-weight: bold;
 }
  form {
    max-width: 746px;
    background-color: #fff;
    padding-top: 25px;
    padding-left: 31px;
    padding-right: 53px;
    padding-bottom: 119px;
    margin-left: 95px;
    @media screen and (max-width: 768px) {
      padding-left: 11px;
      padding-right: 33px;
      margin-left: 0;
    }
  }
  .name-input {
    .text-input {
      display: flex;
      flex-direction: row;
      .input-wrapper, .select-wrap {
        input {
          width: 100%;
        }
      }
      label {
        text-align: end;
        width: 140px;
        padding-right: 28px;
        margin-left: 10px;
          @media screen and (max-width: 768px) {
            width: 200px;
          }
        }
    }
  }
  .inputs-row {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 650px) {
      flex-direction: column;
      > :nth-child(2) {
        margin-top: 10px;
      }
      margin-top: 10px;
    }
    .select-wrap {
      max-width: 200px;
    }
    .text-input, .select-input {
      display: grid;
      grid-template-columns: 2fr 3fr;
      .input-wrapper, .select-wrap {
        min-width: 195px;
        @media screen and (max-width: 768px) {
          min-width: 105px;
          label {
            margin-right: 20px;
          }
        }
      }
      label {
        width: 100px;
        text-align: end;
        @media screen and (max-width: 768px) {
          margin-left: 0;
          margin-right: 24px;
        }
      }
      &.location-district {
        label {
          margin-left: 19px;
          min-width: 106px;
          margin-right: 0;
          @media screen and (max-width: 768px) {
              margin-left: 0;
              margin-right: 6px;
            }
          }
      }
    }
    .category {
      .select-wrap {
        margin-left: -2px;
      }
    }
  }
  .single-input-row ,.inputs-row, .textfield-row, .name-input {
    margin-bottom: 17px;
  }
  .single-input-row {
    .select-wrap {
      margin-left: 5px;
    }
    label {
      width: 150px;
      @media screen and (max-width: 768px) {
        width: 180px;
      }
      text-align: end;
      padding-right: 29px;
      margin-right: 0;
    }  
  }
  .phone-input {
    input {
      height: 39px;
      padding-left: 5px;
    }
  }
  .textfield-row {
    .text-area-wrapper {
      display: flex;
      label {
        text-align: end;
        width: 130px;
        padding-right: 28px;
        margin-left: 20px;
      }
      textarea {
        width: 100%;
        height: 113px;
        font-size: 14px;
        border-width: 0.5px;
        outline-color: #d2cece;
        border-color: #d2cece;
        color: ${(props) => props.theme.primaryTextColor};
      }
    }
  }
  input {
    min-width: 195px;
    @media screen and (max-width: 768px) {
      min-width: 185px;
    }
  }
  .select-input {
    .select-wrap {
      height: 38px;
    }
    label {
      font-weight: normal;
    }  
  }
  .save-row {
    text-align: end;
    .save-btn {
      max-width: 100px;  
    }
  }
`;
