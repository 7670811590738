import { Modal } from 'rsuite';
import styled from 'styled-components';
import clearIco from '../../../../assets/clear.png';
import CustomButton from '../../../../components/Buttons/CustomButton';
import BinSvg from '../../../../components/SvgElements/BinSvg';

type WarningModalProps = {
    open: boolean
    onClose: () => void
    onApproveClose: () => void
    locationsQuantity?: number
}

function WarningModal({
  open, onClose, onApproveClose, locationsQuantity,
}: WarningModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <StyledModalHeader>
        <button type="button" onClick={onClose}>
          <img src={clearIco} alt="clear" />
        </button>
      </StyledModalHeader>
      <Modal.Body>
        <ModalContainer>
          <IconWrapper className="remove-ico">
            <BinSvg />
          </IconWrapper>
          <div className="text">
            Deleting this location will also delete
            {' '}
            {locationsQuantity}
            {' '}
            inventory items at this location.
          </div>
          <div className="text">
            Are you sure you want to proceed?
          </div>
          <CustomButton
            wrapperClassname="archive-btn"
            variant="primary"
            onClick={onApproveClose}
          >
            delete
          </CustomButton>
        </ModalContainer>
      </Modal.Body>
    </Modal>
  );
}

export default WarningModal;

const StyledModalHeader = styled.div`
display: flex;
justify-content: flex-end;
  button {
    padding: 0;
    margin: 0;
    background: transparent;
  }
  img {
    cursor: pointer;
    transition: all ease 0.2s;
    :hover {
      transform: scale(1.2);
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h3 {
    margin-top: 10px;
  }
  .text {
    margin-top: 11px;
    font-size: 18px;
    color: ${(props) => props.theme.secondaryTextColor};
  }
  .archive-btn {
    width: 121px;
    height: 35px;
    margin-top: 32px;
  }
  img {
    width: 52px;
    height: 52px;
  }
`;

const IconWrapper = styled.div`
  svg {
    height: 52px;
    width: 52px;
  }
`;
