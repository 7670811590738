import { useEffect, useState } from 'react';
import { queryClient } from '../App';
import { IntegrationMockType } from '../pages/SettingsSections/data';
import { RequestKeys } from '../store/keys';

export const useGetExtensionDealer = (): boolean => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const integrationData = queryClient.getQueryData<IntegrationMockType>([RequestKeys.INTEGRATION_STORE]);
  const extensionNode = document.getElementById('extension-id');
  const extensionID = extensionNode?.getAttribute('data-ex-id');
  useEffect(() => {
    if (extensionNode) {
      setIsConnected(true);
    }
  }, [extensionNode, extensionID]);
  useEffect(() => {
    if (integrationData?.craigsList?.extensionId) {
      setIsConnected(true);
    }
  }, [integrationData]);
  return isConnected;
};
