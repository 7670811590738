import styled from 'styled-components';
import { ChangeEvent, InputHTMLAttributes, useId } from 'react';
import searchIco from '../../../assets/search.png';

interface SearchInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>{
    onChangePure?: (event: ChangeEvent<HTMLInputElement>) => void
    label?: string
    onChange?: (value: string) => void
}

function SearchInput({
  placeholder = 'Search', value, onChange, onChangePure, ...props
}:SearchInputProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangePure?.(event);
    onChange?.(event.target.value);
  };
  const generatedId = useId();
  return (
    <SearchInputWrapper className="search__container">
      <input
        id={props?.id || generatedId}
        className="search__input"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        {...props}
      />
    </SearchInputWrapper>
  );
}

export default SearchInput;

const SearchInputWrapper = styled.div`
  width: 100%;

  .search__input {
    width: 100%;
    padding: 7px 59px;
    padding-left: 43px;
    background-color: #fff;
    font-size: 14px;
    line-height: 18px;
    color: #575756;
    background-image: url("${searchIco}");
    background-repeat: no-repeat;
    background-size: 18px 18px;
    background-position: 4.4% center;
    border-radius: 23px;
    border: 1px solid #c1c1c1;
    height: 100%;
    @media screen and (max-width: 768px) {
      padding: 7px 6px 7px 26px;
    } 
    @media screen and (max-width: 1050px) {
      padding: 7px 6px 7px 26px;
    } 
    @media screen and (max-width: 1400px) {
      padding: 7px 6px 7px 36px;
    } 
  }

  input::placeholder {
    color: #c1c1c1 !important;
    font-size: 16px;
    line-height: 1.3rem;
    padding-left: 10px;
  }
  transition: all ease 0.2s;
  .search__input:hover,
  .search__input:focus {
    outline: 1px solid #c1c1c1;
    border: none;
  }
`;
