import moment from 'moment-timezone';
import { RenderImageT, UploadedFile } from '../components/FileUploader';
import { SelectValue } from '../components/inputs/CustomSelect';
import { UnitFormInterface } from '../pages/UnitPage';
import { websiteSelectOptions } from '../pages/UnitPage/Sections/WebsiteConfiguration';
import {
  CreateUnitRequestData, FeatureKeys, ItemAttributesResponse, ItemSpecificAttribute, UnitType,
} from '../store/unit/actions';
import { convertInToInFt, formSelectOptions, TimeOption } from './index';
import { AutopostingDataFormData } from '../pages/SettingsSections/SchedulerSection/ScheduleTab';

export const formUnitFormData = (unit: UnitType | undefined): UnitFormInterface => {
  const featureIds = Object.keys(unit?.features || {}).map((featureKey: FeatureKeys) => unit?.features?.[featureKey] || [])
    .flat(1).filter((el) => !el.is_custom || (el.is_custom && el.is_active))
    .map((el) => el.id);
  return {
    specifics: {
      inventoryType: unit?.item_type || null,
      item_category: unit?.item_category || null,
      title: unit?.title || '',
      stock: unit?.stock || '',
      condition: unit?.condition ? formSelectOptions([unit?.condition])[0] : null,
      autopopulate: null,
      year: unit?.year ? formSelectOptions([unit?.year])[0] : null,
      manufacturer: unit?.manufacturer ? formSelectOptions([unit?.manufacturer])[0] : null,
      status: unit?.status ? formSelectOptions([unit?.status])[0] : null,
      dealership_location: unit?.dealership_location || null,
      vin: unit?.vin || '',
      model_name: unit?.model_name || '',

      chassis_year: unit?.chassis_year ? formSelectOptions([unit?.chassis_year])[0] : null,
      brand: unit?.brand || '',
    },
    details: {
      notes: unit?.details?.notes || '',

      price: unit?.details?.price ? String(unit?.details?.price) : '',
      websitePrice: unit?.details?.website_price ? String(unit?.details?.website_price) : '',
      rebatePrice: unit?.details?.rebate_price ? String(unit?.details?.rebate_price) : '',
      sales_price: unit?.details?.sales_price ? String(unit?.details?.sales_price) : '',
      msrp: unit?.details?.msrp ? String(unit?.details?.msrp) : '',
      hiddenPrice: unit?.details?.hidden_price ? String(unit?.details?.hidden_price) : '',
      pymt: unit?.details?.monthly_payment ? String(unit?.details?.monthly_payment) : '',

      florHeightTotal: unit?.details?.floor_height ? String(unit?.details?.floor_height) : '',
      florHeightIn: '',
      florHeightFt: '',

      florLengthTotal: unit?.details?.floor_length ? String(unit?.details?.floor_length) : '',
      florLengthIn: '',
      florLengthFt: '',

      florWidthTotal: unit?.details?.floor_width ? String(unit?.details?.floor_width) : '',
      florWidthIn: '',
      florWidthFt: '',
      // .split('.')[0] is because some values saved with decimals, but should be numeric
      weight: unit?.details?.weight ? String(unit?.details?.weight).split('.')[0] : '',
      payloadCapacity: unit?.details?.payload_capacity ? String(unit?.details?.payload_capacity).split('.')[0] : '',
      gvwr: unit?.details?.gvwr ? String(unit?.details?.gvwr).split('.')[0] : '',
      axleCapacity: unit?.details?.axle_capacity ? String(unit?.details?.axle_capacity).split('.')[0] : '',

      unit_cost: unit?.admin_details?.unit_cost || '',
      prep_fee: unit?.admin_details?.prep_fee || '',
      freight_cost: unit?.admin_details?.freight_cost || '',
      packaging_fee: unit?.admin_details?.packaging_fee || '',
      total_cost: unit?.admin_details?.total_cost || '0.00',
      true_total_cost: unit?.admin_details?.true_total_cost || '0.00',
    },
    description: {
      markdown: unit?.details?.description || '',
    },
    attributes: {
      ...unit?.attributes,

      living_quarters: unit?.attributes?.living_quarters ? formSelectOptions(['Yes'])[0] : formSelectOptions(['No'])[0],
      manger: unit?.attributes?.manger ? formSelectOptions(['Yes'])[0] : formSelectOptions(['No'])[0],
      is_rental: unit?.attributes?.is_rental ? formSelectOptions(['Yes'])[0] : formSelectOptions(['No'])[0],
      is_tilt: unit?.attributes?.is_tilt ? formSelectOptions(['Yes'])[0] : formSelectOptions(['No'])[0],

      overallLengthTotal: unit?.attributes?.overall_length || '',
      overallLengthIn: unit?.attributes?.overall_length ? String(convertInToInFt(Number(unit?.attributes?.overall_length)).inches) : '',
      overallLengthFt: unit?.attributes?.overall_length ? String(convertInToInFt(Number(unit?.attributes?.overall_length)).feet) : '',

      minWidthTotal: unit?.attributes?.min_width || '',
      minWidthIn: unit?.attributes?.min_width ? String(convertInToInFt(Number(unit?.attributes?.min_width)).inches) : '',
      minWidthFt: unit?.attributes?.min_width ? String(convertInToInFt(Number(unit?.attributes?.min_width)).feet) : '',

      minLengthTotal: unit?.attributes?.min_length || '',
      minLengthIn: unit?.attributes?.min_length ? String(convertInToInFt(Number(unit?.attributes?.min_length)).inches) : '',
      minLengthFt: unit?.attributes?.min_length ? String(convertInToInFt(Number(unit?.attributes?.min_length)).feet) : '',

      sideWallHeightTotal: unit?.attributes?.side_wall_height || '',
      sideWallHeightIn: unit?.attributes?.side_wall_height ? String(convertInToInFt(Number(unit?.attributes?.side_wall_height)).inches) : '',
      sideWallHeightFt: unit?.attributes?.side_wall_height ? String(convertInToInFt(Number(unit?.attributes?.side_wall_height)).feet) : '',

      minHeightTotal: unit?.attributes?.min_height || '',
      minHeightIn: unit?.attributes?.min_height ? String(convertInToInFt(Number(unit?.attributes?.min_height)).inches) : '',
      minHeightFt: unit?.attributes?.min_height ? String(convertInToInFt(Number(unit?.attributes?.min_height)).feet) : '',

      axleCapacity: unit?.attributes?.axleCapacity || '',
      axles: unit?.attributes?.axles || '',
      bodyStyle: unit?.attributes?.bodyStyle || '',
      bore: unit?.attributes?.bore || '',
      clearancePrice: unit?.attributes?.clearancePrice || '',
      color: unit?.attributes?.color || '',
      colors: unit?.attributes?.colors || '',
      compressionRatio: unit?.attributes?.compressionRatio || '',
      construction: unit?.attributes?.construction || '',
      cooling: unit?.attributes?.cooling || '',
      coupler: unit?.attributes?.coupler || '',
      curbWeight: unit?.attributes?.curbWeight || '',
      displacement: unit?.attributes?.displacement || '',
      electrical: unit?.attributes?.electrical || '',
      emissions: unit?.attributes?.emissions || '',
      emptyWeight: unit?.attributes?.emptyWeight || '',
      engineNumber: unit?.attributes?.engineNumber || '',
      engineType: unit?.attributes?.engineType || '',
      expectedDate: unit?.attributes?.expectedDate || '',
      featureOptions: unit?.attributes?.featureOptions || '',
      fenders: unit?.attributes?.fenders || '',
      finalDrive: unit?.attributes?.finalDrive || '',
      firstPrice: unit?.attributes?.firstPrice || '',
      floorHeight: unit?.attributes?.floorHeight || '',
      floorLength: unit?.attributes?.floorLength || '',
      floorWidth: unit?.attributes?.floorWidth || '',
      frontBrakingSystem: unit?.attributes?.frontBrakingSystem || '',
      frontSuspension: unit?.attributes?.frontSuspension || '',
      frontTire: unit?.attributes?.frontTire || '',
      frontTravel: unit?.attributes?.frontTravel || '',
      fuelCapacity: unit?.attributes?.fuelCapacity || '',
      groundClearance: unit?.attributes?.groundClearance || '',
      hitch: unit?.attributes?.hitch || '',
      ignition: unit?.attributes?.ignition || '',
      induction: unit?.attributes?.induction || '',
      intColor: unit?.attributes?.intColor || '',
      inventoryCategoryId: unit?.attributes?.inventoryCategoryId || '',
      inventoryCategoryName: unit?.attributes?.inventoryCategoryName || '',
      inventoryId: unit?.attributes?.inventoryId || '',
      inventoryTypeName: unit?.attributes?.inventoryTypeName || '',
      isActiveTmp: unit?.attributes?.isActiveTmp || '',
      jack: unit?.attributes?.jack || '',
      lengthTotal: unit?.attributes?.lengthTotal || '',
      livingQuarters: unit?.attributes?.livingQuarters || '',
      location: unit?.attributes?.location || '',
      locationAddress: unit?.attributes?.locationAddress || '',
      locationCellphone: unit?.attributes?.locationCellphone || '',
      locationCity: unit?.attributes?.locationCity || '',
      locationCustomerId: unit?.attributes?.locationCustomerId || '',
      locationEmail: unit?.attributes?.locationEmail || '',
      locationFax: unit?.attributes?.locationFax || '',
      locationId: unit?.attributes?.locationId || '',
      locationName: unit?.attributes?.locationName || '',
      locationPhoneNumber: unit?.attributes?.locationPhoneNumber || '',
      locationPhoneTollFree: unit?.attributes?.locationPhoneTollFree || '',
      locationState: unit?.attributes?.locationState || '',
      locationZip: unit?.attributes?.locationZip || '',
      make: unit?.attributes?.make || '',
      manufacturedDate: unit?.attributes?.manufacturedDate || '',
      model: unit?.attributes?.model || '',
      modelId: unit?.attributes?.modelId || '',
      modelNumber: unit?.attributes?.modelNumber || '',
      noseType: unit?.attributes?.noseType || '',
      odometer: unit?.attributes?.odometer || '',
      options: unit?.attributes?.options || '',
      payloadCapacity: unit?.attributes?.payloadCapacity || '',
      protectionPlan: unit?.attributes?.protectionPlan || '',
      purchaseDate: unit?.attributes?.purchaseDate || '',
      raketrail: unit?.attributes?.raketrail || '',
      ramp: unit?.attributes?.ramp || '',
      ramps: unit?.attributes?.ramps || '',
      rearBrakingSystem: unit?.attributes?.rearBrakingSystem || '',
      rearDoors: unit?.attributes?.rearDoors || '',
      rearSuspension: unit?.attributes?.rearSuspension || '',
      rearTire: unit?.attributes?.rearTire || '',
      rearTravel: unit?.attributes?.rearTravel || '',
      redTagPrice: unit?.attributes?.redTagPrice || '',
      roofType: unit?.attributes?.roofType || '',
      salesCategory: unit?.attributes?.salesCategory || '',
      seatHeight: unit?.attributes?.seatHeight || '',
      secondPrice: unit?.attributes?.secondPrice || '',
      sideDoors: unit?.attributes?.sideDoors || '',
      sleeps: unit?.attributes?.sleeps || '',
      slides: unit?.attributes?.slides || '',
      stalls: unit?.attributes?.stalls || '',
      stock: unit?.attributes?.stock || '',
      stroke: unit?.attributes?.stroke || '',
      suspension: unit?.attributes?.suspension || '',
      thirdPrice: unit?.attributes?.thirdPrice || '',
      title: unit?.attributes?.title || '',
      tongue: unit?.attributes?.tongue || '',
      totalCost: unit?.attributes?.totalCost || '',
      transmission: unit?.attributes?.transmission || '',
      valveTrain: unit?.attributes?.valveTrain || '',
      warranty: unit?.attributes?.warranty || '',
      webPrice: unit?.attributes?.webPrice || '',
      wheelbase: unit?.attributes?.wheelbase || '',
      wheels: unit?.attributes?.wheels || '',
      widthTotal: unit?.attributes?.widthTotal || '',
      year: unit?.attributes?.year || '',
    },
    features: {
      features: featureIds,
      custom_features: [],
      features_to_delete: [],
    },
    website: {
      is_active: !!unit?.website_configuration?.is_active,
      racing_junk: !!unit?.website_configuration?.racing_junk,
      is_featured: unit?.website_configuration?.is_featured ? websiteSelectOptions[0] : websiteSelectOptions[1],
      is_on_special: unit?.website_configuration?.is_on_special ? websiteSelectOptions[0] : websiteSelectOptions[1],
      custom_status: unit?.website_configuration?.custom_status ? formSelectOptions([unit?.website_configuration?.custom_status])[0] : null,
      video_embed_code: unit?.website_configuration?.video_embed_code || [],
    },
    images: {
      overlaySetting: unit?.overlay_setting ? formSelectOptions([unit?.overlay_setting])[0] : null,
      files: [],
      filesFromResponse: unit?.images?.sort((imageA, imageB) => imageA.order - imageB.order) || [],
    },
    pdfs: {
      files: [],
      filesFromResponse: unit?.pdfs?.sort((a, b) => a.order - b.order) || [],
    },
    hiddenFiles: {
      files: [],
      filesFromResponse: unit?.hidden_files?.sort((a, b) => a.order - b.order) || [],
    },
  };
};

export const formUnitRequestData = (data: UnitFormInterface, itemAttributes: ItemAttributesResponse[], specificAttributes: ItemSpecificAttribute[] | undefined, dealerId: number, isEditPage: boolean): CreateUnitRequestData => {
  const formSpecifics: CreateUnitRequestData['specifics'] = {
    title: data?.specifics?.title,
    item_type_id: Number(data.specifics.inventoryType?.id),
    stock: data.specifics.stock,
    condition: data.specifics.condition?.value ? String(data.specifics.condition.value) : '',
    autopopulate: data.specifics.autopopulate?.value ? String(data.specifics.autopopulate?.value) : '',
    year: data.specifics.year?.value ? `${String(data.specifics.year?.value)}` : '',
    manufacturer: data.specifics.manufacturer?.value ? String(data.specifics.manufacturer?.value) : '',
    status: data.specifics.status?.value ? String(data.specifics.status?.value) : '',
    dealership_location_id: Number(data.specifics.dealership_location?.id),
    vin: data.specifics.vin,
    model_name: data.specifics.model_name || '',
    item_category_id: Number(data.specifics.item_category?.id),
    ...{ ...(data.specifics.brand && { brand: data.specifics.brand }) },
    ...{ ...(data.specifics.chassis_year && { chassis_year: data.specifics.chassis_year?.value ? `${String(data.specifics.chassis_year.value)}` : '' }) },
    overlay_setting: data?.images?.overlaySetting?.value ? String(data.images.overlaySetting.value) : 'All',
  };
  const formDetailsData: CreateUnitRequestData['details'] = {
    price: Number(data.details.price),
    hidden_price: data.details.hiddenPrice ?? '',
    msrp: data.details.msrp ?? '',
    monthly_payment: data.details.pymt ?? '',
    sales_price: data.details.sales_price ?? '',
    website_price: data.details.websitePrice ?? '',
    rebate_price: data.details.rebatePrice ?? '',
    notes: data.details.notes,
    floor_height: data.details.florHeightTotal ?? '',
    floor_length: data.details.florLengthTotal ?? '',
    floor_width: data.details.florWidthTotal ?? '',
    gvwr: data.details.gvwr ?? '',
    weight: data.details.weight ?? '',
    payload_capacity: data.details.payloadCapacity ?? '',
    axle_capacity: data.details.axleCapacity ?? '',
    description: data.description.markdown,
  };

  const formAdminDetailsData: CreateUnitRequestData['admin_details'] = {
    unit_cost: data?.details?.unit_cost,
    prep_fee: data?.details?.prep_fee,
    freight_cost: data?.details?.freight_cost,
    packaging_fee: data?.details?.packaging_fee,
    total_cost: data?.details?.total_cost || '0.00',
    true_total_cost: data?.details?.true_total_cost || '0.00',
  };

  const formAttributesData = (): {[key: string]: string} => {
    const result: {[key: string]: string} = {};

    const attributesByInventory = itemAttributes.find((attribute) => attribute.name === data?.specifics.inventoryType?.name)?.attributes
        || [];
    const isActiveRental = (data.attributes?.is_rental as SelectValue)?.value === 'Yes';

    const attributeKeys = [...attributesByInventory.map((attribute) => attribute.name), ...isActiveRental ? ['is_rental', 'weekly_rate', 'daily_rate', 'monthly_rate'] : []];
    const booleanAttributeSelectKeys = ['is_rental', 'is_tilt', 'living_quarters', 'manger']; // BE expect boolean in these selects
    attributeKeys.forEach((key) => {
      if (typeof data.attributes[key] === 'object' && data.attributes[key] !== null) {
        Object.assign(result, { [key]: String((data.attributes[key] as SelectValue)?.value) });
        if (booleanAttributeSelectKeys.includes(key)) {
          const boolAttribute = (data.attributes[key] as SelectValue)?.value === 'Yes' ? 1 : 0;
          Object.assign(result, { [key]: boolAttribute });
        }
      }
      if (typeof data.attributes[key] === 'string') {
        Object.assign(result, { [key]: data.attributes[key] });
      }
      if (data.attributes[key] === null) {
        Object.assign(result, { [key]: ' ' });
      }
    });
    const keysToDelete = [ // these attribute keys exists only on FE
      'overallLengthTotal',
      'overallLengthIn',
      'overallLengthFt',
      'minWidthTotal',
      'minWidthIn',
      'minWidthFt',
      'minLengthTotal',
      'minLengthIn',
      'minLengthFt',
      'sideWallHeightTotal',
      'sideWallHeightIn',
      'sideWallHeightFt',
      'minHeightTotal',
      'minHeightIn',
      'minHeightFt',
    ];
    keysToDelete.forEach((key) => {
      delete result[key];
    });
    if (attributeKeys.includes('side_wall_height')) {
      Object.assign(result, { side_wall_height: data.attributes.sideWallHeightTotal || '' });
    }
    specificAttributes?.forEach((categories) => {
      Object.keys(categories).forEach((catKey) => {
        categories[catKey]?.forEach((sections: ItemSpecificAttribute) => {
          Object.keys(sections).forEach((secKey) => {
            sections[secKey]?.forEach((attributes: ItemSpecificAttribute) => {
              if (typeof data.attributes[attributes?.name] === 'object' && data.attributes[attributes?.name] !== null) {
                Object.assign(result, { [attributes?.name]: String((data.attributes[attributes?.name] as SelectValue)?.value) });
                if (booleanAttributeSelectKeys.includes(attributes?.name)) {
                  const boolAttribute = (data.attributes[attributes?.name] as SelectValue)?.value === 'Yes' ? 1 : 0;
                  Object.assign(result, { [attributes?.name]: boolAttribute });
                }
              }
              if (typeof data.attributes[attributes?.name] === 'string' && data.attributes[attributes?.name] !== '') {
                Object.assign(result, { [attributes?.name]: data.attributes[attributes?.name] });
              }
              /* if (data.attributes[attributes?.name] === null) {
                Object.assign(result, { [attributes?.name]: ' ' });
              } */
            });
          });
        });
      });
    });
    return result;
  };

  const filteredVideoEmbedField = data.website.video_embed_code?.filter((code) => code);

  const formWebsiteData: CreateUnitRequestData['website_config'] = {
    is_active: data.website.is_active ? 1 : 0,
    racing_junk: data.website.racing_junk ? 1 : 0,
    is_featured: data.website.is_featured?.value === 'Yes' ? 1 : 0,
    is_on_special: data.website.is_on_special?.value === 'Yes' ? 1 : 0,
    custom_status: data.website.custom_status?.value ? String(data.website.custom_status?.value) : '',
    video_embed_code: filteredVideoEmbedField.length > 0 ? filteredVideoEmbedField : [''],
  };

  const formImages: {[key: `images[${number}]`]: UploadedFile | string | number} = {};
  if (data.images.files?.length) {
    data.images.files.forEach((file: RenderImageT, index) => {
      formImages[`images[${index}]`] = file.isFile ? file as UploadedFile : file.name;
    });
  } else {
    data.images.filesFromResponse.forEach((file: RenderImageT, index) => {
      formImages[`images[${index}]`] = file.name;
    });
  }

  const formPdfs: {[key: `pdfs[${number}]`]: UploadedFile | number} = {};
  data.pdfs.files.forEach((file: RenderImageT, index) => {
    formPdfs[`pdfs[${index}]`] = file.isFile ? file as UploadedFile : file.id;
  });

  const formHiddenFiles: {[key: `hidden_files[${number}]`]: UploadedFile | number} = {};
  data.hiddenFiles.files.forEach((file: RenderImageT, index) => {
    formHiddenFiles[`hidden_files[${index}]`] = file.isFile ? file as UploadedFile : file.id;
  });

  const formCustomFeatures: CreateUnitRequestData['custom_features'] = data.features.custom_features.map((feature) => ({
    name: feature.name,
    item_feature_id: feature.item_feature_id,
    is_active: feature.is_active ? 1 : 0,
  }));

  const formedRequestData: CreateUnitRequestData = {
    specifics: formSpecifics,
    details: formDetailsData,
    admin_details: formAdminDetailsData,
    attributes: {
      ...formAttributesData(),
      overall_length: data.attributes.overallLengthTotal || '',
      min_width: data.attributes.minWidthTotal || '',
      min_length: data.attributes.minLengthTotal || '',
      min_height: data.attributes.minHeightTotal || '',
    },
    features: data.features.features,
    ...((!data.features.features.length && isEditPage) && { 'features[]': [''] }), // required only on edit
    custom_features: formCustomFeatures,
    features_to_delete: data.features.features_to_delete,
    website_config: formWebsiteData,
    ...formImages,
    ...((!Object.keys(formImages).length && isEditPage) && { 'images[]': [''] }), // required only on edit
    ...formPdfs,
    ...((!Object.keys(formPdfs).length && isEditPage) && { 'pdfs[]': [''] }), // required only on edit
    ...formHiddenFiles,
    ...((!Object.keys(formHiddenFiles).length && isEditPage) && { 'hidden_files[]': [''] }), // required only on edit
    dealer_id: dealerId,
  };
  return formedRequestData;
};

export const getDateForSchedulerPosting = (selectedDate: Date | undefined) => moment(selectedDate).format('DD.MM.YYYY');

export const datesArray = (from: moment.Moment, to: moment.Moment, interval: number) => {
  const ret: moment.Moment[] = [];
  const fromDate = moment(from);
  const toDate = moment(to);
  let date = fromDate.add(interval, 'days');
  while (toDate > date) {
    ret.push(date);
    date = moment(date).add(interval, 'days');
  }
  return ret;
};
export const dateOptions = datesArray(moment().subtract(1, 'day'), moment().add(1, 'month'), 1).map((day) => {
  const label = `${day.toDate().toLocaleDateString('en-US', { weekday: 'long' })}, ${day.toDate().toLocaleDateString('en-US', { month: 'long' })}, ${day.toDate().getDate()}`;
  return { label, value: label, date: day.toDate() };
});

export const formTimeValue = (initialTime: string | undefined | null): AutopostingDataFormData['autopost_end_time'] | null => {
  if (initialTime) {
    const time = initialTime;
    const formatedTime = moment().set({ hours: Number(time.split(':')[0]), minute: Number(time.split(':')[1]) }).format('hh:mm A');
    return {
      value: formatedTime,
      label: formatedTime,
      time: formatedTime.split(' ')[0],
      meridiem: formatedTime.split(' ')[1] as TimeOption['meridiem'],
    };
  }
  return null;
};

export const timeOptionToStringFormat = (timeOption: TimeOption) => {
  const formated = moment().set({
    hours: Number(timeOption.time.split(':')?.[0]) || 0,
    minute: Number(timeOption.time.split(':')[1]) || 0,
  });
  if (timeOption.time.split(':')[0] === '12') {
    formated.subtract(12, 'hour');
  }
  if (timeOption.meridiem === 'PM') {
    formated.add(12, 'hour');
  }
  return formated.format('HH:mm');
};
