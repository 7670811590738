import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Calendar, Loader, Tooltip, Whisper,
} from 'rsuite';
import styled from 'styled-components';
import { useAppMessageContext } from '../../../../appMessagesContext';
import CustomButton from '../../../../components/Buttons/CustomButton';
import DeleteModal from '../../../../components/DeleteModal';
import CustomSelect from '../../../../components/inputs/CustomSelect';
import BinSvg from '../../../../components/SvgElements/BinSvg';
import RoundPlus from '../../../../components/SvgElements/RoundPlus';
import { ApplicationTheme } from '../../../../globalStyles';
import { formSelectOptions, roleHasAccess } from '../../../../helpers';
import { useGetSelectedDealer } from '../../../../hooks/getDealersHook';
import useNavigateHook from '../../../../hooks/useNavigateHook';
import Pages from '../../../../Router/pages';
import { getMeRequest } from '../../../../store/auth/actions';
import { SupperAdminRoles } from '../../../../store/constants';
import {
  DeleteScheduleRequest,
  getCLProfilesRequest,
  getCLSchedulesRequest,
  GetCLSchedulesRequestParams,
  ScheduleT,
} from '../../../../store/settings/actions';
import { LocationsI } from '../../../../store/settings/requests';
import { SchedulerSectionTabsT } from '../index';
import { SchedulerDetailsDataT, ProfileOption } from '../ScheduleTab';

export type CalendarEventStatus = 'Successful' | 'Scheduled' | 'Failed' | 'Low Budget' | 'Urgent' | 'Expired' | 'Sold'

export type CalendarEventUnit = {
    id: number | null
    time: string
    title: string
    stock: string
    price: string | number | null
    location: LocationsI | null
    status: CalendarEventStatus
    date: moment.Moment
    posting_id: null | string
    posting_url: null | string
} | null
function getTodoList(date: Date, eventsData: ScheduleT[]): CalendarEventUnit[] {
  const filtered: ScheduleT[] = eventsData.filter((schedule: ScheduleT) => {
    const eventData = moment(`${schedule.date_UTC}`).utc(true).set({
      hour: Number(schedule.time_UTC.split(':')[0]),
      minute: Number(schedule.time_UTC.split(':')[1]),
    }).toDate();
    eventData.getDate();
    return moment(moment(date).startOf('day')).isSame(moment(eventData).startOf('day'));
  });
  return filtered.map((event: ScheduleT) => ({
    id: event?.id || null,
    time: event?.time_UTC || '',
    title: event?.item?.title || '',
    stock: event?.item?.stock || '',
    location: event?.profile?.address || null,
    price: event?.item?.details?.price || '',
    status: event?.status === 'Urgent' ? 'Scheduled' : event?.status,
    date: moment(`${event.date_UTC}`).set({
      hours: Number(event?.time_UTC.split(':')[0]),
      minutes: Number(event?.time_UTC.split(':')[1]),
    }),
    posting_id: event?.posting_id,
    posting_url: event?.posting_url,
  }));
}
type RenderCellProps = {
  date: Date
  schedulesData: ScheduleT[]
  setShowModal: (item: CalendarEventUnit) => void
}
function RenderCell({ date, schedulesData, setShowModal }: RenderCellProps) {
  const navigate = useNavigateHook();
  const list = getTodoList(date, schedulesData);
  const params = useParams<{tab: SchedulerSectionTabsT, type: SchedulerDetailsDataT['type']}>();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urgentQuery = Object.fromEntries(urlSearchParams.entries()).urgent ? Object.fromEntries(urlSearchParams.entries()).urgent : '0';
  const handlePlusClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const initialType: SchedulerDetailsDataT['type'] = params.type || 'facebook';
    navigate(`${Pages.SchedulerSettingPure + (initialType)}/posting?date=${moment(date).format('DD.MM.yyyy')}${urgentQuery === '1' ? '&urgent=1' : ''}`);
  };

  const renderEvent = (item: CalendarEventUnit) => {
    const tooltip = (
      <StyledTooltip>
        <div className="tooltip">
          <span>
            #
            {item?.stock}
          </span>
          <br />
          <span>
            {item?.title}
          </span>
          <br />
          <span>
            {item?.location?.street_address}
          </span>
          <br />
          <span>
            {item?.price ? `$ ${item?.price}` : null}
          </span>
          <br />
          {item?.posting_url
          && (
          <a href={item?.posting_url} target="_blank" rel="noreferrer">
            {item?.posting_url}
          </a>
          )}
        </div>
      </StyledTooltip>
    );

    return (
      <Whisper placement="top" controlId="control-id-focus" trigger="click" speaker={tooltip}>
        <div className="calendar-event-wrap">
          <div className="event-stock">
            #
            {item?.stock}
            {' '}
          </div>
          <div className="event-title">
            {item?.title}
            {' '}
          </div>
          <div className="event-time">{moment(item?.date.utc(true).toDate()).format('hh:mm A')}</div>
          {item?.status !== 'Successful'
          && (
          <div className="remove-div">
            <button type="button" className="remove-bin" onClick={() => setShowModal(item)}>
              <BinSvg fill={ApplicationTheme.primaryColor} stroke={ApplicationTheme.primaryColor} />
            </button>
          </div>
          )}
        </div>
      </Whisper>
    );
  };
  return (
    <StyledCellList className="calendar-todo-list">
      {list.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index} className={item?.status}>
          {renderEvent(item)}
        </li>
      ))}
      {!list.length
            && (
            <div className="plus-btn">
              <CustomButton variant="text" onClick={handlePlusClick}>
                <RoundPlus />
              </CustomButton>
            </div>
            )}
    </StyledCellList>
  );
}
type PendingTabProps = {
  schedulerType: GetCLSchedulesRequestParams['type']
  detailsData: SchedulerDetailsDataT
}
function PendingTab({ schedulerType, detailsData }: PendingTabProps) {
  const { showMessage } = useAppMessageContext();
  const [calendarDate, setCalendarDate] = useState<Date>(new Date());
  const selectedDealer = useGetSelectedDealer();
  const [showModal, setShowModal] = useState<CalendarEventUnit>(null);
  const [deleteModal, setDeleteModal] = useState<{ all: boolean } | false>(false);
  const deleteRequest = DeleteScheduleRequest();

  const [params, setParams] = useReducer(
    (state: GetCLSchedulesRequestParams, newState: Partial<GetCLSchedulesRequestParams>) => ({
      ...state,
      ...newState,
    }),
    {
      type: schedulerType,
      is_urgent: '1',
    },
  );
  useEffect(() => {
    setParams({ type: schedulerType, profile_id: schedulerType === 'craigslist' ? params?.profile_id : undefined });
  }, [schedulerType]);

  useEffect(() => {
    if (detailsData.type === 'facebook') {
      setParams({ is_urgent: undefined });
    } else {
      setParams({ is_urgent: detailsData.urgent ? '1' : '0' });
    }
  }, [detailsData.urgent, detailsData.type]);
  const getMe = getMeRequest();
  const dealersIDReqLoading = (roleHasAccess(getMe?.data?.data.role, SupperAdminRoles) ? !!selectedDealer?.id : true);
  const { responseData: profilesData } = getCLProfilesRequest({ dealer_id: selectedDealer?.id }, !dealersIDReqLoading);
  const startDate = moment(calendarDate.toISOString()).subtract(1, 'month');
  const endDate = moment(calendarDate.toISOString()).add(1, 'month');
  const { responseData: schedulesData, isFetching: scheduleIsLoading, refetch: refetchSchedules } = getCLSchedulesRequest({
    ...params,
    dealer_id: selectedDealer?.id,
    date: [
      `${startDate.daysInMonth()}.${startDate.get('month') + 1}.${startDate.get('years')}`,
      `${endDate.daysInMonth()}.${endDate.get('month') + 1}.${endDate.get('years')}`,
    ],
  }, dealersIDReqLoading);
  const handleMonthView = () => { // simulate click on month to open year selection dropdown
    const button = document.getElementsByClassName('rs-calendar-header-title  rs-calendar-header-title-date  rs-btn  rs-btn-subtle  rs-btn-sm')[0] as HTMLElement;
    button.click();
  };
  const handleNextMonth = () => {
    setCalendarDate(new Date(calendarDate.setMonth(calendarDate.getMonth() + 1)));
  };
  const handlePrevMonth = () => {
    setCalendarDate(new Date(calendarDate.setMonth(calendarDate.getMonth() - 1)));
  };

  useEffect(() => {
    setTimeout(() => {
      const collection = document.getElementsByClassName('rs-calendar-table-cell');
      Array.from(collection).forEach((node) => {
        if (node?.ariaLabel && moment(node?.ariaLabel).startOf('date').toDate() < moment().startOf('date').toDate()) {
          node.classList.add('past'); // bg color for past dates
        }
      });
    }, 0);
  }, [calendarDate]);

  const statusOptions = formSelectOptions(['Successful', 'Scheduled', 'Sold', 'Failed', 'Expired', 'Low Budget']);
  type StatusOptionType = {
    label: CalendarEventStatus
    value: CalendarEventStatus
  }
  const statusOptionLabel = (status: StatusOptionType) => <div className={`status-option ${status.value}`}>{status.label}</div>;

  const profileOptions: ProfileOption[] = profilesData.map((profile) => ({
    ...profile,
    value: profile.name,
    label: profile.name,
  }));
  const handleModalAccept = (item: CalendarEventUnit) => {
    if (item?.id) {
      deleteRequest.mutate({ ids: [item.id], ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }) });
    }
  };
  useEffect(() => {
    if (deleteRequest.isSuccess) {
      deleteRequest.reset();
      showMessage({ type: 'success', message: 'Schedule Deleted successfully' });
      setShowModal(null);
      refetchSchedules();
    }
  }, [deleteRequest.isSuccess]);

  const modalText = () => (
    <div>
      <h3>
        Permanently Delete
      </h3>
      <div className="text">
        Are you sure you would like
        <br />
        to Permanently Delete this
        <br />
        schedule?
      </div>
    </div>
  );

  const deleteAllModalText = () => (
    <div>
      <h3>
        Permanently Delete
      </h3>
      <div className="text">
        Are you sure you would like
        <br />
        to Permanently Delete
        {' '}
        {(typeof deleteModal === 'object' && deleteModal?.all) ? 'all' : 'failed' }
        <br />
        schedules?
      </div>
    </div>
  );
  const handleDeleteAll = () => {
    setDeleteModal(false);
    deleteRequest.mutate({ delete_all: params?.type, ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }) });
  };
  const handleDeleteAllFailed = () => {
    setDeleteModal(false);
    const ids = schedulesData.filter((schedule) => schedule.status === 'Failed' || schedule.status === 'Expired')
      ?.map((schedule) => schedule.id);
    deleteRequest.mutate({ ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }), ids });
  };
  return (
    <Container className="scheduled-section">
      <div>
        <div className="calendar-header left">
          <div className="remove-buttons">
            <CustomButton onClick={() => setDeleteModal({ all: true })}>
              Remove all schedules
            </CustomButton>
            <CustomButton onClick={() => setDeleteModal({ all: false })}>
              Remove all failed
            </CustomButton>
          </div>
          <div className="middle">
            <div className="month-wrap">
              <CustomButton variant="text" wrapperClassname="button left" onClick={handlePrevMonth}><StyledArrow /></CustomButton>
              <span onClick={handleMonthView} className="month">
                {calendarDate?.toLocaleString('en-us', { month: 'long' })}
              </span>
              <CustomButton variant="text" wrapperClassname="button right" onClick={handleNextMonth}><StyledArrow /></CustomButton>
            </div>
            <span onClick={handleMonthView} className="year">
              {calendarDate?.toLocaleString('en-us', { year: 'numeric' })}
            </span>
          </div>
          <div className="right">
            {schedulerType === 'craigslist'
                && (
                <div className="profile-select-wrap">
                  <CustomSelect
                    label="Profile (Location)"
                    options={profileOptions}
                    onChange={(val: ProfileOption) => setParams({ profile_id: val?.id })}
                    controlStyles={{
                      borderRadius: '4px',
                      overflow: 'hidden',
                    }}
                    indicatorContainerStyles={{
                      borderLeft: '1px solid #bfbcbc',
                      height: 'inherit',
                      marginTop: '0',
                      backgroundColor: ApplicationTheme.primaryBGColor,
                      width: '34px',
                      svg: {
                        position: 'absolute',
                        top: '0',
                        bottom: '0',
                        margin: 'auto 0',
                        marginTop: '5px',
                        right: '40px',
                      },
                      div: {
                        padding: '0',
                      },
                    }}
                    placeholder="Profile"
                    isClearable
                  />
                </div>
                )}
            <div className="status-select-wrap">
              <CustomSelect
                label=" "
                onChange={(val: StatusOptionType) => setParams({ status: val?.value })}
                controlStyles={{
                  borderRadius: '4px',
                  overflow: 'hidden',
                }}
                indicatorContainerStyles={{
                  borderLeft: '1px solid #bfbcbc',
                  height: 'inherit',
                  marginTop: '0',
                  backgroundColor: ApplicationTheme.primaryBGColor,
                  width: '34px',
                  svg: {
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    margin: 'auto 0',
                    marginTop: '5px',
                    right: '40px',
                  },
                  div: {
                    padding: '0',
                  },
                }}
                optionStyles={{
                  padding: '0',
                }}
                options={statusOptions}
                placeholder="Post Status"
                isClearable
                formatOptionLabel={(data: StatusOptionType) => statusOptionLabel(data)}
              />
            </div>
          </div>
        </div>
        <div className="calendar-container">
          {scheduleIsLoading
          && (
          <div className="loader">
            <Loader center size="lg" color="red" />
          </div>
          )}
          <Calendar
            renderCell={(date) => <RenderCell date={date} schedulesData={schedulesData} setShowModal={setShowModal} />}
            value={calendarDate}
            onChange={setCalendarDate}
            onSelect={setCalendarDate}
            locale={{
              sunday: 'Sunday',
              monday: 'Monday',
              tuesday: 'Tuesday',
              wednesday: 'Wednesday',
              thursday: 'Thursday',
              friday: 'Friday',
              saturday: 'Saturday',
              formattedMonthPattern: 'MMMM',
              hours: 'Hours',
              minutes: 'Minutes',
              seconds: 'Seconds',
            }}
            bordered
          />
          <DeleteModal<CalendarEventUnit>
            openObj={showModal}
            onApproveClose={handleModalAccept}
            onClose={() => setShowModal(null)}
            mainContent={modalText()}
          />
          <DeleteModal
            openObj={deleteModal}
            onApproveClose={() => {
              if ((typeof deleteModal === 'object' && deleteModal?.all)) {
                handleDeleteAll();
              } else {
                handleDeleteAllFailed();
              }
            }}
            onClose={() => setDeleteModal(false)}
            mainContent={deleteAllModalText()}
          />
        </div>
      </div>
    </Container>
  );
}

export default PendingTab;

const StyledTooltip = styled(Tooltip)`
 background-color: #c0c0c0;
  color: ${(props) => props.theme.primaryTextColor};
`;

const StyledCellList = styled.ul`
  list-style: none;
  padding: 0;
  height: 80%;
  position: relative;
  overflow: auto;
  font-size: 13px;

  :not(:hover) {
    ::-webkit-scrollbar {
      width: 2px;
    }
  }

  li {
    margin-top: 2px;
    text-align: start;

    &.Successful {
      background-color: #cbe8ba;
      border: 1px solid #92d36e;
      border-left: 3px solid #629c44;
    }

    &.Sold {
      background-color: #668ae9;
      border: 1px solid #031e66;
      border-left: 3px solid #1257fc;
    }

    &.Scheduled {
      background-color: #ffd783;
      border: 1px solid #ffa834;
      border-left: 3px solid #ffa834;
    }

    &.Failed {
      background-color: #ff8a84;
      border: 1px solid #ff3823;
      border-left: 3px solid #ff3823;
    }

    &.Expired {
      background-color: rgb(183 183 183 / 35%);
      border: 1px solid rgb(118 118 118);
      border-left: 3px solid rgb(118 118 118);
    }

    &.Low {
      background-color: #e692f8;
      border: 1px solid #d757f6;
      border-left: 3px solid #7e2199;
    }
  }

  .plus-btn {
    position: absolute;
    bottom: 5px;
    right: 5px;

    svg {
      height: 20px;
    }
  }
`;
const StyledArrow = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' viewBox='0 0 24 24' width='100%25' fill='%23747474' preserveAspectRatio='none'%3E%3Cpath d='M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z'/%3E%3C/svg%3E");
    width:  32px;
    height: 32px;
    margin-right: 4px;
`;

const Container = styled.div`
  text-align: start;
  .title {
    color: #444444;
    font-weight: bold;
    font-size: 25px;
    text-align: start;
    margin-top: 17px;
  }
  
  .calendar-header {
    .remove-buttons {
      min-width: 440px;
      display: flex;
      gap: 10px;
      @media screen and (max-width: 1000px) {
        flex-direction: column;
        div {
          max-width: 250px;
        }
      }
    }
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 120px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }
  }
  .calendar-container {
    position: relative;
    .loader {
      position: absolute;
      right: 50%;
      top: 40%;
    }
    }
  }
  .spend {
    display: flex;
    .spend-title {
      font-weight: bold;
      font-size: 18px;
      margin-top: 4px;
    }
    .spend-value {
      color: #629c44;
      font-weight: bold;
      font-size: 24px;
      margin-left: 10px;
    }
  }
  .funds-btn {
    margin-top: 17px;
    max-width: 80px;
    cursor: pointer;
    transition: all ease 0.2s;
    :hover {
      font-weight: bold;
    }
    svg {
      width: 37px;
      height: 37px;
    }
  }
  .month-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: ${(props) => props.theme.primaryColor};
    .month {
      min-width: 180px;
    }
    .button {
      width: 32px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &.left {
        transform: rotate(180deg);
      }
    }
    .month {
      cursor: pointer;
    }
  }
    .middle {
      flex:1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      .month-wrap {
        font-size: 36px;
      }
      .year {
        font-size: 14px;
        font-weight: bold;
      }
    }
  .right {
    //margin-left:auto;
    .profile-select-wrap {
      .select-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .select-wrap {
          height: 31px;
        }
      }
    }
    .status-select-wrap {
      margin-top: 15px;
      label {
        display: none;
      }
      display: flex;
      .select-wrap {
        width: 215px;
        height: 31px;
      }
      .add-profile-btn {
        margin-left: 10px;
      }
        button {
        text-decoration: none;
      }
      span {
        color: #444444;
        margin-left: 10px;
        font-size: 13px;
      }
    }
    .type-select-wrap {
      .select-wrap {
        width: 215px;
        height: 31px;
      }
      margin-top: 15px;
    }
  }
  
  .rs-calendar-header {
    display: none;
  }
  .rs-calendar-table-header-cell-content {
    width: 100%;
    display: block;
    background-color: #e5e5e5;
    border-right: 1px solid #606060;
    border-top: 1px solid #606060;
  }
  .rs-calendar-table-cell {
    border: 1px solid #606060;
    border-left: none;
  }
  .rs-calendar-table-cell-content {
    padding-left: 0;
    padding-right: 0;
  }
  .rs-calendar-table {
    border-left: 1px solid #606060;
  }
  [role="gridcell"] {
    &.past {
      background-color: #bfbcbc38;
    }
  }
  .rs-calendar-table-cell-day {
    text-align: start;
    margin-left: inherit!important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .status-option {
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 20px;
    &.Successful {
      border-left: 3px solid #629c44;
    }
    &.Sold {
      border-left: 3px solid #1257fc;
    }
    &.Scheduled {
      border-left: 3px solid #ffa834;
    }
    &.Failed {
      border-left: 3px solid #ff3823;
    }
    &.Expired {
      border-left: 3px solid rgb(118 118 118);
    }
    &.Low {
      border-left: 3px solid #7e2199;
    }
  }
  .calendar-event-wrap {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    color: ${(props) => props.theme.primaryTextColor};
    transition: all ease 0.2s;
    :hover {
     .remove-div {
       display: unset;
     } 
    }
    .remove-div {
      display: none;
      .remove-bin {
        height: 20px;
        width: 30px;
      }  
    }
    .event-time {
      margin-left: 5px;
      font-weight: bold;
      text-align: right;
    }
  .event-stock {
    overflow-x: clip;
  }
  .event-title {
    margin-left: 5px;
    display: inline-block;
    white-space: nowrap;
    overflow-x: hidden;
  }
`;
