import { Path } from '@remix-run/router/history';
import { useLocation, useNavigate } from 'react-router-dom';
import Pages from '../Router/pages';

export type LocationState = {
    page?: string | number
}

const useNavigateHook = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (to: string | Partial<Path> | number, newState?: LocationState) => {
    const formState = {
      ...location.state,
      ...newState,
    };
    if (to === Pages.inventoryManager) {
      navigate(`${Pages.inventoryManager}`, { state: formState });
    } else {
      navigate(to as string, { state: formState });
    }
  };
};

export default useNavigateHook;
