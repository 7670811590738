import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppMessageContext } from '../../../../appMessagesContext';
import { formSelectOptions } from '../../../../helpers';
import { SelectedDealer, useGetSelectedDealer } from '../../../../hooks/getDealersHook';
import useNavigateHook from '../../../../hooks/useNavigateHook';
import Pages from '../../../../Router/pages';
import {
  CreateCLProfileRequest,
  EditCLProfileRequest,
  getCLProfileByIdRequest,
} from '../../../../store/settings/actions';
import ProfileTabForm, { ProfileTabFormT } from './ProfileTabForm';
import ProfileTabTable from './ProfileTabTable';

export type CLProfilesPageMode = {
  mode: 'new' | 'edit' | 'duplicate'
  id: string
}
export const formCLProfileData = (data: ProfileTabFormT, selectedDealer: SelectedDealer) => ({
  name: data.name,
  location_city: data.location_city?.value ? String(data.location_city?.value) : '',
  ...{ ...(data.location_district?.value && { location_district: String(data.location_district?.value) }) },
  contact_name: data.contact_name,
  phone_number: data.phone_number,
  location_id: data.address?.id || 0,
  category: data.category?.value ? String(data.category?.value) : '',
  condition: data.condition?.value ? String(data.condition?.value) : '',
  description: data.description,
  ...(selectedDealer?.id && { dealer_id: Number(selectedDealer?.id) }),
});

function ProfilesTab() {
  const params = useParams<CLProfilesPageMode>();
  const { responseData } = getCLProfileByIdRequest(Number(params.id));
  const navigate = useNavigateHook();
  const { showMessage } = useAppMessageContext();

  const formInitialValues: ProfileTabFormT = {
    name: responseData?.name || '',
    location_city: responseData?.location_city ? formSelectOptions([responseData?.location_city])[0] : null,
    location_district: responseData?.location_district ? formSelectOptions([responseData?.location_district])[0] : null,
    contact_name: responseData?.contact_name || '',
    phone_number: responseData?.phone_number || '',
    address: responseData?.address ? responseData?.address : null,
    category: responseData?.category ? formSelectOptions([responseData?.category])[0] : null,
    condition: responseData?.condition ? formSelectOptions([responseData?.condition])[0] : null,
    description: responseData?.description || '',
  };

  const saveRequest = CreateCLProfileRequest();
  const editRequest = EditCLProfileRequest();
  const requestErrors = saveRequest?.error?.response?.data.errors || editRequest?.error?.response?.data.errors;
  const selectedDealer = useGetSelectedDealer();

  useEffect(() => {
    if (saveRequest.isSuccess) {
      saveRequest.reset();
      showMessage({ type: 'success', message: 'Profile Saved successfully' });
      navigate(Pages.SchedulerSetting);
    }
  }, [saveRequest.isSuccess]);
  useEffect(() => {
    if (editRequest.isSuccess) {
      editRequest.reset();
      showMessage({ type: 'success', message: 'Profile Updated successfully' });
      navigate(Pages.SchedulerSetting);
    }
  }, [editRequest.isSuccess]);

  const handleSubmit = (data: ProfileTabFormT) => {
    saveRequest.mutate(formCLProfileData(data, selectedDealer));
  };
  const handleEdit = (data: ProfileTabFormT) => {
    if (params.id) {
      editRequest.mutate({ data: formCLProfileData(data, selectedDealer), id: params.id });
    }
  };

  const renderTab = () => {
    switch (params.mode) {
      case 'edit':
        return <ProfileTabForm initialValueProps={formInitialValues} onSubmit={handleEdit} requestErrors={requestErrors} />;
      case 'duplicate':
        return <ProfileTabForm onSubmit={handleSubmit} requestErrors={requestErrors} initialValueProps={{ ...formInitialValues, name: '' }} />;
      case 'new':
        return <ProfileTabForm onSubmit={handleSubmit} requestErrors={requestErrors} />;
      default:
        return <ProfileTabTable />;
    }
  };

  return (
    <Container>
      {renderTab()}
    </Container>
  );
}

export default ProfilesTab;

const Container = styled.div`
  text-align: start;
  .header {
    text-align: end;
    margin-top: 10px;
  }
    .new-profile-button {
      width: 200px;
      margin-right: 20px;
      span {
        margin-left: 10px;
      }
    }
`;
