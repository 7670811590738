export enum RequestKeys {
    USER = 'USER',
    REGISTRATION = 'REGISTRATION',
    REGISTRATION_ERROR = 'REGISTRATION_ERROR',
    LOGIN = 'LOGIN',
    RESET_PASSWORD = 'RESET_PASSWORD',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    ADMIN_LOGIN = 'ADMIN_LOGIN',
    LOGIN_ERROR = 'LOGIN_ERROR',
    LOGOUT = 'LOGOUT',
    LOGOUT_ERROR = 'LOGOUT_ERROR',
    LOCATION_DATA = 'LOCATION_DATA',
    GET_LOCATION_DATA = 'GET_LOCATION_DATA',
    UPDATE_LOCATION = 'UPDATE_LOCATION',
    UPDATE_MAIN_LOCATION = 'UPDATE_MAIN_LOCATION',
    DELETE_LOCATION = 'DELETE_LOCATION',
    CREATE_LOCATION = 'CREATE_LOCATION',
    GET_OVERLAY = 'GET_OVERLAY',
    UPDATE_OVERLAY = 'UPDATE_OVERLAY',
    UPLOAD_CSV = 'UPLOAD_CSV',
    CSV_DUPLICATES = 'CSV_DUPLICATES',
    CREATE_USER = 'CREATE_USER',
    UPDATE_USER = 'UPDATE_USER',
    DELETE_USER = 'DELETE_USER',
    GET_USERS = 'GET_USERS',
    GET_UNIT_TYPES = 'GET_UNIT_TYPES',
    GET_UNIT_CATEGORIES = 'GET_UNIT_CATEGORIES',
    GET_UNIT_ATTRIBUTES = 'GET_UNIT_ATTRIBUTES',
    CREATE_UNIT = 'CREATE_UNIT',
    CLONE_UNIT = 'CLONE_UNIT',
    UPDATE_UNIT = 'UPDATE_UNIT',
    GET_ITEM_BY_ID = 'GET_ITEM_BY_ID',
    GET_ITEMS = 'GET_ITEMS',
    GET_ITEMS_FOR_PRINT = 'GET_ITEMS_FOR_PRINT',
    GET_INVENTORY_MANAGER_FILTERS = 'GET_INVENTORY_MANAGER_FILTERS',
    GET_MANUFACTURES_LIST = 'GET_MANUFACTURES_LIST',
    ME = 'ME',
    NOTIFICATIONS = 'NOTIFICATIONS',
    NOTIFICATIONS_DELETE = 'NOTIFICATIONS_DELETE',
    NOTIFICATIONS_READ = 'NOTIFICATIONS_READ',
    UPDATE_DEALER = 'UPDATE_DEALER',
    GET_DEALERS = 'GET_DEALERS',
    GET_DEALER_BY_ID = 'GET_DEALER_BY_ID',
    GET_DEALER = 'GET_DEALER',
    DELETE_DEALER = 'DELETE_DEALER',
    GET_PROVIDERS = 'GET_PROVIDERS',
    GET_PROVIDER = 'GET_PROVIDER',
    CREATE_PROVIDER = 'CREATE_PROVIDER',
    DELETE_PROVIDER = 'DELETE_PROVIDER',
    DELETE_ADMIN_USER = 'DELETE_ADMIN_USER',
    FEED_UPDATE = 'FEED_UPDATE',
    GET_ADMIN_USER = 'GET_ADMIN_USER',
    CREATE_ADMIN_USER = 'CREATE_ADMIN_USER',
    UPDATE_ADMIN_USER = 'UPDATE_ADMIN_USER',
    GET_UNIT_FEATURES = 'GET_UNIT_FEATURES',
    CREATE_DEALER = 'CREATE_DEALER',
    DELETE_BULK_UNITS = 'DELETE_BULK_UNITS',
    DELETE_UNIT = 'DELETE_UNIT',
    SET_ACTIVE_UNIT = 'SET_ACTIVE_UNIT',
    GET_CUSTOM_STATUSES = 'GET_CUSTOM_STATUSES',
    DELETE_CUSTOM_STATUS = 'DELETE_CUSTOM_STATUS',
    SELECTED_DEALER_ID = 'SELECTED_DEALER_ID',
    GET_CSV = 'GET_CSV',
    GET_ADMIN_USERS = 'GET_ADMIN_USERS',
    GET_FB_PROFILES = 'GET_FB_PROFILES',
    GET_FB_PROFILE_BY_ID = 'GET_FB_PROFILE_BY_ID',
    EDIT_FB_PROFILE = 'EDIT_FB_PROFILE',
    CREATE_FB_PROFILE = 'CREATE_FB_PROFILE',
    DELETE_FB_PROFILE = 'DELETE_FB_PROFILE',
    GET_CL_PROFILES = 'GET_CL_PROFILES',
    GET_CL_PROFILE_BY_ID = 'GET_CL_PROFILE_BY_ID',
    GET_CL_PROFILE_OPTIONS = 'GET_CL_PROFILE_OPTIONS',
    CREATE_CL_PROFILE = 'CREATE_CL_PROFILE',
    CREATE_CL_SCHEDULE = 'CREATE_CL_SCHEDULE',
    EDIT_CL_PROFILE = 'EDIT_CL_PROFILE',
    DELETE_CL_PROFILE = 'DELETE_CL_PROFILE',
    GET_CL_SCHEDULES = 'GET_CL_SCHEDULES',
    GET_TIMEZONES = 'GET_TIMEZONES',
    GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD',
    SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD',
    REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD',
    GET_DEALER_SETTINGS = 'GET_DEALER_SETTINGS',
    CHECKOUT = 'CHECKOUT',
    ADD_FUNDS_STATE = 'ADD_FUNDS_STATE',
    GE_BALANCE = 'GE_BALANCE',
    GET_PERMISSIONS = 'GET_PERMISSIONS',
    GET_PERMISSIONS_BY_ROLE = 'GET_PERMISSIONS_BY_ROLE',
    GET_ROLES = 'GET_ROLES',
    GET_ROLE_BY_ID = 'GET_ROLE_BY_ID',
    CREATE_ROLE = 'CREATE_ROLE',
    UPDATE_ROLE = 'UPDATE_ROLE',
    DELETE_ROLE = 'DELETE_ROLE',
    DELETE_SCHEDULE = 'DELETE_SCHEDULE',
    MFA_CODE = 'MFA_CODE',
    GET_AUTOPOPULATE_LIST = 'GET_AUTOPOPULATE_LIST',
    GET_AUTOPOPULATE_ITEM = 'GET_AUTOPOPULATE_ITEM',
    CREATE_MANUFACTURER = 'CREATE_MANUFACTURER',
    GET_BILLING_TRANSACTIONS = 'GET_BILLING_TRANSACTIONS',
    SOLD_UNIT = 'SOLD_UNIT',
    TOGGLE_AUTOPOSTING = 'TOGGLE_AUTOPOSTING',
    STOCK_VALIDATION = 'STOCK_VALIDATION',

    INTEGRATION_STORE = 'INTEGRATION_STORE',
    QUICK_EDIT_DATA = 'QUICK_EDIT_DATA',
    INVENTORY_MANAGER_FILTERS = 'INVENTORY_MANAGER_FILTERS',
    SCHEDULER_TABLE_FILTERS = 'SCHEDULER_TABLE_FILTERS',
    PENDING_REQUESTS = 'PENDING_REQUESTS',
    TEMPORARY_UNIT_FORM_DATA = 'TEMPORARY_UNIT_FORM_DATA',
    REFETCH_INVENTORY = 'REFETCH_INVENTORY',
    DEALERS_TABLE = 'DEALERS_TABLE',
}

export enum LocalStorageKeys {
    TOKEN = 'token',
    DEALER_ID = 'dealer_id',
    UNDEADER_NOTIFICATION = 'unreaded_notification'
}
