import styled from 'styled-components';
import logoBig from '../../../assets/logoBig.png';
import CustomButton from '../../../components/Buttons/CustomButton';
import { LogoutRequest } from '../../../store/auth/actions';

function AdminHeader() {
  const logoutRequest = LogoutRequest(true);
  const handleLogOut = () => {
    logoutRequest.mutate();
  };

  return (
    <StyledHeader>
      <div className="logo-wrap">
        <img src={logoBig} alt="logo" />
      </div>
      <div>
        <CustomButton variant="outlined-box" onClick={handleLogOut}>
          Logout
        </CustomButton>
      </div>
    </StyledHeader>
  );
}

export default AdminHeader;

const StyledHeader = styled.header`
  height: 72px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 22px 60px 20px 20px;
  .logo-wrap {
    img {
      height: 32px;
    }
  }

  .search-wrap {
    margin-left: 101px;

    input {
      background-color: #f6f6f6;
    }
  }
`;
