import PlaceholderComponent from '../../components/PlaceholderComponent';

function Showrooms() {
  return (
    <div>
      <PlaceholderComponent />
    </div>
  );
}

export default Showrooms;
