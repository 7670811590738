import React, { useId } from 'react';

interface CustomRadioProps {
    onChange?: (value: string | undefined, checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean
    value: string
    label?: string
    id: string
}

function CustomRadio({
  onChange, checked, value, label, id, ...props
}: CustomRadioProps) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(value, !checked, e);
  };
  const generatedId = useId();
  return (
    <div>
      <div className="radio-wrapper">
        <label htmlFor={id || generatedId}>
          {label}
        </label>
        <div className={`rs-radio ${checked && 'rs-radio-checked'}`} aria-checked="true">
          <div className="rs-radio-checker">
            <span className="rs-radio-wrapper">
              <input
                type="radio"
                onChange={handleChange}
                checked={checked}
                aria-label={id || generatedId}
                id={id || generatedId}
                {...props}
              />
              <span className="rs-radio-inner" aria-hidden="true" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomRadio;
