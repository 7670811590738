import styled from 'styled-components';
import React, {
  FocusEvent, KeyboardEvent, useEffect, useMemo, useState,
} from 'react';
import ArrowRightIcon from '@rsuite/icons/ArrowRight';
import PlusIcon from '@rsuite/icons/Plus';
import PlusRoundIcon from '@rsuite/icons/PlusRound';
import CustomInput from '../../../../components/inputs/CustomInput';
import SearchInput from '../../../../components/inputs/SearchInput';
import CircledRemoveSvg from '../../../../components/SvgElements/CircledRemoveSvg';
import { ApplicationTheme } from '../../../../globalStyles';
import useResize from '../../../../hooks';
import { ItemFeature, ItemFeaturesResponse } from '../../../../store/unit/actions';
import { FeatureTitle } from './featureList';

interface ListItemProps {
    title: FeatureTitle
    children: string | JSX.Element | JSX.Element[]
    selectedFeature: FeatureTitle | null
    setFeatureListToShow: (title:FeatureTitle) => void
}
function ListItem({
  selectedFeature, title, children, setFeatureListToShow,
}: ListItemProps) {
  const collapsed = selectedFeature === title;
  return (
    <List>
      <div
        className="features-list-control"
        onClick={() => setFeatureListToShow(title)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setFeatureListToShow(title);
          }
        }}
        role="presentation"
      >
        <button type="button" className={`${!collapsed && 'full'}`}>
          <ArrowRightIcon color={ApplicationTheme.primaryColor} />
        </button>
        <div className={`features-list-title ${!collapsed && 'full'}`}>{title}</div>
      </div>
      {collapsed
            && (
            <div className="features-list-content">
              {children}
            </div>
            )}
    </List>
  );
}

interface SubFeatureProps {
    subFeature: ListItemFeature
    handleCheck: () => void
    checked: boolean
    highlight: boolean
    handleRemoveCustom: () => void
}
function SubFeature({
  subFeature, handleCheck, checked, highlight, handleRemoveCustom,
}: SubFeatureProps) {
  return (
    <div className="feature-item-container">
      <div className="feature-item" onClick={handleCheck}>
        <div className="feature-item-icon">
          {checked ? <PlusRoundIcon color={ApplicationTheme.primaryColor} />
            : <PlusIcon color={ApplicationTheme.primaryColor} />}
        </div>
        <span className={`feature-item-title ${highlight && 'highlighted'}`}>
          {subFeature.name}
        </span>
      </div>
      {subFeature.is_custom
      && (
      <div className="feature-item-remove-icon" onClick={handleRemoveCustom}>
        <CircledRemoveSvg />
      </div>
      )}
    </div>
  );
}

interface ListItemFeature extends Omit<ItemFeature, 'id'> {
    id?: number
    tempId?: number
}

export type CustomFeatureT = {
    name: string
    item_feature_id: number
    is_active: boolean
}
export type FormedFeaturesDataT = {
    features: number[]
    custom_features: CustomFeatureT[]
    features_to_delete: number[]
}

type FeaturesProps = {
    itemFeaturesList: ItemFeaturesResponse[]
    loading: boolean
    featureIds: number[]
    onChange: (featuresData: FormedFeaturesDataT) => void
}
function Features({
  itemFeaturesList, loading, featureIds, onChange,
}: FeaturesProps) {
  const { isMobile } = useResize();
  const [featureListToShow, setFeatureListToShow] = useState<FeatureTitle>(itemFeaturesList[0]?.name);
  const [showInput, setShowInput] = useState<boolean>(false);
  type ItemFeatures = Omit<ItemFeaturesResponse, 'feature_options'> & {
        feature_options: ListItemFeature[]
  }
  const [featuresList, setFeaturesList] = useState<ItemFeatures[]>(itemFeaturesList);
  const [checkedFeatureIds, setCheckedFeatureIds] = useState<number[]>(featureIds);
  const [checkedSavedFeaturesIds, setCheckedSavedFeaturesIds] = useState<number[]>(featureIds);
  const [checkedCustomFeatures, setCheckedCustomFeatures] = useState<CustomFeatureT[]>([]);
  const [featuresToDeleteIDs, setFeaturesToDeleteIDs] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [displaySearchDropdown, setDisplaySearchDropdown] = useState<boolean>(false);
  const [inputError, setInputError] = useState<string>('');

  useEffect(() => {
    setFeaturesList(itemFeaturesList);
  }, [itemFeaturesList]);

  useEffect(() => {
    const formedFeaturesData: FormedFeaturesDataT = {
      features: checkedSavedFeaturesIds,
      custom_features: checkedCustomFeatures,
      features_to_delete: featuresToDeleteIDs,
    };
    onChange(formedFeaturesData);
  }, [checkedFeatureIds, checkedSavedFeaturesIds, checkedCustomFeatures, featuresToDeleteIDs]);

  useEffect(() => {
    if (!loading) {
      setFeatureListToShow(itemFeaturesList[0]?.name);
      setFeaturesList(itemFeaturesList);
    }
  }, [loading]);

  const handleAddFeature = () => {
    setShowInput(true);
  };
  const handleInputBlur = (newFeature:string) => {
    if (newFeature) {
      const featureIndex = featuresList.findIndex((el) => el.name === featureListToShow);
      const tempVal = [...featuresList];
      if (!tempVal[featureIndex].feature_options.some((el) => el.name === newFeature)) {
        tempVal[featureIndex].feature_options = [...featuresList[featureIndex].feature_options, {
          name: newFeature,
          tempId: Date.now(),
          item_feature_id: featuresList[featureIndex].id,
          is_custom: true,
          is_new: true,
        }];
        setFeaturesList(tempVal);

        setCheckedCustomFeatures((prev) => [...prev, {
          name: newFeature,
          item_feature_id: featuresList[featureIndex].id,
          is_active: false,
        }]);
        setShowInput(false);
        setInputError('');
      } else {
        setInputError('Feature must be unique');
      }
    } else {
      setShowInput(false);
    }
  };

  const handleCustomSubFeatureCheck = (subFeature: ListItemFeature) => {
    setCheckedCustomFeatures((prev) => {
      const temp = [...prev];
      const existedIDIndex = prev.findIndex((feature) => feature.name === subFeature.name);
      if (existedIDIndex > -1) {
        temp[existedIDIndex] = {
          name: subFeature.name,
          item_feature_id: subFeature.item_feature_id,
          is_active: !temp[existedIDIndex].is_active,
        };
      }
      return [...temp];
    });
  };

  const handleSavedFeatureCheck = (subFeature: ListItemFeature) => {
    const itemFeatureId = Number(subFeature.id || subFeature.tempId);
    const existedIDIndex = checkedFeatureIds.findIndex((id) => id === itemFeatureId);
    if (existedIDIndex > -1) {
      const tempArr = [...checkedSavedFeaturesIds];
      tempArr.splice(existedIDIndex, 1);
      setCheckedSavedFeaturesIds(tempArr);
    } else {
      setCheckedSavedFeaturesIds((prevState) => [...new Set([...prevState, itemFeatureId])]);
    }
  };

  const handleSubFeatureCheck = (subFeature: ListItemFeature) => {
    const itemFeatureId = Number(subFeature.id || subFeature.tempId);
    const existedIDIndex = checkedFeatureIds.findIndex((id) => id === itemFeatureId);
    if (subFeature.is_custom && subFeature.is_new) {
      handleCustomSubFeatureCheck(subFeature);
    } else {
      handleSavedFeatureCheck(subFeature);
    }
    if (existedIDIndex > -1) {
      const tempArr = [...checkedFeatureIds];
      tempArr.splice(existedIDIndex, 1);
      setCheckedFeatureIds(tempArr);
    } else {
      setCheckedFeatureIds((prevState) => [...new Set([...prevState, itemFeatureId])]);
    }
  };

  const allFeaturesList = featuresList.map((feature) => feature.feature_options).flat(1);
  const filteredFeatures = useMemo(
    () => allFeaturesList.filter((feature) => feature.name?.toLocaleLowerCase().includes(searchValue.toLowerCase())),
    [allFeaturesList, searchValue],
  );

  const handleSearchItemClick = (feature: ListItemFeature) => {
    const featureCategory = featuresList.find((category) => category.id === feature.item_feature_id);
    if (featureCategory) {
      setFeatureListToShow(featureCategory.name);
      setSearchValue(feature.name);
    }
  };

  const removeCustomItem = (feature: ListItemFeature) => {
    const id = Number(feature.id || feature.tempId);
    const featureIndex = featuresList.findIndex((el) => el.name === featureListToShow);
    const tempVal = [...featuresList];
    tempVal[featureIndex].feature_options = [...featuresList[featureIndex].feature_options
      .filter((subFeature) => subFeature.id !== id && subFeature.tempId !== id)];
    setFeaturesList(tempVal);
    if (!feature.is_new) { // set IDs only for the features that saved on server
      setFeaturesToDeleteIDs((prevState) => [...new Set([...prevState, id])]);
    }
  };

  return (
    <SectionContainer>
      <div className="search-wrapper">
        <SearchInput
          placeholder="Search Features"
          value={searchValue}
          onChange={(value) => setSearchValue(value)}
          onFocus={() => setDisplaySearchDropdown(true)}
          onBlur={() => setDisplaySearchDropdown(false)}
        />
        {displaySearchDropdown
        && (
        <div className="search-options-container">
            {filteredFeatures.map((feature) => (
              <div
                className="search-option"
                key={`${feature.name}_${feature.id}`}
                // onMouseDown calls before search's onBlur
                onMouseDown={() => handleSearchItemClick(feature)}
              >
                {feature.name}
              </div>
            ))}
        </div>
        )}
      </div>
      <div className="features-container">
        {!isMobile
        && (
        <div className="features-list">
          {featuresList.map((feature) => (
            <div key={feature.name} onClick={() => setFeatureListToShow(feature.name)} className={`feature ${feature.name === featureListToShow && 'active'}`}>
              {feature.name}
            </div>
          ))}
        </div>
        )}
        <div className="features-container-item-subitem">
          {featuresList.map((feature) => (
            <React.Fragment key={feature.name}>
              <ListItem title={feature.name} selectedFeature={featureListToShow} setFeatureListToShow={setFeatureListToShow}>
                {feature.feature_options.map((subFeature, subFeatureIndex) => (
                  <React.Fragment key={`${subFeature.name + subFeatureIndex}`}>
                    <SubFeature
                      key={subFeature.id}
                      subFeature={subFeature}
                      checked={checkedFeatureIds.includes(Number(subFeature.id || subFeature.tempId))}
                      handleCheck={() => handleSubFeatureCheck(subFeature)}
                      highlight={subFeature.name === searchValue}
                      handleRemoveCustom={() => removeCustomItem(subFeature)}
                    />
                  </React.Fragment>
                ))}
              </ListItem>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="add-feature-container">
        <div className="add-feature" onClick={handleAddFeature}>
          <div className="add-feature-icon">
            <PlusRoundIcon color={ApplicationTheme.primaryColor} />
          </div>
          <span className="add-feature-text">
            Add Custom Feature
          </span>
        </div>
        {showInput
              && (
              <div className="add-feature-input">
                <CustomInput
                  autoFocus
                  error={inputError}
                  onBlur={(e: FocusEvent<HTMLInputElement>) => {
                    handleInputBlur(e.target.value);
                  }}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      handleInputBlur((e.target as HTMLInputElement).value);
                    }
                  }}
                />
              </div>
              )}
      </div>
    </SectionContainer>
  );
}

export default Features;

const List = styled.div`
  .feature-item-container {
    display: flex;
  }
  .features-list-content {
    text-align: initial;
    padding: 2px 0 10px 21px;
    color: #232323;
  }
  .features-list-control {
    display: flex;
    align-items: center;
  }
  .features-list-title {
    font-size: 13px;
    color:${(props) => props.theme.primaryTextColor};
    font-weight: normal;
  }
 .feature-item {
   display: flex;
   font-size: 10px;
   color:${(props) => props.theme.primaryTextColor};
   font-weight: normal;
   cursor: pointer;
   transition: all ease 0.2s;
   :hover {
     font-weight: bold;
   }
   .feature-item-icon {
     width: 10px;
     height: 10px;
   }
   .feature-item-title {
     margin-left: 4px;
     &.highlighted {
       background-color: rgba(240, 173, 78, 0.2);
     }
   }
 }
  .feature-item-remove-icon {
    margin-left: 5px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    transition: all ease 0.2s;
    svg {
      :hover {
        fill: #000;
      }
    }
  }
`;
const SectionContainer = styled.div`
  .search-wrapper {
    padding: 0 35px;
    max-width: 482px;
    position: relative;
    .search__container {
      .search__input::placeholder {
        color: #c1c1c1;
       font-size: 10px;
      }
      input {
        height: 29px;
        @media screen and (min-width: 767px) {
          height: 38px;
          font-size: 12px;
        }
      }
      input::placeholder {
        font-size: 12px!important;
      }
    }    
    .search-options-container {
      padding-top: 14px;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.29);
      padding-bottom: 6px;
      position: absolute;
      z-index: 3;
      max-width: 482px;
      background-color: #fff;
      width: 72%;
      left: 72px;
      top: 38px;
      max-height: 400px;
      overflow: auto;
      .search-option {
        cursor: pointer;
        user-select: none;
        font-size: 16px;
        color: #212121;
        padding-top: 7px;
        padding-left: 65px;
        padding-bottom: 13px;
        margin-top: 5px;
        text-align: start;
        transition: all ease 0.2s;
        :hover {
          background-color: ${(props) => props.theme.secondaryColor};
        }
      }
    }
  }
  .add-feature-container {
    .add-feature {
      display: flex;
      font-size: 12px;
      align-items: center;
      margin-left: 10px;
      .add-feature-text {
        margin-left: 6px;
        font-size: 13px;
        @media screen and (max-width: 768px) {
          font-size: 10px;
        }
       }
      .add-feature-icon {
          svg {
            @media screen and (max-width: 768px) {
              width: 10px;
              height: 10px;    
            }
          width: 20px;
          height: 20px;
        }
      }
    }
    .add-feature-input {
      margin-top: 10px;
      max-width: 300px;
      margin-left: 10px;
    }
  }
  @media screen and (min-width: 767px) {
    width: 100%;
    .features-list-control {
      display: none;
    }
    .features-container {
      display: flex;
      text-align: start;
      margin-top: 23px;
        .features-container-item-subitem {
          max-width: 790px
        }
      .features-list {
        margin-left: 12px;
        background-color: ${(props) => props.theme.primaryBGColor};
        .feature {
          min-height: 37px;
          min-width: 205px;
          padding: 9px;
          font-size: 12px;
          color:${(props) => props.theme.primaryTextColor};
          cursor: pointer;
          transition: all ease 0.2s;
          :hover {
            font-weight: bold;
          }
        }
        .active {
          background-color: #fff;
          color: ${(props) => props.theme.primaryColor};
        }
      }
      .features-container-item-subitem {
        width: 100%;
      }
      .features-list-content {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(170px, 185px));
        color: #232323;
        font-size: 10px;
        gap: 4px;
        .feature-item {
          display: flex;
        }
      }
    }
    
    .add-feature-container {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 29px;
      font-size: 13px;
      align-items: start;
      margin-left: 152px;
      .add-feature {
        display: flex;
        color: ${(props) => props.theme.primaryColor};
        align-items: center;
        cursor: pointer;
        margin-right: 10px;
        transition: all ease 0.2s;
        :hover {
          font-weight: bold;
        }
      }
      .add-feature-input {
        margin-bottom: 10px;
      }
      .add-feature-icon {
        svg {
          width: 24px;
          height: 24px;
        }
      }
     }
  }
`;
