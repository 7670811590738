import { createContext, useContext } from 'react';
import { MessageProps } from 'rsuite/esm/Message/Message';
import { ToastContainerProps } from 'rsuite/esm/toaster/ToastContainer';

export type AppMessageType = MessageProps & {message: string} & ToastContainerProps
export interface AppMessageContextType {
    showMessage: (val: AppMessageType) => void
    workInProgress: () => void
}

const AppMessageContextInitialValue: AppMessageContextType = {
  showMessage: () => null,
  workInProgress: () => null,
};

export const AppMessageContext = createContext<AppMessageContextType>(AppMessageContextInitialValue);
export const useAppMessageContext = (): AppMessageContextType => useContext(AppMessageContext);
