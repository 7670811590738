import { ApplicationTheme } from '../../../globalStyles';

interface SVGProps {
    fill?: string
    stroke?: string
}

function ArchiveSvg(props: SVGProps): JSX.Element {
  const { fill, stroke } = props;
  return (
    <svg
      fill={fill || ApplicationTheme.secondaryColor}
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      viewBox="0 0 24 24"
      width="100%"
      preserveAspectRatio="none"
    >
      <path stroke={stroke} d="M19 3H4.99C3.88 3 3 3.9 3 5v14c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 12h-3.13c-.47 0-.85.34-.98.8-.35 1.27-1.52 2.2-2.89 2.2s-2.54-.93-2.89-2.2c-.13-.46-.51-.8-.98-.8H4.99V6c0-.55.45-1 1-1H18c.55 0 1 .45 1 1v9zm-3-5h-2V7h-4v3H8l3.65 3.65c.2.2.51.2.71 0L16 10z" />
    </svg>
  );
}

export default ArchiveSvg;
