import styled from 'styled-components';
import {
  ChangeEvent, InputHTMLAttributes, RefObject, useId, useRef, useState,
} from 'react';
import { deciNumericInputPattern, numericInputPattern } from '../../../helpers/regexPatterns';
import { StyledError } from '../../StyledComponents';

export interface CustomInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>{
  onChangePure?: (event: ChangeEvent<HTMLInputElement>, ref?: RefObject<HTMLInputElement>) => void
  label?: string
  wrapperClass?: string
  inputSymbol?: string
  symbolPosition?: 'start' | 'end'
  onChange?: (value: string) => void
  value?: string | number
  pattern?: HTMLInputElement['pattern'] | 'numeric' | 'decinumeric'
  error?: string
}

function CustomInput({
  placeholder, type, value, onChange, onChangePure, label, name, wrapperClass, required, inputSymbol, pattern, symbolPosition = 'start', error, ...props
}: CustomInputProps) {
  const [usePattern, setUsePattern] = useState<boolean>(true);
  const inputCard = useRef<HTMLInputElement>(null);
  const maxMinValidation = (currentValue: string | number): boolean => {
    if (props?.max) {
      return Number(currentValue) <= props?.max;
    }
    if (props?.min) {
      return Number(currentValue) >= props?.min;
    }
    return true;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isValid = maxMinValidation(event?.target?.value);
    if (!event?.target?.validity.valid || !isValid) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      onChangePure?.(event, inputCard);
      onChange?.(event.target.value);
    }
  };

  const getPattern = () => {
    switch (pattern) {
      case 'numeric':
        return numericInputPattern;
      case 'decinumeric':
        return deciNumericInputPattern;
      default:
        return pattern;
    }
  };
  const generatedId = useId();

  return (
    <InputWrapper className={wrapperClass}>
      <label htmlFor={name || generatedId}>
        {label}
        {required
          && <span className="required">*</span>}
      </label>
      <div className={`symbol-input-wrapper ${inputSymbol && 'with-symbol'} position-${symbolPosition}`}>
        {inputSymbol
            && <span className="input-symbol">{inputSymbol}</span>}
        <StyledInput
          ref={inputCard}
          id={name || generatedId}
          placeholder={placeholder}
          type={type}
          value={value}
          pattern={usePattern ? getPattern() : undefined}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.key === 'Backspace') { // allow to use Backspace to change input if value not valid
              setUsePattern(false);
            } else {
              setUsePattern(true);
            }
          }}
          className={`${props.className} ${error ? 'input-error' : ''}`}
          {...props}
        />
        {error
            && <StyledError className="error">{error}</StyledError>}
      </div>
    </InputWrapper>
  );
}

export default CustomInput;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .required {
    color: ${(props) => props.theme.primaryColor}
  }
  label {
    color: #232323;
  }
  .symbol-input-wrapper {
    :not(.with-symbol) {
      width: 100%;
    }
    &.position-start.with-symbol {
      input {
        padding-left: 30px;
      }
    }
    position: relative;
    .input-symbol {
      font-weight: bold;
      color:${(props) => props.theme.primaryTextColor};
      position: absolute;
      @media screen and (max-width: 768px) {
        left: 11px;
      }
        left: 22px;
      top: 6px;
    }
  }
`;
const StyledInput = styled.input`
  padding: 8px;
  border-radius: 4px;
  border-style: solid;
  height: 39px;
  font-size: 14px;
  border-width: 0.5px;
  outline-color: #d2cece;
  border-color: #d2cece;
  color:${(props) => props.theme.primaryTextColor};
  &:focus {
    outline-color: ${(props) => props.theme.primaryColor};
  }
  &.input-error {
    border-color: ${(props) => props.theme.primaryColor};
  }
`;
