import { useState } from 'react';
import styled from 'styled-components';
import useNavigateHook from '../../../hooks/useNavigateHook';
import Pages from '../../../Router/pages';

export type AdminSideBarItem = {
  label: string
  subCategory: {label: string, link: string, key: string}[]
}[]

interface AdminSideBarProps {
  sideBarItems: AdminSideBarItem
  defaultCollapsed?: string[]
}
function AdminSideBar({ sideBarItems, defaultCollapsed = [] }: AdminSideBarProps) {
  const navigate = useNavigateHook();
  const [collapsed, setCollapsed] = useState<string[]>(defaultCollapsed);
  const activeTabKey = window.location.pathname.split('/')[2];
  const handleCollapse = (key: string) => {
    const indexOfCategory = collapsed.findIndex((el) => el === key);
    if (indexOfCategory > -1) {
      const tempArr = [...collapsed];
      tempArr.splice(indexOfCategory, 1);
      setCollapsed(tempArr);
    } else {
      setCollapsed([...new Set(collapsed), key]);
    }
  };
  return (
    <StyledSideBar className="side-bar">
      <button
        type="button"
        className="side-bar-top"
        onClick={() => navigate(Pages.dealersPage)}
      >
        <div className="side-bar-top-ico">
          <div className="main-ico">
            <div className="ico" />
            <div className="ico" />
            <div className="ico" />
            <div className="ico" />
          </div>
        </div>
        <div className="side-bar-top-text">
          Main
        </div>
      </button>
      <div className="side-bar-content">
        {sideBarItems.map((el) => (
          <div key={el.label}>
            <div className="side-bar-collapsable" onClick={() => handleCollapse(el.label)}>
              <div className="side-bar-section-ico" />
              <span>{el.label}</span>
              <div className="side-bar-section-arrow" />
            </div>
            {collapsed.includes(el.label)
            && (
            <div className="subcatories">
              {el.subCategory.map((sub) => (
                <div
                  className={`side-bar-subcategory ${sub.key === activeTabKey && 'active'}`}
                  key={`${el.label}_${sub.label}`}
                  onClick={() => navigate(sub.link)}
                >
                  {sub.label}
                </div>
              ))}
            </div>
            ) }
          </div>
        ))}
      </div>
    </StyledSideBar>
  );
}

export default AdminSideBar;

const StyledSideBar = styled.div`
    min-height: calc(100vh - 103px);
    min-width: 200px;
    user-select: none;
    @media screen and (max-width: 768px) {
      min-width: 145px;
    }
    .side-bar-top {
      margin-top: 33px;
      margin-left: 20px;
      display: flex;
      margin-bottom: 31px;
      .main-ico {
        display: grid;
        grid-template-columns: 6px 6px;
        gap: 3px;
        cursor: pointer;
        transition: all ease 0.2s;
        .ico {
          border: 1px solid #747474;
          width: 6px;
          height: 6px;
          border-radius: 2px;
        }
        :hover {
          .ico {
            border-width: 2px;
          }
        }
      }
      .side-bar-top-text {
        margin-left: 8px;
        font-size: 16px;
      }
    }

    .side-bar-collapsable {
      display: flex;
      align-items: flex-end;
      margin-left: 14px;
      cursor: pointer;
      margin-top: 10px;
      .side-bar-section-ico {
        margin-right: 5px;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23747474' height='100%25' viewBox='0 0 24 24' width='100%25' preserveAspectRatio='none'%3E%3Cpath d='M15 1H4c-1.1 0-2 .9-2 2v13c0 .55.45 1 1 1s1-.45 1-1V4c0-.55.45-1 1-1h10c.55 0 1-.45 1-1s-.45-1-1-1zm4 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm-1 16H9c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1h9c.55 0 1 .45 1 1v12c0 .55-.45 1-1 1z'/%3E%3C/svg%3E");
      }
      .side-bar-section-arrow {
        width: 11px;
        height: 14px;
        margin-left: 26px;
        background-size: 10px 10px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='24' fill='%23747474' height='24' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath stroke='null' id='svg_1' d='m1.1752,9.02285l8.56872,13.25122c1.29027,1.99536 3.37455,1.99536 4.66483,0l8.56872,-13.25122c2.08428,-3.22327 0.59551,-8.74887 -2.34895,-8.74887l-17.13744,0c-2.94446,0 -4.40015,5.5256 -2.31587,8.74887z'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
    .subcatories {
      margin-left: 45px;
      text-align: start;
      .side-bar-subcategory {
        cursor: pointer;
        margin-top: 11px;
        font-size: 14px;
        transition: all ease 0.2s;
        &.active {
          color: ${(props) => props.theme.primaryColor};
          font-weight: bold;
        }
        :hover {
          font-weight: bold;
        }
      }
    }
`;
