import styled from 'styled-components';
import MasterCard from '../../../assets/cards/mc_symbol.svg';
import AMEX from '../../../assets/cards/American_Express-Logo.wine.svg';
import Discovery from '../../../assets/cards/discover.png';
import Diners from '../../../assets/cards/diners-club-logo-svg-clipart.png';
import Visa from '../../../assets/cards/visa.svg';
import JCB from '../../../assets/cards/JCB.svg';
import CHINA_UNION_PAY from '../../../assets/cards/UnionPay_logo.svg';

export type BillingCardProps = {
  cardType?: string
  lastNumbers?: string
  onCardClick?: () => void
}

function BillingCard({ cardType, onCardClick, lastNumbers }: BillingCardProps) {
  const getCardIcon = () => {
    switch (cardType) {
      case 'amex':
        return AMEX;
      case 'discover':
        return Discovery;
      case 'diners':
        return Diners;
      case 'visa':
        return Visa;
      case 'jcb':
        return JCB;
      case 'china_union_pay':
        return CHINA_UNION_PAY;
      default:
        return MasterCard;
    }
  };

  const handleClick = () => {
    onCardClick?.();
  };

  return (
    <StyledContainer onClick={handleClick}>
      <div className="card-ico-wrap">
        <img src={getCardIcon()} alt="card-type" />
      </div>
      <div className="card-label">
        Card Number
      </div>
      <div className="card-number">
        <span className="number-part">
          ****
        </span>
        <span className="number-part">
          ****
        </span>
        <span className="number-part">
          ****
        </span>
        <span className="number-part">
          {lastNumbers}
        </span>
      </div>
    </StyledContainer>
  );
}

export default BillingCard;

const StyledContainer = styled.div`
  max-width: 200px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #bfbcbc;
  background-color: #fff;
  padding: 8px;
  padding-left: 22px;
  max-height: 115px;
  min-width: 306px;
  @media screen and (max-width: 768px) {
    min-width: 206px;
    .number-part {
      font-size: 14px;
    }
  }
  color: #606060;
  font-weight: bold;
  font-size: 16px;
  .card-label, .card-number {
    text-align: start;    
  }
  .card-ico-wrap {
    max-width: 30px;
    margin-left: auto;
    margin-right: 0;
    img {
      max-height: 23px;
    }
  }
  .card-number {
    font-weight: bold;
    font-size: 24px;
    margin-top: 10px;
      span:not(:first-child) {
        margin-left: 10px;
    }
  }
`;
