import styled from 'styled-components';
import { TextareaHTMLAttributes, useId } from 'react';

interface TextAreaInterface extends TextareaHTMLAttributes<HTMLTextAreaElement>{
    wrapperClassName?: string
    label?: string
}

function TextArea({
  wrapperClassName, id, label, ...props
}: TextAreaInterface) {
  const generatedId = useId();
  return (
    <TextAreaWrapper className={`text-area-wrapper ${wrapperClassName}`}>
      <label htmlFor={id || generatedId}>{label}</label>
      <textarea
        id={id || generatedId}
        {...props}
      />
    </TextAreaWrapper>
  );
}

export default TextArea;

const TextAreaWrapper = styled.div`
textarea {
  border: 0.5px solid #c1c1c1;
  border-radius: 4px;
  &:focus {
    outline-color: ${(props) => props.theme.primaryColor};
  }
  resize: none;
  color: ${(props) => props.theme.primaryTextColor};
  padding: 8px;
  font-size: 12px;
}    
`;
