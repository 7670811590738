import React from 'react';
import styled from 'styled-components';
import FileUploader, { RenderImageT, UploadedFile } from '../../../../components/FileUploader';
import { FileFromResponse } from '../../../../store/unit/actions';

export type HiddenFilesFormInputs = {
  files: UploadedFile[]
  filesFromResponse: FileFromResponse[]
}
interface HiddenFilesProps {
  onChange: (files: RenderImageT[]) => void
  initialValue: HiddenFilesFormInputs
}

function HiddenFiles({ onChange, initialValue }: HiddenFilesProps) {
  return (
    <StyledContainer>
      <FileUploader
        onPreviewChange={onChange}
        filesFromResponse={initialValue.filesFromResponse}
        title="Hidden Files"
        subtitle=""
        fileExtensions={['.pdf', '.doc', '.docx', '.html', '.htm', '.xls', '.xlsx', '.txt']}
      />
    </StyledContainer>
  );
}

export default HiddenFiles;

const StyledContainer = styled.div`
.select-wrapper {
 label {
   white-space: nowrap;
   font-weight: normal;
 } 
}
`;
