import { useFormik } from 'formik';
import React, {
  useEffect, useReducer, useState,
} from 'react';
import styled from 'styled-components';
import {
  Toggle, Tooltip, Whisper,
} from 'rsuite';
import { useParams } from 'react-router-dom';
import CustomButton from '../../../../../components/Buttons/CustomButton';
import CustomInput from '../../../../../components/inputs/CustomInput';
import CustomSelect, { SelectValue } from '../../../../../components/inputs/CustomSelect';
import {
  CreateFbProfileRequestData, getLocationsRequest, getTimezonesRequest, ToggleAutopostingRequest,
} from '../../../../../store/settings/actions';
import { clProfileFormSchema } from '../../../../../validation/clProfileFormSchema';
import { useGetSelectedDealer } from '../../../../../hooks/getDealersHook';
import { getMeRequest, getMfaCodeRequest } from '../../../../../store/auth/actions';
import CustomMultiSelect from '../../../../../components/inputs/CustomMultiSelect';
import { InfoIco } from '../../../OverlaySection/OverlaySectionForm';
import { ApplicationTheme } from '../../../../../globalStyles';
import {
  arrayRange, formSelectOptions, getAutoPostingTimeOptions,
} from '../../../../../helpers';
import { AutopostingDataFormData, SchedulerDetailsDataT } from '../../ScheduleTab';
import { formTimeValue, timeOptionToStringFormat } from '../../../../../helpers/formHelpers';
import { useAppMessageContext } from '../../../../../appMessagesContext';

const InfoTooltip = styled(Tooltip)`
  max-width: 241px;
  padding: 10px;
  text-align: center;
  font-size: 10px;
  background-color: ${(props) => props.theme.primaryBGColor};
  border-radius: 3px;
  border: 1px solid #f7f6f6;
  color: ${(props) => props.theme.primaryTextColor};
  .highlighted {
    color: ${(props) => props.theme.primaryColor};
    font-weight: bold;
    line-height: 1.3rem;
  }
`;

export type AutoPostingConfigurationT = {
  fb_profile_id: number
  fb_autoposting: boolean
  autopost_daily: string
  autopost_start_time: string
  autopost_end_time: string
  autopost_timezone: string
}

export type FbProfileTabFormT = {
  id?: number | string
  name: string
  fb_token: string
  fb_login: string
  fb_password: string
  locations: LocationT[]
  autoposting_configuration?: AutoPostingConfigurationT
}

type CLProfileFormProps = {
  initialValueProps?: FbProfileTabFormT
  onSubmit: (data: FbProfileTabFormT) => void
  requestErrors?: CreateFbProfileRequestData
}

export type LocationT = {
  id?: number
  name?: string
    value: number
    label: string
}

interface Errors {
  [key: string]: string
}

function FbProfileTabForm({ initialValueProps, onSubmit, requestErrors }: CLProfileFormProps) {
  const initialValues: FbProfileTabFormT = initialValueProps || {
    name: '',
    fb_token: '',
    fb_login: '',
    fb_password: '',
    locations: [],
  };

  const selectedDealer = useGetSelectedDealer();

  const user = getMeRequest()?.data?.data;

  const formik = useFormik<FbProfileTabFormT>({
    initialValues,
    onSubmit,
    validationSchema: clProfileFormSchema,
    validate: (values) => ({}),
    enableReinitialize: true,
  });

  const { responseData: timezones } = getTimezonesRequest();

  const params = useParams<{ mode:string, id: string }>();

  const {
    setFieldValue, values: formikValues, errors, touched,
  } = formik;

  const handleInputChange = (key: keyof FbProfileTabFormT, value: string | SelectValue) => {
    setFieldValue(key, value);
  };

  const requestParams: { available: number; dealer_id: string | undefined; fb_profile_id?: number } = {
    available: 1,
    dealer_id: (user?.dealer_id ? String(user?.dealer_id) : undefined) || (selectedDealer?.id ? String(selectedDealer?.id) : undefined),
  };

  if (params?.id !== 'null') {
    requestParams.fb_profile_id = Number(params.id);
  }

  const { data: locationDataResp, refetch, isFetching } = getLocationsRequest(requestParams);
  const locationData = locationDataResp?.data || [];

  const locationsDataFormated = locationData.map((location) => ({ value: location.id, label: location.name }));
  const currentFbProfileLocations = formikValues.locations.map((location, index) => ({
    index,
    value: location.id || location.value,
    label: location.name || location.label,
  })) || [];

  const locationsOptions = [...currentFbProfileLocations, ...locationsDataFormated];

  const validateAutoPosting = (): boolean => {
    const autoPostingErrors: Errors = {};
    const autoPostingRequiredFields = ['autopost_timezone', 'autopost_daily', 'autopost_start_time', 'autopost_end_time'];
    const isMissingAutoPostingField = (data: AutopostingDataFormData, field: string): boolean => !data?.[field as keyof AutopostingDataFormData];

    autoPostingRequiredFields.forEach((field) => {
      if (isMissingAutoPostingField(autopostingData, field)) {
        Object.assign(autoPostingErrors, { [field]: 'This field is required' });
      }
    });

    setAutoPostingValidationErrors(autoPostingErrors);
    return !Object.keys(errors).length;
  };

  const [autopostingData, setAutopostingData] = useReducer(
    (state: Omit<AutopostingDataFormData, 'dealer_id'>, newState: Partial<AutopostingDataFormData>) => ({
      ...state,
      ...newState,
    }),
    {
      profile: null,
      fb_autoposting: false,
      autopost_daily: null,
      autopost_start_time: null,
      autopost_end_time: null,
      autopost_timezone: null,
    },
  );

  const formInitialOption = (option: null | string | undefined | number): SelectValue => (option ? formSelectOptions([option as string])[0] : null);
  useEffect(() => {
    const initialEndTime = formTimeValue(formikValues.autoposting_configuration?.autopost_end_time);
    const initialStartTime = formTimeValue(formikValues.autoposting_configuration?.autopost_start_time);
    setAutopostingData({
      fb_autoposting: formikValues.autoposting_configuration?.fb_autoposting || false,
      autopost_daily: formInitialOption(formikValues.autoposting_configuration?.autopost_daily),
      ...(initialEndTime && { autopost_end_time: initialEndTime }),
      ...(initialStartTime && { autopost_start_time: initialStartTime }),
      autopost_timezone: formInitialOption(formikValues.autoposting_configuration?.autopost_timezone),
    });
  }, [formikValues.autoposting_configuration]);

  const infoTooltip = (
    <InfoTooltip arrow={false}>
      <div>
        Enable automatic posting units with the selected parameters
      </div>
    </InfoTooltip>
  );

  const [autoPostingValidationErrors, setAutoPostingValidationErrors] = useState<{[key in keyof AutopostingDataFormData]?: string}>({});

  const autoPostingTimeArray = getAutoPostingTimeOptions();
  const formatedAutoPostingTimeOptions = autoPostingTimeArray.filter(() => true);

  const toggleAutopostingRequest = ToggleAutopostingRequest();

  const { showMessage } = useAppMessageContext();

  const mfaCode = getMfaCodeRequest(Number(formikValues.id));

  const saveAutopostingChanges = () => {
    const dealerId = selectedDealer?.id ? selectedDealer?.id : user?.dealer_id;
    if (validateAutoPosting() && dealerId) {
      toggleAutopostingRequest.mutate({
        fb_autoposting_profile_id: initialValues?.id || '',
        fb_autoposting: !!autopostingData?.fb_autoposting,
        dealer_id: dealerId,
        autopost_daily: autopostingData?.autopost_daily?.value || undefined,
        ...(autopostingData?.autopost_start_time?.time && { autopost_start_time: timeOptionToStringFormat(autopostingData?.autopost_start_time) }),
        ...(autopostingData?.autopost_end_time?.time && { autopost_end_time: timeOptionToStringFormat(autopostingData?.autopost_end_time) }),
        autopost_timezone: autopostingData?.autopost_timezone?.value ? String(autopostingData?.autopost_timezone?.value) : undefined,
      });
    }
  };

  useEffect(() => {
    if (toggleAutopostingRequest.isSuccess) {
      toggleAutopostingRequest.reset();
      showMessage({ type: 'success', message: 'Autoposting settings saved successfully' });
    }
  }, [toggleAutopostingRequest.isSuccess]);

  useEffect(() => {
    if (toggleAutopostingRequest.isError) {
      toggleAutopostingRequest.reset();
      showMessage({ type: 'error', message: toggleAutopostingRequest?.error?.response?.data?.message || 'Error saving autoposting settings' });
    }
  }, [toggleAutopostingRequest.isError]);

  const emptyLocations = !currentFbProfileLocations.length;

  return (
    <Container>
      <div className="title">
        Facebook Profile
      </div>
      <form onSubmit={(e) => {
        e.preventDefault();
        formik.submitForm();
      }}
      >
        <div className="inputs-row">
          <div className="input-wrapper">
            <CustomInput
              wrapperClass="text-input"
              label="Profile Name"
              required
              value={formikValues.name}
              onChange={(value) => handleInputChange('name', value)}
              maxLength={250}
              error={errors.name && touched?.name ? errors.name : requestErrors?.name}
            />
          </div>
        </div>
        <hr />
        <div className="inputs-row">
          <div className="input-wrapper">
            <CustomInput
              wrapperClass="text-input"
              label="Email"
              required
              value={formikValues.fb_login}
              onChange={(value) => handleInputChange('fb_login', value)}
              maxLength={250}
              error={errors.fb_login && touched?.fb_login ? errors.fb_login : requestErrors?.fb_login}
            />
          </div>
        </div>
        <div className="inputs-row">
          <div className="input-wrapper">
            <CustomInput
              wrapperClass="text-input"
              label="Password"
              required
              value={formikValues.fb_password}
              onChange={(value) => handleInputChange('fb_password', value)}
              maxLength={250}
              error={errors.fb_password && touched?.fb_password ? errors.fb_password : requestErrors?.fb_password}
            />
          </div>
        </div>
        <hr />
        <div className="inputs-row">
          <div className="input-wrapper">
            <CustomInput
              wrapperClass="text-input"
              label="Token"
              value={formikValues.fb_token}
              onChange={(value) => handleInputChange('fb_token', value)}
              maxLength={250}
              error={errors.fb_token && touched?.fb_token ? errors.fb_token : requestErrors?.fb_token}
            />
            <i>{ mfaCode?.data && `2FA Code: ${mfaCode?.data}` }</i>
          </div>
        </div>
        <hr />
        <div className="inputs-row">
          <div className="input-wrapper">
            <CustomMultiSelect
              label="Locations"
              value={isFetching ? [{ value: 0, label: 'Loading locations...' }] : currentFbProfileLocations}
              options={locationsOptions}
              isMulti={!isFetching}
              disabled={isFetching}
              onChange={(value) => handleInputChange('locations', value)}
            />
            { emptyLocations && <i>Locations must be assigned before saving this profile or setting autoposting.</i> }
          </div>
        </div>
        <div className="save-row">
          <CustomButton className="save-btn" disabled={emptyLocations}>Save</CustomButton>
        </div>
      </form>
      <div className={`calendar-form-wrapper autoposting ${params?.id === 'null' ? 'disabled-autoposting' : ''}`}>
        <div className="form">
          <div className="form-title autoposting">
            Autoposting
            <Whisper placement="auto" controlId="save-id-hover" trigger="hover" speaker={infoTooltip}>
              <div>
                <InfoIco />
              </div>
            </Whisper>
          </div>
          <div className="autoposting">
            <span className="toggle-label">Auto-posting to FB</span>
            <Toggle
              id="autoposting"
              arial-label="Switch"
              checked={Boolean(autopostingData?.fb_autoposting)}
              disabled={autopostingData?.fb_autoposting ? false : !autopostingData?.autopost_daily?.value}
              onChange={(val) => {
                setAutopostingData({ fb_autoposting: val });
              }}
            />
          </div>
          <div className="input-wrapper">
            <CustomSelect
              label="Autoposting Time Zone"
              value={autopostingData?.autopost_timezone}
              onChange={(val: SchedulerDetailsDataT['timezone']) => setAutopostingData({ autopost_timezone: val })}
              options={formSelectOptions(timezones)}
              placeholder={' '}
              controlStyles={{
                borderRadius: '4px',
                overflow: 'hidden',
              }}
              indicatorContainerStyles={{
                borderLeft: '1px solid #bfbcbc',
                height: 'inherit',
                marginTop: '0',
                backgroundColor: ApplicationTheme.primaryBGColor,
              }}
              error={autoPostingValidationErrors?.autopost_timezone || ''}
            />
          </div>
          <div className="input-wrapper">
            <CustomSelect
              label="Daily posts"
              value={autopostingData?.autopost_daily}
              onChange={(val) => setAutopostingData({ autopost_daily: val })}
              options={formSelectOptions(arrayRange(1, 100, 1).reverse())}
              placeholder=" "
              controlStyles={{
                borderRadius: '4px',
                overflow: 'hidden',
              }}
              indicatorContainerStyles={{
                borderLeft: '1px solid #bfbcbc',
                height: 'inherit',
                marginTop: '0',
                backgroundColor: ApplicationTheme.primaryBGColor,
              }}
              error={autoPostingValidationErrors?.autopost_daily || ''}
            />
          </div>
          <div className="input-wrapper">
            <CustomSelect
              label="First Post"
              value={autopostingData?.autopost_start_time}
              onChange={(val: AutopostingDataFormData['autopost_start_time']) => setAutopostingData({ autopost_start_time: val })}
              options={formatedAutoPostingTimeOptions}
              placeholder={' '}
              controlStyles={{
                borderRadius: '4px',
                overflow: 'hidden',
              }}
              indicatorContainerStyles={{
                borderLeft: '1px solid #bfbcbc',
                height: 'inherit',
                marginTop: '0',
                backgroundColor: ApplicationTheme.primaryBGColor,
              }}
              error={autoPostingValidationErrors?.autopost_start_time || ''}
            />
          </div>
          <div className="input-wrapper">
            <CustomSelect
              label="Last Post"
              value={autopostingData?.autopost_end_time}
              onChange={(val: AutopostingDataFormData['autopost_end_time']) => setAutopostingData({ autopost_end_time: val })}
              options={formatedAutoPostingTimeOptions}
              placeholder={' '}
              controlStyles={{
                borderRadius: '4px',
                overflow: 'hidden',
              }}
              indicatorContainerStyles={{
                borderLeft: '1px solid #bfbcbc',
                height: 'inherit',
                marginTop: '0',
                backgroundColor: ApplicationTheme.primaryBGColor,
              }}
              error={autoPostingValidationErrors?.autopost_end_time || ''}
            />
          </div>
          <div className="form-btn-wrapper">
            <CustomButton onClick={saveAutopostingChanges} disabled={params?.id === 'null' || emptyLocations}>Save</CustomButton>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default FbProfileTabForm;

const Container = styled.div`
  margin-top: 17px;

  .title {
    color: ${(props) => props.theme.primaryColor};
    font-size: 25px;
    font-weight: bold;
  }

  form {
    margin-top: 20px;
    max-width: 746px;
    background-color: #fff;
    padding: 15px 53px 30px 31px;
    @media screen
    and(max-width: 768px) {
      padding-left: 11px;
      padding-right: 33px;
      margin-left: 0;
    }

    div.inputs-row {
      div.input-wrapper {
        div.text-input {
          display: flex !important;
          justify-content: space-between !important;
          flex-direction: row;
          margin-top: 20px;
          margin-bottom: 20px;

          label {
            width: 30%;
            color: ${(props) => props.theme.primaryTextColor};
            font-size: 14px;
            font-weight: bold;
          }

          input {
            width: 100%;
          }
        }
      }
    }

    .save-row {
      text-align: end;
      margin-top: 30px;
      button {
        width: 152px;
        text-transform: none;
        font-size: 16px;
      }
    }
  }
  
  .calendar-form-wrapper {
    margin-top: 20px;
    max-width: 746px;
    background-color: #fff;
    padding: 15px 53px 30px 31px;
    @media screen
    and(max-width: 768px) {
      padding-left: 11px;
      padding-right: 33px;
      margin-left: 0;
    }
  }
  
  .disabled-autoposting {
    pointer-events: none;
    filter: blur(2px);
  }

  i {
    font-size: 12px;
    text-align: start;
    color: #ff9b41;
  }

  .autoposting { 
    margin-bottom: 20px;
  }
  .form {
    text-align: start;
    margin-top: 10px;
    .form-title {
      font-weight: bold;
      font-size: 25px;
      &.autoposting {
        display: flex;
        flex-direction: row;
        justify-content: flex-start
      }
    }
    .autoposting, .input-wrapper {
      margin-top: 13px;
    }
    .input-wrapper {
      .plus-btn {
        text-align: end;
        margin-top: 17px;
      }
    }
    .autoposting {
      display: flex;
      justify-content: space-between;
    }
    .select-container {
      display: flex;
      flex-direction: column;
      align-items: start;
      .select-wrap {
        height: 28px;
        min-width: 220px;
        @media screen and (max-width: 1200px) {
          min-width: 280px;
        }
      }
    }
    .form-btn-wrapper {
      text-align: end;
      margin-top: 30px;
      button {
        width: 152px;
        text-transform: none;
        font-size: 16px;
      }
    }
    .posting-type {
      display: flex;
    }
  }
`;
