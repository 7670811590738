import MenuIcon from '@rsuite/icons/Menu';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Drawer from 'rsuite/esm/Drawer/Drawer';
import styled from 'styled-components';
import BackSvg from '../../assets/back-arrow.png';
import { useGetSelectedDealer } from '../../hooks/getDealersHook';
import { getMeRequest } from '../../store/auth/actions';

export type SidebarItem = {
  label: string
  key: string
  active?: boolean
  hidden?: boolean
  onClick?: (barMenu: SidebarItem) => void
  page: string
}

interface SideBarProps {
  navigateOnBack: string | number
  items: SidebarItem[]
  activeSection: string
}
function SideBar({ navigateOnBack, items, activeSection }: SideBarProps) {
  const selectedDealer = useGetSelectedDealer();
  const user = getMeRequest()?.data?.data;
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  return (
    <SidebarContainer>
      <div className="mobile-menu">
        <div className="burger">
          <MenuIcon onClick={() => setShowSidebar(true)} height={20} width={20} />
        </div>
        <Drawer
          open={showSidebar}
          onClose={() => setShowSidebar(false)}
          size="xs"
          placement="left"
        >
          <StyledDrawerBody>
            <StyledSideBar className={`side-bar ${showSidebar && 'mobile-show'}`}>
              <div className="navigation-wrap">
                <div className="side-bar-sticky">
                  <div className="side-bar-back">
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <Link to={navigateOnBack}>
                      <button type="button" className="back">
                        <img src={BackSvg} alt="back" />
                        <span>Back</span>
                      </button>
                    </Link>
                  </div>
                  {items.map((barMenu) => (
                    <div className={`side-bar-item ${activeSection === barMenu.key && 'active'} ${barMenu.hidden && 'hidden'}`} key={barMenu.label} onClick={() => barMenu?.onClick?.(barMenu)}>
                      {barMenu.label}
                    </div>
                  ))}
                </div>
              </div>
              <div className="email">
                {selectedDealer?.primary_email || user?.email}
              </div>
            </StyledSideBar>
          </StyledDrawerBody>
        </Drawer>
      </div>
      <StyledSideBar className="side-bar">
        <div className="navigation-wrap">
          <div className="side-bar-sticky">
            <div className="side-bar-back">
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <Link to={navigateOnBack}>
                <button type="button" className="back">
                  <img src={BackSvg} alt="back" />
                  <span>Back</span>
                </button>
              </Link>
            </div>
            {items.map((barMenu) => (
              <Link to={`${barMenu.page}/${barMenu.key}`} className={`side-bar-item ${activeSection === barMenu.key && 'active'} ${barMenu.hidden && 'hidden'}`} key={barMenu.label} onClick={() => barMenu?.onClick?.(barMenu)}>
                {barMenu.label}
              </Link>
            ))}
          </div>
        </div>
        <div className="email">
          {selectedDealer?.primary_email || user?.email}
        </div>
      </StyledSideBar>
    </SidebarContainer>
  );
}

export default SideBar;

const StyledDrawerBody = styled(Drawer.Body)`
  background-color: ${(props) => props.theme.primaryBGColor};
  padding: 0;
`;
const SidebarContainer = styled.div`
  .mobile-menu {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }    
  }
  background-color: ${(props) => props.theme.primaryBGColor};
  @media screen and (max-width: 768px) {
    display: block;
    width: 40px;
  }
  .burger {
    margin-top: 20px;
  }
`;
const StyledSideBar = styled.div`
  background-color: ${(props) => props.theme.primaryBGColor};
  min-height: calc(100vh - 103px);
  @media screen and (max-width: 768px) {
    display: none;
    &.mobile-show {
      display: flex;
    }
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: sticky;
  top: 82px;
  @media screen and (max-width: 768px) {
    top: 20px;  
  }
  .side-bar-sticky {
    position: sticky;
    top: 112px; //header height
  }
  .side-bar-back {
    margin-top: 25px;
    margin-left: 30px;
    display: flex;
    margin-bottom: 16px;
    a {
      text-decoration: none;
      color: inherit;  
    }
  }

  .side-bar-item {
    cursor: pointer;
    padding: 8px 8px 8px 38px;
    height: 69px;
    width: 253px;
    @media screen and (max-width: 1024px) {
      width: 200px;
    }
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    &.hidden {
     display: none; 
    }
    
    &.active {
      color: ${(props) => props.theme.primaryColor};
      background-color: #fff;
    }
    transition: all ease 0.2s;
    :hover {
      color: ${(props) => props.theme.primaryColor};
    }
  }
  .email {
    color: #7a7a7a;
    font-size: 14px;
    margin-top: 60px;
    text-align: start;
    margin-left: 60px;
    margin-bottom: 40px;
  }
`;
