import { useFormik } from 'formik';
import styled from 'styled-components';
import CustomButton from '../../../components/Buttons/CustomButton';
import CustomInput from '../../../components/inputs/CustomInput';
import { PermissionT, RoleCreationRequestErr, RoleT } from '../../../store/dealers/actions';
import rolesFormSchema from '../../../validation/rolesFormSchema';

export type RoleFormData = {
  id?: string | number | null
  name: string
  display_name: string
  guard_name: string
  description: string
  permission_ids: number[]
}

type RoleFormProps = {
  roleData?: RoleT
  permissions: PermissionT[]
  activeRoleIds: number[]
  onSubmit: (data: RoleFormData) => void
  errorsProps?: RoleCreationRequestErr
}

function RoleForm({
  roleData, permissions, activeRoleIds, onSubmit, errorsProps,
}: RoleFormProps) {
  const initialValues: RoleFormData = {
    id: roleData?.id,
    name: roleData?.name || '',
    display_name: roleData?.display_name || '',
    guard_name: roleData?.guard_name || '',
    description: roleData?.description || '',
    permission_ids: activeRoleIds || [],
  };

  const formik = useFormik<RoleFormData>({
    initialValues,
    onSubmit,
    validationSchema: rolesFormSchema,
  });

  const {
    setFieldValue, values: formikValues, errors, touched,
  } = formik;
  const handleFieldChange = (key: keyof RoleFormData, value: string | number[]) => {
    setFieldValue(key, value);
  };

  const handlePermissionChange = (id: number) => {
    const temArr = [...formikValues.permission_ids];
    const idIndex = temArr.indexOf(id);

    if (idIndex !== -1) {
      temArr.splice(idIndex, 1);
    } else {
      temArr.push(id);
    }
    handleFieldChange('permission_ids', [...new Set(temArr)]);
  };
  const renderPermission = () => (
    <div>
      <ul className="permissions-list">
        {permissions.map((permission) => (
          <div key={permission.id} onClick={() => handlePermissionChange(permission.id)}>
            <li className="permission-list-item" key={permission.name}>
              <div className={`circle ${formikValues.permission_ids?.includes(permission.id) ? 'green' : 'red'}`} />
              {permission.name}
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
  return (
    <Container>
      <div className="section">
        {formikValues.id
                && (
                <div className="row">
                  <div>ID</div>
                  <div>{formikValues.id}</div>
                </div>
                )}
        <div className="row">
          <div>Name</div>
          <div>
            <CustomInput
              value={formikValues?.name}
              onChange={(value) => handleFieldChange('name', value)}
              error={errors.name && touched?.name ? errors.name : errorsProps?.name}
              data-testid="name"
            />
          </div>
        </div>
        <div className="row">
          <div>Displayed name</div>
          <div>
            <CustomInput
              value={formikValues?.display_name}
              onChange={(value) => handleFieldChange('display_name', value)}
              error={errors.display_name && touched?.display_name ? errors.display_name : errorsProps?.display_name}
              data-testid="display"
            />
          </div>
        </div>
        {formikValues.id && (
          <div className="row">
            <div>Guard name</div>
            <div>
              {formikValues.guard_name}
              {/* <CustomInput */}
              {/*  value={formikValues?.guard_name} */}
              {/*  onChange={(value) => handleFieldChange('guard_name', value)} */}
              {/*  disabled */}
              {/*  error={errors.guard_name && touched?.guard_name ? errors.guard_name : errorsProps?.guard_name} */}
              {/* /> */}
            </div>
          </div>
        )}
        <div className="row">
          <div>Description</div>
          <div>
            <CustomInput
              value={formikValues?.description}
              onChange={(value) => handleFieldChange('description', value)}
              error={errors.description && touched?.description ? errors.description : errorsProps?.description}
              data-testid="description"
            />
          </div>
        </div>
        <div className="row">
          <div>Permissions</div>
          {renderPermission()}
        </div>
      </div>
      <div className="create-button">
        <CustomButton type="submit" onClick={formik.submitForm}>Save</CustomButton>
      </div>
    </Container>
  );
}

export default RoleForm;

const Container = styled.div`
   .section {
     margin-top: 20px;
     background-color: #fff;
     border-radius: 8px;
     padding: 8px;
     box-shadow: 0 5px 5px rgba(23, 23, 23, 0.31);
     .row {
       padding: 8px;
       padding-top: 15px;
       padding-bottom: 15px;
       font-size: 16px;
       display: grid;
       grid-template-columns: 200px auto;
       text-align: start;
       :not(:last-child) {
         border-bottom: 0.5px solid #c1c1c1;
       }
       .permissions-header {
         font-weight: bold;
         font-size: 18px;
         color: ${(props) => props.theme.primaryTextColor};
       }
       .permissions-list {
         display: grid;
         grid-template-columns: repeat(auto-fill, clamp(150px, 40%, 250px));
         padding-left: 0;
         margin-top: 15px;
         list-style: none;
         .permission-list-item {
           display: flex;
           align-items: center;
           cursor: pointer;
           user-select: none;
           transition: all ease 0.2s;
           :hover {
             font-weight: bold;
           }
         }
         .circle {
           margin-right: 10px;
           margin-bottom: 2px;
           height: 10px;
           width: 10px;
           border-radius: 50%;
           &.green {
             background-color: green;
           }
           &.red {
             background-color: red;
           }
         }
       }
     }
   }
  .create-button {
    max-width: 150px;
    margin-top: 20px;
      .primary {
        text-transform: unset;
        border-radius: 4px;
      }
  }
`;
