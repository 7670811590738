import axios from 'axios';
import { axiosInstance } from '../../config/axios';
import { ExtensionFiles } from '../../pages/SettingPages/SettingsIntegrations';
import {
  AutopopulateListParams,
  CloneUnitRequestData,
  CreateManufacturerData,
  CreateUnitRequestData,
  ItemsPaginationParams,
  StockValidationT,
} from './actions';
import { ExportModes, ExportUnitRequestParams } from './types';

export const UnitRequests = {
  async GetItemTypes() {
    return axiosInstance.get('/config/item/types');
  },
  async GetItemCategories() {
    return axiosInstance.get('/config/item/categories');
  },
  async GetItemAttributesRequest() {
    return axiosInstance.get('/config/item/attributes');
  },
  async GetItemFeaturesRequest() {
    return axiosInstance.get('/config/item/features');
  },
  async CreateUnit(data: CreateUnitRequestData, dlRequest?: boolean) {
    return axiosInstance.post(
      `/items?dl_request=${Number(dlRequest)}`,
      {
        ...data,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  async CloneUnit(id: string | number, data: CloneUnitRequestData) {
    return axiosInstance.post(`items/${id}/clone`, data);
  },
  async UpdateUnit(data: CreateUnitRequestData, params: {id: string | number, dlRequest?: boolean}) {
    return axiosInstance.post(
      `/items/${params.id}?dl_request=${Number(params.dlRequest)}`,
      {
        ...data,
        _method: 'PUT',
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  async GetItems(params: ItemsPaginationParams) {
    return axiosInstance.get('/items', {
      params: {
        ...params,
      },
    });
  },
  async GetItemImagesZipFile(id: string | undefined) {
    return axiosInstance.get(`/items/${id}/downloadImages`);
  },
  async GetItemsForPrint(params: ItemsPaginationParams) {
    return axiosInstance.get('/items/print', {
      params: {
        ...params,
      },
    });
  },
  async GetItem(params: {id: string | number, dlRequest?: boolean}) {
    return axiosInstance.get(`/items/${params.id}?dl_request=${Number(params.dlRequest)}`);
  },
  async GetInventoryManagerFilters(params: {dealer_id?: number}) {
    return axiosInstance.get('/items/availableFilters?with_item_count=1', { params });
  },
  async DeleteUnit(id: number, params?: {approve_delete: '1' | '0'}) {
    return axiosInstance.delete(`/items/${id}`, { params });
  },
  async SetActiveUnits(ids: number[]) {
    return axiosInstance.post('/items/setActive', { ids });
  },
  async DeleteUnits(ids: number[], approveDelete?: boolean) {
    return axiosInstance.delete('/items/bulk', { data: { ids, approve_delete: approveDelete } });
  },
  async GetCustomStatuses(params: {dealer_id?: string | number}) {
    return axiosInstance.get('/items/customStatuses', { params });
  },
  sampleDownload() {
    axiosInstance.get('/config/item/sampleCsv').then((response) => {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'sampleCsv.csv';
      link.click();
    });
  },
  csvCheatsheetDownload() {
    axiosInstance.get('/config/item/csvCheatsheet').then((response) => {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'csvCheatsheet.pdf';
      link.click();
    });
  },
  exportInventory(mode: ExportModes, params: ExportUnitRequestParams) {
    axiosInstance.get('/items/export', { params }).then((response) => {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${mode}_units.csv`;
      link.click();
    });
  },
  async GetAutopopulateList(params: AutopopulateListParams) {
    return axios.get('http://api.datalink.ai/api/units/search', {
      params,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${process.env.REACT_APP_DATALINK_TOKEN}`,
      },
    });
  },
  async GetAutopopulateItem(id: number | string) {
    return axios.get(`http://api.datalink.ai/api/units/${id}/autopopulate`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${process.env.REACT_APP_DATALINK_TOKEN}`,
      },
    });
  },
  async CreateManufacturer(data: CreateManufacturerData) {
    return axiosInstance.post('item/itemManufacturers/auto-populate', data);
  },
  async GetManufacturesList() {
    return axios.get(
      'https://api.datalink.ai/api/inventories/makes',
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${process.env.REACT_APP_DATALINK_TOKEN}`,
        },
      },
    );
  },
  async SoldUnit(data: number[]) {
    const route = `${process.env.REACT_APP_NODE_BE}/master-account/cl/delete/`;
    const token = process.env.REACT_APP_NODE_TOKEN;
    return axios.delete(route, {
      params: {
        ids: data.join(','),
      },
      headers: {
        Accept: 'application/json, text/plain, */*',
        Authorization: `Bearer ${token}`,
      },
    });
  },
  fbAppDownload(version: keyof typeof ExtensionFiles) {
    return axiosInstance.get('/config/extension', {
      params: {
        version,
      },
    }).then((response) => {
      const getFileName = () => {
        switch (version) {
          case 'mac':
            return 'TrailerFunnel.dmg';
          case 'x32':
            return 'TrailerFunnel_ia32.exe';
          default:
            return 'TrailerFunnel_x64.exe';
        }
      };
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = getFileName();
      link.click();
    });
  },
  async StockValidation(params: StockValidationT) {
    return axiosInstance.get('/items/validateStock', { params });
  },
};
