import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import FileUploader, { RenderImageT, UploadedFile } from '../../../components/FileUploader';
import { FileFromResponse } from '../../../store/unit/actions';
import CustomButton from '../../../components/Buttons/CustomButton';
import { setPlaceholderImageRequest, getDealerSettingsRequest } from '../../../store/settings/actions';
import { getMeRequest } from '../../../store/auth/actions';
import { useGetSelectedDealer } from '../../../hooks/getDealersHook';
import { useAppMessageContext } from '../../../appMessagesContext';

export type ImagePlaceholderSectionInputs = {
  files: UploadedFile[]
  filesFromResponse: FileFromResponse[]
}
interface ImagePlaceholderSectionProps {
  onChange: (files: RenderImageT[]) => void;
  initialValue: ImagePlaceholderSectionInputs;
}

function ImagePlaceholderSection({ onChange, initialValue }: ImagePlaceholderSectionProps) {
  const { showMessage } = useAppMessageContext();

  const setPlaceholderImage = setPlaceholderImageRequest();

  const userRequest = getMeRequest();
  const user = userRequest?.data?.data;
  const selectedDealer = useGetSelectedDealer();

  const [dealerId, setDealerId] = useState<number | null>(null);

  const dealerSettings = getDealerSettingsRequest({ dealerId: selectedDealer?.id || user?.dealer_id });
  const placeholderImageUrl = dealerSettings?.data?.data?.placeholder_image_url;

  const [previewImagePlaceholder, setPreviewImagePlaceholder] = useState<FileFromResponse[]>([]);
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);

  useEffect(() => {
    if (selectedDealer?.id) {
      setDealerId(selectedDealer.id);
    }

    if (user?.dealer_id) {
      setDealerId(user.dealer_id);
    }
  }, [selectedDealer?.id, user?.dealer_id]);

  useEffect(() => {
    if (placeholderImageUrl) {
      setPreviewImagePlaceholder([{
        id: 1,
        name: '',
        order: 0,
        size: 1,
        type: 'image/*',
        url: placeholderImageUrl,
      }]);
    }
  }, [placeholderImageUrl]);

  const handleOnChange = (files: RenderImageT[]) => {
    if (files.length > 0 && !files[0].isFile) {
      setIsDisabledBtn(true);
      return;
    }

    onChange(files);
    setIsDisabledBtn(false);
  };

  return (
    <ImagePlaceholderContent>
      <Formik
        initialValues={initialValue}
        onSubmit={(values, { resetForm }) => {
          setPlaceholderImage.mutate(
            {
              dealer_id: String(dealerId),
              placeholder_image: initialValue.files[0],
            },
            {
              onSuccess: () => {
                resetForm();
                showMessage({ type: 'success', message: 'Image saved successfully' });

                dealerSettings.refetch();
              },
            },
          );
        }}
        enableReinitialize
      >
        {() => (
          <Form>
            <div className="file-uploader-wrap">
              <div>
                <div className="section-title">Inventory Placeholder</div>
                <p className="section-subtitle">Add a custom placeholder to be displayed on your website.</p>
              </div>
              <div className="file-uploader-body">
                <FileUploader
                  maxFiles={1}
                  onPreviewChange={handleOnChange}
                  filesFromResponse={previewImagePlaceholder}
                  title="Placeholder Image"
                  subtitle=""
                  fileType={'image/*'}
                  fileExtensions={['.jpg', '.jpeg', '.png']}
                />
                <CustomButton
                  variant="primary"
                  wrapperClassname="save-btn"
                  type="submit"
                  disabled={isDisabledBtn}
                >
                  Save
                </CustomButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ImagePlaceholderContent>
  );
}

// Add styles here
const ImagePlaceholderContent = styled.div`
background-color: #ffffff;
padding: 20px;

.file-uploader-wrap {
    width: 435px;
    background-color: #f7f6f6;
    border-radius: 10px;
    padding: 25px;
    
    .section-title {
        font-size: 25px;
        color: #ff9b41;
        text-align: left;
    }
    
    .section-subtitle {
        text-align: left;
    }
    
    .file-uploader-body {
        background-color: #ffffff;
        border-radius: 10px;
        padding: 20px;
        margin-top: 20px;
    }
    
    .preview-container {
        margin-top: 0;
    }
}
.save-btn {
  margin-top: 20px;
}
`;

export default ImagePlaceholderSection;
