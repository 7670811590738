import { useEffect } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import styled from 'styled-components';
import { useAppMessageContext } from '../../appMessagesContext';
import { getToken } from '../../helpers';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import logoBig from '../../assets/logoBig.png';
import { ForgotPasswordRequest, LoginRequest } from '../../store/auth/actions';
import { RequestKeys } from '../../store/keys';
import LoginForm, { LoginFormData } from './loginForm';

export interface ForgotPasswordData {
  email: string
}
function Login() {
  const { showMessage } = useAppMessageContext();
  const navigate = useNavigateHook();
  const loginReq = LoginRequest();
  const forgotPassReq = ForgotPasswordRequest();
  const isLoggedIn = getToken();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(Pages.inventoryManager);
    }
  }, [isLoggedIn]);
  useEffect(() => {
    if (forgotPassReq.isSuccess) {
      showMessage({ type: 'info', message: 'Password recovery link was sent to your email' });
      forgotPassReq.reset();
    }
  }, [forgotPassReq]);

  const handleSubmit = (data: LoginFormData) => {
    loginReq.mutate(data);
  };
  const errors: UseQueryResult<{ message: string; }> = useQuery({ queryKey: [RequestKeys.LOGIN_ERROR] });

  const handleForgotPassword = (data: LoginFormData) => {
    forgotPassReq.mutate({ email: data.email });
  };
  const formErrors = {
    ...(errors?.data?.message?.toLowerCase()?.includes('password') ? { password: errors?.data?.message } : { email: errors?.data?.message }),
  };
  return (
    <Container>
      <img src={logoBig} alt="logo" />
      <LoginForm handleSubmit={handleSubmit} handleForgotPassword={handleForgotPassword} loading={loginReq.isPending} error={formErrors} />
    </Container>
  );
}

export default Login;

const Container = styled.div`
img {
  max-width: 217px;
}
`;
