import React, { useEffect, useState } from 'react';
import SettingsLayout from '../../../components/SettingsLayout';
import Pages from '../../../Router/pages';
import ImagePlaceholderSection, { ImagePlaceholderSectionInputs } from '../../SettingsSections/ImagePlaceholderSection';
import { UploadedFile } from '../../../components/FileUploader';

function SettingsImagePlaceholder() {
  const [selectedFiles, setSelectedFiles] = useState<UploadedFile[]>([]); // Updated type to UploadedFile
  const [filesFromResponse, setFilesFromResponse] = useState<ImagePlaceholderSectionInputs['filesFromResponse']>([]);

  const handleImageChange = (files: UploadedFile[]) => { // Updated type to UploadedFile
    setSelectedFiles(files);
  };

  useEffect(() => {
    if (selectedFiles) {
      setFilesFromResponse([{
        id: 1,
        name: '',
        order: 0,
        size: 1,
        type: 'image/*',
        url: selectedFiles[0]?.url || '',
      }]);
    }
  }, [selectedFiles]);

  return (
    <SettingsLayout navigateOnBack={Pages.inventoryManager} headerTitle="settings">
      <ImagePlaceholderSection
        onChange={handleImageChange}
        initialValue={{
          files: selectedFiles,
          filesFromResponse,
        }}
      />
    </SettingsLayout>

  );
}
export default SettingsImagePlaceholder;
