import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DeepKeysArrAndObj } from '../../helpers';
import { AdminUsersFromData, GenerateCreateAdminUserErrors } from '../../pages/AdminUserCreation/AdminUserCreationForm';
import { DealerFromData } from '../../pages/DealerCreation/DealerCreationForm';
import { ProviderFromData } from '../../pages/ProviderCreation/ProviderCreationForm';
import { RequestKeys } from '../keys';
import { ResponseWithPagination } from '../types';
import { DealerRequests } from './requests';
import { RolesType } from '../auth/types';
import { SelectOption } from '../../components/inputs/CustomSelect';

export type GenerateCreateLocationErrors = {
  [key in DeepKeysArrAndObj<DealerFromData>]: string
} & {
  'dealer_data.password': string
  'dealer_data.rj_slots': string
}

export type DealerType = {
  id: number
  code: number
  dealership_name: string
  overlay_id: number
  first_name: string
  last_name: string
  link: null | string
  phone_number: string
  primary_email: string
  main_location_name: string
  stripe_id: null | string
  created_at: string
  updated_at: string

  ksl_folder_name: string
  ksl_password: string

  factory_feed: boolean
  factory_feeds: SelectOption[]

  rj_dealer_id: string
  rj_integration: boolean
  rj_slots: string
  rj_slots_used: string

  role: RolesType

  server_address?: string
  ftp_login?: string
  ftp_password?: string
  filename?: string

  fb_token: string
  fb_profiles: boolean

  dl_customer_id?: string

  fb_autoposting: boolean
  autopost_daily: number | null
  autopost_end_time: string | null
  autopost_start_time: string | null
  autopost_timezone: string | null
}
export type DealerCreationResponsePagination = ResponseWithPagination<DealerType[]>

export interface CreateUpdateDealerRequestData {
    dealer_data: {
        dealership_name: string
        primary_email: string
        password?: string | undefined
        first_name: string
        last_name: string
        phone_number: string
        server_address: string
        ftp_login: string
        ftp_password: string
        filename: string
        fb_token: string
        stripe_id: string

        rj_dealer_id: string
        rj_integration?: boolean
        rj_slots?: number
        rj_slots_used?: number

        factory_feed: boolean
        factory_feeds: SelectOption[]
    }
    main_location: {
        id?: number
        name: string
        contact_first_name: string
        contact_last_name: string
        website: string
        phone_number: string
        email: string[]
        street_address: string
        city: string
        country: string
        state: string
        zip_code: string
        county: string
    }
    locations: {
        id?: number
        name: string
        contact_first_name: string
        contact_last_name: string
        website: string
        phone_number: string
        email: string[]
        street_address: string
        city: string
        state: string
        country: string
        zip_code: string
        county: string
    }[]
}
export const CreateDealer = () => {
  const mutation = useMutation<AxiosResponse<DealerType>, AxiosError<{ errors: GenerateCreateLocationErrors, message: string }>, {data: CreateUpdateDealerRequestData}>(
    {
      mutationKey: [RequestKeys.CREATE_DEALER],
      mutationFn: ({ data }) => DealerRequests.CreateDealer(data),
    },

  );
  return { ...mutation };
};
export const UpdateDealer = () => {
  const mutation = useMutation<AxiosResponse<DealerType>, AxiosError<{ errors: GenerateCreateLocationErrors, message: string }>, {data: CreateUpdateDealerRequestData, id: string}>(
    {
      mutationKey: [RequestKeys.UPDATE_DEALER],
      mutationFn: ({ data, id }) => DealerRequests.UpdateDealer(data, id),
    },

  );
  return { ...mutation };
};

export type GetDealersParams = {
  limit: number
  page: number
  search?: string
}
export const GetDealers = (params: GetDealersParams) => {
  const request = useQuery<AxiosResponse<DealerCreationResponsePagination>>(
    {
      queryKey: [RequestKeys.GET_DEALERS, params],
      queryFn: () => DealerRequests.GetDealers(params),
      staleTime: 2000,
    },
  );
  return { ...request, data: request?.data?.data?.data || [], meta: request?.data?.data?.meta };
};
export const GetDealersById = (id: string | number | null, enabled: boolean) => {
  const request = useQuery<AxiosResponse<DealerType>>(
    {
      queryKey: [RequestKeys.GET_DEALER_BY_ID, id],
      queryFn: () => DealerRequests.GetDealerById(id as number), // "enabled" disable request if id === null
      enabled,
      staleTime: 2000,
    },
  );
  return { ...request, data: request?.data?.data };
};

export const GetDealer = (id: string) => {
  const request = useQuery<AxiosResponse<DealerType>>(
    {
      queryKey: [RequestKeys.GET_DEALER, id],
      queryFn: () => DealerRequests.GetDealer(id),
      enabled: !!id,
      staleTime: 2000,
    },
  );
  return { ...request, data: request?.data?.data };
};

export const DeleteDealerRequest = () => {
  const mutation = useMutation <AxiosResponse<DealerType>, AxiosError<{ errors: string, message: string }>, {ids: number[]}>(
    {
      mutationKey: [RequestKeys.DELETE_DEALER],
      mutationFn: ({ ids }) => DealerRequests.DeleteDealers(ids),
    },
  );
  return { ...mutation };
};

export const FeedUpdateRequest = () => {
  const mutation = useMutation <AxiosResponse<{ success: boolean }>, AxiosError<{ errors: string, message: string }>, {id: number}>(
    {
      mutationKey: [RequestKeys.FEED_UPDATE],
      mutationFn: ({ id }) => DealerRequests.feedUpdate(id),
    },
  );
  return { ...mutation };
};

export type AdminUserType = {
  id: number
  email: string
  role: string[]
  created_at: string
  updated_at: string

  department: string
  first_name: string
  last_name: string
}

export const GetAdminUser = (id: string) => {
  const request = useQuery<AxiosResponse<AdminUserType>>(
    {
      queryKey: [RequestKeys.GET_ADMIN_USER, id],
      queryFn: () => DealerRequests.GetAdminUser(id),
      enabled: !!id,
      staleTime: 2000,
    },
  );
  return { ...request, data: request?.data?.data };
};

export const CreateAdminUser = () => {
  const mutation = useMutation<AxiosResponse<AdminUserType>, AxiosError<{ errors: GenerateCreateAdminUserErrors, message: string }>, {data: AdminUsersFromData}>(
    {
      mutationKey: [RequestKeys.CREATE_ADMIN_USER],
      mutationFn: ({ data }) => DealerRequests.CreateAdminUser(data),
    },
  );
  return { ...mutation };
};
export const UpdateAdminUser = () => {
  const mutation = useMutation<AxiosResponse<AdminUserType>, AxiosError<{ errors: GenerateCreateAdminUserErrors, message: string }>, {data: Partial<AdminUsersFromData>, id: string}>(
    {
      mutationKey: [RequestKeys.UPDATE_ADMIN_USER],
      mutationFn: ({ data, id }) => DealerRequests.UpdateAdminUser(data, id),
    },
  );
  return { ...mutation };
};

export const GetAdminUsers = () => {
  const request = useQuery<AxiosResponse<AdminUserType[]>>(
    {
      queryKey: [RequestKeys.GET_ADMIN_USERS],
      queryFn: () => DealerRequests.GetAdminUsers(),
      staleTime: 2000,
    },
  );
  return { ...request, data: request?.data?.data || [] };
};

export const DeleteAdminUserRequest = () => {
  const mutation = useMutation <AxiosResponse<DealerType>, AxiosError<{ errors: string, message: string }>, {ids: number[]}>(
    {
      mutationKey: [RequestKeys.DELETE_ADMIN_USER],
      mutationFn: ({ ids }) => DealerRequests.DeleteAdminUsers(ids),
    },
  );
  return { ...mutation };
};

export type PermissionT = {
  created_at: string
  description: string
  display_name: string
  guard_name: string
  id: number
  name: string
  updated_at: string
}

export const GetAvailablePermissions = () => {
  const request = useQuery<AxiosResponse<{ data: PermissionT[] }>>(
    {
      queryKey: [RequestKeys.GET_PERMISSIONS],
      queryFn: DealerRequests.GetAvailablePermissions,
    },
  );
  return { ...request, data: request?.data?.data?.data || [] };
};
export const GetPermissionsByRole = (id?: string | number) => {
  const request = useQuery<AxiosResponse<{ data: PermissionT[] }>>(
    {
      queryKey: [RequestKeys.GET_PERMISSIONS_BY_ROLE, id],
      queryFn: () => DealerRequests.GetPermissionsByRole(id),
      enabled: !!id,
    },
  );
  return { ...request, data: request?.data?.data?.data || [] };
};
export type RoleT = {
  id: number
  name: string
  display_name: string
  description: string
  guard_name: string
  created_at: string
  updated_at: string
}
export const GetRoles = () => {
  const request = useQuery<AxiosResponse<{ data: RoleT[] }>>(
    {
      queryKey: [RequestKeys.GET_ROLES],
      queryFn: DealerRequests.GetRoles,
      staleTime: 2000,
    },
  );
  return { ...request, data: request?.data?.data?.data || [] };
};

export const GetRoleById = (id?: number | string) => {
  const request = useQuery<AxiosResponse<RoleT>>(
    {
      queryKey: [RequestKeys.GET_ROLE_BY_ID, id],
      queryFn: () => DealerRequests.GetRoleById(id),
      enabled: !!id,
      staleTime: 2000,
    },
  );
  return { ...request, data: request?.data?.data };
};

export type RoleCreationRequest = {
  name: string
  display_name: string
  description: string
  permission_ids: number[]
}

export type RoleCreationRequestErr = {
  [key in keyof RoleCreationRequest | 'guard_name']?: string
}
export const CreateRole = () => {
  const mutation = useMutation<AxiosResponse<DealerType>, AxiosError<{ errors: RoleCreationRequestErr, message: string }>, {data: RoleCreationRequest}>(
    {
      mutationKey: [RequestKeys.CREATE_ROLE],
      mutationFn: ({ data }) => DealerRequests.CreateRole(data),
    },
  );
  return { ...mutation };
};
export const UpdateRole = () => {
  const mutation = useMutation<AxiosResponse<DealerType>, AxiosError<{ errors: RoleCreationRequestErr, message: string }>, {data: RoleCreationRequest, id: string | number}>(
    {
      mutationKey: [RequestKeys.UPDATE_ROLE],
      mutationFn: ({ data, id }) => DealerRequests.UpdateRole(data, id),
    },
  );
  return { ...mutation };
};

export const DeleteRoleRequest = () => {
  const mutation = useMutation <AxiosResponse<DealerType>, AxiosError<{ errors: string, message: string }>, {id: number}>(
    {
      mutationKey: [RequestKeys.DELETE_ROLE],
      mutationFn: ({ id }) => DealerRequests.DeleteRole(id),
    },
  );
  return { ...mutation };
};

export type ProvidersT = {
  id: number
  name: string
  email: string
  token: string
  dealer_id: string
  created_at: string
  updated_at: string
}
export const GetProviders = (params?: {dealer_id?: string, limit?: number}) => {
  const request = useQuery<AxiosResponse<ProvidersT[]>>(
    {
      queryKey: [RequestKeys.GET_PROVIDERS, params],
      queryFn: () => DealerRequests.GetProviders(params),
      staleTime: 2000,
    },
  );
  return { ...request, data: request?.data?.data || [] };
};

export const GetProvider = (id: string) => {
  const request = useQuery<AxiosResponse<ProvidersT>>(
    {
      queryKey: [RequestKeys.GET_PROVIDER, id],
      queryFn: () => DealerRequests.GetProvider(id),
      enabled: !!id,
      staleTime: 2000,
    },
  );
  return { ...request, data: request?.data?.data };
};

export type ProviderCreationErrors = {
  [key in DeepKeysArrAndObj<ProviderFromData>]: string
}
export const CreateProvider = () => {
  const mutation = useMutation<AxiosResponse<ProvidersT>, AxiosError<{ errors: ProviderCreationErrors, message: string }>, {data: ProviderFromData}>(
    {
      mutationKey: [RequestKeys.CREATE_PROVIDER],
      mutationFn: ({ data }) => DealerRequests.CreateProvider(data),
    },
  );
  return { ...mutation };
};

export const UpdateProvider = () => {
  const mutation = useMutation<AxiosResponse<ProvidersT>, AxiosError<{ errors: ProviderCreationErrors, message: string }>, {data: ProviderFromData, id: string}>(
    {
      mutationKey: [RequestKeys.UPDATE_DEALER],
      mutationFn: ({ data, id }) => DealerRequests.UpdateProvider(data, id),
    },
  );
  return { ...mutation };
};

export const DeleteProviderRequest = () => {
  const mutation = useMutation <AxiosResponse<ProvidersT>, AxiosError<{ errors: string, message: string }>, {id: number}>(
    {
      mutationKey: [RequestKeys.DELETE_PROVIDER],
      mutationFn: ({ id }) => DealerRequests.DeleteProvider(id),
    },
  );
  return { ...mutation };
};
