import { ApplicationTheme } from '../../../globalStyles';

interface SVGProps {
    fill?: string
    stroke?: string
}

function RoundPlus(props: SVGProps): JSX.Element {
  const { fill, stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 24 24" width="100%" preserveAspectRatio="none" fill={fill || ApplicationTheme.iconsColor} stroke={stroke}>
      <path d="M12 7c-.55 0-1 .45-1 1v3H8c-.55 0-1 .45-1 1s.45 1 1 1h3v3c0 .55.45 1 1 1s1-.45 1-1v-3h3c.55 0 1-.45 1-1s-.45-1-1-1h-3V8c0-.55-.45-1-1-1zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </svg>
  );
}

export default RoundPlus;
