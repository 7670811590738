import { ApplicationTheme } from '../../../globalStyles';

interface SVGProps {
    fill?: string
    stroke?: string
}

function DownloadSvg(props: SVGProps): JSX.Element {
  const { fill, stroke } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill={fill || ApplicationTheme.iconsColor} stroke={stroke}>
      <title>
        download
      </title>
      <path d="M17 12v5H3v-5H1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5z" />
      <path d="M10 15l5-6h-4V1H9v8H5l5 6z" />
    </svg>
  );
}

export default DownloadSvg;
