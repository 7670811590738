import { useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Modal } from 'rsuite';
import styled from 'styled-components';
import { queryClient } from '../../../App';
import { useAppMessageContext } from '../../../appMessagesContext';
import clearIco from '../../../assets/clear.png';
import editIco from '../../../assets/edit.png';
import CustomButton from '../../../components/Buttons/CustomButton';
import FileUploader, { RenderImageT } from '../../../components/FileUploader';
import CustomInput from '../../../components/inputs/CustomInput';
import CustomSelect, { SelectValue } from '../../../components/inputs/CustomSelect';
import { removeMultipleSpaces } from '../../../helpers';
import { formUnitFormData, formUnitRequestData } from '../../../helpers/formHelpers';
import useNavigateHook from '../../../hooks/useNavigateHook';
import Pages from '../../../Router/pages';
import { getMeRequest } from '../../../store/auth/actions';
import { RequestKeys } from '../../../store/keys';
import {
  CreateUnitRequestData,
  getItemAttributesRequest,
  UnitType,
  UpdateUnitRequest,
} from '../../../store/unit/actions';
import unitQuickEditFormSchema from '../../../validation/unitQuickEditFormSchema';
import { SectionList, UnitFormInterface } from '../../UnitPage';
import { ImagesFormInputs } from '../../UnitPage/Sections/Images';
import { inputsByInventoryType } from '../../UnitPage/Sections/Specifics/data';
import { SpecificFormInputs } from '../../UnitPage/types';

export type QuickEditForm = {
  title: string
  price: string
  sales_price: string
  status: SpecificFormInputs['status']
  notes: string
  images: ImagesFormInputs
  defaultOpenSections?: Array<keyof typeof SectionList>
}

export type QuickEditModalProps = {
    showModel: UnitType | null
    setShowModal: (val: boolean) => void
    handleSuccessClose: () => void
}

function QuickEditModal({
  setShowModal, showModel, handleSuccessClose,
}: QuickEditModalProps) {
  const { showMessage } = useAppMessageContext();
  const navigate = useNavigateHook();
  const initialValues: UnitFormInterface = formUnitFormData(showModel as UnitType);
  const updateUnitRequest = UpdateUnitRequest();
  const { responseData: itemAttributes } = getItemAttributesRequest();
  const userRequest = getMeRequest()?.data?.data;

  const handleSubmit = (formikData: QuickEditForm) => {
    const formedRequestData: CreateUnitRequestData = formUnitRequestData({
      ...initialValues,
      ...formikData,
    }, itemAttributes, [], userRequest?.dealer_id ?? showModel?.dealer?.id ?? 0, true);
    formedRequestData.specifics.title = formikData.title;
    formedRequestData.details.price = Number(formikData.price);
    formedRequestData.details.sales_price = Number(formikData.sales_price);
    formedRequestData.specifics.status = formikData.status?.value ? String(formikData.status?.value) : '';
    formedRequestData.details.notes = formikData.notes;
    initialValues.pdfs.filesFromResponse.forEach((file, index) => {
      formedRequestData[`pdfs[${index}]`] = file.id;
    });
    initialValues.hiddenFiles.filesFromResponse.forEach((file, index) => {
      formedRequestData[`hidden_files[${index}]`] = file.id;
    });
    if (showModel?.id) {
      updateUnitRequest.mutate({
        data: formedRequestData,
        id: showModel?.id,
        initialStatus: initialValues?.specifics?.status?.value ? String(initialValues?.specifics?.status?.value) : '',
        showMessage,
      });
    }
  };
  useEffect(() => {
    if (updateUnitRequest.isSuccess) {
      showMessage({ type: 'success', message: 'Unit Updated successfully, images/files are being processed, will be available shortly.' });
      updateUnitRequest.reset();
      handleSuccessClose();
    }
  }, [updateUnitRequest.isSuccess]);

  const statusOptions = useMemo(() => (initialValues.specifics.inventoryType?.name
    ? inputsByInventoryType[initialValues.specifics.inventoryType?.name].find((input) => input.key === 'status')?.options
    : []), [initialValues.specifics.inventoryType]);
  const formik = useFormik<QuickEditForm>({
    initialValues: {
      title: initialValues?.specifics?.title,
      price: initialValues.details.price,
      sales_price: initialValues.details.sales_price,
      status: initialValues?.specifics?.status,
      notes: initialValues.details.notes,
      images: initialValues?.images,
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
    validationSchema: unitQuickEditFormSchema,
  });

  const {
    setFieldValue, values: formikValues, errors, touched,
  } = formik;

  useEffect(() => {
    queryClient.setQueryData([RequestKeys.QUICK_EDIT_DATA], null);
  }, []);
  const handleInputChange = (key: keyof QuickEditForm, value: string | SelectValue) => {
    setFieldValue(key, value);
  };
  const handleNavigateToEdit = async (files: RenderImageT[]) => {
    await setFieldValue('images', files);
    await queryClient.setQueryData([RequestKeys.QUICK_EDIT_DATA], {
      ...formikValues,
      images: {
        ...formikValues.images,
        files,
      },
      defaultOpenSections: ['Images'],
    });
    navigate(`${Pages.editUnitPure}/${showModel?.id}`);
  };
  return (
    <Modal
      size="md"
      open={!!showModel}
      onClose={() => setShowModal(false)}
    >
      <StyledModalHeader>
        <button type="button" onClick={() => setShowModal(false)}>
          <img src={clearIco} alt="clear" />
        </button>
      </StyledModalHeader>
      <Modal.Body>
        <ModalContainer>
          <h3>
            Quick Edit
          </h3>
          <div className="text">
            <CustomInput
              label="Title"
              required
              wrapperClass="input-wrapper"
              value={formikValues.title}
              onChange={(value) => handleInputChange('title', removeMultipleSpaces(value))}
              error={errors.title && touched?.title ? errors.title : updateUnitRequest.error?.response?.data.errors['specifics.title']}
            />
            <StyledWrapInputs>
              <CustomInput
                label="Price"
                required
                wrapperClass="input-wrapper"
                pattern="decinumeric"
                inputSymbol="$"
                value={formikValues.price}
                onChange={(value) => handleInputChange('price', value)}
                error={errors.price && touched?.price ? errors.price : updateUnitRequest.error?.response?.data.errors['details.price']}
              />
              <CustomInput
                label="Sales price"
                // required
                wrapperClass="input-wrapper"
                pattern="decinumeric"
                inputSymbol="$"
                value={formikValues.sales_price}
                onChange={(value) => handleInputChange('sales_price', value)}
                error={errors.sales_price && touched?.sales_price ? errors.sales_price : updateUnitRequest.error?.response?.data.errors['details.sales_price']}
              />
            </StyledWrapInputs>
            <StyledWrapInputs>
              <CustomSelect
                label="Status"
                required
                value={formikValues.status}
                onChange={(value) => handleInputChange('status', value)}
                options={statusOptions}
                className="input-wrapper"
                error={errors.status && touched?.status ? errors.status : updateUnitRequest.error?.response?.data.errors['specifics.status']}
              />
              <CustomInput
                label="Notes"
                value={formikValues.notes}
                onChange={(value) => handleInputChange('notes', value)}
                wrapperClass="input-wrapper notes"
                error={errors.notes && touched?.notes ? errors.notes : updateUnitRequest.error?.response?.data.errors['details.notes']}
              />
            </StyledWrapInputs>
            <div className="mobile-actions">
              <CustomButton variant="text" wrapperClassname="edit-button" onClick={() => navigate(`${Pages.editUnitPure}/${showModel?.id}`)}>
                <IconWrapper>
                  <img src={editIco} alt="edit" />
                </IconWrapper>
                <div>Edit Unit</div>
              </CustomButton>
              <div className="file-uploader-wrap">
                <FileUploader
                  title="Add Images"
                  fileType={'image/*'}
                  onPreviewChange={(files) => {
                    if (files.length > (showModel?.images?.length || 0)) {
                      handleNavigateToEdit(files);
                    }
                  }}
                  acceptFiles={{
                    'image/jpeg': [],
                    'image/png': [],
                    'image/gif': [],
                    'image/heic': [],
                    'image/heif': [],
                  }}
                  onDeleteAll={() => {
                    setFieldValue('images.files', []);
                    setFieldValue('images.filesFromResponse', []);
                  }}
                  filesFromResponse={showModel?.images}
                  hidePreview
                />
              </div>
            </div>
          </div>
          <CustomButton
            wrapperClassname="archive-btn"
            variant="primary"
            onClick={formik.submitForm}
          >
            save
          </CustomButton>
        </ModalContainer>
      </Modal.Body>
    </Modal>
  );
}

export default QuickEditModal;

const StyledModalHeader = styled.div`
display: flex;
justify-content: flex-end;
  button {
    padding: 0;
    margin: 0;
    background: transparent;
  }
  img {
    cursor: pointer;
    transition: all ease 0.2s;
    :hover {
      transform: scale(1.2);
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    margin-top: 10px;
  }
  .text {
    margin-top: 11px;
    font-size: 18px;
    color: ${(props) => props.theme.secondaryTextColor};
    width: 75%; 
    input {
      width: 100%;
    }
  }
  .archive-btn {
    width: 121px;
    height: 35px;
    margin-top: 32px;
  }
  .with-symbol {
    width: 100%;
    .input-symbol {
      margin-top: 2px;
      left: 8px;
    }
    input {
      padding: 8px!important;
      padding-left: 16px!important;
    }
  }
  .select-container, .input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    font-size: 16px;
    label {
      font-weight: normal;
      font-size: 16px;
    }
    @media screen and (max-width: 768px) {
      &.notes {
        display: none;
      }
    }
  }
  .mobile-actions {
    display: none;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .edit-button {
      max-width: 150px;
    }
    button {
      color: #ed7419;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      text-decoration: none;
    }
    .file-uploader-wrap {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .container {
        min-width: unset;
        max-width: 200px;
        .dropzone {
          min-width: unset;
          height: 40px;
          
        }
      }  
    }
  }
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease 0.2s;
  border: 1px solid ${(props) => props.theme.primaryColor};
  img {
    height: 16px;
  }
  :hover {
    border: 2px solid ${(props) => props.theme.primaryColor};
  }
`;

const StyledWrapInputs = styled.div`
  div:first-child {
    margin-right: 5px;
  }
    display: flex;
`;
