import {
  Cell, Column, HeaderCell, SortType, Table,
} from 'rsuite-table';
import React, {
  ChangeEvent, useEffect, useMemo, useState,
} from 'react';
import { SelectPicker } from 'rsuite';
import { RowDataType, TableSizeChangeEventName } from 'rsuite-table/src/@types/common';
import styled from 'styled-components';
import { useAppMessageContext } from '../../appMessagesContext';
import useResize from '../../hooks';
import CustomCheckbox from '../inputs/CustomCheckbox';
import CustomPagination from './customPaginagion';

interface CheckCellProps<T> {
  rowData?: {
    [key: string]: unknown;
  };
  onChange: (value: number | undefined, checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
  checkedKeys: number[]
  dataKey: string
  customSelectCell: CustomTableProps<T>['customSelectCell']
}

type GenericRowData<D> = RowDataType & D

function CheckCell<C>({
  rowData, onChange, checkedKeys, dataKey, customSelectCell, ...props
}: CheckCellProps<C>) {
  return (
    <Cell
      {...props}
      style={{
        padding: 0,
        marginTop: '6px',
        maxHeight: '60px',
      }}
    >
      {!rowData?.hideSelect
      && customSelectCell ? customSelectCell({ data: rowData as GenericRowData<C>, onChange, checked: checkedKeys.some((item) => item === rowData?.[dataKey]) }) : (
        <div className="cell-content" style={{ lineHeight: '46px' }}>
          <CustomCheckbox
            value={rowData?.[dataKey]}
            onChange={onChange}
            checked={checkedKeys.some((item) => item === rowData?.[dataKey])}
          />
        </div>
        )}
    </Cell>
  );
}
export interface CustomTableProps<T> {
  data: GenericRowData<T>[]
  columns: {
    fixed?: boolean | 'left' | 'right',
    label: string,
    key: string,
    sortable?: boolean,
    width?: number,
    minWidth?: number,
  }[]
  selectable?: boolean
  onPageChange?: (page: number) => void
  showHeader?: boolean
  onItemSelect?: (selected: number[]) => void
  displayTotal?: boolean
  displayPagination?: boolean
  hideSelectHeader?: boolean
  hideSelectHeaderCheckbox?: boolean
  fillHeight?: boolean
  autoHeight?: boolean
  fullHeightContainer?: boolean
  TableWrapperID?: string
  checkedKeysProp?: number[]
  maxSelectedItems?: number
  limit?: number
  onLimitChange?: (lim: number) => void
  page?: number
  totalPages?: number
  showTotalPages?: boolean
  totalItems?: number
  rowHeight?: number
  onColumnSort?: (column: string, type: 'desc' | 'asc') => void
  serverSorting?: boolean
  loading?: boolean
  virtualized?: boolean
  tableHeight?: number
  serverSortColumn?: string
  serverSortType?: SortType
  customSelectCell?: ({ data, onChange, checked }: {
    data: GenericRowData<T>,
    onChange?: (value: number | undefined, checked: boolean, event: ChangeEvent<HTMLInputElement>) => void
    checked?: boolean
  }) => JSX.Element
  shouldUpdateScroll?:
      | boolean
      | ((event: TableSizeChangeEventName) => {
    x?: number;
    y?: number;
  });
  rowClassName?: string | ((rowData: RowDataType) => string);
}

function CustomTable<T>({
  data, columns, selectable = true, onPageChange, showHeader, onItemSelect, displayTotal = true, displayPagination = true, hideSelectHeader, hideSelectHeaderCheckbox,
  fillHeight = true,
  autoHeight = false,
  fullHeightContainer = true,
  TableWrapperID,
  checkedKeysProp = [],
  maxSelectedItems,
  limit = 15,
  onLimitChange,
  page = 1,
  totalPages,
  showTotalPages,
  totalItems,
  onColumnSort,
  serverSorting,
  serverSortColumn,
  serverSortType,
  shouldUpdateScroll,
  customSelectCell,
  rowHeight,
  loading,
  virtualized,
  tableHeight,
  rowClassName,
}: CustomTableProps<T>) {
  const [checkedKeys, setCheckedKeys] = useState<number[]>(checkedKeysProp);
  const [defaultWidth, setDefaultWidth] = useState(200);
  const { isMobile, clientWidth } = useResize();

  const [sortColumn, setSortColumn] = useState<string>('');
  const [sortType, setSortType] = useState<'desc' | 'asc'>('asc');
  const sortingColumnWidth = 30;
  const columnsDeclaredWidth = columns.reduce((n, { width }) => n + (width || 0), 0);
  const { showMessage } = useAppMessageContext();

  useEffect(() => {
    setCheckedKeys(checkedKeysProp);
  }, [checkedKeysProp?.length]);

  const handleChangeLimit = (dataKey: number) => {
    onPageChange?.(1);
    onLimitChange?.(dataKey);
  };

  let checked = false;
  let indeterminate = false;

  if ((checkedKeys.length === data.length) && data.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
    indeterminate = true;
  }

  const handleCheckAll = (value: string | number, check: boolean) => {
    const keys = check ? data.map((item) => item.id) : [];
    if (!maxSelectedItems) {
      setCheckedKeys(keys);
    } else if (maxSelectedItems && keys.length <= maxSelectedItems) {
      setCheckedKeys(keys);
    } else {
      showMessage({ type: 'info', message: `Maximum ${maxSelectedItems} items` });
    }
  };
  const handleCheck = (value: number, check: boolean) => {
    const keys = check ? [...checkedKeys, value] : checkedKeys.filter((item) => item !== value);
    if (!maxSelectedItems) {
      setCheckedKeys(keys);
    } else if (maxSelectedItems && keys.length <= maxSelectedItems) {
      setCheckedKeys(keys);
    } else {
      showMessage({ type: 'info', message: `Maximum ${maxSelectedItems} items` });
    }
  };

  useEffect(() => {
    onItemSelect?.(checkedKeys);
  }, [checkedKeys]);

  const handlePageChange = (nevPage: number) => {
    onPageChange?.(nevPage);
  };

  useEffect(() => {
    const offset = document.getElementById('table-wrapper')?.offsetWidth || 0;
    const itemsWithoutWidthLen = columns.filter((el) => !el.width).length || 1;
    const calcOffset = selectable ? (offset - (columnsDeclaredWidth + sortingColumnWidth)) : (offset - columnsDeclaredWidth);
    const columnWidth = (calcOffset / itemsWithoutWidthLen < 80) ? 80
      : (calcOffset / itemsWithoutWidthLen); // subtraction include borders
    setDefaultWidth(isMobile ? calcOffset : columnWidth);
  }, [columns.length, selectable, clientWidth]);

  const handleSortColumn = (column: string, type: 'desc' | 'asc') => {
    if (serverSorting) {
      onColumnSort?.(column, type);
    } else {
      setSortColumn(column);
      setSortType(type);
    }
  };

  const sortedData = useMemo(() => {
    if (sortColumn && sortType) {
      return data.sort((a: GenericRowData<T>, b: GenericRowData<T>) => {
        if (sortType === 'asc') {
          return a?.[sortColumn].localeCompare(b?.[sortColumn]);
        }
        return b?.[sortColumn].localeCompare(a?.[sortColumn]);
      });
    }
    return data;
  }, [sortType, sortColumn, data]);

  return (
    <div>
      <TableWrapper
        id={TableWrapperID || 'table-wrapper'}
        className={fullHeightContainer ? 'fullHeightContainer' : ''}
      >
        {displayTotal
        && (
        <TotalItemsWrapper className="top">
          <span>Displaying</span>
          <StyledSelect
            data={(totalItems || data.length) < 15 ? [(totalItems || data.length)].map((el) => ({ value: el, label: el }))
              : [15, 30, 45, ...[totalItems || data.length]]?.sort((a, b) => a - b)
                ?.filter((lim) => lim <= (totalItems || data.length))
                .map((el) => ({ value: el, label: (totalItems || data.length) === el ? 'All' : el }))}
            value={limit}
            onChange={handleChangeLimit}
            searchable={false}
            cleanable={false}
            appearance="subtle"
          />
          <span>
            of
            {' '}
            {totalItems || data.length}
            {' '}
            Units
          </span>
        </TotalItemsWrapper>
        )}
        <Table
          fillHeight={fillHeight}
          autoHeight={autoHeight}
          data={sortedData}
          loading={loading}
          virtualized={virtualized}
          height={tableHeight}
          locale={{
            emptyMessage: 'No matches found',
          }}
          rowHeight={rowHeight || 65}
          sortColumn={serverSortColumn || sortColumn}
          sortType={serverSortType || sortType}
          onSortColumn={handleSortColumn}
          wordWrap="break-word"
          showHeader={showHeader}
          shouldUpdateScroll={shouldUpdateScroll}
          rowClassName={rowClassName}
        >
          {selectable
        && (
        <Column align="center" fixed width={sortingColumnWidth}>
          <StyledHeader>
            {!hideSelectHeader
             && (
             <StyledSelectHeader>
                 {!hideSelectHeaderCheckbox
                     && (
                     <CustomCheckbox
                       inline
                       checked={checked}
                       indeterminate={indeterminate}
                       onChange={handleCheckAll}
                     />
                     )}
             </StyledSelectHeader>
             )}
          </StyledHeader>
          <CheckCell dataKey="id" checkedKeys={checkedKeys} onChange={handleCheck} customSelectCell={customSelectCell} />
        </Column>
        )}
          {columns.map((column) => (
            <Column fixed={column?.fixed} align="center" key={column.key} width={column.width || defaultWidth} minWidth={column?.minWidth} sortable={column.sortable} flexGrow={column?.minWidth ? 1 : 0}>
              <StyledHeader renderSortIcon={(sort: 'desc' | 'asc') => <div className={`custom-sorting ${sort}`} />}>
                {column.label}
              </StyledHeader>
              <Cell dataKey={column.key} />
            </Column>
          ))}
        </Table>

      </TableWrapper>
      {displayPagination
          && (
          <PaginationWrapper className="pagination-wrapper">
            <TotalItemsWrapper className="bottom">
              <span className="pre-label">Displaying</span>
              <StyledSelect
                data={[15, 30, 45].map((el) => ({ value: el, label: el }))}
                value={limit}
                onChange={handleChangeLimit}
                searchable={false}
                cleanable={false}
                appearance="subtle"
              />
              <span>
                of
                {' '}
                {totalItems || data.length}
                {' '}
                Units
              </span>
            </TotalItemsWrapper>
            <CustomPagination
              limit={limit}
              activePage={page}
              total={totalItems || data?.length}
              onChangePage={handlePageChange}
              totalPages={totalPages || Math.round(((totalItems || data.length) / limit))}
              maxButtons={!isMobile ? 3 : 1}
            />
            {showTotalPages
                && (
                <div className="total-pages-info">
                  Showing
                  {' '}
                  {page}
                  {' '}
                  of
                  {' '}
                  {totalPages || (totalItems ? Math.round((totalItems / limit)) : '')}
                </div>
                )}
          </PaginationWrapper>
          )}
    </div>
  );
}

export default CustomTable;

const TotalItemsWrapper = styled.div`
display: flex;
  &.bottom {
    display: none;
  }
  @media screen and (max-width: 767px) {
    &.top {
      display: none;
    }
    &.bottom {
      display: flex;
      .pre-label {
        display: none;
      }
    }
  }
`;

const TableWrapper = styled.div`
  height: unset;
  color: ${(props) => props.theme.primaryTextColor};
  .rs-table-row.rs-table-row-header  {
    .rs-checkbox-checker .rs-checkbox-wrapper {
      left: 9px;
    }
  }
  .rs-table-row:not(.rs-table-row-header)  {
    border-bottom: ${(props) => `1px solid ${props.theme.primaryColor}80`};
    border-top: ${(props) => `1px solid ${props.theme.primaryColor}80`};
    transition: all ease 0.2s;
    .rs-table-cell-group-fixed-left {
      :hover {
        background-color: #f6f5f5;
      }
    }
    :hover {
      .rs-table-cell-group-fixed-left {
        background-color: #f6f5f5;
      }
      background-color: rgba(255, 155, 65, 0.05);
      border-bottom: ${(props) => `3px solid ${props.theme.primaryColor}0F`};
      border-top: ${(props) => `3px solid ${props.theme.primaryColor}0F`};
    }
    .rs-checkbox-checker .rs-checkbox-wrapper {
      left: 7px;
      top: 5px;
    }
  }

  .rs-table-cell-wrap {
    display: flex;
    align-items: center;
  }

  .rs-table-cell {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.fullHeightContainer {
    height: calc(100vh - 262px);
  }

  margin: 21px;

  .custom-sorting {
    height: 18px;
    width: 18px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' viewBox='0 0 24 24' width='100%25' fill='%23c1c1c1' preserveAspectRatio='none'%3E%3Cpath d='M16 17.01V11c0-.55-.45-1-1-1s-1 .45-1 1v6.01h-1.79c-.45 0-.67.54-.35.85l2.79 2.78c.2.19.51.19.71 0l2.79-2.78c.32-.31.09-.85-.35-.85H16zM8.65 3.35L5.86 6.14c-.32.31-.1.85.35.85H8V13c0 .55.45 1 1 1s1-.45 1-1V6.99h1.79c.45 0 .67-.54.35-.85L9.35 3.35c-.19-.19-.51-.19-.7 0z'/%3E%3C/svg%3E");

    &.desc {
      background-image: url("data:image/svg+xml,%3Csvg fill='%23c1c1c1' height='14px' width='14px' version='1.1' asid='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 476.492 476.492' xml:space='preserve' stroke='%23c1c1c1'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpolygon points='253.246,339.952 253.246,0 223.246,0 223.246,339.952 101.707,339.952 238.246,476.492 374.785,339.952 '%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
    }

    &.asc {
      background-image: url("data:image/svg+xml,%3Csvg fill='%23c1c1c1' height='14px' width='14px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 476.492 476.492' xml:space='preserve' stroke='%23c1c1c1'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpolygon points='253.246,339.952 253.246,0 223.246,0 223.246,339.952 101.707,339.952 238.246,476.492 374.785,339.952 '%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
      transform: rotate(180deg);
      background-repeat: no-repeat;
    }
  }

  @media screen and (max-width: 767px) {
    margin: 10px;
  }
  .rs-table-cell-content {
    display: flex;
    align-items: center;
  }

  .top {
    margin-left: 10px;
    font-size: 12px;

    .rs-picker {
      margin-top: -2px;
    }
  }

  .rs-stack > :nth-child(2) {
    margin-top: 4px;
    margin-right: 4px;

    svg {
      background-size: 25px 45px;
      background-position: -9px -7px;
      background-image: ${(props) => `url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' width='1em' viewBox='0 0 21 32' fill='${props.theme.primaryColor.replace('#', '%23')}' height='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z'%3E%3C/path%3E%3C/svg%3E")`};

      path {
        display: none;
      }
    }
  }

  .rs-btn-subtle {
    padding-right: 15px !important;

    .rs-picker-toggle-caret {
      right: 1px;
    }
  }
`;

const StyledHeader = styled(HeaderCell)`
font-weight: bold;
color: ${(props) => props.theme.primaryTextColor};
  font-size: 14px;
  .rs-table-cell-content {
    padding: 10px;
  }
`;

const StyledSelectHeader = styled.div`
  margin-top: 2px;
  white-space: nowrap;
  margin-left: -22px;
  min-width: 20px;
`;

const StyledSelect = styled(SelectPicker)`
  border: none;
  outline: none;
  margin-left: 10px;
  margin-right: 5px;
  &.rs-picker-toggle-wrapper {
    .rs-picker-toggle {
      min-width: unset;
    }
    .rs-picker-toggle:focus {
      //background-color: inherit;
    }
  }
  .rs-picker-toggle {
    padding: 0;
  }
  .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 0;
    color: ${(props) => props.theme.secondaryColor};
  }
`;

const PaginationWrapper = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  .rs-pagination-btn {
    font-weight: bold;
    color:${(props) => props.theme.primaryTextColor};
    font-size: 12px;
  }
  .rs-pagination-btn.rs-pagination-btn-active {
    border-color: ${(props) => props.theme.primaryColor};
  }
  .rs-pagination-btn {
    transition: all ease 0.2s;
    :hover {
      background: inherit;
      color: ${(props) => props.theme.primaryColor};
    }
  }
  [aria-label="Previous"] {
    height: 25px;
    display: flex;
    align-items: center;
    .rs-pagination-symbol {
      transform: rotate(90deg);
      svg {
        display: none;
      }
      width: 10px;
      height: 10px;
      background-image: ${(props) => `url("data:image/svg+xml,%3Csvg width='24' fill='${props.theme.primaryColor.replace('#', '%23')}' height='24' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath stroke='null' id='svg_1' d='m1.1752,9.02285l8.56872,13.25122c1.29027,1.99536 3.37455,1.99536 4.66483,0l8.56872,-13.25122c2.08428,-3.22327 0.59551,-8.74887 -2.34895,-8.74887l-17.13744,0c-2.94446,0 -4.40015,5.5256 -2.31587,8.74887z'/%3E%3C/g%3E%3C/svg%3E")`};

      background-repeat: no-repeat;
      background-size: cover;
    }
    &.rs-pagination-btn-disabled {
      .rs-pagination-symbol {
        background-image: url("data:image/svg+xml,%3Csvg width='24' fill='gray' height='24' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath stroke='null' id='svg_1' d='m1.1752,9.02285l8.56872,13.25122c1.29027,1.99536 3.37455,1.99536 4.66483,0l8.56872,-13.25122c2.08428,-3.22327 0.59551,-8.74887 -2.34895,-8.74887l-17.13744,0c-2.94446,0 -4.40015,5.5256 -2.31587,8.74887z'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
} 
  [aria-label="Next"] {
    height: 25px;
    display: flex;
    align-items: center;
    .rs-pagination-symbol {
      transform: rotate(-90deg);
      svg {
        display: none;
      }
      width: 10px;
      height: 10px;
      background-image: ${(props) => `url("data:image/svg+xml,%3Csvg width='24' fill='${props.theme.primaryColor.replace('#', '%23')}' height='24' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath stroke='null' id='svg_1' d='m1.1752,9.02285l8.56872,13.25122c1.29027,1.99536 3.37455,1.99536 4.66483,0l8.56872,-13.25122c2.08428,-3.22327 0.59551,-8.74887 -2.34895,-8.74887l-17.13744,0c-2.94446,0 -4.40015,5.5256 -2.31587,8.74887z'/%3E%3C/g%3E%3C/svg%3E")`};
      background-repeat: no-repeat;
      background-size: cover;
    }
    &.rs-pagination-btn-disabled {
      .rs-pagination-symbol {
        background-image: url("data:image/svg+xml,%3Csvg width='24' fill='gray' height='24' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath stroke='null' id='svg_1' d='m1.1752,9.02285l8.56872,13.25122c1.29027,1.99536 3.37455,1.99536 4.66483,0l8.56872,-13.25122c2.08428,-3.22327 0.59551,-8.74887 -2.34895,-8.74887l-17.13744,0c-2.94446,0 -4.40015,5.5256 -2.31587,8.74887z'/%3E%3C/g%3E%3C/svg%3E");
      }
    }
}

  .total-pages-info {
    margin-top: 2px;
    margin-left: 5px;
  }
`;
