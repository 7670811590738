import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

function MainLayout() {
  return (
    <StyledLayout>
      <Outlet />
    </StyledLayout>
  );
}

export default MainLayout;

const StyledLayout = styled.div`
background: #f5f5f5;    
min-height: 100vh;
`;
