import SettingsLayout from '../../../components/SettingsLayout';
import Pages from '../../../Router/pages';
import OverlaySection from '../../SettingsSections/OverlaySection';
import { getLocalStorage } from '../../../helpers';
import { LocalStorageKeys } from '../../../store/keys';

function SettingsOverlay() {
  const dealerId = String(getLocalStorage(LocalStorageKeys.DEALER_ID));

  return (
    <SettingsLayout navigateOnBack={Pages.inventoryManager} headerTitle="settings">
      <OverlaySection dealerId={dealerId} />
    </SettingsLayout>

  );
}
export default SettingsOverlay;
