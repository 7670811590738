import { Modal } from 'rsuite';
import styled from 'styled-components';
import clearIco from '../../../assets/clear.png';
import CustomButton from '../../../components/Buttons/CustomButton';
import CustomCheckbox from '../../../components/inputs/CustomCheckbox';
import ArchiveSvg from '../../../components/SvgElements/ArchiveSvg';
import BinSvg from '../../../components/SvgElements/BinSvg';
import { useInventoryManagerContext } from '../inventoryManagerContext';

export type InventoryManagerDeleteModalProps = {
    showModel: boolean
    setShowModal: (val: boolean) => void
    handleAcceptModal: () => void
    mode?: 'Archive'| 'Delete' | 'Unarchive'
    bulk?: boolean
}

function InventoryManagerDeleteModal({
  setShowModal, showModel, handleAcceptModal, mode, bulk,
}: InventoryManagerDeleteModalProps) {
  const { permanentDelete, setPermanentDelete } = useInventoryManagerContext();
  const isDeleteMode = permanentDelete || mode === 'Delete';
  return (
    <Modal
      open={showModel}
      onClose={() => setShowModal(false)}
    >
      <StyledModalHeader>
        <button type="button" onClick={() => setShowModal(false)}>
          <img src={clearIco} alt="clear" />
        </button>
      </StyledModalHeader>
      <Modal.Body>
        <ModalContainer>
          {isDeleteMode
            ? (
              <IconWrapper>
                <BinSvg />
              </IconWrapper>
            )
            : (
              <IconWrapper>
                <ArchiveSvg />
              </IconWrapper>
            )}
          <h3>
            {permanentDelete ? 'Permanent delete' : mode}
          </h3>
          <div className="text">
            Are you sure you would like
            {' '}
            <br />
            to
            {' '}
            {permanentDelete ? 'permanent delete' : mode}
            {' '}
            these Unit(s)?
          </div>
          {(mode === 'Archive' && bulk)
              && (
              <div>
                <label htmlFor="permanent">Permanent delete</label>
                <CustomCheckbox
                  id="permanent"
                  checked={permanentDelete}
                  value={permanentDelete}
                  onChange={() => setPermanentDelete(!permanentDelete)}
                />
              </div>
              )}
          <CustomButton
            wrapperClassname="archive-btn"
            variant="primary"
            onClick={handleAcceptModal}
          >
            {permanentDelete ? 'Delete' : mode}
          </CustomButton>
        </ModalContainer>
      </Modal.Body>
    </Modal>
  );
}

export default InventoryManagerDeleteModal;

const StyledModalHeader = styled.div`
display: flex;
justify-content: flex-end;
  button {
    padding: 0;
    margin: 0;
    background: transparent;
  }
  img {
    cursor: pointer;
    transition: all ease 0.2s;
    :hover {
      transform: scale(1.2);
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    margin-top: 10px;
  }
  .text {
    margin-top: 11px;
    font-size: 18px;
    color: ${(props) => props.theme.secondaryTextColor};
  }
  .archive-btn {
    width: 121px;
    height: 35px;
    margin-top: 32px;
  }
`;

const IconWrapper = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 36px;
    height: 36px;
  }
`;
