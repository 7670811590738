import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { XYCoord } from 'react-dnd/src/types/monitors';

const style = {
  cursor: 'move',
};

interface DndCardI {
  id: number
  children: string | JSX.Element | JSX.Element[]
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
}
type Item<T> = T & {
  index: number
}

function DndCard<T>({
  id, children, index, moveCard,
}:DndCardI) {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: Item<T>, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = (ref.current as HTMLElement)?.getBoundingClientRect();
      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset() as XYCoord;
      // Get pixels to the top
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;
      // Only perform the move when the mouse has crossed half of the items height
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
      {children}
    </div>
  );
}

export default DndCard;
