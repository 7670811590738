import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppMessageContext } from '../../appMessagesContext';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import {
  CreateAdminUser,
  GetAdminUser,
  UpdateAdminUser,
} from '../../store/dealers/actions';
import AdminUserCreationForm, { AdminUsersFromData } from './AdminUserCreationForm';

function AdminUserCreation() {
  const params = useParams<{ id: string }>();
  const navigate = useNavigateHook();
  const { showMessage } = useAppMessageContext();
  const { data: userData, isFetching: userLoading } = GetAdminUser(params.id || '');
  const createAdminUserReq = CreateAdminUser();
  const updateAdminUserReq = UpdateAdminUser();
  const handleSubmit = (data: AdminUsersFromData) => {
    if (params.id) {
      const formData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        department: data.department,
        ...{ ...(data.password && { password: data.password }) },
        ...{ ...(data.password_confirmation && { password_confirmation: data.password_confirmation }) },
      };
      updateAdminUserReq.mutate({
        data: formData,
        id: params.id,
      });
    } else {
      createAdminUserReq.mutate({ data });
    }
  };

  useEffect(() => {
    if (createAdminUserReq.isSuccess) {
      showMessage({ type: 'success', message: 'User Created successfully' });
      createAdminUserReq.reset();
      navigate(Pages.adminUsersPage);
    }
    if (updateAdminUserReq.isSuccess) {
      showMessage({ type: 'success', message: 'User Updated successfully' });
      updateAdminUserReq.reset();
      navigate(Pages.adminUsersPage);
    }
  }, [createAdminUserReq.isSuccess, updateAdminUserReq.isSuccess]);

  const formInitial: AdminUsersFromData = {
    first_name: userData?.first_name || '',
    last_name: userData?.last_name || '',
    email: userData?.email || '',
    password: '',
    password_confirmation: '',
    department: userData?.department || '',
  };

  if (userLoading) {
    return null;
  }

  return (
    <StyledContainer>
      <AdminUserCreationForm
        onSubmit={handleSubmit}
        editMode={!!params.id}
        editableValues={formInitial}
        errorsProps={createAdminUserReq?.error?.response?.data.errors || updateAdminUserReq?.error?.response?.data.errors}
      />
    </StyledContainer>
  );
}

export default AdminUserCreation;

const StyledContainer = styled.div`
    width: 100%;
`;
