import SettingsLayout from '../../../components/SettingsLayout';
import Pages from '../../../Router/pages';
import CSVSection from '../../SettingsSections/CSVSection';

function SettingsCSV() {
  return (
    <SettingsLayout navigateOnBack={Pages.inventoryManager} headerTitle="settings">
      <CSVSection />
    </SettingsLayout>

  );
}
export default SettingsCSV;
