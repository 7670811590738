import moment from 'moment-timezone';
import React, { useEffect, useReducer, useState } from 'react';
import styled from 'styled-components';
import {
  Whisper, Tooltip, Toggle, Loader,
} from 'rsuite';
import { queryClient } from '../../../../App';
import { useAppMessageContext } from '../../../../appMessagesContext';
import CustomButton from '../../../../components/Buttons/CustomButton';
import CustomCalendar from '../../../../components/CustomCalendar';
import CustomTable, { CustomTableProps } from '../../../../components/CustomTable';
import CustomCheckbox from '../../../../components/inputs/CustomCheckbox';
import CustomSelect, { SelectValue } from '../../../../components/inputs/CustomSelect';
import SearchInput from '../../../../components/inputs/SearchInput';
import { ApplicationTheme } from '../../../../globalStyles';
import {
  arrayRange,
  formSelectOptions,
  getTimeOptions,
  getAutoPostingTimeOptions,
  roleHasAccess,
  SchedulerAvailableStatuses,
  TimeOption,
  unitPostingValidation,
} from '../../../../helpers';
import {
  dateOptions, timeOptionToStringFormat, formTimeValue, getDateForSchedulerPosting,
} from '../../../../helpers/formHelpers';
import { useGetSelectedDealer } from '../../../../hooks/getDealersHook';
import useNavigateHook from '../../../../hooks/useNavigateHook';
import Pages from '../../../../Router/pages';
import { FundsState } from '../../../../store';
import { getMeRequest } from '../../../../store/auth/actions';
import { BillingRoles, SupperAdminRoles } from '../../../../store/constants';
import { RequestKeys } from '../../../../store/keys';
import {
  CreateCLScheduleRequest,
  CreateCLScheduleRequestData, getBalanceRequest,
  getCLProfilesRequest, getFbProfilesRequest,
  getTimezonesRequest, ToggleAutopostingRequest,
} from '../../../../store/settings/actions';
import { FbProfileI, ProfileI } from '../../../../store/settings/requests';
import {
  getInventoryManagerFilters,
  getUnits,
  ItemsPaginationParams,
  LocationFilters, UnitSchedule, UnitType,
} from '../../../../store/unit/actions';
import { UnitsColumns } from '../../../InventoryManager';
import { FiltersType } from '../../../InventoryManager/ControlPanel/Filters';
import {
  ActionCell,
  ImageCell,
  LocationCell,
  PriceCell, SalesPriceCell,
} from '../../../InventoryManager/UnintsTableCells';
import { PendingUnitRequest } from '../../../../store/types';
import { InfoIco } from '../../OverlaySection/OverlaySectionForm';

export interface ScheduleTableData extends Omit<UnitType, 'images_count' | 'dealer_schedules'> {
  images_count?: JSX.Element
  dealer_schedules?: JSX.Element
}

export type DateOption = {
  label: string
  value: string
  date: Date
}

export type ProfileOption = ProfileI & {
  value: string
  label: string
}
export type FbProfileOption = FbProfileI & {
  value: string
  label: string
}
export type SchedulerFiltersT = {
  params: ItemsPaginationParams
  filters: {
    locationFilter: FiltersType['location'],
    search: string,
    limit: number,
    page: number,
  }
  selectedItems: number[]
}
export type SchedulerDetailsDataT = {
  profile: ProfileOption | FbProfileOption | null
  date: DateOption | null
  time: TimeOption | null
  timezone: SelectValue
  type: 'facebook' | 'craigslist'
  urgent: boolean
}
export type AutopostingDataFormData = {
  profile: FbProfileOption | null
  fb_autoposting: boolean
  dealer_id?: number | string
  autopost_daily: SelectValue
  autopost_start_time: TimeOption | null
  autopost_end_time: TimeOption | null
  autopost_timezone: SelectValue
}
interface Errors {
  [key: string]: string
}
type ScheduleTabProp = {
  onDetailsDataChange: (data: Partial<SchedulerDetailsDataT>) => void
  detailsData: SchedulerDetailsDataT
}
function ScheduleTab({ onDetailsDataChange: setDetailsData, detailsData }: ScheduleTabProp) {
  const selectedDealer = useGetSelectedDealer();
  const getMe = getMeRequest();
  const navigate = useNavigateHook();
  const { showMessage } = useAppMessageContext();
  const tableCashedParams = queryClient.getQueryData<SchedulerFiltersT>([RequestKeys.SCHEDULER_TABLE_FILTERS]);
  const dealersReqLoading = (roleHasAccess(getMe?.data?.data.role, SupperAdminRoles) ? (getMe.isLoading) : false);
  const dealersIDReqLoading = (roleHasAccess(getMe?.data?.data.role, SupperAdminRoles) ? !!selectedDealer?.id : true);
  const { responseData: profilesData } = getCLProfilesRequest({ dealer_id: selectedDealer?.id }, !dealersIDReqLoading);
  const { responseData: fbProfilesData } = getFbProfilesRequest({ dealer_id: selectedDealer?.id }, !dealersIDReqLoading);
  const { responseData: timezones } = getTimezonesRequest();
  const createRequest = CreateCLScheduleRequest();
  const toggleAutopostingRequest = ToggleAutopostingRequest();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const dateFromUrl = Object.fromEntries(urlSearchParams.entries());
  const [calendarDate, setCalendarDate] = useState<Date>(moment(dateFromUrl).toDate());

  const profileOptions = profilesData
    .sort((a, b) => Number(b.is_default) - Number(a.is_default))
    .map((profile) => ({
      ...profile,
      value: profile.name,
      label: profile.name,
    }));

  const fbProfileOptions = fbProfilesData
    .sort((a, b) => Number(b.is_default) - Number(a.is_default))
    .map((profile) => ({
      ...profile,
      value: profile.name,
      label: profile.name,
    }));

  const [validationErrors, setValidationErrors] = useState<{[key in keyof SchedulerDetailsDataT]?: string}>({});
  const [autoPostingValidationErrors, setAutoPostingValidationErrors] = useState<{[key in keyof AutopostingDataFormData]?: string}>({});

  const [selectedItems, setSelectedItems] = useState<number[]>(tableCashedParams?.selectedItems || []);
  const initialPage = Object.fromEntries(urlSearchParams.entries()).page ? Number(Object.fromEntries(urlSearchParams.entries()).page) : 1;
  const [page, setPage] = useState<number>(tableCashedParams?.filters?.page || initialPage);
  const [limit, setLimit] = useState<number>(tableCashedParams?.filters?.limit || 15);
  const [locationFilter, setLocationFilter] = useState<FiltersType['location']>(tableCashedParams?.filters?.locationFilter || {
    name: 'All', is_main: false, is_placeholder: true,
  });
  const [search, setSearch] = useState<string>(tableCashedParams?.filters?.search || '');

  const handleSelectItems = (ids: number[]) => {
    setSelectedItems(ids);
    queryClient.setQueryData(
      [RequestKeys.SCHEDULER_TABLE_FILTERS],
      (oldVal: SchedulerFiltersT) => ({
        ...oldVal,
        selectedItems: ids,
      }),
    );
  };
  const [params, setParams] = useReducer(
    (state: ItemsPaginationParams, newState: Partial<ItemsPaginationParams>) => ({
      ...state,
      ...newState,
    }),
    {
      ...tableCashedParams?.params,
      limit,
      page,
      is_inventory_manager: '1',
      status: SchedulerAvailableStatuses,
    },
  );

  const user = getMeRequest()?.data?.data;
  const {
    responseData: unitsList, meta: unitsMeta, refetch: refetchUnits, isFetching,
  } = getUnits(params, dealersReqLoading);
  const { responseData: balance, refetch: refetchBalance } = getBalanceRequest({ dealer_id: selectedDealer?.id || user?.dealer_id }, dealersIDReqLoading);

  const refetchInventory = () => {
    refetchUnits();
  };
  queryClient.setQueryData<() => void>([RequestKeys.REFETCH_INVENTORY], () => refetchInventory);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    setParams({
      page: newPage,
    });
  };
  const handleLimitChange = (lim: number) => {
    setLimit(lim);
    setParams({
      limit: lim,
    });
  };
  const handleColumnSort = (column: string, order: 'desc' | 'asc') => {
    if (column !== 'primary_image') {
      setParams({
        order,
        sort: column,
      });
    }
    queryClient.setQueryData(
      [RequestKeys.SCHEDULER_TABLE_FILTERS],
      (oldVal: SchedulerFiltersT) => ({
        ...oldVal,
        params: {
          ...oldVal.params,
          order,
          sort: column,
        },
      }),
    );
  };

  const { responseData: managerFilters } = getInventoryManagerFilters(
    { dealer_id: selectedDealer?.id || undefined },
    (getMe.isFetching),
  );
  const locationOptions = managerFilters?.location || [];
  const formLocationOption = [{ name: 'All', is_placeholder: true }, ...locationOptions];

  const locationLabel = (data: LocationFilters) => (
    <div>{data.name}</div>
  );

  useEffect(() => {
    setValidationErrors({});
    setAutoPostingValidationErrors({});
  }, [detailsData?.type]);

  useEffect(() => { // handle filters change
    setParams({
      ...(search ? { search } : { search: undefined }),
      ...(!locationFilter?.is_placeholder ? { location_id: [Number(locationFilter?.id)] } : { location_id: undefined }),
      ...(selectedDealer?.id ? { dealer_id: selectedDealer?.id ? [Number(selectedDealer?.id)] : undefined } : { dealer_id: undefined }),
    });
    const formUpdaterData: SchedulerFiltersT = {
      params: {
        ...(search ? { search } : { search: undefined }),
        ...(!locationFilter?.is_placeholder ? { location_id: [Number(locationFilter?.id)] } : { location_id: undefined }),
        ...(selectedDealer?.id ? { dealer_id: selectedDealer?.id ? [Number(selectedDealer?.id)] : undefined } : { dealer_id: undefined }),
      },
      filters: {
        locationFilter,
        search,
        limit,
        page,
      },
      selectedItems,
    };
    queryClient.setQueryData(
      [RequestKeys.SCHEDULER_TABLE_FILTERS],
      (oldVal: SchedulerFiltersT) => ({
        params: {
          ...oldVal?.params,
          ...formUpdaterData.params,
        },
        filters: {
          ...oldVal?.filters,
          ...formUpdaterData.filters,
        },
        selectedItems,
      }),
    );
  }, [search, locationFilter, selectedDealer?.id, locationFilter, limit, page]);

  const timeArray = getTimeOptions();
  const autoPostingTimeArray = getAutoPostingTimeOptions();
  const formatedTimeOptions = timeArray.filter((t) => {
    const formatedTime = moment().set({
      hour: Number(t?.time.split(':')?.[0]) || 0,
      minute: Number(t?.time.split(':')[1]) || 0,
      date: moment(detailsData?.date?.date).get('date'),
      months: moment(detailsData?.date?.date).get('months'),
    });
    if (t?.time.split(':')[0] === '12') {
      formatedTime.subtract(12, 'hour');
    }
    if (t?.meridiem === 'PM') {
      formatedTime.add(12, 'hour');
    }
    const zonedTime = moment.tz(formatedTime.format('M/D/YYYY h:mm a'), 'M/D/YYYY h:mm a', false, detailsData?.timezone?.value ? String(detailsData?.timezone?.value) : 'America/New_York');
    return zonedTime > moment();
  });
  const formatedAutoPostingTimeOptions = autoPostingTimeArray.filter(() => true);
  useEffect(() => {
    const index = formatedTimeOptions.findIndex((option: TimeOption) => detailsData?.time?.label === option.label);
    if (index < 0) {
      setDetailsData({ time: null });
    }
  }, [detailsData.date, detailsData.time, detailsData.timezone]);
  const validate = (): boolean => {
    const errors: Errors = {};
    const requiredFields = ['date', 'time', 'timezone'];
    const isMissingField = (data: SchedulerDetailsDataT, field: string): boolean => !data?.[field as keyof SchedulerDetailsDataT];

    if (!detailsData?.profile?.id && detailsData.type === 'craigslist') {
      Object.assign(errors, { profile: 'This field is required' });
    }

    if (!detailsData.urgent) {
      requiredFields.forEach((field) => {
        if (isMissingField(detailsData, field)) {
          Object.assign(errors, { [field]: 'This field is required' });
        }
      });
    }

    setValidationErrors(errors);
    return !Object.keys(errors).length;
  };
  const validateAutoPosting = (): boolean => {
    const errors: Errors = {};
    const autoPostingRequiredFields = ['autopost_timezone', 'autopost_daily', 'autopost_start_time', 'autopost_end_time'];
    const isMissingAutoPostingField = (data: AutopostingDataFormData, field: string): boolean => !data?.[field as keyof AutopostingDataFormData];

    autoPostingRequiredFields.forEach((field) => {
      if (isMissingAutoPostingField(autopostingData, field)) {
        Object.assign(errors, { [field]: 'This field is required' });
      }
    });

    setAutoPostingValidationErrors(errors);
    return !Object.keys(errors).length;
  };
  const handlePost = () => {
    const date = getDateForSchedulerPosting(detailsData?.date?.date);
    const formatedTime = moment().set({
      hours: Number(detailsData?.time?.time.split(':')?.[0]) || 0,
      minute: Number(detailsData?.time?.time.split(':')[1]) || 0,
    });
    if (detailsData?.time?.time.split(':')[0] === '12') {
      formatedTime.subtract(12, 'hour');
    }
    if (detailsData?.time?.meridiem === 'PM') {
      formatedTime.add(12, 'hour');
    }
    if (validate()) {
      const formData: CreateCLScheduleRequestData = {
        items_ids: selectedItems,
        ...(detailsData?.date?.date && { date }),
        ...(detailsData?.time?.time && { time: formatedTime.format('HH:mm') }),
        ...(detailsData?.timezone?.value && { time_zone: String(detailsData?.timezone?.value) }),
        ...((detailsData?.type === 'craigslist') && { profile_id: Number(detailsData?.profile?.id) }),
        ...((detailsData?.type === 'facebook' && (selectedDealer?.fb_profiles === true || getMe.data?.data.fb_profiles === true)) && { fb_profile_id: Number(detailsData?.profile?.id) }),
        ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }),
        urgent: detailsData.urgent ? '1' : '0',
        type: detailsData.type,
      };
      createRequest.mutate(formData);
    }
  };

  useEffect(() => {
    if (createRequest.isSuccess) {
      handleSelectItems([]);
      if (detailsData.urgent) {
        window.postMessage({ type: 'POSTING_NOW', text: createRequest?.data?.data?.[0]?.id || 'posting' }, '*');
      }
      showMessage({ type: 'success', message: 'Schedule Posted successfully' });
      createRequest.reset();
      refetchUnits();
      refetchBalance();
    }
    if (createRequest.isError) {
      if (createRequest.error.response?.data?.errors?.items_ids) {
        showMessage({ type: 'error', message: 'Please Select Items' });
      } else {
        showMessage({ type: 'error', message: createRequest.error.response?.data.message || createRequest.error.message });
      }
      createRequest.reset();
    }
  }, [createRequest.isSuccess, createRequest.isError]);

  useEffect(() => {
    if (detailsData.type === 'facebook') {
      setDetailsData({ urgent: false });
    }
  }, [selectedItems.length, detailsData.type]);

  useEffect(() => {
    handleSelectItems([]);
  }, [detailsData.type]);
  const selectCell: CustomTableProps<ScheduleTableData>['customSelectCell'] = ({
    data,
    onChange,
    checked,
  }) => {
    const { unitValidation, lackOfBudget } = unitPostingValidation({
      balance: Number(balance?.balance),
      postingType: detailsData?.type,
      isUrgent: detailsData?.urgent,
      selectedItems,
      unitID: data.id,
      fieldsToValidate: {
        year: data?.year,
        imagesCount: data?.images?.length,
        dealership_location: data?.dealership_location,
        manufacturer: data?.manufacturer,
        model_name: data?.model_name,
        details: data?.details,
        description: data?.details?.description,
        status: data?.status,
        title: data?.title,
        overall_length: data?.attributes?.overall_length,
        category: data?.item_category,
      },
    });
    const handleAddFunds = (selectedAmount?: number | string) => {
      queryClient.setQueryData<FundsState>([RequestKeys.ADD_FUNDS_STATE], {
        funds: selectedAmount ? String(selectedAmount) : '5',
      });
      navigate(Pages.billingAddFunds);
    };

    const disabledTooltip = (
      <StyledTooltip className={!Object.keys(unitValidation).length && 'hidden'}>
        <div className="disabled-tooltip">
          <ul>
            {Object.keys(unitValidation).map((key) => (
              <li key={key}>
                {unitValidation[key]}
              </li>
            ))}
          </ul>
          {lackOfBudget && roleHasAccess(user?.role, BillingRoles)
          && (
          <CustomButton
            variant="text"
            onClick={() => {
              handleAddFunds(6);
            }}
          >
            Add Budget
          </CustomButton>
          )}
        </div>
      </StyledTooltip>
    );
    return (
      <div style={{ lineHeight: '46px' }}>
        <Whisper placement="top" controlId="disabled-id-hover" trigger="hover" speaker={disabledTooltip} enterable>
          <div>
            <CustomCheckbox
              value={data.id}
              inline
              checked={checked}
              onChange={onChange}
              disabled={!!Object.keys(unitValidation).length && !checked}
            />
          </div>
        </Whisper>
      </div>
    );
  };
  const dateQuery = Object.fromEntries(urlSearchParams.entries()).date;

  useEffect(() => {
    if (dateQuery && new Date(dateQuery)) {
      const day = moment(dateQuery, 'DD.MM.YYYY', true);
      const label = `${day.toDate().toLocaleDateString('en-US', { weekday: 'long' })}, ${day.toDate().toLocaleDateString('en-US', { month: 'long' })}, ${day.toDate().getDate()}`;
      setDetailsData({ date: { label, value: label, date: day.toDate() } });
      setCalendarDate(day.toDate());
    }
  }, [dateQuery]);
  const pendingRequests = queryClient.getQueryData<PendingUnitRequest[]>([RequestKeys.PENDING_REQUESTS]);
  const pendingIds = pendingRequests?.filter((req) => req?.status === 'PENDING')
    ?.map((pendingReq) => pendingReq?.unitID);
  const errorIds = pendingRequests?.filter((req) => req?.status === 'ERROR')
    ?.map((pendingReq) => pendingReq?.unitID);

  const [autopostingData, setAutopostingData] = useReducer(
    (state: Omit<AutopostingDataFormData, 'dealer_id'>, newState: Partial<AutopostingDataFormData>) => ({
      ...state,
      ...newState,
    }),
    {
      profile: null,
      fb_autoposting: false,
      autopost_daily: null,
      autopost_start_time: null,
      autopost_end_time: null,
      autopost_timezone: null,
    },
  );

  useEffect(() => {
    setDetailsData({ profile: null });
  }, [detailsData.type]);

  useEffect(() => {
    const selectedProfile = fbProfileOptions.find((profile) => profile.id === user?.fb_autoposting_profile_id);

    setAutopostingData(
      {
        profile: selectedProfile || fbProfileOptions[0],
      },
    );
  }, [user, fbProfileOptions.length]);

  const formInitialOption = (option: null | string | undefined | number): SelectValue => (option ? formSelectOptions([option as string])[0] : null);
  useEffect(() => {
    const initialEndTime = formTimeValue(selectedDealer?.autopost_end_time || getMe?.data?.data?.autopost_end_time);
    const initialStartTime = formTimeValue(selectedDealer?.autopost_start_time || getMe?.data?.data?.autopost_start_time);
    setAutopostingData({
      fb_autoposting: selectedDealer ? !!selectedDealer?.fb_autoposting : !!getMe?.data?.data?.fb_autoposting,
      autopost_daily: selectedDealer ? formInitialOption(selectedDealer?.autopost_daily) : formInitialOption(getMe?.data?.data?.autopost_daily),
      ...(initialEndTime && { autopost_end_time: initialEndTime }),
      ...(initialStartTime && { autopost_start_time: initialStartTime }),
      autopost_timezone: selectedDealer ? formInitialOption(selectedDealer?.autopost_timezone) : formInitialOption(getMe?.data?.data?.autopost_timezone),
    });
  }, [selectedDealer, getMe?.data?.data]);

  const saveAutopostingChanges = () => {
    const dealerId = selectedDealer?.id ? selectedDealer?.id : getMe?.data?.data?.dealer_id;
    if (validateAutoPosting() && dealerId) {
      toggleAutopostingRequest.mutate({
        fb_autoposting_profile_id: autopostingData?.profile?.id || '',
        fb_autoposting: !!autopostingData?.fb_autoposting,
        dealer_id: dealerId,
        autopost_daily: autopostingData?.autopost_daily?.value || undefined,
        ...(autopostingData?.autopost_start_time?.time && { autopost_start_time: timeOptionToStringFormat(autopostingData?.autopost_start_time) }),
        ...(autopostingData?.autopost_end_time?.time && { autopost_end_time: timeOptionToStringFormat(autopostingData?.autopost_end_time) }),
        autopost_timezone: autopostingData?.autopost_timezone?.value ? String(autopostingData?.autopost_timezone?.value) : undefined,
      });
    }
  };
  useEffect(() => {
    if (toggleAutopostingRequest?.isError) {
      showMessage({ type: 'error', message: toggleAutopostingRequest.error?.response?.data?.message || '' });
    }
    if (toggleAutopostingRequest.isSuccess) {
      showMessage({ type: 'success', message: 'Autoposting setting saved successfully' });
      toggleAutopostingRequest.reset();
      getMe.refetch();
    }
  }, [toggleAutopostingRequest?.isSuccess, toggleAutopostingRequest?.isError]);
  const infoTooltip = (
    <InfoTooltip arrow={false}>
      <div>
        Enable automatic posting units with the selected parameters
      </div>
    </InfoTooltip>
  );
  return (
    <Container className="posting-section">
      <div className="main-content-container">
        <div className="schedule-section">
          <div className="calendar-form-wrapper">
            <div className="form">
              <div className="form-title">
                Details
              </div>
              {detailsData.type === 'facebook'
                  && (
                    (selectedDealer?.fb_profiles === true || getMe.data?.data.fb_profiles === true) && (
                      <div className="input-wrapper">
                        <CustomSelect
                          label="FB Profile"
                          placeholder={' '}
                          value={{
                            ...detailsData.profile,
                            value: detailsData?.profile?.name,
                            label: detailsData?.profile?.name,
                          }}
                          onChange={(val: FbProfileOption) => setDetailsData({ profile: val })}
                          options={fbProfileOptions}
                          controlStyles={{
                            borderRadius: '4px',
                            overflow: 'hidden',
                          }}
                          indicatorContainerStyles={{
                            borderLeft: '1px solid #bfbcbc',
                            height: 'inherit',
                            marginTop: '0',
                            backgroundColor: ApplicationTheme.primaryBGColor,
                          }}
                          error={validationErrors?.profile || ''}
                        />
                      </div>
                    ))}
              {detailsData.type === 'craigslist'
              && (
              <div className="input-wrapper">
                <CustomSelect
                  label="Profile (Location)"
                  placeholder={' '}
                  value={{
                    ...detailsData.profile,
                    value: detailsData?.profile?.name,
                    label: detailsData?.profile?.name,
                  }}
                  onChange={(val: ProfileOption) => setDetailsData({ profile: val })}
                  options={profileOptions}
                  controlStyles={{
                    borderRadius: '4px',
                    overflow: 'hidden',
                  }}
                  indicatorContainerStyles={{
                    borderLeft: '1px solid #bfbcbc',
                    height: 'inherit',
                    marginTop: '0',
                    backgroundColor: ApplicationTheme.primaryBGColor,
                  }}
                  error={validationErrors?.profile || ''}
                />
              </div>
              )}
              {!detailsData.urgent
                && (
                <>
                  <div className="input-wrapper">
                    <CustomSelect
                      label="Date"
                      placeholder={' '}
                      value={detailsData.date}
                      onChange={(val: SchedulerDetailsDataT['date']) => {
                        setDetailsData({ date: val });
                        if (val?.date) {
                          setCalendarDate(val.date);
                        }
                      }}
                      options={dateOptions}
                      controlStyles={{
                        borderRadius: '4px',
                        overflow: 'hidden',
                      }}
                      indicatorContainerStyles={{
                        borderLeft: '1px solid #bfbcbc',
                        height: 'inherit',
                        marginTop: '0',
                        backgroundColor: ApplicationTheme.primaryBGColor,
                      }}
                      valueContainerStyles={{
                        maxHeight: '28px',
                        marginTop: '-10px',
                      }}
                      singleValueStyles={{
                        maxHeight: '28px',
                        marginTop: '10px',
                      }}
                      error={validationErrors?.date || ''}
                    />
                  </div>
                  <div className="input-wrapper">
                    <CustomSelect
                      label="Time Zone"
                      value={detailsData.timezone}
                      onChange={(val: SchedulerDetailsDataT['timezone']) => setDetailsData({ timezone: val })}
                      options={formSelectOptions(timezones)}
                      placeholder={' '}
                      controlStyles={{
                        borderRadius: '4px',
                        overflow: 'hidden',
                      }}
                      indicatorContainerStyles={{
                        borderLeft: '1px solid #bfbcbc',
                        height: 'inherit',
                        marginTop: '0',
                        backgroundColor: ApplicationTheme.primaryBGColor,
                      }}
                      error={validationErrors?.timezone || ''}
                    />
                  </div>
                  <div className="input-wrapper">
                    <CustomSelect
                      label="Time"
                      value={detailsData.time}
                      onChange={(val: SchedulerDetailsDataT['time']) => setDetailsData({ time: val })}
                      options={formatedTimeOptions}
                      placeholder={' '}
                      controlStyles={{
                        borderRadius: '4px',
                        overflow: 'hidden',
                      }}
                      indicatorContainerStyles={{
                        borderLeft: '1px solid #bfbcbc',
                        height: 'inherit',
                        marginTop: '0',
                        backgroundColor: ApplicationTheme.primaryBGColor,
                      }}
                      error={validationErrors?.time || ''}
                    />
                  </div>
                </>
                )}
              <div className="form-btn-wrapper">
                {createRequest?.isPending
                    && <Loader center />}
                <CustomButton
                  onClick={handlePost}
                  disabled={createRequest?.isPending}
                >
                  {detailsData.urgent ? 'Post Now' : 'Schedule Posting'}
                </CustomButton>
              </div>
            </div>
          </div>
          <div className="calendar-container">
            <CustomCalendar
              date={calendarDate}
              setDate={(newDate) => {
                if (moment(newDate) >= moment()) {
                  setCalendarDate(newDate);
                  const day = moment(newDate);
                  const label = `${day.toDate().toLocaleDateString('en-US', { weekday: 'long' })}, ${day.toDate().toLocaleDateString('en-US', { month: 'long' })}, ${day.toDate().getDate()}`;
                  setDetailsData({ date: { label, value: label, date: day.toDate() } });
                } else {
                  setCalendarDate(moment().toDate());
                  const day = moment();
                  const label = `${day.toDate().toLocaleDateString('en-US', { weekday: 'long' })}, ${day.toDate().toLocaleDateString('en-US', { month: 'long' })}, ${day.toDate().getDate()}`;
                  setDetailsData({ date: { label, value: label, date: day.toDate() } });
                }
              }}
            />
          </div>
          {detailsData?.type === 'facebook'
              && ((selectedDealer?.fb_profiles !== true && getMe.data?.data.fb_profiles !== true) && (
              <div className="calendar-form-wrapper autoposting">
                <div className="form">
                  <div className="form-title autoposting">
                    Autoposting
                    <Whisper placement="auto" controlId="save-id-hover" trigger="hover" speaker={infoTooltip}>
                      <div>
                        <InfoIco />
                      </div>
                    </Whisper>
                  </div>
                  <div className="autoposting">
                    <span className="toggle-label">Auto-posting to FB</span>
                    <Toggle
                      id="autoposting"
                      arial-label="Switch"
                      checked={autopostingData?.fb_autoposting}
                      disabled={autopostingData?.fb_autoposting ? false : !autopostingData?.autopost_daily?.value}
                      onChange={(val) => {
                        setAutopostingData({ fb_autoposting: val });
                      }}
                    />
                  </div>
                  <div className="input-wrapper">
                    <CustomSelect
                      label="Autoposting Time Zone"
                      value={autopostingData?.autopost_timezone}
                      onChange={(val: SchedulerDetailsDataT['timezone']) => setAutopostingData({ autopost_timezone: val })}
                      options={formSelectOptions(timezones)}
                      placeholder={' '}
                      controlStyles={{
                        borderRadius: '4px',
                        overflow: 'hidden',
                      }}
                      indicatorContainerStyles={{
                        borderLeft: '1px solid #bfbcbc',
                        height: 'inherit',
                        marginTop: '0',
                        backgroundColor: ApplicationTheme.primaryBGColor,
                      }}
                      error={autoPostingValidationErrors?.autopost_timezone || ''}
                    />
                  </div>
                  <div className="input-wrapper">
                    <CustomSelect
                      label="Daily posts"
                      value={autopostingData?.autopost_daily}
                      onChange={(val) => setAutopostingData({ autopost_daily: val })}
                      options={formSelectOptions(arrayRange(1, 100, 1).reverse())}
                      placeholder=" "
                      controlStyles={{
                        borderRadius: '4px',
                        overflow: 'hidden',
                      }}
                      indicatorContainerStyles={{
                        borderLeft: '1px solid #bfbcbc',
                        height: 'inherit',
                        marginTop: '0',
                        backgroundColor: ApplicationTheme.primaryBGColor,
                      }}
                      error={autoPostingValidationErrors?.autopost_daily || ''}
                    />
                  </div>
                  <div className="input-wrapper">
                    <CustomSelect
                      label="First Post"
                      value={autopostingData?.autopost_start_time}
                      onChange={(val: AutopostingDataFormData['autopost_start_time']) => setAutopostingData({ autopost_start_time: val })}
                      options={formatedAutoPostingTimeOptions}
                      placeholder={' '}
                      controlStyles={{
                        borderRadius: '4px',
                        overflow: 'hidden',
                      }}
                      indicatorContainerStyles={{
                        borderLeft: '1px solid #bfbcbc',
                        height: 'inherit',
                        marginTop: '0',
                        backgroundColor: ApplicationTheme.primaryBGColor,
                      }}
                      error={autoPostingValidationErrors?.autopost_start_time || ''}
                    />
                  </div>
                  <div className="input-wrapper">
                    <CustomSelect
                      label="Last Post"
                      value={autopostingData?.autopost_end_time}
                      onChange={(val: AutopostingDataFormData['autopost_end_time']) => setAutopostingData({ autopost_end_time: val })}
                      options={formatedAutoPostingTimeOptions}
                      placeholder={' '}
                      controlStyles={{
                        borderRadius: '4px',
                        overflow: 'hidden',
                      }}
                      indicatorContainerStyles={{
                        borderLeft: '1px solid #bfbcbc',
                        height: 'inherit',
                        marginTop: '0',
                        backgroundColor: ApplicationTheme.primaryBGColor,
                      }}
                      error={autoPostingValidationErrors?.autopost_end_time || ''}
                    />
                  </div>
                  <div className="form-btn-wrapper">
                    <CustomButton onClick={saveAutopostingChanges} disabled={createRequest?.isPending}>Save</CustomButton>
                  </div>
                </div>
              </div>
              )
              )}
        </div>

        <div className="table-section">
          <div className="table-header">
            <div className="table-header-inputs">
              <div className="filter-select">
                <CustomSelect
                  options={formLocationOption}
                  label="Location"
                  name="location"
                  value={locationFilter}
                  formatOptionLabel={(data: LocationFilters) => locationLabel(data)}
                  isOptionSelected={(data: LocationFilters) => data.id === locationFilter?.id}
                  onChange={(val) => setLocationFilter(val as LocationFilters)}
                  controlStyles={{ border: 'none', width: '195px' }}
                />
              </div>
              <div className="search-wrapper">
                <SearchInput
                  placeholder="Search Inventory"
                  value={search}
                  onChange={(value) => {
                    setSearch(value);
                    handlePageChange(1);
                  }}
                />
              </div>
            </div>
          </div>
          <CustomTable<ScheduleTableData>
            loading={isFetching}
            onPageChange={handlePageChange}
            limit={limit}
            onLimitChange={handleLimitChange}
            page={page}
            tableHeight={850}
            onColumnSort={handleColumnSort}
            serverSorting
            checkedKeysProp={selectedItems}
            maxSelectedItems={10}
            rowHeight={76}
            hideSelectHeaderCheckbox
            onItemSelect={handleSelectItems}
            serverSortColumn={params?.sort}
            serverSortType={params?.order}
            totalItems={unitsMeta?.total}
            totalPages={unitsMeta?.last_page}
            customSelectCell={selectCell}
            data={unitsList.map((dataRow) => ({
              ...dataRow,
              images_count: <ImageCell item={dataRow} />,
              dealer_schedules: <ScheduleStatusCell item={dataRow} type={detailsData?.type} />,
              location: <LocationCell item={dataRow} />,
              price: <PriceCell item={dataRow} />,
              sales_price: <SalesPriceCell item={dataRow} />,
              actionButtons: <ActionCell item={dataRow} hideExtra refetchUnits={refetchUnits} />,
            }))}
            columns={columns}
            rowClassName={(rowData: UnitType) => {
              if (errorIds?.includes(rowData?.id?.toString())) {
                return 'row-error';
              }
              if (pendingIds?.includes(rowData?.id?.toString())) {
                return 'row-pending';
              }
              return '';
            }}
            fillHeight={false}
            displayTotal
          />
        </div>
      </div>
    </Container>
  );
}

export default ScheduleTab;

const InfoTooltip = styled(Tooltip)`
  max-width: 241px;
  padding: 10px;
  text-align: center;
  font-size: 10px;
  background-color: ${(props) => props.theme.primaryBGColor};
  border-radius: 3px;
  border: 1px solid #f7f6f6;
  color: ${(props) => props.theme.primaryTextColor};
  .highlighted {
    color: ${(props) => props.theme.primaryColor};
    font-weight: bold;
    line-height: 1.3rem;
  }
`;

function ScheduleStatusCell({ item, type }: {item: UnitType, type: SchedulerDetailsDataT['type']}) {
  const filteredByType: UnitSchedule[] = (item?.dealer_schedules || [])?.filter((schedule) => schedule.type === type)
    .sort((a, b) => new Date(a.schedule_date_time).valueOf() - new Date(b.schedule_date_time).valueOf());
  const [currentIndex, setCurrentIndex] = useState<number>((filteredByType.length - 1 < 0) ? 0 : filteredByType.length - 1);
  const scheduleTodisplay = filteredByType[currentIndex];
  const handlePrev = () => {
    setCurrentIndex((prevState) => {
      if (prevState - 1 >= 0) {
        return prevState - 1;
      }
      return prevState;
    });
  };
  const handleNext = () => {
    setCurrentIndex((prevState) => {
      if (prevState + 1 <= filteredByType.length - 1) {
        return prevState + 1;
      }
      return prevState;
    });
  };
  const tooltip = (
    <StyledTooltip className={!scheduleTodisplay?.status && 'hidden'}>
      <button type="button" className="left" onClick={handlePrev} disabled={!currentIndex}>
        <StyledArrow />
      </button>
      <div className="tooltip">
        <span className={`status ${scheduleTodisplay?.status}`}>
          {scheduleTodisplay?.status}
        </span>
        <br />
        <span>
          {scheduleTodisplay?.type}
        </span>
        <br />
        <span>
          {moment(scheduleTodisplay?.schedule_date_time || '').utc(true).format('DD.MM.yyyy:hh:mm A')}
        </span>
      </div>
      <button type="button" className="right" onClick={handleNext} disabled={currentIndex === filteredByType.length - 1}>
        <StyledArrow />
      </button>
    </StyledTooltip>
  );

  return (
    <ScheduleStatusContainer>
      <Whisper placement="top" controlId={`${item?.stock}_${item?.dealer_schedules?.length}`} trigger={['active']} speaker={tooltip}>
        <div className="status-wrap">
          <div className={`status ${scheduleTodisplay?.status}`} />
        </div>
      </Whisper>
    </ScheduleStatusContainer>
  );
}
const StyledArrow = styled.div`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' viewBox='0 0 24 24' width='100%25' fill='%23747474' preserveAspectRatio='none'%3E%3Cpath d='M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z'/%3E%3C/svg%3E");
    width:  12px;
    height: 12px;
    margin-right: 4px;
`;
const StyledTooltip = styled(Tooltip)`
 background-color: #c0c0c0;
  color: ${(props) => props.theme.primaryTextColor};
  font-size: 14px;
  display: flex;
  .left {
    transform: rotate(180deg);
  }
  .status {
    font-weight: bold;
  }
  &.hidden {
    display: none;
  }
  li {
    list-style: none;
  }
`;
const ScheduleStatusContainer = styled.div`
  .status-wrap {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .status {
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    &.Successful {
      background-color: #629c44;
    }
    &.Sold {
      background-color: #668ae9;
    }
    &.Scheduled {
      background-color: #ffa834;
    }
    &.Failed {
      background-color: #ff3823;
    }
    &.Expired {
      background-color: rgb(118 118 118);
    }
    &.Low {
      background-color: #7e2199;
    }
  }
`;

const columns: UnitsColumns[] = [
  {
    label: '',
    key: 'dealer_schedules',
    width: 25,
  },
  {
    label: 'Primary Image',
    key: 'images_count',
    width: 145,
    sortable: true,
  },
  {
    label: 'Stock',
    key: 'stock',
    sortable: true,
    width: 95,
  },
  {
    label: 'Title',
    key: 'title',
    sortable: true,
  },
  {
    label: 'Status',
    key: 'status',
    sortable: true,
    width: 110,
  },
  {
    label: 'Price',
    key: 'price',
    sortable: true,
    width: 82,
  },
  {
    label: 'Sales price',
    key: 'sales_price',
    sortable: false,
    width: 100,
  },
  {
    label: 'Location',
    key: 'location',
    sortable: true,
    width: 106,
  },
  {
    label: 'Exported',
    key: 'exported',
    sortable: false,
    width: 106,
  },
  {
    label: '',
    key: 'actionButtons',
    width: 120,
  },
];

const Container = styled.div`
  .row-pending {
    background-color: #ffd783;
  }
  .row-error {
    //background-color: #ff8a84;
  }
  .rs-table-row {
    :hover {
      .rs-table-cell-first {
        background-color: inherit!important;
      }
      &.row-error {
        .rs-table-cell {
          background-color: #ff8a84;
        }
      }
      &.row-pending {
        .rs-table-cell {
          background-color: #ffd783;
        }
      }
    }
  }
  .rs-table-cell {
    :hover {
      * {
        //background-color: inherit;
      }
    }
    background-color: inherit;
  }
  padding-right: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 14px;
    padding-top: 17px;
    padding-right: 15px;

    .title {
      color: #444444;
      font-size: 25px;
      font-weight: bold;
    }
  }
  .box-section {
    display: flex;
    justify-content: space-between;
    padding-left: clamp(1rem,2vw,3.625rem);
    padding-right: 20px;
  }
  .amount-section-container {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
  .amount-box {
    &.select-type {
      width: auto;
      h4 {
        margin-bottom: 10px;
      }
      .control {
        display: flex;
        align-items: center;  
      }
      label {
        margin-left: 10px;
        margin-right: 10px;
      }
      span {
        font-weight: bold;
      }
      .rs-toggle-presentation {
        background-color: #a682ff;
      }
      .rs-toggle-checked {
        .rs-toggle-presentation {
          background-color: #3b5998;
        }  
      }
    }
    background-color: #fff;
    padding: 23px 32px 13px;
    width: 198px;
    height: 144px;
    border: 1px solid #fef4e2;
    border-radius: 8px;
    .select-wrapper {
      .select-wrap {
        width: 134px;
        height: 28px;
      }
    }
    .box-value {
      font-weight: bold;
      font-size: 30px;
      color: #eea236;
      margin-top: 12px;
    }
    .box-title {
      font-size: 18px;
      font-weight: bold;
      color: #444444;
    }
    button {
      margin-top: 20px;
      width: 134px;
      font-size: 16px;
      padding: 5px;
    }
  }
  .budget-btn {
    button {
      text-transform: none;
    }
  }
 .plus-btn {
   cursor: pointer;
   span {
     margin-left: 5px;
   }
 }
  .main-content-container {
    display: flex;
    justify-content: space-between;
    margin-top: 43px;
    //padding-left: 3.625rem;
    padding-left: clamp(1rem, 2vw, 3.625rem);
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
    }
      .schedule-section {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        height: fit-content;
        @media screen and (max-width: 1200px) {
           gap: 40px;
          margin-top: 0;
        }
      > div {
        background-color: #fff;
        border-radius: 8px;
        padding: 8px;
        &.calendar-container {
         padding-bottom: 0;
        }
      }
      .form-info {
        text-align: end;
        .form-info-title {
          font-weight: bold;
          span {
            margin-left: 10px;
            color: ${(props) => props.theme.secondaryTextColor};
            font-weight: bold;
          }
        }
      }
      .autoposting {
        margin-bottom: 20px;
      }
      .form {
        text-align: start;
        margin-top: 10px;
        .form-title {
          font-weight: bold;
          font-size: 25px;
          &.autoposting {
            display: flex;
            flex-direction: row;
            justify-content: flex-start
          }
        }
        .autoposting, .input-wrapper {
          margin-top: 13px;
        }
        .input-wrapper {
          .plus-btn {
            text-align: end;
            margin-top: 17px;
          }
        }
        .autoposting {
          display: flex;
          justify-content: space-between;
        }
        .select-container {
          display: flex;
          flex-direction: column;
          align-items: start;
          .select-wrap {
            height: 28px;
            min-width: 220px;
            @media screen and (max-width: 1200px) {
              min-width: 280px;
            }
          }
        }
        .form-btn-wrapper {
          text-align: end;
          margin-top: 30px;
          button {
            width: 152px;
            text-transform: none;
            font-size: 16px;
          }
        }  
        .posting-type {
          display: flex;
        }
      }
    }
    
    .table-section {
      .rs-checkbox-disabled:not(.rs-checkbox-checked):not(.rs-checkbox-indeterminate) .rs-checkbox-wrapper .rs-checkbox-inner::before {
        background-color: #c1c1c1;
      }
      width: 100%;
      .table-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;
        @media screen and (max-width: 925px) {
          justify-content: flex-start;
        }
        .table-header-inputs {
          display: flex;
          @media screen and (max-width: 1450px) {
            //flex-direction: column;
            align-items: flex-end;
            gap: 10px;
          }
          @media screen and (max-width: 1200px) {
            flex-direction: row;
            align-items: flex-end;
            margin-top: 10px;
          }
          @media screen and (max-width: 925px) {
            flex-direction: column;
            padding-left: 0;
          }
        }
        .filter-select {
          width: 265px;
        }
        .search-wrapper {
          margin-left: 15px;
          width: 241px;
          input {
            ::placeholder {
              font-size: 12px;
            }
          }
        }
      }
      .fullHeightContainer {
        height: auto;
      }
      #table-wrapper {
        margin-top: 10px;
      }
    }
  }
  .rs-calendar-table-cell-content {
    display: flex;
    align-items: center;
  } 
`;
