import '@toast-ui/editor/dist/toastui-editor.css';
import Markdown from '../../../../components/Markdown';

interface DescriptionProps {
  markdownValue: string
  onInputChange: (key: 'markdown', value: string) => void
}
function Description({ markdownValue, onInputChange }: DescriptionProps) {
  const handleMarkdownChange = (value: string) => {
    onInputChange('markdown', value);
  };
  return (
    <div>
      <Markdown onChange={handleMarkdownChange} initialVal={markdownValue} />
    </div>
  );
}

export default Description;
