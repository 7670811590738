import styled from 'styled-components';

function PlaceholderComponent() {
  return (
    <Container>
      <h1>Hic sunt dracones</h1>
      <h5>Page is not ready, please go back</h5>
    </Container>
  );
}
export default PlaceholderComponent;

const Container = styled.div`
padding: 40px;
`;
