import { useEffect } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import styled from 'styled-components';
import { getToken, roleHasAccess } from '../../helpers';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import logoBig from '../../assets/logoBig.png';
import { AdminLoginRequest, getMeRequest } from '../../store/auth/actions';
import { SupperAdminRoles } from '../../store/constants';
import { RequestKeys } from '../../store/keys';
import LoginForm, { LoginFormData } from '../Login/loginForm';

function AdminLogin() {
  const navigate = useNavigateHook();
  const loginReq = AdminLoginRequest();
  const isLoggedIn = getToken();
  const userRequest = getMeRequest(!!isLoggedIn);
  const user = userRequest?.data?.data;

  useEffect(() => {
    if (roleHasAccess(user?.role, SupperAdminRoles)) {
      navigate(Pages.dealersPage);
    }
  }, [user]);

  const handleSubmit = (data: LoginFormData) => {
    loginReq.mutate(data);
  };
  const errors: UseQueryResult<{ message: string; }> = useQuery({ queryKey: [RequestKeys.LOGIN_ERROR] });

  const formErrors = {
    ...(errors?.data?.message?.toLowerCase()?.includes('password') ? { password: errors?.data?.message } : { email: errors?.data?.message }),
  };
  return (
    <StyledAdminLoginPage>
      <img src={logoBig} alt="logo" />
      <div className="title">Admin Dashboard</div>
      <LoginForm handleSubmit={handleSubmit} loading={loginReq.isPending} error={formErrors} isAdminLogin />
    </StyledAdminLoginPage>
  );
}

export default AdminLogin;

const StyledAdminLoginPage = styled.div`
  img {
    max-width: 217px;
  }
  .title {
    color: ${(props) => props.theme.primaryTextColor};
    font-weight: bold;
    margin-top: 5px;
  }
  form > div {
    margin-top: 0;
  }
`;
