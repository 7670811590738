import { createContext, useContext } from 'react';
import { SelectValue } from '../../components/inputs/CustomSelect';
import { LocationFilters } from '../../store/unit/actions';
import { FiltersType } from './ControlPanel/Filters';

export interface InventoryManagerContextType {
    setSearch: (val: string) => void
    search: string
    locationFilter: FiltersType['location']
    setLocationFilter: (val: LocationFilters) => void
    manufacturerFilter: FiltersType['manufacturer']
    setManufacturerFilter: (val: FiltersType['manufacturer']) => void
    categoryFilter: FiltersType['category']
    setCategoryFilter: (val: FiltersType['category']) => void
    statusFilter: FiltersType['status']
    setStatusFilter: (val: SelectValue) => void
    conditionFilter: FiltersType['conditions']
    setConditionFilter: (val: SelectValue) => void

    inventoryType: FiltersType['inventoryType']
    setInventoryType: (val: FiltersType['inventoryType']) => void
    showFilters: boolean
    setShowFilters: (val: boolean) => void

    permanentDelete: boolean
    setPermanentDelete: (val: boolean) => void

    handlePrinting: () => void

    page: number
    setPage: (val: number) => void
}

const InventoryManagerContextInitialValue: InventoryManagerContextType = {
  search: '',
  setSearch: () => undefined,

  locationFilter: null,
  setLocationFilter: () => undefined,
  manufacturerFilter: null,
  setManufacturerFilter: () => undefined,
  categoryFilter: null,
  setCategoryFilter: () => undefined,
  statusFilter: null,
  setStatusFilter: () => undefined,
  conditionFilter: null,
  setConditionFilter: () => undefined,
  inventoryType: { value: 'Active Inventory', label: 'Active Inventory', is_active: true },
  setInventoryType: () => undefined,

  showFilters: false,
  setShowFilters: () => undefined,

  permanentDelete: false,
  setPermanentDelete: () => undefined,

  page: 1,
  setPage: () => undefined,

  handlePrinting: () => undefined,
};

export const InventoryManagerContext = createContext<InventoryManagerContextType>(InventoryManagerContextInitialValue);
export const useInventoryManagerContext = (): InventoryManagerContextType => useContext(InventoryManagerContext);
