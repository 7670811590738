import PlusRoundIcon from '@rsuite/icons/PlusRound';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { useAppMessageContext } from '../../../appMessagesContext';
import CustomButton from '../../../components/Buttons/CustomButton';
import CustomInput from '../../../components/inputs/CustomInput';
import CustomPhoneInput from '../../../components/inputs/CustomPhoneInput';
import CustomSelect, { SelectValue } from '../../../components/inputs/CustomSelect';
import { LocationInputPattern } from '../../../helpers/regexPatterns';
import { LocationReqError, SetLocationRequest } from '../../../store/settings/actions';
import { LocationsI } from '../../../store/settings/requests';
import locationFormSchema from '../../../validation/locationFormSchema';
import {
  CountriesList, CountriesOptions, statesByCounty,
} from '../../SettingsSections/data';
import { ApplicationTheme } from '../../../globalStyles';

export interface LocationFormInterface extends Omit<LocationsI, 'created_at' | 'updated_at' | 'state' | 'country'> {
  state: SelectValue
  country: SelectValue
}

type LocationFormFields = keyof LocationFormInterface

interface LocationFormProps {
  initialValues?: LocationFormInterface
  onSubmit: (data: SetLocationRequest) => void
  errorsProps?: LocationReqError
}
function LocationForm({ initialValues, onSubmit, errorsProps }: LocationFormProps) {
  const { showMessage } = useAppMessageContext();
  const handleSubmit = (data: LocationFormInterface) => {
    const formData: SetLocationRequest = {
      ...data,
      email: data.email?.filter(Boolean),
      state: data.state?.value ? String(data.state?.value) : '',
      country: data.country?.value ? String(data.country?.value) : '',
    };
    onSubmit(formData);
  };

  const formikInitialValues = initialValues || {
    name: '',
    contact_first_name: '',
    contact_last_name: '',
    phone_number: '',
    email: [''],
    street_address: '',
    city: '',
    county: '',
    state: null,
    country: null,
    zip_code: '',
  };
  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: locationFormSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });
  const {
    setFieldValue, values: formikValues, errors, touched,
  } = formik;

  const handleFieldChange = (field: LocationFormFields, value: string | SelectValue) => {
    setFieldValue(field, value);
  };
  const handleEmailChange = (value: string, index: number) => {
    const tempData = [...formikValues.email];
    tempData[index] = value;
    setFieldValue('email', tempData);
  };

  const handleSaveClick = async () => {
    await formik.submitForm();
    if (!formik.isValid) {
      showMessage({ type: 'error', message: 'Please fill required fields' });
    }
  };

  return (
    <div>
      <div className="header">
        <div className="title">Specifics</div>
        <div className="button">
          <CustomButton variant="primary" onClick={handleSaveClick}>Save</CustomButton>
        </div>
      </div>

      <StyledForm className="form">
        <div className="left">
          <div className="location">
            <CustomInput
              label="Location Name"
              required
              wrapperClass="input-wrapper"
              value={formikValues.name}
              onChange={(value) => handleFieldChange('name', value)}
              error={errors.name && touched.name ? errors.name : errorsProps?.name}
            />
          </div>
          <div className="contact-inputs">
            <CustomInput
              label="Location Contact"
              required
              placeholder="First Name"
              wrapperClass="input-wrapper with-label"
              value={formikValues.contact_first_name}
              onChange={(value) => handleFieldChange('contact_first_name', value)}
              error={errors.contact_first_name && touched.contact_first_name ? errors.contact_first_name : errorsProps?.contact_first_name}
            />
            <CustomInput
              label=""
              wrapperClass="input-wrapper"
              placeholder="Last Name"
              value={formikValues.contact_last_name}
              onChange={(value) => handleFieldChange('contact_last_name', value)}
              error={errors.contact_last_name && touched.contact_last_name ? errors.contact_last_name : errorsProps?.contact_last_name}
            />
          </div>
          <div className="phone">
            <CustomPhoneInput
              label="Phone Number"
              required
              specialLabel=""
              alwaysDefaultMask
              placeholder="  (  )    -  "
              value={formikValues.phone_number}
              onChange={(value: string) => handleFieldChange('phone_number', `+${value}`)}
              error={errors.phone_number && touched.phone_number ? errors.phone_number : errorsProps?.phone_number}
            />
          </div>
          {(formikValues.email)?.map((mail, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="email" key={index}>
              <CustomInput
                label="Email"
                required
                wrapperClass="input-wrapper"
                value={formikValues.email[index]}
                onChange={(value) => handleEmailChange(value, index)}
                error={errors.email && touched.email ? errors.email as string : errorsProps?.[`email.${index}`]}
              />
            </div>
          ))}
          <div
            className="add-email"
            onClick={() => {
              const tempData = [...formikValues.email];
              tempData.push('');
              setFieldValue('email', tempData);
            }}
          >
            <PlusRoundIcon color={ApplicationTheme.primaryColor} />
            <span>Add Another Email</span>
          </div>
        </div>
        <div className="right">
          <div className="bg">
            <div className="title">LOCATION ADDRESS</div>
            <div className="address">
              <CustomInput
                label="Street Address"
                required
                wrapperClass="input-wrapper"
                value={formikValues.street_address}
                onChange={(value) => handleFieldChange('street_address', value)}
                error={errors.street_address && touched.street_address ? errors.street_address : errorsProps?.street_address}
              />
            </div>

            <div className="country-row">
              <div className="country">
                <CustomSelect
                  label="Country"
                  required
                  placeholder={' '}
                  controlStyles={{ border: 'none' }}
                  options={CountriesOptions}
                  value={formikValues.country}
                  onChange={(value: SelectValue) => {
                    if (formikValues?.country !== value) {
                      handleFieldChange('state', null);
                      handleFieldChange('zip_code', '');
                    }
                    handleFieldChange('country', value);
                  }}
                  error={errors.country && touched.country ? errors.country : errorsProps?.country}
                />
              </div>
            </div>
            <div className="second-row">
              <div className="city">
                <CustomInput
                  label="City"
                  required
                  pattern={LocationInputPattern}
                  wrapperClass="input-wrapper"
                  value={formikValues.city}
                  onChange={(value) => handleFieldChange('city', value)}
                  error={errors.city && touched.city ? errors.city : errorsProps?.city}
                />
              </div>
              <div className="county">
                <CustomInput
                  label="County"
                  required
                  wrapperClass="input-wrapper"
                  pattern={LocationInputPattern}
                  value={formikValues.county}
                  onChange={(value) => handleFieldChange('county', value)}
                  error={errors.county && touched.county ? errors.county : errorsProps?.county}
                />
              </div>
            </div>
            <div className="third-row">
              <div className="state">
                <CustomSelect
                  label="State"
                  required
                  placeholder={' '}
                  controlStyles={{ border: 'none' }}
                  options={statesByCounty[formikValues?.country?.label as typeof CountriesList[number]]}
                  value={formikValues.state}
                  onChange={(value: SelectValue) => handleFieldChange('state', value)}
                  error={errors.state && touched.state ? errors.state : errorsProps?.state}
                />
              </div>
              <div className="zip">
                <CustomInput
                  label="Zip Code"
                  required
                  wrapperClass="input-wrapper"
                  value={formikValues.zip_code}
                  onChange={(value) => handleFieldChange('zip_code', value)}
                  error={errors.zip_code && touched.zip_code ? errors.zip_code : errorsProps?.zip_code}
                />
              </div>
            </div>
          </div>

        </div>
      </StyledForm>
    </div>
  );
}

export default LocationForm;

const StyledForm = styled.div`
  &.form {
    display: grid;
    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
    grid-template-columns: 1fr  1fr;
    .left {
      @media screen and (max-width: 910px) {
        margin-top: 10px;
      }
      display: block;
      margin-left: 91px;
      @media screen and (max-width: 768px) {
        margin-left: 0;
      }
      input {
        height: 35px;
      }


      label {
        width: 135px;
        font-size: 13px;
      }

      .input-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        text-align: start;

        input {
          width: 313px;
          @media screen and (max-width: 768px) {
            width: 113px;
          }
        }
      }
      .location {
        margin-left: 9px;
        label {
          width: unset;
          white-space: nowrap;
          margin-right: 7px;
        }
        @media screen and (max-width: 768px) {
          input {
            width: 154px;
          }
        }
      }

      .contact-inputs {
        margin-top: 18px;
        display: flex;
        max-width: 414px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          > :nth-child(2) {
            margin-top: 10px;
            margin-left: 110px;
          }
        }
        label {
          width: 0;
        }

        .with-label {
          max-width: 274px;

          label {
            width: 194px;
          }
        }

        input {
          width: 150px;
        }
      }

      .phone {
        margin-top: 20px;
        margin-left: 11px;
        label {
          width: unset;
        }
        @media screen and (max-width: 768px) {
          input {
            width: 154px;
          }
        }
      }

      .email {
        margin-top: 18px;
        margin-left: 66px;
        label {
          width: unset;
          white-space: nowrap;
          margin-right: 7px;
        }
        @media screen and (max-width: 768px) {
          input {
            width: 154px;
          }
        }
        .symbol-input-wrapper {
          input {
            height: 33px;
          }
        }
      }

      .add-email {
        cursor: pointer;
        margin-top: 23px;
        font-size: 13px;
        color: ${(props) => props.theme.primaryColor};
        margin-left: 113px;
        text-align: start;

        svg {
          width: 20px;
          height: 20px;
        }

        span {
          margin-left: 9px;
        }
      }

    }

    .right {
      @media screen and (max-width: 1200px) {
       margin-left: 50px;
      }
      @media screen and (max-width: 768px) {
        margin-left: 0;
      }
      .bg {
        max-width: 500px;
        margin-top: 36px;
        background-color: #fef4e2;
        padding: 15px 23px 28px 16px;
        color: ${(props) => props.theme.primaryTextColor};
        border-radius: 8px;
        input {
          border: none;
        }
        .title {
          font-size: 13px;
          margin-left: 7px;
        }
        label {
          font-size: 13px;
          color: ${(props) => props.theme.primaryTextColor};
        }
        .address {
          text-align: end;
          margin-top: 13px;
          label {
            margin-left: 7px;
          }
        }

        .input-wrapper {
          display: flex;
          flex-direction: row;
          white-space: nowrap;
          margin-left: auto;
          margin-right: 0;
          width: auto;
         .symbol-input-wrapper {
           max-width: 354px;
         }
          label {
            margin-right: 10px;
          }
          input {
            width: 100%;
          }
        }
          .country-row {
            margin-top: 15px;
            label {
              font-weight: normal;
              width: 125px;
              text-align: end;
            }
            .select-wrap {
              width: 100%;
              @media screen and (max-width: 768px) {
                width: 85px;
              }
            }
          }
        .second-row {
          display: flex;
          justify-content: flex-end;
          margin-top: 15px;
          .city {
            margin-right: 14px;
          }
          input {
            width: 100%;
            max-width: 139px;
          }
        }

        .third-row {
          display: flex;
          justify-content: flex-end;
          margin-top: 15px;
          input {
            width: 100%;
            max-width: 139px;
          }
          .state {
            margin-right: 16px;

            label {
              font-weight: normal;
            }
            .select-wrap {
              width: 166px;
              @media screen and (max-width: 768px) {
                width: 85px;
              }
            }
          }

          .zip {
            input {
              max-width: 102px;
              height: 36px;
            }
          }
        }
      }
    }
  }

`;
