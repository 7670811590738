import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';
import Drawer from 'rsuite/Drawer';
import ArchiveSvg from '../../../components/SvgElements/ArchiveSvg';
import BinSvg from '../../../components/SvgElements/BinSvg';
import PrintSvg from '../../../components/SvgElements/PrintSvg';
import { ApplicationTheme } from '../../../globalStyles';
import { CategoryFilters, LocationFilters } from '../../../store/unit/actions';
import InventoryManagerDeleteModal, { InventoryManagerDeleteModalProps } from '../InventoryManagerDeleteModal';
import Filters, { FiltersType, inventoryTypeFilterT } from './Filters';
import { useInventoryManagerContext } from '../inventoryManagerContext';
import CustomButton from '../../../components/Buttons/CustomButton';
import CustomSelect, { SelectOption } from '../../../components/inputs/CustomSelect';
import SearchInput from '../../../components/inputs/SearchInput';
import addCircle from '../../../assets/addCircle.png';
import filterIco from '../../../assets/filterIco.png';
import filterDisabled from '../../../assets/filterDisabled.png';
import Pages from '../../../Router/pages';
import useResize from '../../../hooks';

interface ControlPanelProps {
  locationOptions: LocationFilters[]
  manufacturerOptions: SelectOption[]
  categoryOptions: CategoryFilters[]
  statusOptions: SelectOption[]
  conditionOptions: SelectOption[]
  selectedItems: number[]
  inventoryTypeOptions: inventoryTypeFilterT[]
  onModalAccept: (mode: InventoryManagerDeleteModalProps['mode']) => void
}

function ControlPanel({
  locationOptions, manufacturerOptions, categoryOptions, statusOptions, selectedItems, inventoryTypeOptions, onModalAccept, conditionOptions,
}: ControlPanelProps) {
  const { isMobile } = useResize();
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    search, setSearch, setInventoryType, showFilters, setShowFilters, inventoryType, handlePrinting, setPage,
  } = useInventoryManagerContext();
  const [modalMode, setModalMode] = useState<InventoryManagerDeleteModalProps['mode']>(inventoryType?.value === 'Active Inventory' ? 'Archive' : 'Delete');

  const handleInventoryTypeChange = (val: FiltersType['inventoryType']) => {
    setInventoryType(val);
    setPage(1);
  };

  const handleAcceptModal = () => {
    setShowModal(false);
    onModalAccept(modalMode);
    setModalMode(inventoryType?.value === 'Active Inventory' ? 'Archive' : 'Delete');
  };

  const handleFiltersChange = () => {
    setPage(1);
  };

  return (
    <StyledControlPanelContainer className="controll-header">
      <ControlContainer>
        <ControlItemWrapper className="active-inventory">
          <CustomSelect
            value={inventoryType}
            options={inventoryTypeOptions}
            name="active_inventory"
            controlStyles={{
              width: '190px',
              height: '38px',
              borderColor: ApplicationTheme.bordersColor,
              borderRadius: '30px',
            }}
            onChange={(val) => handleInventoryTypeChange(val as FiltersType['inventoryType'])}
            singleValueStyles={{
              color: ApplicationTheme.primaryColor,
            }}
          />
        </ControlItemWrapper>
        <ControlItemWrapper className="search">
          <SearchInput
            value={search}
            onChange={(val) => {
              setSearch?.(val);
              handleFiltersChange();
            }}
            placeholder="Search Inventory"
          />
        </ControlItemWrapper>
        <ControlItemWrapper className="add">
          <Link to={Pages.addUnitPure}>
            <CustomButton
              variant="primary"
            >
              <img src={addCircle} alt="add" />
              <span className="button-text">
                add unit
              </span>
            </CustomButton>
          </Link>
        </ControlItemWrapper>
        <div className="filter-export-wrapper">
          <ControlItemWrapper className="filter">
            <CustomButton variant="outlined-box" onClick={() => setShowFilters(!showFilters)}>
              <div className="hide-show-text">
                <img src={showFilters ? filterDisabled : filterIco} alt="filter" />
                <span className="button-text">
                  {showFilters ? 'Hide Filters' : 'Show Filters'}
                </span>
              </div>
            </CustomButton>
          </ControlItemWrapper>
          <ControlItemWrapper>
            <div className="action-buttons">
              <CustomButton variant="outlined-box" onClick={handlePrinting} className="print-btn">
                <PrintSvg fill={ApplicationTheme.bordersColor} />
              </CustomButton>
            </div>
          </ControlItemWrapper>
        </div>
        {!!selectedItems?.length
            && (
            <IconWrapper
              className="action-ico"
              onClick={() => {
                setShowModal(true);
                setModalMode(inventoryType?.value === 'Active Inventory' ? 'Archive' : 'Delete');
              }}
            >
              <BinSvg />
            </IconWrapper>
            )}
        {(!!selectedItems?.length && inventoryType.value === 'Archived Inventory')
            && (
            <IconWrapper
              className="action-ico unarchive"
              onClick={() => {
                setModalMode('Unarchive');
                setShowModal(true);
              }}
            >
              <ArchiveSvg />
            </IconWrapper>
            )}
      </ControlContainer>
      {showFilters
        && (!isMobile ? (
          <Filters
            locationOptions={locationOptions}
            manufacturerOptions={manufacturerOptions}
            statusOptions={statusOptions}
            conditionOptions={conditionOptions}
            categoryOptions={categoryOptions}
          />
        )
          : (
            <div>
              <Drawer
                backdrop
                open={showFilters}
                onClose={() => setShowFilters(false)}
                size="xs"
              >
                <Drawer.Body>
                  <DrawerContainer>
                    <ControlItemWrapper className="active-inventory drawer">
                      <CustomSelect
                        defaultValue={inventoryTypeOptions[0]}
                        options={inventoryTypeOptions}
                        name="active_inventory"
                        controlStyles={{
                          height: '38px',
                          borderColor: ApplicationTheme.primaryColor,
                        }}
                        onChange={(val) => handleInventoryTypeChange(val as FiltersType['inventoryType'])}
                        singleValueStyles={{
                          color: ApplicationTheme.primaryColor,
                        }}
                      />
                      <Filters
                        locationOptions={locationOptions}
                        manufacturerOptions={manufacturerOptions}
                        statusOptions={statusOptions}
                        conditionOptions={conditionOptions}
                        categoryOptions={categoryOptions}
                        className="drawer-filters"
                      />
                      <StyledButtonWrapper>
                        <CustomButton
                          variant="primary"
                          onClick={() => setShowFilters(false)}
                        >
                          <span>
                            search
                          </span>
                        </CustomButton>
                      </StyledButtonWrapper>
                    </ControlItemWrapper>
                  </DrawerContainer>
                </Drawer.Body>
              </Drawer>
            </div>
          ))}
      <InventoryManagerDeleteModal
        showModel={showModal}
        setShowModal={() => {
          setShowModal(false);
          setModalMode(inventoryType?.value === 'Active Inventory' ? 'Archive' : 'Delete');
        }}
        handleAcceptModal={handleAcceptModal}
        mode={modalMode}
        bulk
      />
    </StyledControlPanelContainer>
  );
}
export default ControlPanel;

const ControlContainer = styled.div`
  display: flex;
  overflow: inherit;
  max-height: 53px;
  justify-content: space-around;
  @media screen and (max-width: 1600px) {
    max-height: 73px;
  }
  flex-wrap: nowrap;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    max-height: 100px;
    border-bottom: 1px solid gray;
    box-shadow: 0 5px 5px gray;
  }
  gap: 10px;
  padding: 6px 2px;
  > div {
    display: flex;
    flex: 22%;
    gap: 5px;
  }
  .search {
    flex: 2 4 auto;
    min-width: 100px;
    @media screen and (min-width: 1300px) {
      margin-left: 8%;
    }
  }
  .add {
    margin-left: 9%;
    @media screen and (max-width: 1200px) {
      margin-left: 2%;
    }
    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
    a {
      width: 100%;
    }
    img {
      margin-left: -13px;
      margin-top: -4px;
      height: 25px;
    }
    button {
      width: 192px;
      height: 39px;
      font-size: 14px;
      @media screen and (max-width: 900px) {
        font-size: 12px;
        img {
          height: 10px;
        }
      }
    }
  }
  .export {
    button {
      max-width: 70px;
    }
  }
  .filter {
    .intercom-namespace {
      float: right!important;
      div {
        left: -44px;
        top: 10px;
        @media screen and (max-width: 1200px) {
          left: -70px;
        }
        @media screen and (max-width: 1000px) {
          left: -19px;
        }
      }
    }
    .outlined-box {
      border-radius: 30px;
      :hover {
        border: 1px solid black;
      }
    }
    @media screen and (max-width: 768px) {
      margin-left: 30px;
    }
    @media screen and (max-width: 1000px) {
      margin-left: 10px;
      width: 129px;
    }
    @media screen and (max-width: 1200px) {
      margin-left: 2%;
    }
    margin-left: 70px;
    width: 192px;
    button {
      height: 40px;
    }
  }
  @media screen and (min-width: 1000px) {
    flex-wrap: nowrap;
    > div {
      border: none;
      flex: inherit;
    }
  }
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    > div {
      flex: unset;
    }    
    padding: 10px 20px;
  }
  .filter-export-wrapper {
    display: flex;
    margin-left: 7%;
    @media screen and (max-width: 1200px) {
      margin-left: 2%;
    }
  }
    @media screen and (max-width: 1600px) {
    .filter-export-wrapper {
      display: flex;
      gap: 10px;
    }
      @media screen and (max-width: 768px) {
        .filter-export-wrapper {
          display: flex;
          flex-direction: row;
          gap: 2px;
          order: 2;
          .export {
            margin-left: 10px;
            }
          }
        }
      }
`;

const StyledControlPanelContainer = styled.div`
    margin-top: 10px;
    padding: 10px 30px;
    padding-bottom: 0;
  @media screen and (max-width: 768px) {
    padding: 10px 0;
  }
`;

const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ControlItemWrapper = styled.div`
  height: 39px;
  .hide-show-text {
    text-align: start;
    @media screen and (min-width: 768px) {
      margin-left: 32px;
      font-size: 13px;
    }
    @media screen and (max-width: 1200px) {
      margin-left: 0;
      font-size: 13px;
    }
    @media screen and (min-width: 1000px) {
      font-size: 14px;
    }
    font-size: 14px;
    color: ${(props) => props.theme.secondaryTextColor};
    margin-top: -2px;
  }
  &.search {
    width: auto;
    height: 39px;
  }
  &.add {
    width: 192px;
    height: 39px;
  }
  .button-text {
    margin-left: 5px;
  }
  &.active-inventory.drawer {
      width: 100%;
      min-width: 147px;
      max-width: 197px;
    }
  &.active-inventory {
    width: 192px;
  }
  &.filter {
    img {
      width: 19px;
      height: 19px;
    }
  }
  @media screen and (max-width: 768px) {
    &.active-inventory:not(.drawer) {
      display: none;
    }
    &.add {
      order: 1;
      width: 133px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        padding: 0;
        width: 133px;
        img {
          margin-left: 0;
          width: 18px;
          height: 18px;
        }
      }
    }
    &.search {
      width: 100%;
      height: 31px;
      @media screen and (min-width: 600px) {
        .search__container {
          width: 70%;
          margin: 0 auto;
        }
      }
      input {
        height: 31px;
      }
      order: 0;
    }
    &.filter {
      order: 4;
      width: 133px;
      height: 31px;
      button {
        padding: 0;
        width: 133px;
        height: 31px;
        padding-left: 10px;
        margin-top: 5px;
      }
    }
    &.export {
      order: 5;
      button {
        padding: 2px;
        font-size: 12px;
      }
    }
  }
  .action-buttons {
    .outlined-box {
      border-radius: 30px;
      height: 40px;
      :hover {
        border: 1px solid black;
      }
    }
    .print-btn {
      margin-right: 5px;
      padding-left: 15px;
      padding-right: 15px;
      svg {
        width: 35px;
        margin-left: 4px;
        height: 30px; 
      }
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
      display: flex;
  }
`;

const StyledButtonWrapper = styled.div`
    display: flex;
    margin-top: 33px;
    justify-content: center;
`;

const IconWrapper = styled.div`
  &.action-ico {
    border-radius: 50%;
    width: 39px;
    height: 39px;
    display: flex;
    flex: initial;
    margin-left: 12px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.primaryColor};
    img {
      height: 28px;
      width: 28px;
    }
    svg {
      height: 25px;
      width: 25px;
    }
    transition: all ease 0.2s;
    :hover {
      border: 2px solid ${(props) => props.theme.primaryColor};
    }
    &.unarchive {
      transform: rotate(180deg);
    }
    @media screen and (max-width: 768px) {
      order: 3;
      width: 31px;
      height: 31px;
    }
  }
`;
