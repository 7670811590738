import SettingsLayout from '../../../components/SettingsLayout';
import { useGetSelectedDealer } from '../../../hooks/getDealersHook';
import Pages from '../../../Router/pages';
import { getMeRequest } from '../../../store/auth/actions';
import { getUsersRequest } from '../../../store/settings/actions';
import UsersSection from '../../SettingsSections/UsersSection';

function SettingsUsers() {
  const user = getMeRequest()?.data?.data;

  const selectedDealer = useGetSelectedDealer();

  const { data: tableData, refetch: refetchUsers, isFetching } = getUsersRequest({
    ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }),
  });
  const usersData = tableData?.data || [];

  return (
    <SettingsLayout navigateOnBack={Pages.inventoryManager} headerTitle="settings">
      <UsersSection
        usersData={usersData}
        user={user}
        refetchTable={refetchUsers}
        loading={isFetching}
      />
    </SettingsLayout>

  );
}
export default SettingsUsers;
