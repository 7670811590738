import { useFormik } from 'formik';
import styled from 'styled-components';
import ArrowRightIcon from '@rsuite/icons/ArrowRight';
import React, {
  RefObject,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/esm/Whisper/Whisper';
import { queryClient } from '../../App';
import { useAppMessageContext } from '../../appMessagesContext';
import { RenderImageT, UploadedFile } from '../../components/FileUploader';
import { SelectOption, SelectValue } from '../../components/inputs/CustomSelect';
import { ApplicationTheme } from '../../globalStyles';
import {
  convertInFtToTotalIn, convertInToInFt, formSelectOptions,
} from '../../helpers';
import useResize from '../../hooks';
import { useGetSelectedDealer } from '../../hooks/getDealersHook';
import { getMeRequest } from '../../store/auth/actions';
import { RequestKeys } from '../../store/keys';
import { getCustomStatusOverlaysRequest, getLocationsRequest, GetOverlayRequest } from '../../store/settings/actions';
import { LocationsI } from '../../store/settings/requests';
import { TempUnitFormData } from '../../store/types';
import {
  CreateUnitReqError,
  CreateUnitRequestData,
  DatalinkUnit,
  getItemAttributesRequest,
  getItemCategoriesRequest,
  getItemFeaturesRequest,
  getItemTypesRequest,
  ItemCategory,
  ItemFeaturesResponse,
  ItemSpecificAttribute,
  StockValidationErrors,
  UnitType,
  UnitUserOverlaySetting,
} from '../../store/unit/actions';
import MainLayoutHeader from '../InventoryManager/Header';
import CustomButton from '../../components/Buttons/CustomButton';
import BackSvg from '../../assets/back-arrow.png';
import {
  isRentalOptions, isTiltOptions, livingQuartersOptions, mangerOptions, mockedAttributes,
} from './Sections/Attributes/data';
import Description from './Sections/Description';
import HiddenFiles, { HiddenFilesFormInputs } from './Sections/HiddenFiles';
import PDFs, { PDFFormInputs } from './Sections/PDFs';
import Specifics from './Sections/Specifics';
import Details, { DetailsFormInputKey, DetailsFormInputs } from './Sections/Details';
import Attributes, { AttributesInputs } from './Sections/Attributes';
import Features, { FormedFeaturesDataT } from './Sections/Features';
import { inputsByInventoryType } from './Sections/Specifics/data';
import WebsiteConfiguration, {
  WebsiteFormInputs,
  WebsiteInputKeys, WebsiteRefHandle,
  websiteSelectOptions,
} from './Sections/WebsiteConfiguration';
import Images, { ImagesFormInputs } from './Sections/Images';
import { SpecificFormInputs, SpecificInputsKeys } from './types';
import { formUnitRequestData } from '../../helpers/formHelpers';

export enum SectionList {
    'Specifics' = 'Specifics',
    'Details' = 'Details',
    'Description' = 'Description',
    'Attributes' = 'Attributes',
    'Features' = 'Features',
    'Website Configuration' = 'Website Configuration',
    'Images' = 'Images',
    'PDFs' = 'PDFs',
    'Hidden Files' = 'Hidden Files',
}

interface ListItemProps {
  title: keyof typeof SectionList
  children?: string | JSX.Element | JSX.Element[]
  openSectionsList?: Array<keyof typeof SectionList>
}
function ListItem({
  title, children, openSectionsList,
}: ListItemProps) {
  const [collapsed, setCollapsed] = useState<boolean>(!openSectionsList?.includes(title));
  return (
    <StyledListItem>
      <div
        className="control"
        onClick={() => setCollapsed(!collapsed)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setCollapsed(!collapsed);
          }
        }}
        role="presentation"
      >
        <div className={`title ${!collapsed && 'full'}`}>{title}</div>
        <button type="button" className={`${!collapsed && 'full'}`}>
          <ArrowRightIcon color={ApplicationTheme.primaryColor} />
        </button>
      </div>
      <div className="content">
        {!collapsed && children}
      </div>
    </StyledListItem>
  );
}

export interface UnitFormInterface {
    specifics: SpecificFormInputs
    details: DetailsFormInputs
    description: {
      markdown: string
    }
    attributes: AttributesInputs
    features: FormedFeaturesDataT
    website: WebsiteFormInputs
    images: ImagesFormInputs
    pdfs: PDFFormInputs
    hiddenFiles: HiddenFilesFormInputs
}
export interface UnitPageProps {
  headerTitle: 'Edit Unit' | 'Add Unit'
  valuesFromProps?: UnitFormInterface
  onFormSubmit: (data: CreateUnitRequestData, newUnitID?: string) => void
  requestErrors?: CreateUnitReqError
  stockValidationErrors?: StockValidationErrors['errors']
  savedFeaturesList?: UnitType['features']
  isEditPage?: boolean | undefined
  openSections?: Array<keyof typeof SectionList>
  loading: boolean
  overlaySettings?: UnitUserOverlaySetting
  dealer?: UnitType['dealer']
  disableSave?: boolean
  handleStockValidation: (stock: string) => void
  handleStockValidationReset: () => void
}
function UnitPage({
  headerTitle, valuesFromProps, onFormSubmit, requestErrors, savedFeaturesList, isEditPage, openSections, loading, overlaySettings, handleStockValidation, handleStockValidationReset,
  dealer, stockValidationErrors, disableSave,
}: UnitPageProps) {
  const { isMobile } = useResize();
  const { showMessage } = useAppMessageContext();
  const dealerId = useGetSelectedDealer()?.id;
  const [preventChangingTab, setPreventChangingTab] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<keyof typeof SectionList | null>(null);

  const userRequest = getMeRequest()?.data?.data;
  const selectedDealer = useGetSelectedDealer();
  const { responseData: itemsType, isLoading: itemTypesLoading } = getItemTypesRequest();
  const { responseData: itemCategories, isLoading: itemCategoriesLoading } = getItemCategoriesRequest();
  const locationData: LocationsI[] = getLocationsRequest({ dealer_id: userRequest?.dealer_id || dealerId })?.data?.data || [];
  const { responseData: defaultOverlaySetting } = GetOverlayRequest(userRequest?.ovelay_id || selectedDealer?.overlay_id);
  const { responseData: itemAttributes } = getItemAttributesRequest();
  const { responseData: itemList, isLoading: featureListIsLoading } = getItemFeaturesRequest();

  const [loggedDealerId, setloggedDealerId] = useState<number>(0);

  useEffect(() => {
    if (selectedDealer?.id) {
      setloggedDealerId(selectedDealer.id);
    }

    if (userRequest?.dealer_id) {
      setloggedDealerId(userRequest.dealer_id);
    }
  }, [selectedDealer?.id, userRequest?.dealer_id]);

  const { responseData: customStatusOverlays, refetch: refetchCustomStatusOverlays } = getCustomStatusOverlaysRequest({ dealer_id: loggedDealerId, is_default: 0 });
  const customStatusOverlayOptions: SelectOption[] = [
    ...customStatusOverlays?.map((item: any) => ({ label: item?.status_overlay, value: item?.status_overlay })) || [],
  ];

  const itemFeatures: ItemFeaturesResponse[] = useMemo(() => itemList.map(((feature) => ({
    ...feature,
    feature_options: [
      ...feature.feature_options,
      ...(savedFeaturesList?.[feature.name] || []) // form features list with customs
        .filter((el) => el.is_custom)
        .map((el) => ({
          ...el,
          item_feature_id: feature.id,
          is_custom: el.is_custom,
          is_new: false,
        })),
    ],
  }))), [itemList, savedFeaturesList]);

  const { specificAttributes: specificAttributesA } = mockedAttributes[6] as { specificAttributes: ItemSpecificAttribute[] };
  const { specificAttributes: specificAttributesB } = mockedAttributes[9] as { specificAttributes: ItemSpecificAttribute[] };

  const specificAttributes = [...specificAttributesA, ...specificAttributesB];

  const findAttributes = (category: keyof ItemSpecificAttribute): ItemSpecificAttribute | undefined => specificAttributes?.find((attr: ItemSpecificAttribute) => category in attr) as ItemSpecificAttribute;
  // ATV
  const atvAttributes = findAttributes('ATV');

  // Side by Side (Utility)
  const utvAttributes = findAttributes('Side by Side (Utility)');

  // Snowmobile Vehicle
  const snowmobileAttributes = findAttributes('Snowmobile Vehicle');

  // Motorcycle
  const motorcycleAttributes = findAttributes('Motorcycle');

  const getSpecificAttributes = (inventoryTypeName: string): ItemSpecificAttribute[] => {
    switch (true) {
      case inventoryTypeName === 'ATV':
        return atvAttributes ? [atvAttributes] : [];
      case inventoryTypeName === 'Side by Side (Utility)':
        return utvAttributes ? [utvAttributes] : [];
      case inventoryTypeName === 'Snowmobile Vehicle':
        return snowmobileAttributes ? [snowmobileAttributes] : [];
      case inventoryTypeName.includes('Motorcycle'):
        return motorcycleAttributes ? [motorcycleAttributes] : [];
      default:
        return [];
    }
  };

  const specificRef = useRef(null);
  const detailsRef = useRef(null);
  const descriptionRef = useRef(null);
  const attributesRef = useRef(null);
  const featuresRef = useRef(null);
  const websiteRef = useRef(null);
  const imagesRef = useRef(null);
  const pdfsRef = useRef(null);
  const hiddenFilesRef = useRef(null);
  const websiteSectionRef = useRef<WebsiteRefHandle>(null);

  const visibleSections: {[key in keyof typeof SectionList]: boolean} = {
    [SectionList.Specifics]: useIsInViewport(specificRef),
    [SectionList.Details]: useIsInViewport(detailsRef),
    [SectionList.Description]: useIsInViewport(descriptionRef),
    [SectionList.Attributes]: useIsInViewport(attributesRef),
    [SectionList.Features]: useIsInViewport(featuresRef),
    [SectionList['Website Configuration']]: useIsInViewport(websiteRef),
    [SectionList.Images]: useIsInViewport(imagesRef),
    [SectionList.PDFs]: useIsInViewport(pdfsRef),
    [SectionList['Hidden Files']]: useIsInViewport(hiddenFilesRef),
  };
  // first visible section will be active in sidebar
  const firstVisibleSection: keyof typeof SectionList | undefined = Object.keys(visibleSections).find((key: keyof typeof SectionList) => visibleSections[key]) as keyof typeof SectionList;
  useEffect(() => {
    if (firstVisibleSection && firstVisibleSection !== activeSection && !preventChangingTab) {
      setActiveSection(firstVisibleSection);
    }
  }, [firstVisibleSection]);
  const scrollToSection = async (ref: RefObject<HTMLDivElement> | null, sectionTitle: keyof typeof SectionList) => {
    await setActiveSection(sectionTitle);
    await setPreventChangingTab(true);
    if (ref?.current) {
      const yOffset = -80;
      const y = (ref?.current as HTMLDivElement).getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
    setTimeout(() => {
      setPreventChangingTab(false);
    }, 1000);
  };

  const params = useParams<{id?: string}>();

  const unitID = useMemo(() => params?.id || new Date().getTime().toString(), []);
  const handleSubmit = (data: UnitFormInterface) => {
    websiteSectionRef?.current?.removeEmptyEmbedCodes();
    const formedRequestData: CreateUnitRequestData = formUnitRequestData(data, itemAttributes, specificAttributes, userRequest?.dealer_id ?? dealerId ?? 0, !!isEditPage);
    queryClient.setQueryData<TempUnitFormData>(
      [RequestKeys.TEMPORARY_UNIT_FORM_DATA, unitID],
      () => ({ data, unitID: params?.id }),
    );
    onFormSubmit(formedRequestData, unitID);
  };

  const dataFromFailedRequest = queryClient.getQueryData<TempUnitFormData>([RequestKeys.TEMPORARY_UNIT_FORM_DATA, unitID]);
  const initialValues: UnitFormInterface = dataFromFailedRequest?.data || valuesFromProps || {
    specifics: {
      inventoryType: { name: 'Trailer or Truck Bed', id: 8 },
      title: '',
      stock: '',
      condition: null,
      autopopulate: null,
      year: null,
      manufacturer: null,
      status: null,
      dealership_location: null,
      vin: '',
      model_name: '',
      item_category: null,

      chassis_year: null,
      brand: '',
    },
    details: {
      notes: '',

      price: '',
      websitePrice: '',
      rebatePrice: '',
      sales_price: '',
      msrp: '',
      hiddenPrice: '',
      pymt: '',

      florHeightTotal: '',
      florHeightIn: '',
      florHeightFt: '',

      florLengthTotal: '',
      florLengthIn: '',
      florLengthFt: '',

      florWidthTotal: '',
      florWidthIn: '',
      florWidthFt: '',

      weight: '',
      payloadCapacity: '',
      gvwr: '',
      axleCapacity: '',
    },
    description: {
      markdown: '',
    },
    attributes: {
      overallLengthTotal: '',
      overallLengthIn: '',
      overallLengthFt: '',

      minWidthTotal: '',
      minWidthIn: '',
      minWidthFt: '',

      minLengthTotal: '',
      minLengthIn: '',
      minLengthFt: '',

      sideWallHeightTotal: '',
      sideWallHeightIn: '',
      sideWallHeightFt: '',

      minHeightTotal: '',
      minHeightIn: '',
      minHeightFt: '',

      is_rental: isRentalOptions[1],
      manger: mangerOptions[1],
      is_tilt: isTiltOptions[1],
      living_quarters: livingQuartersOptions[1],

      engineType: '',
    },
    features: {
      features: [],
      custom_features: [],
      features_to_delete: [],
    },
    website: {
      is_active: true,
      racing_junk: false,
      is_featured: websiteSelectOptions[1],
      is_on_special: websiteSelectOptions[1],
      custom_status: null,
      video_embed_code: [],
    },
    images: {
      overlaySetting: null,
      files: [],
      filesFromResponse: [],
    },
    pdfs: {
      files: [],
      filesFromResponse: [],
    },
    hiddenFiles: {
      files: [],
      filesFromResponse: [],
    },
  };
type FormikErrorTypes = {
  specifics: { [key in SpecificInputsKeys]?: string }
  details: { [key in keyof DetailsFormInputs]?: string }
}
const formik = useFormik({
  initialValues,
  validate: (values: UnitFormInterface) => {
    const errors: FormikErrorTypes = {
      specifics: {},
      details: {},
    };
    if (values.specifics?.inventoryType) {
      inputsByInventoryType[values.specifics?.inventoryType.name].map((input) => {
        if (input.required) {
          if (typeof values.specifics[input.key] === 'string') {
            if (!values.specifics[input.key]) {
              errors.specifics[input.key] = 'This is a required field';
            }
          }
          if (typeof values.specifics[input.key] === 'object') {
            if (input.key === 'item_category' && !(values.specifics?.[input.key] as ItemCategory)?.id) {
              errors.specifics[input.key] = 'This is a required field';
            }
            if (!(values.specifics?.[input.key] as SelectValue)?.value && input.key !== 'item_category' && input.key !== 'dealership_location') {
              errors.specifics[input.key] = 'This is a required field';
            }
            if (!(values.specifics?.[input.key] as LocationsI)?.id && input.key === 'dealership_location') {
              errors.specifics[input.key] = 'This is a required field';
            }
            if (input.key === 'condition' && !(values.specifics?.[input.key] as SelectValue)?.value) {
              errors.specifics[input.key] = 'This is a required field';
            }
          }
        }
        return input;
      });
    } else {
      errors.specifics.inventoryType = 'This is a required field';
    }
    if (Object.keys(errors.specifics).length || Object.keys(errors.details).length) {
      return errors;
    }
    return {};
  },
  onSubmit: handleSubmit,
});

const {
  setFieldValue, values: formikValues, errors, touched,
} = formik;
type SectionKeys = keyof typeof initialValues
type InputKeys = SpecificInputsKeys | DetailsFormInputKey | 'markdown' | string
type ValueTypes = string | SelectValue | ItemCategory | WebsiteFormInputs['video_embed_code'] | UploadedFile[] | RenderImageT[]
const setFormikInputs = (section: SectionKeys, input: InputKeys, value: ValueTypes) => {
  setFieldValue(`${section}.${input}`, value);
};

const selectedCategory = String(formikValues.specifics.item_category?.name).includes('Motorcycle') ? 'Motorcycle' : formikValues.specifics.item_category?.name;

const handleSpecificsChange = (key: SpecificInputsKeys, value: string | SelectValue | ItemCategory) => {
  setFormikInputs('specifics', key, value);
};

const handleDetailsChange = (key: DetailsFormInputKey, value: string | SelectValue) => {
  setFormikInputs('details', key, value);
};
const handleDescriptionChange = (key: 'markdown', value: string) => {
  setFormikInputs('description', key, value);
};
const handleAttributesChange = (input: string, value: string | SelectValue) => {
  setFormikInputs('attributes', input, value);
};

const handleWebsiteChange = (input: WebsiteInputKeys, value: WebsiteFormInputs['video_embed_code'] | SelectValue) => {
  setFormikInputs('website', input, value);
};

const handleImagesChange = (files: RenderImageT[]) => {
  files.forEach((file) => {
    if (file.isFile) {
      setFormikInputs('images', 'files', files);
    } else {
      setFormikInputs('images', 'filesFromResponse', files);
    }
  });
};
const handleImagesClear = () => {
  setFormikInputs('images', 'files', []);
  setFormikInputs('images', 'filesFromResponse', []);
};
const handleImagesSettingChange = (value: SelectValue) => {
  setFormikInputs('images', 'overlaySetting', value);
};

const handlePDFsChange = (files: UploadedFile[]) => {
  setFormikInputs('pdfs', 'files', files);
};
const handleHiddenFilesChange = (files: UploadedFile[]) => {
  setFormikInputs('hiddenFiles', 'files', files);
};
const convertToRenderImageT = (images: string[]): RenderImageT[] => images.map((imageUrl, index) => ({
  id: index,
  name: imageUrl,
  url: imageUrl,
  isFile: false,
}));
const handleAutopopulate = (item: DatalinkUnit) => {
  if (!isEditPage) {
    const currentType = itemCategories.find((category) => category.name === formikValues?.specifics?.inventoryType?.name);
    if (currentType) {
      handleSpecificsChange('item_category', currentType.item_categories.find((itemCategory) => itemCategory.name === item.unitCategory) || null);
    }
    handleSpecificsChange('model_name', item.model);
    handleSpecificsChange('year', formSelectOptions([item.year])[0]);
  }

  handleDetailsChange('gvwr', item.gvwr);
  handleAttributesChange('ramps', item.ramp);
  handleAttributesChange('axles', item.axles);
  handleAttributesChange('color', item.colors.charAt(0).toUpperCase() + item.colors.slice(1));
  handleAttributesChange('pull_type', item.hitch);
  handleDescriptionChange('markdown', item.notes);

  const pattern = /(?:(\d+)')?(?:[-| ]*)(?:(\d+)(?: ?(\d+\/\d+))?")?/;
  let totalLen: null | number;
  const initialLength = item.lengthTotal;

  if (!Number.isNaN(Number(initialLength))) {
    totalLen = Number(initialLength);
  } else {
    const replacedValue = initialLength.replace('\'\'', '"').split(' ')[0];
    const matches = replacedValue.match(pattern);
    if (matches) {
      const feet = matches[1] ? Number(matches[1]) : 0;
      const inches = matches[2] ? Number(matches[2]) : 0;
      totalLen = convertInFtToTotalIn(inches, feet);
    } else {
      totalLen = null;
    }
  }

  if (totalLen) {
    const convertedLengthIn = convertInToInFt(totalLen);
    handleDetailsChange('florLengthTotal', String(totalLen));
    if (convertedLengthIn) {
      handleDetailsChange('florLengthIn', `${convertedLengthIn.inches}`);
      handleDetailsChange('florLengthFt', `${convertedLengthIn.feet}`);
    }
  }

  let totalWidth: null | number;
  const initialVal = item.widthTotal;

  if (!Number.isNaN(Number(initialVal))) {
    totalWidth = Number(initialVal);
  } else {
    const replacedValue = initialVal.replace('\'\'', '"').split(' ')[0];
    const matches = replacedValue.match(pattern);
    if (matches) {
      const feet = matches[1] ? Number(matches[1]) : 0;
      const inches = matches[2] ? Number(matches[2]) : 0;
      totalWidth = convertInFtToTotalIn(inches, feet);
    } else {
      totalWidth = null;
    }
  }

  if (totalWidth) {
    const convertedWidthIn = convertInToInFt(totalWidth);
    handleDetailsChange('florWidthTotal', String(totalWidth));
    if (convertedWidthIn) {
      handleDetailsChange('florWidthIn', `${convertedWidthIn.inches}`);
      handleDetailsChange('florWidthFt', `${convertedWidthIn.feet}`);
    }
  }

  if (item.images) {
    const renderImages = convertToRenderImageT(item.images);
    handleImagesChange(renderImages);
  }
};

useEffect(() => {
  if (defaultOverlaySetting?.default_setting && !formikValues.images.overlaySetting) { // set default option from overlay settings only for "ADD UNIT" page
    handleImagesSettingChange(formSelectOptions([defaultOverlaySetting.default_setting])[0]);
  }
}, [defaultOverlaySetting]);
const scrollToError = async () => {
  const requiredElement = await document.querySelector('.error');
  const y = await requiredElement?.getBoundingClientRect().top;
  if (y) {
    window.scrollTo({ top: y + window.pageYOffset - 120, behavior: 'smooth' });
  }
};

useEffect(() => {
  if (formikValues?.specifics?.stock) {
    handleStockValidationReset();
  }
}, [formikValues?.specifics?.stock]);
const handleSaveClick = async () => {
  await formik.submitForm();
  if (formik.errors || requestErrors) {
    scrollToError();
  }
  if (!formik.isValid) {
    showMessage({ type: 'error', message: 'Please fill required fields' });
  }
};

const formOverlayText = (textToDisplay: string | undefined): string => {
  const selectedLocation = locationData.find((location) => location.id === formikValues.specifics.dealership_location?.id);
  switch (textToDisplay) {
    case "Unit's Location Name":
      return formikValues.specifics.dealership_location?.name || '';
    case 'Unit\'s Location Phone Number':
      return selectedLocation?.phone_number || '';
    default:
      return '';
  }
};
const upperText = formOverlayText(defaultOverlaySetting?.upper_text);
const lowerText = formOverlayText(defaultOverlaySetting?.lower_text);
const formOverlayData: UnitUserOverlaySetting = {
  logo_placement: overlaySettings?.logo_placement || defaultOverlaySetting?.logo_placement || '',
  logo_size: overlaySettings?.logo_size || defaultOverlaySetting?.logo_size || 0,
  logo_url: overlaySettings?.logo_url || defaultOverlaySetting?.logo_url || '',
  lower_background_color: overlaySettings?.lower_background_color || defaultOverlaySetting?.lower_background_color || '',
  lower_text: lowerText || overlaySettings?.lower_text || '',
  lower_text_color: overlaySettings?.lower_text_color || defaultOverlaySetting?.lower_text_color || '',
  upper_background_color: overlaySettings?.upper_background_color || defaultOverlaySetting?.upper_background_color || '',
  upper_text: upperText || overlaySettings?.upper_text || '',
  upper_text_color: overlaySettings?.upper_text_color || defaultOverlaySetting?.upper_text_color || '',
};

return (
  <div>
    <MainLayoutHeader title={headerTitle} />
    {isMobile
      ? (
        <MobileContainer>
          <MobileHeader>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Link to={-1}>
              <button type="button" className="back">
                <img src={BackSvg} alt="back" />
                <span>Back</span>
              </button>
            </Link>
            <Whisper
              controlId="edit-tooltip-container"
              preventOverflow
              trigger={disableSave ? 'hover' : 'none'}
              speaker={(
                <Tooltip>
                  Unit is unable to be edited while being Posted
                </Tooltip>
                )}
              placement="left"
            >
              <CustomButton wrapperClassname="save-btn" onClick={handleSaveClick} disabled={loading || disableSave || !!stockValidationErrors?.stock}>Save</CustomButton>
            </Whisper>
          </MobileHeader>
          <div className="mobile-main-section">
            <div className="placeholder" />
            <ListItem title="Specifics" openSectionsList={openSections}>
              <Specifics
                isEditing={isEditPage}
                locations={locationData}
                itemCategories={itemCategories}
                itemsType={itemsType}
                itemCategoriesLoading={itemCategoriesLoading}
                itemTypesLoading={itemTypesLoading}
                initialValues={formikValues.specifics}
                onInputChange={handleSpecificsChange}
                handleAutopopulate={handleAutopopulate}
                errors={errors.specifics}
                touched={touched.specifics}
                disableInventoryType={headerTitle === 'Edit Unit'}
                requestErrors={requestErrors}
                formReset={formik.resetForm}
                handleStockValidation={handleStockValidation}
                stockValidationErrors={stockValidationErrors}
              />
            </ListItem>
            <ListItem title="Details" openSectionsList={openSections}>
              <Details
                initialValues={formikValues.details}
                onInputChange={handleDetailsChange}
                requestErrors={requestErrors}
                attributesValues={formikValues.attributes}
                onAttributeInputChange={handleAttributesChange}
                errors={errors.details}
                touched={touched.details}
              />
            </ListItem>
            <ListItem title="Description" openSectionsList={openSections}>
              <Description onInputChange={handleDescriptionChange} markdownValue={formikValues.description.markdown} />
            </ListItem>
            <ListItem title="Attributes" openSectionsList={openSections}>
              <Attributes
                itemAttributes={itemAttributes}
                onInputChange={handleAttributesChange}
                initialValues={formikValues.attributes}
                inventoryType={formikValues.specifics.inventoryType}
                requestErrors={requestErrors}
                detailsInitialValues={formikValues.details}
                onDetailInputChange={handleDetailsChange}
              />
            </ListItem>
            <ListItem title="Features" openSectionsList={openSections}>
              <Features
                itemFeaturesList={itemFeatures}
                loading={featureListIsLoading}
                featureIds={formikValues.features.features}
                onChange={(featuresData) => setFieldValue('features', featuresData)}
              />
            </ListItem>
            <ListItem title="Website Configuration" openSectionsList={openSections}>
              <WebsiteConfiguration selectedDealer={selectedDealer || userRequest} onInputChange={handleWebsiteChange} initialValues={formikValues.website} ref={websiteRef} customStatusOptions={customStatusOverlayOptions} />
            </ListItem>
            <ListItem title="Images" openSectionsList={openSections}>
              <Images onChange={handleImagesChange} onDeleteAll={handleImagesClear} onSelectChange={handleImagesSettingChange} initialValue={formikValues.images} overlaySettings={formOverlayData} downloadBtn={isEditPage} />
            </ListItem>
            <ListItem title="PDFs" openSectionsList={openSections}>
              <PDFs onChange={handlePDFsChange} initialValue={formikValues.pdfs} />
            </ListItem>
            <ListItem title="Hidden Files" openSectionsList={openSections}>
              <HiddenFiles onChange={handleHiddenFilesChange} initialValue={formikValues.hiddenFiles} />
            </ListItem>
          </div>
        </MobileContainer>
      )
      : (
        <DesktopContainer>
          <div className="side-bar">
            <div className="side-bar-sticky">
              <div className="side-bar-back">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Link to={-1}>
                  <button type="button" className="back">
                    <img src={BackSvg} alt="back" />
                    <span>Back</span>
                  </button>
                </Link>
              </div>
              <div
                className={`side-bar-item ${activeSection === SectionList.Specifics && 'active'}`}
                onClick={() => scrollToSection(specificRef, SectionList.Specifics)}
              >
                Specifics
              </div>
              <div
                className={`side-bar-item ${activeSection === SectionList.Details && 'active'}`}
                onClick={() => scrollToSection(detailsRef, SectionList.Details)}
              >
                Details
              </div>
              <div
                className={`side-bar-item ${activeSection === SectionList.Description && 'active'}`}
                onClick={() => scrollToSection(descriptionRef, SectionList.Description)}
              >
                Description
              </div>
              <div
                className={`side-bar-item ${activeSection === SectionList.Attributes && 'active'}`}
                onClick={() => scrollToSection(attributesRef, SectionList.Attributes)}
              >
                Attributes
              </div>
              <div
                className={`side-bar-item ${activeSection === SectionList.Features && 'active'}`}
                onClick={() => scrollToSection(featuresRef, SectionList.Features)}
              >
                Features
              </div>
              <div
                className={`side-bar-item ${activeSection === SectionList['Website Configuration'] && 'active'}`}
                onClick={() => scrollToSection(websiteRef, SectionList['Website Configuration'])}
              >
                Website Configuration
              </div>
              <div
                className={`side-bar-item ${activeSection === SectionList.Images && 'active'}`}
                onClick={() => scrollToSection(imagesRef, SectionList.Images)}
              >
                Images
              </div>
              <div
                className={`side-bar-item ${activeSection === SectionList.PDFs && 'active'}`}
                onClick={() => scrollToSection(pdfsRef, SectionList.PDFs)}
              >
                PDFs
              </div>
              <div
                className={`side-bar-item ${(activeSection === SectionList['Hidden Files']) && 'active'}`}
                onClick={() => scrollToSection(hiddenFilesRef, SectionList['Hidden Files'])}
              >
                Hidden Files
              </div>
            </div>
          </div>
          <div className="desktop-content">
            <div className="section specifics">
              <div className="section-title specifics">
                <div ref={specificRef}>
                  Specifics
                </div>
                <Whisper
                  controlId="edit-tooltip-container"
                  preventOverflow
                  trigger={disableSave ? 'hover' : 'none'}
                  speaker={(
                    <Tooltip>
                      Unit is unable to be edited while being Posted
                    </Tooltip>
                    )}
                  placement="left"
                >
                  <div className="save-btn-wrap">
                    <CustomButton variant="primary" wrapperClassname="save-btn" onClick={handleSaveClick} disabled={loading || disableSave || !!stockValidationErrors?.stock}>Save</CustomButton>
                  </div>
                </Whisper>
              </div>
              <div className="section-wrapper specifics">
                <Specifics
                  isEditing={isEditPage}
                  locations={locationData}
                  itemCategories={itemCategories}
                  itemsType={itemsType}
                  itemCategoriesLoading={itemCategoriesLoading}
                  itemTypesLoading={itemTypesLoading}
                  initialValues={formikValues.specifics}
                  onInputChange={handleSpecificsChange}
                  handleAutopopulate={handleAutopopulate}
                  errors={errors.specifics}
                  touched={touched.specifics}
                  disableInventoryType={headerTitle === 'Edit Unit'}
                  requestErrors={requestErrors}
                  formReset={formik.resetForm}
                  handleStockValidation={handleStockValidation}
                  stockValidationErrors={stockValidationErrors}
                />
              </div>
            </div>
            <div className="section">
              <div className="section-title details">
                <div ref={detailsRef}>
                  Details
                </div>
              </div>
              <div className="section-wrapper details">
                <Details
                  initialValues={formikValues.details}
                  onInputChange={handleDetailsChange}
                  requestErrors={requestErrors}
                  attributesValues={formikValues.attributes}
                  onAttributeInputChange={handleAttributesChange}
                  errors={errors.details}
                  touched={touched.details}
                />
              </div>
            </div>
            <div className="section">
              <div className="section-title description">
                <div ref={descriptionRef}>
                  Description
                </div>
              </div>
              <div className="section-wrapper description">
                <Description onInputChange={handleDescriptionChange} markdownValue={formikValues.description.markdown} />
              </div>
            </div>
            <div className="section">
              <div className="section-title attributes">
                <div ref={attributesRef}>
                  Attributes
                </div>
              </div>
              <div className="section-wrapper attributes">
                <Attributes
                  itemAttributes={itemAttributes}
                  specificAttributes={(selectedDealer?.dl_customer_id || userRequest?.dl_customer_id) ? Object.fromEntries(
                    getSpecificAttributes(String(formikValues.specifics.item_category?.name) || '').map((attr) => [
                      attr[String(selectedCategory)] || 'undefined',
                      attr[String(selectedCategory)] || [],
                    ]),
                  ) : []}
                  onInputChange={handleAttributesChange}
                  initialValues={formikValues.attributes}
                  inventoryType={formikValues.specifics.inventoryType}
                  requestErrors={requestErrors}
                  detailsInitialValues={formikValues.details}
                  onDetailInputChange={handleDetailsChange}
                />
              </div>
            </div>
            <div className="section">
              <div className="section-title features">
                <div ref={featuresRef}>
                  Features
                </div>
              </div>
              <div className="section-wrapper features">
                <Features
                  itemFeaturesList={itemFeatures}
                  loading={featureListIsLoading}
                  featureIds={formikValues.features.features}
                  onChange={(featuresData) => setFieldValue('features', featuresData)}
                />
              </div>
            </div>
            <div className="section">
              <div className="section-title website-config">
                <div ref={websiteRef}>
                  Website Configuration
                </div>
              </div>
              <div className="section-wrapper website-config">
                <WebsiteConfiguration selectedDealer={selectedDealer || userRequest} onInputChange={handleWebsiteChange} initialValues={formikValues.website} ref={websiteSectionRef} customStatusOptions={customStatusOverlayOptions} />
              </div>
            </div>
            <div className="section">
              <div className="section-title Images">
                <div ref={imagesRef}>
                  Images
                </div>
              </div>
              <div className="section-wrapper Images">
                <Images onChange={handleImagesChange} onDeleteAll={handleImagesClear} initialValue={formikValues.images} onSelectChange={handleImagesSettingChange} overlaySettings={formOverlayData} downloadBtn={isEditPage} />
              </div>
            </div>
            <div className="section">
              <div className="section-title PDFs">
                <div ref={pdfsRef}>
                  PDFs
                </div>
              </div>
              <div className="section-wrapper PDFs">
                <PDFs onChange={handlePDFsChange} initialValue={formikValues.pdfs} />
              </div>
            </div>
            <div className="section">
              <div className="section-title hidden-files">
                <div ref={hiddenFilesRef}>
                  Hidden Files
                </div>
              </div>
              <div className="section-wrapper hidden-files">
                <HiddenFiles onChange={handleHiddenFilesChange} initialValue={formikValues.hiddenFiles} />
              </div>
            </div>
          </div>
        </DesktopContainer>
      )}
  </div>
);
}

function useIsInViewport(ref: RefObject<HTMLDivElement>) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
    [],
  );
  useEffect(() => {
    if (ref?.current) {
      observer.observe(ref?.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);
  return isIntersecting;
}

export default UnitPage;

const MobileContainer = styled.div`
  position: relative;
  .mobile-main-section {
    .placeholder {
      height: 52px;
    }
  }
`;
const MobileHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 11px 16px 11px 6px;
  background: #fff;
  position: fixed;
  width: 100%;
  z-index: 99;
  margin-top: -2px;
  .back {
    font-size: 16px;
    transition: all ease 0.2s;
    color: ${(props) => props.theme.primaryTextColor};
    :hover {
      font-weight: bold;
    }
  }
  .save-btn-wrap {
    width: 83px;
  }
  .save-btn {
    width: 83px;
    button {
      padding: 0;
      border-radius: 23px;
      font-size: 14px;
      height: 35px;
    }
  }
`;

const StyledListItem = styled.div`
  .control {
    display: flex;
    justify-content: space-between;
    padding: 10px 30px 10px 33px;
    .full {
      color: ${(props) => props.theme.primaryColor};
    }
    button.full {
      transform: rotate(90deg);
    }
  }
  .content {
    padding: 1px 2px 10px 2px;
  }
background-color: #fff;
min-height: 45px;
margin-top: 2px;
margin-bottom: 2px;
font-weight: bold;
font-size: 14px;
color: ${(props) => props.theme.primaryTextColor};
  label {
    font-size: 12px;
    font-weight: normal;
    color: ${(props) => props.theme.primaryTextColor};
    margin-right: 8px;
    white-space: nowrap;
  }
  input {
    width: 100%;
  }
  .select-input {
    justify-content: end;
    padding: 0 14px;
    height: 29px;
    margin-top: 13px;
    .select-placeholder-text {
    font-size: 10px;
    text-align: initial;
    margin: -5px 0 0 10px;    
    }
    label {
      font-size: 12px;
      font-weight: normal;
      color: ${(props) => props.theme.primaryTextColor};
    }
    > div {
      width: 236px;
    }
  }
  .input-wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: end;
    padding: 0 14px;
    margin-top: 13px;
    .symbol-input-wrapper {
      width: auto;
    }
    input {
      height: 29px;
      border-radius: 0;
      border-width: 1px;
    }
  }
  .input-with-button {
    display: grid;
    max-width: 500px;
    grid-template-columns: auto 10% ;
    padding: 0 20px;
    .select-container {
      grid-template-columns: 1.2fr 2fr;
      > div {
        width: 100%;
      }
    }
    .primary-btn-wrapper {
      width: auto;
      margin-left: 6px;
      margin-right: 15px;
  }
    .primary-btn {
      width: 38px;
      height: 25px;
      font-size:  10px;
      margin-top: 13px;
    }
    button {
      padding: 6px;
      width: 38px;
      height: 25px;
    }
    .select-input  {
      padding: 0;
    } 
  }

  .select-container,
  .input-wrapper {
    max-width: 500px;
    display: grid;
    grid-template-columns: 2fr 4fr;
    justify-items: end;
    label {
      text-align: end;
    }
    > div, .symbol-input-wrapper {
      height: 29px;
      width: 100%;
    }
    .with-symbol {
      width: auto;
    }
  }
`;

const DesktopContainer = styled.div`
  .section-title {
    display: flex;
    justify-content: space-between;
    padding: 0 20px 0 30px;
    font-size: 25px;
    align-items: flex-start;
    color: ${(props) => props.theme.primaryColor};
    font-weight: bold;
    margin-bottom: 18px;
    &.specifics {
      margin-bottom: 0;
      @media screen and (max-width: 900px) {
        margin-bottom: 10px;
      }
      }
    &.details {
      margin-bottom: 0;
    }
    &.PDFs{ 
      margin-bottom: 0;
    }
    &.hidden-files { 
      margin-bottom: 0;
    }
    .save-btn-wrap {
      width: 102px;
    }
    .save-btn {
      width: 102px;
      margin-top: 10px;
      position: fixed;
      right: 3%;
      z-index: 2;
    }
    &.website-config {
      margin-bottom: 12px;
    }
  }
  display: flex;

  .side-bar {
    margin-top: 25px;
  }
  .side-bar-sticky {
    position: sticky;
    top: 82px; //header height
  }
  .side-bar-back {
    margin-left: 30px;
    display: flex;
    margin-bottom: 16px;
    a {
      text-decoration: none;
      color: inherit;
    }
  }

  .side-bar-item {
    cursor: pointer;
    padding: 8px 8px 8px 38px;
    height: 69px;
    width: 253px;
    @media screen and (max-width: 1024px) {
      width: 200px;
    }
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;

    &.active {
      color: ${(props) => props.theme.primaryColor};
      background-color: #fff;
    }
  }

  .desktop-content {
    width: 100%;
    .section {
      padding: 20px;
      background-color: #fff;
      margin-top: 20px;
      &.specifics {
        margin-top: 4px;
      }
    }
    .section-wrapper {
      display: flex;
      margin-left: 72px;
      &.specifics {
          @media screen and (max-width: 1210px) {
            margin-top: 44px;
          }
        @media screen and (max-width: 768px) {
          margin-left: 30px;
        }
        margin-top: 4px;
        margin-left: 78px;
        label {
           margin-right: 9px;
        }
      }
        &.website-config {
        margin-left: 20px;
      }
      &.description {
        margin-left: 67px;
        margin-top: -10px;
        >div {
          width: 100%;
        }
        @media screen and (max-width: 1024px) {
          margin-left: 10px;
        }
      }
      &.details {
        margin-left: 70px;
        @media screen and (max-width: 1024px) {
          margin-left: 20px;
        }
      } 
      &.Images {
        margin-left: 89px;
      } 
      &.PDFs {
        margin-left: 89px;
      }
      &.hidden-files {
        margin-left: 89px;
      }
      @media screen and (max-width: 900px) {
        margin-left: 20px;
      }
      @media screen and (min-width: 768px) {
        &.features {
          margin-left: 20px;
        }
      }
        align-items: flex-start;
    }
    .select-input-container {
      label {
        font-size: 13px;
        font-weight: normal;
        color: ${(props) => props.theme.primaryTextColor};
      }
      display: grid;
      margin-top: -6px;
      margin-left: 5px;
      
      grid-template-columns: 1fr 1fr;
      column-gap: 96px;
      row-gap: 20px;
      white-space: nowrap;
      width: 100%;
      @media screen and (max-width: 1024px) {
        column-gap: 16px;
      }
      label {
        text-align: end;
      }

      input {
        width: 100%;
      }

      .input-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        label {
          margin-right: 10px;
        }
      }

      .select-container,
      .input-wrapper {
        display: grid;
        grid-template-columns: 2fr 4fr;
        max-height: 34px;
        > div {
          max-width: 195px;
        }
        input {
          max-height: 34px;
          border-radius: 4px;
        }
      }

      .input-with-button {
        grid-column: 1 / span 2;
        display: grid;
        grid-template-columns: 12fr 4fr;
        .select-wrap {
          margin-left: -6px;
        }
        label {
          color: ${(props) => props.theme.primaryColor};
          font-weight: bold;
          margin-right: 18px;
        }
        .primary-btn-wrapper {
          margin-left: 10px;
          width: 50px;
          button {
            padding: 6px;
            height: 30px;
            font-size: 12px;
          }
        }
        .select-container {
            max-width: 518px;          
        }
        .select-container > div {
          max-width: unset;
        }
        .select-container {
          grid-template-columns: 104px 1fr;
        }
      }

      > .building-title {
        max-width: 690px;
        &.Equipment {
          max-width: 695px;
        }
        &.Horse-Trailer {
          max-width: 705px;
        }
        &.Recreational-Vehicles {
          max-width: 757px;
        }
        &.Semi-Trailer {
          max-width: 705px;
        }
        &.Semi-Truck {
          max-width: 701px;
        }
        &.Sports-Vehicle {
          max-width: 717px;
        } 
        &.Trailer-or {
            max-width: 747px;
          }
        grid-column: 1 / span 2;
        grid-template-columns: 1fr 6fr;

        > div {
          max-width: unset;
        }
      }
    }
    .section-wrapper.details {
      margin-top: 3px;
    }
    .section-wrapper.details,
    .section-wrapper.attributes {
      &.attributes {
        margin-left: 0;
      }
      .small-inputs-container {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 768px) {
          margin-top: 18px;
        }
        .price {
          display: grid;
          align-items: center;
          grid-template-columns: 3fr 6fr;
          gap: 0;
          label {
            text-align: end;
            margin-right: 10px;
          }
        }
          .symbol-input-wrapper {
            max-width: 195px;
            input {
              width: 100%;
            }
          }
        }
      .details-section-content {
        display: grid;
        grid-template-columns: 3fr 6fr;
        max-width: unset;
        margin-top: 4px;
        .other-inputs {
          @media screen and (max-width: 768px) {
            margin-left: 12px;
          }
          max-width: 504px;
          margin-left: 42px;
        }
        .bg {
          gap: 10px;
          display: grid;
          grid-template-columns: 3fr 4fr;
          .input-wrapper {
            display: grid;
            grid-template-columns: clamp(40px, 45%, 110px) clamp(40px, 50%, 200px);
            align-items: center;
            gap: 12px;
          }
        }
      }
      .attributes-section-content {
        display: grid;
        grid-template-columns: 2fr 3fr;
        gap: 10px;
        @media screen and (max-width: 1024px) {
          gap: 0;
        }
        &.empty {
          gap: 0;
          grid-template-columns: 1fr;
        }
        label {
          font-weight: normal;
          color: ${(props) => props.theme.primaryTextColor};
          font-size: 13px;
        }
        .left-row,
        .right-row {
          gap: 20px;
          display: flex;
          flex-direction: column;
          max-width: 504px;
          .select-container,
          .input-wrapper {
            display: grid;
            grid-template-columns: 1fr 1.1fr;
            justify-items: end;
            align-items: center;
            label {
              white-space: nowrap;
            }
            input {
              max-height: 34px;
            }
            > div {
              max-width: 195px;
            }
          }
          &.left-row {
            margin-right: 63px;
            @media screen and (max-width: 1024px) {
              margin-right: 0;
            }
            max-width: 380px;
            .input-wrapper {
              input {
                width: 100%
              } 
              label {
                margin-top: 5px;
                margin-right: 10px;
              }
            }
          }
          &.right-row {
            .inputs-section {
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding-right: 10px;
               max-width: unset;
              .select-container,
              .input-wrapper {
               > div {
                 max-width: unset;
               }
             }
              label {
                margin-right: 12px;
              }
              input {
                width: 100%;
              }
            }            
          } 
        }
        .right-row {
          gap: 0;
        }
      }
  }
  }
`;
