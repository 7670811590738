import { useFormik } from 'formik';
import styled from 'styled-components';
import CustomButton from '../../../components/Buttons/CustomButton';
import CustomInput from '../../../components/inputs/CustomInput';
import { DeepKeysArrAndObj } from '../../../helpers';
import { ProviderCreationErrors } from '../../../store/dealers/actions';
import { createProviderFormSchema } from '../../../validation/createProviderFormSchema';

export interface ProviderFromData {
 name: string
 email: string
 dealer_id: string | number
}

type ProviderCreationFormProps = {
  errorsProps?: ProviderCreationErrors
  onSubmit: (data: ProviderFromData) => void
  editProviderMode: boolean
  editableProviderValues?: ProviderFromData
}
function ProviderCreationForm({
  errorsProps, onSubmit, editProviderMode, editableProviderValues,
}: ProviderCreationFormProps) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const dealerId = Object.fromEntries(urlSearchParams.entries()).dealer_id ? Number(Object.fromEntries(urlSearchParams.entries()).dealer_id) : 1;
  const initialValues: ProviderFromData = editableProviderValues || {
    name: '',
    email: '',
    dealer_id: dealerId,
  };

  const formik = useFormik<ProviderFromData>({
    initialValues,
    onSubmit,
    validationSchema: createProviderFormSchema,
  });

  const {
    setFieldValue, values: formikValues, errors: formikErrors, touched,
  } = formik;
  const errors = formikErrors as Partial<ProviderFromData>;
  type TranslationKey = DeepKeysArrAndObj<ProviderFromData>;
  const handleFieldChange = (key: TranslationKey, value: string) => {
    setFieldValue(key, value);
  };

  return (
    <StyledForm onSubmit={(e) => {
      e.preventDefault();
      formik.submitForm();
    }}
    >
      <div className="section">
        <div className="section-content">
          <div className="input-container">
            <CustomInput
              label="Provider Name"
              required
              wrapperClass="input-wrapper"
              value={formikValues.name}
              onChange={(value) => handleFieldChange('name', value)}
              error={errors.name && touched?.name ? errors.name : errorsProps?.name}
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="Email"
              required={!editProviderMode}
              wrapperClass="input-wrapper"
              value={formikValues.email}
              onChange={(value) => handleFieldChange('email', value)}
              error={errors.email && touched?.email ? errors.email : errorsProps?.email}
            />
          </div>
        </div>
      </div>
      <div className="buttons-container">
        <div className="save-button">
          <CustomButton type="submit">
            {editProviderMode ? 'Update Provider' : 'Create Provider'}
          </CustomButton>
        </div>
      </div>
    </StyledForm>
  );
}

export default ProviderCreationForm;

const StyledForm = styled.form`
  padding-left: 61px;
  padding-right: 39px;
  .section {
    padding-top: 31px;
    .section-title-container {
      display: flex;
      justify-content: space-between;
    }
    .section-title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    .section-content {
      background-color: #fff;
      border-radius: 8px;
      padding: 8px;
      padding-left: 0;
      padding-top: 1px;
      box-shadow: 0 5px 5px rgba(23, 23, 23, 0.31);
      
      .input-container {
        padding-top: 18px;
        padding-bottom: 18px;
        :not(:last-child) {
          border-bottom: 0.5px solid #c1c1c1;
        }
        label {
          max-width: 198px;
          color: ${(props) => props.theme.secondaryTextColor};
          font-size: 14px;
        }
        input {
          width: 100%;
          max-width: 315px;
        }
        .input-wrapper {
          padding-left: 36px;
          display: grid;
          grid-template-columns: clamp(150px, 40%, 198px) clamp(215px, 40%, 315px);
        }
        .select-container {
          padding-left: 36px;
          display: grid;
          grid-template-columns: clamp(150px, 40%, 198px) clamp(215px, 40%, 315px);
          label {
            color: ${(props) => props.theme.secondaryTextColor};
            font-size: 14px;
            font-weight: normal;
          }
          .select-wrap {
            max-width: 315px;
            height: 39px;
          }
        }
     
        &.phone-input {
          padding-left: 36px;
          input {
            height: 39px;
            max-width: 315px;
            margin-left: 0;
          }
          > div {
            display: grid;
            grid-template-columns: clamp(150px, 40%, 198px) clamp(215px, 40%, 315px);
          }
        }
        &.double-inputs {
          display: flex;
          padding-left: 0;
          .input-wrapper {
            display: flex;
            flex-direction: row;
          }
          label {
            display: none;
          }
          .with-label {
            label {
              display: block;
              width: 197px;
            }
          
          }
          .input-wrapper {
            &:not(.with-label) {
              padding-left: 0;
              margin-left: 13px;
            }  
          }
          .symbol-input-wrapper {
            max-width: 151px;
          }
          input {
            width: 151px;
          }
        }
        
        &.with-button {
          display: flex;
          align-items: center;
          .input-wrapper {
            display: grid;
            min-width: 513px;
            grid-template-columns: clamp(150px, 60%, 198px) clamp(215px, 60%, 315px);
            label {
              max-width: 198px;
            }
            input {
              width: 100%;
            }
          }
          .add-button {
            margin-left: 18px;
          }
        }
        
        &.with-id {
          display: flex;
          justify-content: space-between;
          padding-right: 15px;
          .input-wrapper {
            width: 500px;
            input {
              width: 320px;
              margin-left: 10px;
            }
          }
        }
        
        
      }
    }
  }

  .buttons-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    .save-button {
      .primary {
        border-radius: 4px;
      }
    }
  }
`;
