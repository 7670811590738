import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

function AuthLayout() {
  return (
    <StyledLayout>
      <Outlet />
    </StyledLayout>
  );
}

export default AuthLayout;

const StyledLayout = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;
