import SettingsLayout from '../../../components/SettingsLayout';
import { useGetSelectedDealer } from '../../../hooks/getDealersHook';
import Pages from '../../../Router/pages';
import { getMeRequest } from '../../../store/auth/actions';
import { getLocationsRequest } from '../../../store/settings/actions';
import LocationSection from '../../SettingsSections/LocationSection';

function SettingsLocation() {
  const selectedDealer = useGetSelectedDealer();

  const user = getMeRequest()?.data?.data;

  const { data: locationDataResp, refetch, isFetching } = getLocationsRequest(
    { dealer_id: (user?.dealer_id ? String(user?.dealer_id) : undefined) || (selectedDealer?.id ? String(selectedDealer?.id) : undefined) },
  );
  const locationData = locationDataResp?.data || [];
  return (
    <SettingsLayout navigateOnBack={Pages.inventoryManager} headerTitle="settings">
      <LocationSection locationData={locationData} refetch={refetch} loading={isFetching} />
    </SettingsLayout>
  );
}

export default SettingsLocation;
