import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAppMessageContext } from '../../appMessagesContext';
import SettingsLayout from '../../components/SettingsLayout';
import { SidebarItem } from '../../components/SideBar';
import { formSelectOptions } from '../../helpers';
import { useGetSelectedDealer } from '../../hooks/getDealersHook';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import {
  CreateLocationRequest,
  getLocationsRequest,
  SetLocationRequest,
  UpdateLocationRequest,
} from '../../store/settings/actions';
import { LocationsI } from '../../store/settings/requests';
import LocationForm from './LocationForm';
import { CountriesOptions } from '../SettingsSections/data';

function Location() {
  const navigate = useNavigateHook();
  const { showMessage } = useAppMessageContext();
  const params = useParams<{id: string}>();
  const sideBarItems: SidebarItem[] = [
    {
      label: 'Specifics', active: true, key: 'specifics', page: Pages.settings,
    },
  ];

  const { data: locations, isFetching } = getLocationsRequest();
  const locationData: LocationsI[] = locations?.data || [];
  const editedLocation = locationData.find((el) => el.id === Number(params?.id));
  const updateLocationRequest = UpdateLocationRequest();
  const createLocationRequest = CreateLocationRequest();
  const selectedDealer = useGetSelectedDealer();

  const updateLocation = (data: SetLocationRequest) => {
    updateLocationRequest.mutate({ data, id: params?.id || '' });
  };
  const addLocation = (data: SetLocationRequest) => {
    createLocationRequest.mutate(data);
  };

  const handleSubmit = (data: SetLocationRequest) => {
    if (params.id) {
      updateLocation(data);
    } else {
      addLocation({
        ...data,
        ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }),
      });
    }
  };

  useEffect(() => {
    if (updateLocationRequest.isSuccess || createLocationRequest.isSuccess) {
      showMessage({ type: 'success', message: 'Location Saved successfully' });
      navigate(Pages.LocationSetting);
    }
  }, [updateLocationRequest.isSuccess, createLocationRequest.isSuccess]);

  const isEdit = useMemo(() => !!params?.id, [params]);

  const formValues = useMemo(() => ((isEdit && editedLocation) ? {
    ...editedLocation,
    state: formSelectOptions([editedLocation.state])[0],
    country: CountriesOptions?.find((opt) => opt.value === editedLocation?.country) || formSelectOptions([editedLocation.country])[0],
  } : undefined), [isEdit, editedLocation]);
  if (isFetching) {
    return null;
  }

  return (
    <SettingsLayout
      navigateOnBack={-1}
      sideBarItems={sideBarItems}
      headerTitle={`${isEdit ? 'Edit' : 'Add'} Location`}
      activeSectionProp="specifics"
    >
      <LocationForm
        initialValues={formValues}
        onSubmit={handleSubmit}
        errorsProps={updateLocationRequest?.error?.response?.data.errors || createLocationRequest?.error?.response?.data.errors}
      />
    </SettingsLayout>
  );
}

export default Location;
