export const stockInputPattern = '^[A-Za-z0-9_]*[A-Za-z0-9 \\-_]*$';
export const numericInputPattern = '[0-9]*';
export const deciNumericInputPattern = '^\\d{1,}$|(?=^.{1,}$)^\\d+\\.\\d{0,}$';
export const CVCInputPattern = '[0-9][0-9]{0,2}';
export const TFAInputPattern = '[^\\s]+';
export const LocationInputPattern = '^[A-Za-z_]*[A-Za-z .\\-]*$';
export const USzipCodePattern = '^[A-Za-z0-9_]*[A-Za-z0-9 .\\-]*$';
export const USzipCodeValidation = '^[A-Za-z0-9_]*[A-Za-z0-9 .\\-]*$';
export const CanadaZipCodePattern = '^[ABCEGHJKLMNPRSTVXY]{1}\\d{1}[A-Z]{1} *\\d{1}[A-Z]{1}\\d{1}$';

export const amexPattern = '^3[47][0-9]{13}$';

export const visaPattern = '^4[0-9]{12}(?:[0-9]{3})?$';

export const cup1Pattern = '^62[0-9]{14}[0-9]*$';
export const cup2Pattern = '^81[0-9]{14}[0-9]*$';

export const mastercardPattern = '^5[1-5][0-9]{14}$';
export const mastercard2Pattern = '^2[2-7][0-9]{14}$';

export const disco1Pattern = '^6011[0-9]{12}[0-9]*$';
export const disco2Pattern = '^62[24568][0-9]{13}[0-9]*$';
export const disco3Pattern = '^6[45][0-9]{14}[0-9]*$';

export const dinersPattern = '^3[0689][0-9]{12}[0-9]*$';
export const jcbPattern = '^35[0-9]{14}[0-9]*$';
