import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { UploadedFile } from '../../components/FileUploader';
import { DeepKeysArrAndObj, FixedLengthArr } from '../../helpers';
import { LocationFormInterface } from '../../pages/Location/LocationForm';
import { CalendarEventStatus } from '../../pages/SettingsSections/SchedulerSection/PendingTab';
import { CSVDuplicateModalForm } from '../../pages/SettingsSections/CSVSection/ImportTab/CSVDuplicateModal';
import { RequestKeys } from '../keys';
import { CreateUnitRequestData, UnitType } from '../unit/actions';
import {
  CustomStatusOverlayI,
  FbProfileI,
  LocationsI, ProfileI, SettingRequests, UsersI,
} from './requests';

export const getLocationsRequest = (params?: {available?: number, fb_profile_id?: number, dealer_id?: string | number, disableRequest?: boolean}) => useQuery<AxiosResponse<LocationsI[]>>(
  {
    queryKey: [RequestKeys.GET_LOCATION_DATA, params],
    queryFn: () => SettingRequests.GetLocations({ available: params?.available, fb_profile_id: params?.fb_profile_id, dealer_id: params?.dealer_id }),
    enabled: !params?.disableRequest,
    staleTime: 2000,
    refetchOnMount: true,
  },
);

export interface SetLocationRequest extends Omit<LocationFormInterface, 'state' | 'country'> {
  state: string
  country: string
  dealer_id: number
}

export type GenerateLocationErrors = {
  [key in keyof Omit<LocationsI, 'email'>]?: string
}

export type LocationReqError = GenerateLocationErrors & {
  [key: `email.${number}`]: string
}

export const UpdateLocationRequest = () => {
  const mutation = useMutation <AxiosResponse<LocationsI>, AxiosError<{ errors: LocationReqError, message: string }>, {data: SetLocationRequest, id: string}>(
    {
      mutationKey: [RequestKeys.UPDATE_LOCATION],
      mutationFn: ({ data, id }) => SettingRequests.UpdateLocation(data, id),
    },
  );
  return { ...mutation };
};
export const UpdateMainLocationRequest = () => {
  const mutation = useMutation <AxiosResponse<LocationsI>, AxiosError<{ errors: LocationReqError, message: string }>, {id: string}>(
    {
      mutationKey: [RequestKeys.UPDATE_MAIN_LOCATION],
      mutationFn: ({ id }) => SettingRequests.UpdateMainLocation(id),
    },
  );
  return { ...mutation };
};
export const CreateLocationRequest = () => {
  const mutation = useMutation <AxiosResponse<LocationsI>, AxiosError<{ errors: LocationReqError, message: string }>, SetLocationRequest>(
    {
      mutationKey: [RequestKeys.CREATE_LOCATION],
      mutationFn: (data: SetLocationRequest) => SettingRequests.CreateLocation(data),
    },
  );
  return { ...mutation };
};
export const DeleteLocationRequest = () => {
  const mutation = useMutation <AxiosResponse<LocationsI>, AxiosError<{ errors: LocationReqError, message: string }>, {ids: number[]}>(
    {
      mutationKey: [RequestKeys.DELETE_LOCATION],
      mutationFn: ({ ids }) => SettingRequests.DeleteLocations(ids),
    },
  );
  return { ...mutation };
};

export const getUsersRequest = (params: {dealer_id?: string | number}) => useQuery<AxiosResponse<UsersI[]>>(
  {
    queryKey: [RequestKeys.GET_USERS, params],
    queryFn: () => SettingRequests.GetUsers(params),
    staleTime: 2000,
  },
);

export interface CreateUserRequestI {
  email: string
  password: string
  password_confirmation: string
  is_dealer_admin: boolean
  access_billing: boolean
  access_scheduler: boolean
  fb_profiles: boolean
  dl_customer_id: string
}
export type UpdateUserRequestI = Partial<CreateUserRequestI>

export type UserReqError = CreateUserRequestI

export const CreateUserRequest = () => {
  const mutation = useMutation <AxiosResponse<UsersI>, AxiosError<{ errors: UserReqError, message: string }>, CreateUserRequestI>(
    {
      mutationKey: [RequestKeys.CREATE_USER],
      mutationFn: (data: CreateUserRequestI) => SettingRequests.CreateUser(data),
    },
  );
  return { ...mutation };
};

export const UpdateUserRequest = () => {
  const mutation = useMutation <AxiosResponse<UsersI>, AxiosError<{ errors: UserReqError, message: string }>, {data: UpdateUserRequestI, id: number}>(
    {
      mutationKey: [RequestKeys.UPDATE_USER],
      mutationFn: ({ data, id }) => SettingRequests.UpdateUser(data, id),
    },
  );
  return { ...mutation };
};

export const DeleteUsersRequest = () => {
  const mutation = useMutation <AxiosResponse<UsersI>, AxiosError<{ errors: UserReqError, message: string }>, {ids: number[]}>(
    {
      mutationKey: [RequestKeys.DELETE_USER],
      mutationFn: ({ ids }) => SettingRequests.BulkDeleteUser(ids),
    },
  );
  return { ...mutation };
};

export interface UpdateOverlayRequestData {
  default_setting: string
  logo?: UploadedFile
  logo_placement: string
  logo_size: string
  status_overlay: string
  status_overlay_file?: UploadedFile
  status_overlay_size: string
  status_overlay_placement: string
  remove_status_overlay: boolean
  upper_text: string
  upper_background_color: string
  upper_text_color: string
  lower_text: string
  lower_background_color: string
  lower_text_color: string
  custom_status: CustomStatusOverlayI[]
  default_status: CustomStatusOverlayI[]
  id?: number
}

export type OverlayResponse = {
  id: number,
  dealer_id: number,
  default_setting: string,
  logo_url: null | string,
  logo_placement: string,
  status_overlay: string,
  status_overlay_size: number
  status_overlay_url: null | string,
  status_overlay_placement: string,
  logo_size: number,
  upper_text: string,
  upper_background_color: string,
  upper_text_color: string,
  lower_text: string,
  lower_background_color: string,
  lower_text_color: string,
  custom_status: null | string[] | CustomStatusOverlayI[],
  created_at: string,
  updated_at: string
}

export interface DealerSettingsResponse {
    id: number;
    dealer_id: string | number;
    placeholder_image_url: string;
}

export const GetOverlayRequest = (id?: number | null) => {
  const response = useQuery<AxiosResponse<OverlayResponse>>(
    {
      queryKey: [RequestKeys.GET_OVERLAY],
      queryFn: () => SettingRequests.GetOverlay(Number(id)), // "enabled" will block request with undefined ID
      enabled: !!id,
    },
  );
  return { responseData: response?.data?.data, ...response };
};
export const UpdateOverlayRequest = () => {
  const mutation = useMutation <AxiosResponse<OverlayResponse>, AxiosError<{ errors: string[], message: string }>, {data: UpdateOverlayRequestData, id: number}>(
    {
      mutationKey: [RequestKeys.UPDATE_OVERLAY],
      mutationFn: ({ data, id }) => SettingRequests.UpdateOverlay(data, id),
    },
  );
  return { ...mutation };
};

export type CSVFileResponse = {
  created_at: string
  id: number
  name: string
  size: number
  updated_at: string
  url: string
}

export type CSVErrorMessagesKeys = {
  [key in DeepKeysArrAndObj<Omit<CreateUnitRequestData, 'images' | 'pdfs' | 'hidden_files'>> | 'features']: string
}
export type CSVErrors = {
error_messages: {[key in keyof CSVErrorMessagesKeys]: string}
}
export type CSVDuplicate = {
  csv_file: CSVFileResponse
  duplicated_stock_numbers: string[]
}
export type CSVUploadWithErrorsResponse = CSVDuplicate | CSVErrors[]

export type CSVResponse = CSVUploadWithErrorsResponse

export interface CSVSaveDuplicatesRequestData {
  stock_numbers: CSVDuplicateModalForm['stock']
}

export interface CSVUploadRequestData {
  file?: UploadedFile
  dealer_id?: number
}

export const UploadCSVRequest = () => {
  const mutation = useMutation <AxiosResponse<CSVResponse>, AxiosError<{ errors: string[], message: string }>, {data: CSVUploadRequestData}>(
    {
      mutationKey: [RequestKeys.UPLOAD_CSV],
      mutationFn: ({ data }) => SettingRequests.UploadCSV(data),
    },
  );
  return { ...mutation };
};

export type CSVSaveDuplicatesRequestErrors = {
  errors: {[key: `stock_numbers.${number}.new`]: string[]}
  message: string
}
export const CSVSaveDuplicatesRequest = () => {
  const mutation = useMutation <AxiosResponse<CSVResponse>, AxiosError<CSVSaveDuplicatesRequestErrors>, {data: CSVSaveDuplicatesRequestData, id: string | number}>(
    {
      mutationKey: [RequestKeys.CSV_DUPLICATES],
      mutationFn: ({ data, id }) => SettingRequests.CSVSaveDuplicates(data, id),
    },
  );
  return { ...mutation };
};

export interface CSVFile {
  created_at: string
  id: number
  name: string
  size: number
  updated_at: string
  url: string
}
export const getCSVRequest = (params: {dealer_id?: string | number}) => {
  const response = useQuery<AxiosResponse<{data: CSVFile[]}>>(
    {
      queryKey: [RequestKeys.GET_CSV, params],
      queryFn: () => SettingRequests.GetCSVList(params),
      staleTime: 2000,
    },
  );
  return { responseData: response?.data?.data?.data || [], ...response };
};

export const getCustomStatusOverlaysRequest = (params: {dealer_id?: string | number, is_default?: boolean | number}) => {
  const response = useQuery<AxiosResponse<{data: CustomStatusOverlayI[]}>>(
    {
      queryKey: [RequestKeys.GET_CUSTOM_STATUSES, params],
      queryFn: () => SettingRequests.GetCustomStatusOverlays(params),
      staleTime: 2000,
      enabled: !!params.dealer_id,
    },
  );
  return { responseData: response?.data?.data?.data || [], ...response };
};

export const getFbProfilesRequest = (params: {dealer_id?: string | number}, disableRequest?: boolean) => {
  const response = useQuery<AxiosResponse<{data: FbProfileI[]}>>(
    {
      queryKey: [RequestKeys.GET_FB_PROFILES, params, disableRequest],
      queryFn: () => SettingRequests.GetFbProfiles(params),
      enabled: !disableRequest,
      staleTime: 2000,
    },
  );
  return { responseData: response?.data?.data?.data || [], ...response };
};
export const getFbProfileByIdRequest = (id: number, params?: {dealer_id?: string | number}) => {
  const response = useQuery<AxiosResponse<FbProfileI>>(
    {
      queryKey: [RequestKeys.GET_FB_PROFILE_BY_ID, params, id],
      queryFn: () => SettingRequests.GetFbProfileById(id, params),
      enabled: !!id,
      staleTime: 2000,
    },
  );
  return { responseData: response?.data?.data, ...response };
};
export const getCLProfilesRequest = (params: {dealer_id?: string | number}, disableRequest?: boolean) => {
  const response = useQuery<AxiosResponse<{data: ProfileI[]}>>(
    {
      queryKey: [RequestKeys.GET_CL_PROFILES, params, disableRequest],
      queryFn: () => SettingRequests.GetProfiles(params),
      enabled: !disableRequest,
      staleTime: 2000,
    },
  );
  return { responseData: response?.data?.data?.data || [], ...response };
};
export const getCLProfileByIdRequest = (id: number, params?: {dealer_id?: string | number}) => {
  const response = useQuery<AxiosResponse<ProfileI>>(
    {
      queryKey: [RequestKeys.GET_CL_PROFILE_BY_ID, params, id],
      queryFn: () => SettingRequests.GetProfileById(id, params),
      enabled: !!id,
      staleTime: 2000,
    },
  );
  return { responseData: response?.data?.data, ...response };
};
export type ProfileOptionsT = {
  categories: string[]
  conditions: string[]
  location_cities: string[]
  location_districts: {[key: string]: string[]}
}

export const getCLProfileOptionsRequest = () => {
  const response = useQuery<AxiosResponse<ProfileOptionsT>>(
    {
      queryKey: [RequestKeys.GET_CL_PROFILE_OPTIONS],
      queryFn: SettingRequests.GetProfileOptions,
    },
  );
  const responseData: ProfileOptionsT = response?.data?.data || {
    categories: [],
    conditions: [],
    location_cities: [],
    location_districts: {},
  };
  return { responseData, ...response };
};
export interface CreateFbProfileRequestData {
    name: string
    fb_token: string
    fb_login: string
    fb_password: string
    dealer_id?: number
}
export interface CreateCLProfileRequestData {
  name: string
  location_city: string
  location_district?: string
  contact_name: string
  phone_number: string
  location_id: number | string
  category: string
  condition: string
  description: string
  dealer_id?: number
}
export interface CreateCustomStatusOverlayRequestData {
  status_overlay: string
  status_overlay_file?: UploadedFile
  status_overlay_placement: string
  status_overlay_size: string
  dealer_id?: number
}
export interface CreateCLScheduleRequestData { // TODO: make conditional type
  items_ids: Array<string|number>
  date?: string
  time?: string
  time_zone?: string
  profile_id?: string | number
  urgent?: '1' | '0'
  type?: 'facebook' | 'craigslist'
  dealer_id?: number
}
export interface ScheduleT {
  id: number
  type: CreateCLScheduleRequestData['type'],
  date_UTC: string
  time_UTC: string
  time_zone: string
  status: CalendarEventStatus
  profile: ProfileI
  item: UnitType
  posting_id: null | string
  posting_url: null | string
}

export const DeleteCustomStatusOverlay = () => {
  const mutation = useMutation <AxiosResponse<ProfileI>, AxiosError<{ errors: CreateCustomStatusOverlayRequestData, message: string }>, { id: number }>(
    {
      mutationKey: [RequestKeys.DELETE_CUSTOM_STATUS],
      mutationFn: ({ id }) => SettingRequests.DeleteCustomStatusOverlay(id),
    },
  );
  return { ...mutation };
};

export const CreateFbProfileRequest = () => {
  const mutation = useMutation <AxiosResponse<ProfileI>, AxiosError<{ errors: CreateFbProfileRequestData, message: string }>, CreateFbProfileRequestData>(
    {
      mutationKey: [RequestKeys.CREATE_FB_PROFILE],
      mutationFn: (data: CreateFbProfileRequestData) => SettingRequests.CreateFbProfile(data),
    },
  );
  return { ...mutation };
};

export const CreateCLProfileRequest = () => {
  const mutation = useMutation <AxiosResponse<ProfileI>, AxiosError<{ errors: CreateCLProfileRequestData, message: string }>, CreateCLProfileRequestData>(
    {
      mutationKey: [RequestKeys.CREATE_CL_PROFILE],
      mutationFn: (data: CreateCLProfileRequestData) => SettingRequests.CreateCLProfile(data),
    },
  );
  return { ...mutation };
};

export const EditFbProfileRequest = () => {
  const mutation = useMutation <AxiosResponse<ProfileI>, AxiosError<{ errors: CreateFbProfileRequestData, message: string }>, { data: CreateFbProfileRequestData, id: number | string }>(
    {
      mutationKey: [RequestKeys.EDIT_FB_PROFILE],
      mutationFn: ({ data, id }) => SettingRequests.EditFbProfile(id, data),
    },
  );
  return { ...mutation };
};

export const EditCLProfileRequest = () => {
  const mutation = useMutation <AxiosResponse<ProfileI>, AxiosError<{ errors: CreateCLProfileRequestData, message: string }>, { data: CreateCLProfileRequestData, id: number | string }>(
    {
      mutationKey: [RequestKeys.EDIT_CL_PROFILE],
      mutationFn: ({ data, id }) => SettingRequests.EditCLProfile(id, data),
    },
  );
  return { ...mutation };
};

export const DeleteCLProfileRequest = () => {
  const mutation = useMutation <AxiosResponse<ProfileI>, AxiosError<{ errors: CreateCLProfileRequestData, message: string }>, { id: number }>(
    {
      mutationKey: [RequestKeys.DELETE_CL_PROFILE],
      mutationFn: ({ id }) => SettingRequests.DeleteCLProfile(id),
    },
  );
  return { ...mutation };
};

export const DeleteFbProfileRequest = () => {
  const mutation = useMutation <AxiosResponse<ProfileI>, AxiosError<{ errors: CreateFbProfileRequestData, message: string }>, { id: number }>(
    {
      mutationKey: [RequestKeys.DELETE_FB_PROFILE],
      mutationFn: ({ id }) => SettingRequests.DeleteFbProfile(id),
    },
  );
  return { ...mutation };
};

export const getTimezonesRequest = () => {
  const response = useQuery<AxiosResponse<string[]>>(
    {
      queryKey: [RequestKeys.GET_TIMEZONES],
      queryFn: SettingRequests.GetTimezones,
    },
  );
  return { responseData: response?.data?.data || [], ...response };
};

export const CreateCLScheduleRequest = () => {
  const mutation = useMutation <AxiosResponse<ScheduleT[]>, AxiosError<{ errors: CreateCLScheduleRequestData, message: string }>, CreateCLScheduleRequestData>(
    {
      mutationKey: [RequestKeys.CREATE_CL_SCHEDULE],
      mutationFn: (data: CreateCLScheduleRequestData) => SettingRequests.CreateCLSchedule(data),
    },
  );
  return { ...mutation };
};

export type GetCLSchedulesRequestParams = {
 dealer_id?: string | number
 profile_id?: string | number
 status?: CalendarEventStatus
 type?: 'facebook' | 'craigslist'
 date?: FixedLengthArr<string, 2>
 is_urgent?: '1' | '0'
}
export const getCLSchedulesRequest = (params: GetCLSchedulesRequestParams, enabled?: boolean) => {
  const response = useQuery<AxiosResponse<{data: ScheduleT[]}>>(
    {
      queryKey: [RequestKeys.GET_CL_SCHEDULES, params],
      queryFn: () => SettingRequests.GetCLSchedules(params),
      staleTime: 2000,
      enabled: !!enabled,
    },
  );
  return { responseData: response?.data?.data?.data || [], ...response };
};

type PaymentExist = {
  id: string
  type: string
  billing_details: {
    address: {
      city: null | string
      country: string
      line1: null | string
      line2: null | string
      postal_code: null | string
      state: null | string
    },
    email: null | string
    name: null | string
    phone: null | string
  },
  card: {
    brand: string
    country: string
    exp_month: number
    exp_year: number
    last4: string
  }
}
type PaymentMessage = {
  message: string
}

export type PaymentMethodT = PaymentExist & PaymentMessage

export const getPaymentMethodRequest = (params: {dealer_id?: string | number}, enabled?: boolean) => {
  const response = useQuery<AxiosResponse<PaymentMethodT>>(
    {
      queryKey: [RequestKeys.GET_PAYMENT_METHOD, params],
      queryFn: () => SettingRequests.GetPaymentMethod(params),
      enabled: !!enabled,
      staleTime: 2000,
    },
  );
  return { responseData: response?.data?.data, ...response };
};

export const setPaymentMethodRequest = () => {
  const mutation = useMutation <AxiosResponse<{ id: string, client_secret: string}>, AxiosError<{ errors: {message: string}, message: string }>, {dealer_id?: string | number}>(
    {
      mutationKey: [RequestKeys.SET_PAYMENT_METHOD],
      mutationFn: (data) => SettingRequests.setPaymentMethod(data),
    },
  );
  return { ...mutation };
};

export const getDealerSettingsRequest = ({ dealerId }: { dealerId?: string | number }) => {
  const response = useQuery<AxiosResponse<DealerSettingsResponse>>(
    {
      queryKey: [RequestKeys.GET_DEALER_SETTINGS, dealerId],
      queryFn: () => SettingRequests.GetDealerSettings(dealerId),
      staleTime: 2000,
      enabled: !!dealerId,
    },
  );
  return { responseData: response?.data?.data || {}, ...response };
};

export const setPlaceholderImageRequest = () => {
  const mutation = useMutation <AxiosResponse<{ id: string, client_secret: string}>, AxiosError<{ errors: {message: string}, message: string }>, {dealer_id: string | number, placeholder_image: UploadedFile | undefined}>(
    {
      mutationKey: [RequestKeys.SET_PAYMENT_METHOD],
      mutationFn: (data) => SettingRequests.UploadImagePlaceholder(data),
    },
  );
  return { ...mutation };
};

export const removePaymentMethodRequest = () => {
  const mutation = useMutation <AxiosResponse<{ id: string, client_secret: string}>, AxiosError<{ errors: {message: string}, message: string }>, {dealer_id?: string | number}>(
    {
      mutationKey: [RequestKeys.REMOVE_PAYMENT_METHOD],
      mutationFn: (data) => SettingRequests.removePaymentMethod(data),
    },
  );
  return { ...mutation };
};

export type CheckoutRequestData = {
  dealer_id: number | undefined,
  amount: number
}
export const checkoutRequest = () => {
  const mutation = useMutation <AxiosResponse<{ amount_added: number, current_balance: number}>, AxiosError<{ errors: {message: string}, message: string }>, CheckoutRequestData>(
    {
      mutationKey: [RequestKeys.CHECKOUT],
      mutationFn: (data) => SettingRequests.checkout(data),
    },
  );
  return { ...mutation };
};

export const getBalanceRequest = (params?: {dealer_id?: string | number}, enabled?: boolean) => {
  const response = useQuery<AxiosResponse<{ balance: number }>>(
    {
      queryKey: [RequestKeys.GE_BALANCE, params],
      queryFn: () => SettingRequests.GetBalance(params),
      enabled: !!enabled,
      staleTime: 2000,
    },
  );
  return { responseData: response?.data?.data, ...response };
};

export type SchedulesDeleteReqT = {
    ids?: number[]
    delete_all?: ScheduleT['type']
    dealer_id?: number | string
}
export const DeleteScheduleRequest = () => {
  const mutation = useMutation <AxiosResponse<ScheduleT>, AxiosError<{ errors: string, message: string }>, SchedulesDeleteReqT>(
    {
      mutationKey: [RequestKeys.DELETE_SCHEDULE],
      mutationFn: (data) => SettingRequests.DeleteLSchedule(data),
    },
  );
  return { ...mutation };
};

export type BillingTransactionsData = {
  id: string,
  amount: number,
  status: string,
  description: null | string,
  remaining_balance: null | string,
  failure_code: null | string,
  failure_message: null | string,
  receipt_url: string,
  created_at: string
}

export type BillingTransactionsResponse = {
  current_page: number,
  data: BillingTransactionsData[],
  first_page_url: string,
  from: number,
  last_page: number,
  last_page_url: string,
  links: {
    url: null | string,
    label: string,
    active: boolean
  }[],
  next_page_url: null | string,
  path: string,
  per_page: number,
  prev_page_url: null | string,
  to: number,
  total: number
}

export const getBillingTransactionsRequest = (params?: {dealer_id?: string | number, page?: string | number, limit?: string | number}) => {
  const response = useQuery<AxiosResponse<BillingTransactionsResponse>>(
    {
      queryKey: [RequestKeys.GET_BILLING_TRANSACTIONS, params],
      queryFn: () => SettingRequests.GetBillingTransactions(params),
      enabled: !!params?.dealer_id,
      staleTime: 2000,
    },
  );
  return { responseData: response?.data?.data?.data || [], ...response };
};

export type AutopostingData = {
    fb_autoposting_profile_id: number | string
    fb_autoposting: boolean
    dealer_id: number | string
    autopost_daily: number | string | undefined
    autopost_start_time?: number | string
    autopost_end_time?: number | string
    autopost_timezone?: string
}
export type ToggleAutopostingResponse = {
    code: number
    dealership_name: string
    fb_autoposting: boolean
    id: number
}
export const ToggleAutopostingRequest = () => {
  const mutation = useMutation <AxiosResponse<ToggleAutopostingResponse>, AxiosError<{ errors: string, message: string }>, AutopostingData>(
    {
      mutationKey: [RequestKeys.TOGGLE_AUTOPOSTING],
      mutationFn: (data: AutopostingData) => SettingRequests.ToggleAutoposting(data),
    },
  );
  return { ...mutation };
};
