import { FocusEventHandler, ReactNode, useId } from 'react';
import Select, {
  CSSObjectWithLabel, FormatOptionLabelMeta, IndicatorSeparatorProps, Options,
  InputActionMeta,
} from 'react-select';
import styled from 'styled-components';
import { ApplicationTheme } from '../../../globalStyles';
import { formSelectOptions } from '../../../helpers';
import { StyledError } from '../../StyledComponents';

export type SelectOption = {label: string | number, value: string | number, isDisabled?: boolean}
export interface SelectUnknownOption extends Partial<SelectOption>{
  [key: string]: unknown
}
export type SelectValue = SelectOption | null
interface CustomSelectProps {
name?: string
label?: string
className?: string
required?: boolean
placeholder?: string
options?: SelectUnknownOption[] | SelectOption[]
defaultValue?: SelectUnknownOption
onChange?: (val: SelectValue) => void
value?: SelectUnknownOption | string | null | number
error?: string
controlStyles?: CSSObjectWithLabel
indicatorContainerStyles?: CSSObjectWithLabel
singleValueStyles?: CSSObjectWithLabel
optionStyles?: CSSObjectWithLabel
valueContainerStyles?: CSSObjectWithLabel
placeholderStyles?: CSSObjectWithLabel
disabled?: boolean
isClearable?: boolean
ariaLabel?: string
isLoading?: boolean
formatOptionLabel?: (data: SelectUnknownOption, formatOptionLabelMeta: FormatOptionLabelMeta<SelectUnknownOption>) => ReactNode;
isOptionDisabled?: (data: SelectUnknownOption) => boolean;
isOptionSelected?: (option: SelectUnknownOption, selectValue: Options<SelectUnknownOption>) => boolean
onBlur?: FocusEventHandler<HTMLInputElement>
onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
noOptionsMessage?: (obj: {
  inputValue: string;
}) => ReactNode;
}

function DropdownIndicator({
  innerProps,
}: IndicatorSeparatorProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <StyledArrow {...innerProps} className="down" />;
}

const StyledArrow = styled.div`
    background-image: ${(props) => `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' viewBox='0 0 24 24' width='100%25' fill='${props.theme.primaryColor.replace('#', '%23')}' preserveAspectRatio='none'%3E%3Cpath d='M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41 0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z'/%3E%3C/svg%3E")`};
    width:  32px;
    height: 32px;
    margin-top: 4px;
    margin-right: 4px;
`;

function CustomSelect({
  options, defaultValue, name, label, className, required, placeholder, error, controlStyles, indicatorContainerStyles,
  singleValueStyles, placeholderStyles, valueContainerStyles, optionStyles, disabled, isClearable, value, ariaLabel, isOptionDisabled, ...props
}:CustomSelectProps) {
  const formValue = typeof value === 'string' ? formSelectOptions([value]) : value;
  const generatedId = useId();
  return (
    <InputContainer className={`select-container ${className}`}>
      {label && (
      <label htmlFor={name || generatedId}>
        {label}
        {required
            && <span className="required">*</span>}
      </label>
      )}
      <div className="select-wrap">
        <Select
          defaultValue={defaultValue}
          name={name}
          inputId={name || generatedId}
          value={formValue}
          placeholder={<div className="select-placeholder-text">{placeholder || 'Please Select'}</div>}
          components={{ DropdownIndicator }}
          aria-label={ariaLabel}
          isOptionSelected={props.isOptionSelected}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              text: 'black',
              primary25: ApplicationTheme.primaryColor,
              primary: 'inherit',
            },
          })}
          isDisabled={disabled}
          formatOptionLabel={props?.formatOptionLabel}
          isOptionDisabled={isOptionDisabled}
          menuPlacement="auto"
          styles={{
            container: (baseStyles) => ({
              ...baseStyles,
              width: '100%',
              height: '100%',
            }),
            control: (baseStyles) => ({
              ...baseStyles,
              borderColor: error ? ApplicationTheme.primaryColor : 'hsl(0,0%,76%)',
              outline: ApplicationTheme.primaryColor,
              minHeight: 'unset',
              height: '100%',
              textAlign: 'initial',
              borderWidth: '0.5px',
              borderRadius: '4px',
              transition: 'all ease 0.2s',
              ...controlStyles,
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              display: 'none',
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              color: ApplicationTheme.primaryColor,
              marginRight: '10px',
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              zIndex: 10,
            }),
            menuList: (baseStyles) => ({
              ...baseStyles,
              padding: 0,
            }),
            option: (baseStyles, state) => {
              const data = state.data as SelectUnknownOption;
              const isDisabled = 'isDisabled' in data && data.isDisabled;
              const defaultColor = state.isSelected ? ApplicationTheme.primaryColor : '#232323';
              const selectedColor = isDisabled ? 'grey' : (defaultColor);
              const cursor = isDisabled ? 'not-allowed' : 'default';

              let hoverColor = ApplicationTheme.primaryColor;

              if (state.isSelected) {
                hoverColor = 'fff';
              } else if (state.isDisabled) {
                hoverColor = '#CCCCCC';
              }

              return {
                ...baseStyles,
                selectedColor,
                cursor,
                background: state.isSelected ? ApplicationTheme.primaryColor : '#ececec',
                fontWeight: 'normal',
                textAlign: 'start',
                '&:hover': {
                  color: hoverColor,
                },
                ...optionStyles,
              };
            },
            placeholder: (baseStyles) => ({
              ...baseStyles,
              fontSize: '12px',
              ...placeholderStyles,
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              marginTop: '-5px',
              position: 'relative',
              svg: {
                position: 'absolute',
                top: '0',
                bottom: '0',
                margin: 'auto 0',
                marginTop: '10px',
                right: '35px',
              },
              ...indicatorContainerStyles,
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              fontWeight: 'normal',
              color: 'red',
              paddingLeft: '18px',
              ...valueContainerStyles,
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              ...singleValueStyles,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              // height: '34px',
            }),
          }}
          options={options}
          isClearable={isClearable}
          {...props}
        />
        {error
          && <StyledError className="error">{error}</StyledError>}
      </div>
    </InputContainer>
  );
}

export default CustomSelect;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  label {
    font-weight: bold;
    font-size: 14px;
    color:${(props) => props.theme.primaryTextColor};
    margin-right: 12px; 
  }
  .required {
    color: ${(props) => props.theme.primaryColor}
  }
  &.input-error {
    border-color: ${(props) => props.theme.primaryColor};
    background-color: green;
  }
  .select-wrap {
    width: 100%;
  }
`;
