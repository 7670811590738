import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useNavigateHook from '../../hooks/useNavigateHook';
import MainLayoutHeader from '../../pages/InventoryManager/Header';
import Pages from '../../Router/pages';
import SideBar, { SidebarItem } from '../SideBar';

interface MainLayoutProps {
    headerTitle: string
    children: JSX.Element
    navigateOnBack: string | number
    sideBarItems?: {
        label: string
        key: string
        page: string
    }[]
    activeSectionProp?: string
}

enum SettingSections {
    Locations = 'Locations',
    Users = 'Users',
    'Overlay & Custom Status' = 'Overlay & Custom Status',
    'CSV File' = 'CSV File',
    'Integrations' = 'Integrations',
    'Image Placeholder' = 'Image Placeholder',
}

function SettingsLayout({
  children, navigateOnBack, sideBarItems, headerTitle, activeSectionProp,
}: MainLayoutProps) {
  const sections = [
    { label: SettingSections.Locations, key: 'location', hidden: false },
    { label: SettingSections.Users, key: 'users', hidden: false },
    { label: SettingSections['Overlay & Custom Status'], key: 'overlay', hidden: false },
    { label: SettingSections['CSV File'], key: 'csv', hidden: false },
    { label: SettingSections.Integrations, key: 'integrations', hidden: false },
    { label: SettingSections['Image Placeholder'], key: 'image-placeholder', hidden: false },
  ] as const;
    interface SettingsSideBar extends SidebarItem {
        label: typeof sections[number]['label']
        key: typeof sections[number]['key']
        hidden: boolean
        page: string
    }
    const navigate = useNavigateHook();
    const [activeSection, setActiveSection] = useState<typeof sections[number]['key']>(sections.find((item) => window.location.pathname.includes(item.key))?.key || 'location');
    const handleSidebarItemClick = (item: SettingsSideBar) => {
      navigate(`${Pages.settings}/${item.key}`);
      setActiveSection(item.key);
    };
    const defaultSideBarItems: SettingsSideBar[] = sections.map((section) => ({
      label: section.label, onClick: handleSidebarItemClick, key: section.key, hidden: section.hidden, page: Pages.settings,
    }));
    useEffect(() => {
      const page = defaultSideBarItems.find((item) => window.location.pathname.includes(item.key))?.key;
      if (page) {
        setActiveSection(page);
      }
    }, [window.location.pathname]);

    return (
      <div>
        <MainLayoutHeader title={headerTitle} />
        <SettingsContent>
          <SideBar navigateOnBack={navigateOnBack} items={sideBarItems || defaultSideBarItems} activeSection={activeSectionProp || activeSection} />
          <div className="content">
            <StyledContent>
              {children}
            </StyledContent>
          </div>
        </SettingsContent>
      </div>
    );
}

export default SettingsLayout;

const SettingsContent = styled.div`
  display: flex;
  background-color: #fff;
 
  .content {
    background-color: ${(props) => props.theme.primaryBGColor};
    width: 100%;
  }
  .table-wrapper {
    #table-wrapper {
      margin-left: 0;
      margin-top: 25px;
    }
  }
`;

const StyledContent = styled.div`
  margin-top: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding-top: 18px;
    padding-left: 51px;
    padding-right: 40px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    .title {
      color: ${(props) => props.theme.primaryColor};
      font-size: 25px;
      font-weight: bold;
    }

    .button {
      margin-top: 13px;
      margin-right: 2px;
      width: 100px;
    }
  }
`;
