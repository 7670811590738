import React from 'react';
import { Link } from 'react-router-dom';
import useResize from '../../hooks';

export type CustomPaginationProps = {
    limit: number
    activePage: number
    total: number
    totalPages: number
    maxButtons: number
    onChangePage: (newPage: number) => void
}

function CustomPagination({
  limit, activePage, total, onChangePage, totalPages, maxButtons,
}: CustomPaginationProps) {
  const { isMobile } = useResize();

  const handleNext = () => {
    const nextPage = activePage + 1;
    onChangePage(nextPage);
  };
  const handlePrev = () => {
    const prevPage = activePage - 1;
    onChangePage(prevPage);
  };

  const pagesList = Array.from({ length: totalPages || (Math.round(total / limit)) }, (_, i) => i + 1)
    .slice(activePage - maxButtons <= 0 ? 0 : activePage - (isMobile ? 2 : 3), activePage - maxButtons <= 0 ? activePage + maxButtons : activePage + maxButtons);
  return (
    <div className="rs-pagination-group rs-pagination-group-xs">
      <div className="rs-pagination rs-pagination-xs">
        <button
          aria-label="Previous"
          title="Previous"
          className={`rs-pagination-btn ${activePage === 1 && 'rs-pagination-btn-disabled'}`}
          disabled={activePage === 1}
          type="button"
          onClick={handlePrev}
        >
          <span
            className="rs-pagination-symbol"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 7 14"
              fill="currentColor"
              aria-hidden="true"
              focusable="false"
              className="rs-icon"
              aria-label="page previous"
              data-category="legacy"
            >
              <path
                d="M2.333 7l3.5-4-.583-.667L1.167 7l4.083 4.667.583-.667z"
              />
            </svg>
          </span>
          <span className="rs-ripple-pond">
            <span className="rs-ripple" />
          </span>
        </button>
        {pagesList.map((page) => (
          <Link to={`?page=${page}`} key={page} className={`rs-pagination-btn ${activePage === page && 'rs-pagination-btn-active'}`} onClick={() => onChangePage(page)}>
            {page}
            <span className="rs-ripple-pond">
              <span className="rs-ripple" />
            </span>
          </Link>
        ))}
        <button
          aria-label="Next"
          title="Next"
          type="button"
          onClick={handleNext}
          disabled={activePage === totalPages}
          className={`rs-pagination-btn ${activePage === totalPages && 'rs-pagination-btn-disabled'}`}
        >
          <span
            className="rs-pagination-symbol"
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 7 14"
              fill="currentColor"
              aria-hidden="true"
              focusable="false"
              className="rs-icon"
              aria-label="page next"
              data-category="legacy"
            >
              <path
                d="M4.667 7l-3.5 4 .583.667L5.833 7 1.75 2.333 1.167 3z"
              />
            </svg>
          </span>
          <span
            className="rs-ripple-pond"
          >
            <span className="rs-ripple" />
          </span>
        </button>
      </div>
    </div>
  );
}

export default CustomPagination;
