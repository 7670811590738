import { useEffect, useState } from 'react';
import { Toggle } from 'rsuite';
import styled from 'styled-components';
import CustomButton from '../../../../components/Buttons/CustomButton';
import CustomInput from '../../../../components/inputs/CustomInput';
import { ApplicationTheme } from '../../../../globalStyles';
import { ChangedTableItemType } from '../index';

type UsernameCellProps = {
    onChange: (val: string) => void
    setEditMode: () => void
    editMode: boolean
    item: ChangedTableItemType
    error?: string
}
export function UsernameCell({
  item, onChange, editMode, setEditMode, error,
}: UsernameCellProps) {
  const [email, setEmail] = useState<string>(item.email || '');
  useEffect(() => {
    onChange(email);
  }, [email]);
  useEffect(() => {
    setEmail(item.email);
  }, [item.email]);
  return (
    <StyledNameCell>
      {editMode
        ? (
          <div className="input-wrap">
            <CustomInput
              value={email}
              onChange={setEmail}
              error={error}
            />
          </div>
        )
        : <div>{item.email}</div>}
      {!editMode
                && (
                <span className="icon" onClick={() => setEditMode()}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 24 24" width="100%" preserveAspectRatio="none" fill={ApplicationTheme.primaryColor}>
                    <path d="M3 17.46v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.81 9.94l-3.75-3.75L3.15 17.1c-.1.1-.15.22-.15.36zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
                  </svg>
                </span>
                )}
    </StyledNameCell>
  );
}

type AccountCellProps = {
    onChange: (val: boolean) => void
    item: ChangedTableItemType
    disabled: boolean
}
export function AccountCell({ item, onChange, disabled }: AccountCellProps) {
  const [active, setActive] = useState<boolean>(!!item?.role.includes('dealer-admin'));
  return (
    <div>
      <Toggle
        arial-label="Switch"
        checked={active}
        disabled={disabled}
        onChange={(val: boolean) => {
          setActive(val);
          onChange(val);
        }}
      />
    </div>
  );
}
export function BillingCell({ item, onChange, disabled }: AccountCellProps) {
  const [active, setActive] = useState<boolean>(!!item?.role.includes('access-billing'));
  return (
    <div>
      <Toggle
        arial-label="Switch"
        checked={active}
        disabled={disabled}
        onChange={(val: boolean) => {
          setActive(val);
          onChange(val);
        }}
      />
    </div>
  );
}
export function ScheduleCell({ item, onChange, disabled }: AccountCellProps) {
  const [active, setActive] = useState<boolean>(!!item?.role.includes('access-scheduler'));
  return (
    <div>
      <Toggle
        arial-label="Switch"
        checked={active}
        disabled={disabled}
        onChange={(val: boolean) => {
          setActive(val);
          onChange(val);
        }}
      />
    </div>
  );
}
type PasswordCell = {
    tableItem: ChangedTableItemType
    onChange: (val: string) => void
    error?: string
}
export function PasswordCell({ tableItem, onChange, error }: PasswordCell) {
  const formError = (tableItem?.password !== tableItem?.password_confirmation)
    ? 'Passwords must match' : '';
  const [passVal, setPassVal] = useState<string>(tableItem?.password || '');
  useEffect(() => {
    onChange(passVal);
  }, [passVal]);
  return (
    <StyledPasswordCell>
      <CustomInput
        type="password"
        value={passVal}
        onChange={(val) => setPassVal(val)}
        error={formError || error}
      />
    </StyledPasswordCell>
  );
}
export function ConfirmPasswordCell({ tableItem, onChange, error }: PasswordCell) {
  const formError = (tableItem?.password !== tableItem?.password_confirmation)
    ? 'Passwords must match' : '';
  const [passVal, setPassVal] = useState<string>(tableItem?.password_confirmation || '');
  useEffect(() => {
    onChange(passVal);
  }, [passVal]);
  return (
    <StyledPasswordCell>
      <CustomInput
        type="password"
        value={tableItem?.password_confirmation || ''}
        onChange={(val) => setPassVal(val)}
        error={formError || error}
      />
    </StyledPasswordCell>
  );
}

type SaveButtonCell = {
    onSave: () => void
}
export function SaveButtonCell({ onSave }: SaveButtonCell) {
  return (
    <StyledSaveCell>
      <CustomButton variant="primary" onClick={onSave}>
        Save
      </CustomButton>
    </StyledSaveCell>
  );
}

const StyledSaveCell = styled.div`
    width: 100px;
`;
const StyledNameCell = styled.div`
  display: flex;
  .input-wrap {
    max-width: 193px;
    input {
      height: 34px;
    }
  }
.icon {
  cursor: pointer;
  margin-left: 8px;
  border: 1px solid ${(props) => props.theme.primaryColor};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  svg{
    height: 15px;
    width: 15px;  
  }
  
}
`;

const StyledPasswordCell = styled.div`
input {
  height: 34px;
}
`;
