import { CustomInputProps } from '../../../../components/inputs/CustomInput';
import { SelectUnknownOption } from '../../../../components/inputs/CustomSelect';
import { arrayRange, formSelectOptions } from '../../../../helpers';
import { stockInputPattern } from '../../../../helpers/regexPatterns';
import { Inventories } from '../../../../store/unit/actions';

const conditionOptions = formSelectOptions([
  'New',
  'Used',
  'Re-manufactured',
  'Consignment',
]);

export const statusOptions = formSelectOptions([
  'Available',
  'Sold',
  'On Order',
  'Pending Sale',
  'Special Order',
]);

const yearOptions = formSelectOptions(arrayRange(1920, new Date().getFullYear() + 2, 1));

type InputTypes = 'select' | 'select-with-button' | 'input' | 'building-title'
export type InventoryTypeInputT = {
  label: string,
  required: boolean,
  type: InputTypes,
  options?: SelectUnknownOption[]
  pattern?: CustomInputProps['pattern']
  key:
      'title'
      | 'stock'
      | 'condition'
      | 'autopopulate'
      | 'year'
      | 'manufacturer'
      | 'status'
      | 'dealership_location'
      | 'vin'
      | 'model_name'
      | 'item_category'
      | 'brand'
      | 'chassis_year'
}

type InputsByInventoryType = {
  [key in Inventories]: InventoryTypeInputT[]
}

const statusInput: InventoryTypeInputT = {
  key: 'status', label: 'Status', required: true, type: 'select', options: statusOptions,
};
const stockInput: InventoryTypeInputT = {
  key: 'stock',
  label: 'Stock #',
  required: true,
  type: 'input',
  pattern: stockInputPattern,
};
const dealershipLocationInput: InventoryTypeInputT = {
  key: 'dealership_location', label: 'Location', required: true, type: 'select',
};
const conditionInput: InventoryTypeInputT = {
  key: 'condition', label: 'Condition', required: true, type: 'select', options: conditionOptions,
};
const vinInput: InventoryTypeInputT = {
  key: 'vin', label: 'VIN', required: false, type: 'input',
};
const autopopulateInput: InventoryTypeInputT = {
  key: 'autopopulate', label: 'Autopopulate', required: false, type: 'select-with-button',
};
const yearInput: InventoryTypeInputT = {
  key: 'year', label: 'Year', required: true, type: 'select', options: yearOptions,
};
const modelNameInput: InventoryTypeInputT = {
  key: 'model_name', label: 'Model Name', required: true, type: 'input', options: formSelectOptions(['Placeholder', 'Option']),
};
const manufacturerInput: InventoryTypeInputT = {
  key: 'manufacturer', label: 'Manufacturer', required: true, type: 'select', options: formSelectOptions(['Placeholder', 'Option']),
};
const categoryInput: InventoryTypeInputT = {
  key: 'item_category', label: 'Category', required: true, type: 'select', options: [],
};
export const inputsByInventoryType: InputsByInventoryType = {
  Building: [
    statusInput,
    stockInput,
    dealershipLocationInput,
    conditionInput,
    vinInput,
    autopopulateInput,
    yearInput,
    modelNameInput,
    manufacturerInput,
    categoryInput,
    {
      key: 'title', label: 'Building Title', required: true, type: 'building-title',
    },
  ],
  Equipment: [
    statusInput,
    stockInput,
    dealershipLocationInput,
    conditionInput,
    vinInput,
    autopopulateInput,
    yearInput,
    modelNameInput,
    manufacturerInput,
    categoryInput,
    {
      key: 'title', label: 'Equipment Title', required: true, type: 'building-title',
    },
  ],
  'Horse Trailer': [
    statusInput,
    stockInput,
    dealershipLocationInput,
    conditionInput,
    vinInput,
    autopopulateInput,
    yearInput,
    modelNameInput,
    manufacturerInput,
    categoryInput,
    {
      key: 'title', label: 'Horse Trailer Title', required: true, type: 'building-title',
    },
  ],
  'Recreational Vehicle': [
    statusInput,
    stockInput,
    dealershipLocationInput,
    conditionInput,
    vinInput,
    autopopulateInput,
    yearInput,
    modelNameInput,
    manufacturerInput,
    categoryInput,
    {
      key: 'chassis_year', label: 'Chassis Year', required: true, type: 'select', options: yearOptions,
    },
    {
      key: 'title', label: 'Recreational Vehicles Title', required: true, type: 'building-title',
    },
  ],
  'Semi-Trailer': [
    statusInput,
    stockInput,
    dealershipLocationInput,
    conditionInput,
    vinInput,
    autopopulateInput,
    yearInput,
    modelNameInput,
    manufacturerInput,
    categoryInput,
    {
      key: 'title', label: 'Semi-Trailer Title', required: true, type: 'building-title',
    },
  ],
  'Semi-Truck': [
    statusInput,
    stockInput,
    dealershipLocationInput,
    conditionInput,
    vinInput,
    autopopulateInput,
    yearInput,
    modelNameInput,
    manufacturerInput,
    categoryInput,
    {
      key: 'title', label: 'Semi-Truck Title', required: true, type: 'building-title',
    },
  ],
  'Sports Vehicle': [
    statusInput,
    stockInput,
    dealershipLocationInput,
    conditionInput,
    vinInput,
    autopopulateInput,
    yearInput,
    modelNameInput,
    manufacturerInput,
    categoryInput,
    {
      key: 'title', label: 'Sports Vehicle Title', required: true, type: 'building-title',
    },
  ],
  'Trailer or Truck Bed': [
    statusInput,
    stockInput,
    dealershipLocationInput,
    conditionInput,
    vinInput,
    autopopulateInput,
    yearInput,
    modelNameInput,
    manufacturerInput,
    categoryInput,
    {
      key: 'title', label: 'Trailer or Truck Bed Title', required: true, type: 'building-title',
    },
  ],
  Watercraft: [
    statusInput,
    stockInput,
    dealershipLocationInput,
    conditionInput,
    vinInput,
    autopopulateInput,
    yearInput,
    modelNameInput,
    manufacturerInput,
    categoryInput,
    {
      key: 'brand', label: 'Brand', required: false, type: 'select', options: formSelectOptions(['Placeholder', 'Option']),
    },
    {
      key: 'title', label: 'Watercraft Title', required: true, type: 'building-title',
    },
  ],
  Vehicle: [
    statusInput,
    stockInput,
    dealershipLocationInput,
    conditionInput,
    vinInput,
    autopopulateInput,
    yearInput,
    modelNameInput,
    manufacturerInput,
    categoryInput,
    {
      key: 'title', label: 'Vehicle Title', required: true, type: 'building-title',
    },
  ],
};
