import { useFormik } from 'formik';
import styled from 'styled-components';
import CustomButton from '../../components/Buttons/CustomButton';
import CustomInput from '../../components/inputs/CustomInput';
import loginFormSchema from '../../validation/loginFormSchema';

export interface LoginFormData {
    email: string
    password: string
}

interface LoginFormProps {
    handleSubmit: (data: LoginFormData) => void
    handleForgotPassword?: (data: LoginFormData) => void
    isAdminLogin?: boolean
    loading: boolean
    error?: {
        email?: string
        password?: string
    }
}

function LoginForm({
  handleSubmit, handleForgotPassword, loading, error, isAdminLogin,
}: LoginFormProps) {
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginFormSchema,
    onSubmit: handleSubmit,
  });
  const {
    setFieldValue, values: formikValues, errors, touched, setErrors,
  } = formik;

  const emailError = errors.email ? errors.email : '';
  const passwordError = errors.password && touched.password ? errors.password : '';

  const onForgetPasswordClick = () => {
    if (!formikValues.email) {
      setErrors({ email: 'Please type email' });
    } else {
      handleForgotPassword?.(formikValues);
    }
  };
  return (
    <form onSubmit={(event) => {
      event.preventDefault();
      formik.submitForm();
    }}
    >
      <InputsContainer>
        <CustomInput
          value={formikValues.email}
          placeholder="Email"
          name="email"
          onChange={(val) => setFieldValue('email', val)}
          wrapperClass="input-wrapper"
          error={emailError || error?.email}
        />
        <CustomInput
          placeholder="Password"
          type="password"
          name="password"
          value={formikValues.password}
          onChange={(val) => setFieldValue('password', val)}
          wrapperClass="input-wrapper"
          error={passwordError || error?.password}
        />
        {!isAdminLogin
        && <CustomButton variant="text" onClick={onForgetPasswordClick} type="button" className="forgot-btn">forgot password?</CustomButton>}
        <PrimaryButtonWrapper>
          <CustomButton type="submit" disabled={loading}>Login</CustomButton>
        </PrimaryButtonWrapper>
      </InputsContainer>
    </form>
  );
}

export default LoginForm;

const InputsContainer = styled.div`
display: flex;   
flex-direction: column;
  margin-top: 34px;
  .input-wrapper {
    width: 283px;
    margin-top: 30px;
  }
  input {
    width: 100%;
  }
  button {
    margin-top: 8px;
    min-width: 14rem;
    &.primary {
    margin-top: 30px;
    }
    &.text {
    margin-top: 30px;
    }
  }
`;

const PrimaryButtonWrapper = styled.div`
  button {
    height: 44px;
    width: 150px;
    min-width: unset;
    font-size: 16px; 
  }
`;
