import CopyIcon from '@rsuite/icons/Copy';
import UnvisibleIcon from '@rsuite/icons/Unvisible';
import VisibleIcon from '@rsuite/icons/Visible';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Whisper from 'rsuite/esm/Whisper';
import Tooltip from 'rsuite/esm/Tooltip';
import { useAppMessageContext } from '../../appMessagesContext';
import BackSvg from '../../assets/back-arrow.png';
import CustomButton from '../../components/Buttons/CustomButton';
import CustomTable from '../../components/CustomTable';
import DeleteModal from '../../components/DeleteModal';
import BinSvg from '../../components/SvgElements/BinSvg';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import {
  DeleteProviderRequest, ProvidersT, GetProviders,
} from '../../store/dealers/actions';
import squareEdit from '../../assets/square-with-pencil.png';

export interface ProvidersTableData extends ProvidersT {
  actions: string
}

type ProvidersTableColumns = {
  label: string
  key: keyof Pick<ProvidersT, 'id' | 'name' | 'email' | 'created_at' | 'token'> | 'action' | 'item_id'
  width?: number
}

function Providers() {
  const navigate = useNavigateHook();
  const params = useParams<{id: string}>();
  const { showMessage } = useAppMessageContext();
  const [showModal, setShowModal] = useState<{id: number} | boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const deleteProviderReq = DeleteProviderRequest();

  const { data: providersData, refetch: refetchProviders, isFetching } = GetProviders({ dealer_id: params.id });

  const handleCreateProvider = () => {
    navigate(`${Pages.providerCreation}?dealer_id=${params.id}`);
  };

  const modalText = () => (
    <div>
      <h3>
        Permanently Delete
      </h3>
      <div className="text">
        Are you sure you would like
        <br />
        to Permanently Delete this
        <br />
        Provider?
      </div>
    </div>
  );
  useEffect(() => {
    if (deleteProviderReq.isSuccess) {
      refetchProviders();
      deleteProviderReq.reset();
      showMessage({ type: 'success', message: 'Provider Deleted successfully' });
    }
  }, [deleteProviderReq.isSuccess]);

  const handleModalAccept = () => {
    if (typeof showModal === 'object') {
      deleteProviderReq.mutate({ id: showModal?.id });
    }
    setShowModal(false);
  };

  return (
    <Container>
      <div className="head">
        <div className="title">
          Providers
        </div>
        <div className="control">
          <div className="back-btn">
            <button type="button" className="back" onClick={() => navigate(Pages.dealersPage)}>
              <img src={BackSvg} alt="back" />
              <span>Back</span>
            </button>
          </div>
          <div className="buttons-container">
            <div className="create-button">
              <CustomButton type="submit" onClick={handleCreateProvider}>Create Provider</CustomButton>
            </div>
          </div>
        </div>
      </div>
      <div className="table-wrap">
        <CustomTable
          loading={isFetching}
          data={providersData.map((dataRow: ProvidersTableData) => ({
            ...dataRow,
            created_at: <CreatedAt item={dataRow} />,
            token: <TokenCell item={dataRow} />,
            action: <ActionsCell
              onEditClick={() => navigate(`${Pages.providerCreation}/${dataRow.id}?dealer_id=${params.id}`)}
              onRemoveClick={() => setShowModal({ id: dataRow.id })}
            />,
          }))}
          columns={columns}
          onItemSelect={(selected) => setSelectedItems(selected)}
          selectable={false}
          hideSelectHeader
          displayTotal={false}
          fillHeight={false}
          autoHeight
          fullHeightContainer={false}
          displayPagination={false}
          checkedKeysProp={selectedItems}
        />
      </div>
      <DeleteModal
        openObj={showModal}
        onApproveClose={handleModalAccept}
        onClose={() => setShowModal(false)}
        mainContent={modalText()}
      />
    </Container>
  );
}

export default Providers;

function CreatedAt({ item }: {item: ProvidersTableData}) {
  const today = new Date(item.created_at);
  const formatedDate = today.toLocaleDateString('en-US', { day: 'numeric', month: 'numeric', year: '2-digit' });
  return (
    <div>
      {formatedDate}
    </div>
  );
}
function TokenCell({ item }: {item: ProvidersTableData}) {
  const [hidden, setHidden] = useState<boolean>(true);
  return (
    <StyledTokenCell>
      <div>
        <button type="button" onClick={() => setHidden(!hidden)}>
          {hidden ? <VisibleIcon /> : <UnvisibleIcon />}
        </button>
        {hidden ? item.token.replace(/./g, '*') : item.token}
      </div>
      <Whisper
        controlId="control-id-container"
        preventOverflow
        trigger="click"
        speaker={(
          <Tooltip style={{ width: 120 }}>
            Token copied
          </Tooltip>
          )}
        placement="top"
      >
        <button type="button" onClick={() => navigator.clipboard.writeText(item.token)}>
          <CopyIcon />
        </button>
      </Whisper>

    </StyledTokenCell>
  );
}
const StyledTokenCell = styled.div`
    display: flex;
  
`;
type ActionsCellProps = {
  onEditClick: () => void
  onRemoveClick: () => void
}
function ActionsCell({
  onRemoveClick, onEditClick,
}: ActionsCellProps) {
  return (
    <StyledActionCell>
      <button type="button" onClick={onEditClick}>
        <img src={squareEdit} alt="edit" />
      </button>
      <button type="button" className="remove-bin" onClick={onRemoveClick}>
        <BinSvg fill="#747474" stroke="#747474" />
      </button>
    </StyledActionCell>
  );
}

const StyledActionCell = styled.div`
  display: flex;
  align-items: center;
  .dots {
    margin-top: 12px;
    > div {
      width: 24px;
    }
   }
  .remove-bin {
    height: 20px;
    margin-top: 5px;
  }
  .feed-update {
    margin-top: 5px;
  }
`;

const columns: ProvidersTableColumns[] = [
  {
    label: 'ID',
    key: 'id',
    width: 50,
  },
  {
    label: 'NAME',
    key: 'name',
  },
  {
    label: 'EMAIL',
    key: 'email',
  },
  {
    label: 'TOKEN',
    key: 'token',
    width: 350,
  },
  {
    label: 'CREATED DATE',
    key: 'created_at',
  },
  {
    label: '',
    key: 'action',
    width: 150,
  },
];
const Container = styled.div`
  padding-top: 33px;
    padding-right: 39px;
  .head {
    .title {
      color: ${(props) => props.theme.secondaryTextColor};
      font-size: 20px;
      font-weight: bold;
    }
    .control {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      .buttons-container {
        display: flex;
        .remove-btn-container {
          margin-right: 20px;
        }
      }
    }
    .create-button {
      .primary {
        text-transform: unset;
        border-radius: 4px;
      }
    }
  }
   .table-wrap {
     margin-top: 24px;
     background-color: #fff;
     border-radius: 8px;
     box-shadow: 0 5px 5px rgba(0, 0, 0, 0.29);

     .table-header {
       padding: 8px;
       display: flex;
       justify-content: space-between; 
       padding-right: 20px;
       .arrow {
         background-image: url("data:image/svg+xml,%3Csvg width='24' fill='%23747474' height='24' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath stroke='null' id='svg_1' d='m1.1752,9.02285l8.56872,13.25122c1.29027,1.99536 3.37455,1.99536 4.66483,0l8.56872,-13.25122c2.08428,-3.22327 0.59551,-8.74887 -2.34895,-8.74887l-17.13744,0c-2.94446,0 -4.40015,5.5256 -2.31587,8.74887z'/%3E%3C/g%3E%3C/svg%3E");
         height: 15px;
         width: 16px;
         background-size: 6px 5px;
         background-repeat: no-repeat;
         background-position: 3px 5px;
         margin-left: 2px;
       }
       .header-checkbox {
         display: flex;
         align-items: center;
         .rs-checkbox-checker {
           padding: 14px;
         }
       }
       .header-filters {
         display: flex;
         align-items: center;
         cursor: pointer;
         img {
           width: 24px;
           height: 24px;
         }
       }
     }
     .rs-table-row-header {
       * {
         background-color: #f6f6f6;
       }
       .rs-table-cell-content {
         color: ${(props) => props.theme.secondaryTextColor};
       }
     }
     #table-wrapper {
         margin: 0;
         color: ${(props) => props.theme.secondaryTextColor};
       .rs-table-cell {
         border-bottom: 1px solid #c1c1c1;
         }
       }
     .table-footer {
       padding: 8px;
       display: flex;
       justify-content: space-between;
       color: ${(props) => props.theme.secondaryTextColor};
       font-size: 14px;
       button {
         color: #c1c1c1;
         font-weight: bold;
       }
     }
     .id-cell {
       color: ${(props) => props.theme.primaryColor};
     }
   }
`;
