import { useEffect } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppMessageContext } from '../../appMessagesContext';
import logoBig from '../../assets/logoBig.png';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import { ResetPasswordRequest } from '../../store/auth/actions';
import { RequestKeys } from '../../store/keys';
import ResetPasswordForm, { ResetPasswordFormT } from './resetPasswordForm';

function resetPassword() {
  const params = useParams<{token: string, email: string}>();
  const navigate = useNavigateHook();
  const { showMessage } = useAppMessageContext();
  const resetPass = ResetPasswordRequest();
  const handleSubmit = (data: ResetPasswordFormT) => {
    resetPass.mutate({
      ...data,
      email: params?.email || '',
      token: params?.token || '',
    });
  };
  const errors: UseQueryResult<{ message: string; }> = useQuery({ queryKey: [RequestKeys.LOGIN_ERROR] });

  useEffect(() => {
    if (resetPass.isSuccess) {
      showMessage({ type: 'success', message: 'Password changed successfully' });
      navigate(Pages.login);
    }
    if (resetPass.isError) {
      showMessage({ type: 'error', message: resetPass?.error?.response?.data?.message || 'Something went wrong' });
    }
  }, [resetPass]);

  return (
    <Container>
      <img src={logoBig} alt="logo" />
      <ResetPasswordForm handleSubmit={handleSubmit} loading={resetPass.isPending} error={errors?.data?.message} />
    </Container>
  );
}

export default resetPassword;

const Container = styled.div`
img {
  max-width: 217px;
}
`;
