import { useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { useToaster } from 'rsuite';
import { ThemeProvider } from 'styled-components';
import { QueryClient } from '@tanstack/react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { AppMessageContext, AppMessageContextType, AppMessageType } from './appMessagesContext';
import SystemMessage from './components/SystemMessage';
import GlobalStyle, { ApplicationTheme } from './globalStyles';
import RoutesComponent from './Router';
import { StoreInitialization } from './store';

export const queryClient = new QueryClient();

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

function App() {
  const toaster = useToaster();
  const showMessage = ({ placement = 'topEnd', ...props }: AppMessageType) => {
    const message = SystemMessage;
    toaster.push((message({ ...props })), { placement });
  };

  const workInProgress = () => {
    showMessage({ type: 'info', message: 'Work In progress' });
  };
  const appMessageContextInitialValue: AppMessageContextType = useMemo(() => ({
    showMessage,
    workInProgress,
  }), []);
  StoreInitialization({ showMessage });

  return (
    <div className="App">
      <ThemeProvider theme={ApplicationTheme}>
        <GlobalStyle />
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{ persister }}
        >
          <Router>
            <AppMessageContext.Provider value={appMessageContextInitialValue}>
              <RoutesComponent />
            </AppMessageContext.Provider>
          </Router>
        </PersistQueryClientProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
