import styled from 'styled-components';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import Logo from '../../assets/Logo.png';
import { RegistrationRequest } from '../../store/auth/actions';
import { RequestKeys } from '../../store/keys';
import RegistrationForm, { RegistrationFormData } from './registrationForm';

export type RegistrationErrorsType = {
  email: string
  name: string
  password: string
  password_confirmation: string
}

function Registration() {
  const registrationReq = RegistrationRequest();
  const handleSubmit = (data: RegistrationFormData) => {
    registrationReq.mutate(data);
  };

  const errors: UseQueryResult<{errors: RegistrationErrorsType}> = useQuery({ queryKey: [RequestKeys.REGISTRATION_ERROR] });

  return (
    <StyledRegistrationPage>
      <img src={Logo} alt="logo" />
      <h1>Create an Account</h1>
      <RegistrationForm handleSubmit={handleSubmit} loading={registrationReq.isPending} errorsProps={errors?.data?.errors} />
    </StyledRegistrationPage>
  );
}

export default Registration;

const StyledRegistrationPage = styled.div`
  h1 {
    margin-top: 43px;
    font-weight: normal;
    font-size: 25px;
    line-height: inherit;
    color: #232323;
  }
`;
