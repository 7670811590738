import { ChangeEvent, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

export interface ControlledNumericInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>{
    label?: string
    wrapperClass?: string
    onChange?: (value: string) => void
    value?: string | number
    maxVal?: number
    minVal?: number
}
function ControlledNumericInput({
  label, wrapperClass, onChange, value, maxVal = 100, minVal = 0,
}: ControlledNumericInputProps) {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.validity.valid) {
      event.preventDefault();
      event.stopPropagation();
    } else if (Number(event.target.value) > maxVal) {
      onChange?.('100');
    } else {
      onChange?.(event.target.value);
    }
  };

  const handleDecrease = () => {
    const newVal = Number(value) - 1;
    if (newVal >= minVal) {
      onChange?.(String(newVal));
    }
  };
  const handleIncrease = () => {
    const newVal = Number(value) + 1;
    if (newVal <= maxVal) {
      onChange?.(String(newVal));
    }
  };
  return (
    <StyledContainer className={wrapperClass}>
      <label htmlFor="size">{label}</label>
      <div className="size-input-wrap">
        <div className="size-input-control" onClick={handleDecrease}>-</div>
        <div className="size-input-symbol-wrap">
          <span className="input-symbol">%</span>
          <StyledInput
            id="size"
            pattern="^\d{1,3}$|(?=^.{1,}$)^\d+\.\d{0,}$"
            value={value}
            onChange={handleChange}
          />
        </div>
        <div className="size-input-control" onClick={handleIncrease}>+</div>
      </div>
    </StyledContainer>

  );
}

export default ControlledNumericInput;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  color: #232323;
  justify-content: flex-end;
  .size-input-control {
    margin-left: 5px;
    margin-right: 5px;
    color: ${(props) => props.theme.primaryColor};
    cursor: pointer;
    user-select: none;
    font-size: 24px;
  }
  .size-input-wrap {
    margin-left: 8px;
    display: flex;
    width: 200px;
    justify-content: center;
    align-items: center;
    .size-input-symbol-wrap {
      position: relative;
      input {
        width: 66px;
        padding-right: 30px;
      }
      .input-symbol {
        font-weight: bold;
        color:${(props) => props.theme.primaryTextColor};
        position: absolute;
        @media screen and (max-width: 768px) {
          left: 11px;
        }
        left: 40px;
        top: 10px;
      }
    }
  }
`;

const StyledInput = styled.input`
  padding: 8px;
  border-radius: 4px;
  border-style: solid;
  height: 39px;
  font-size: 14px;
  border-width: 0.5px;
  outline-color: #d2cece;
  border-color: #d2cece;
  color:${(props) => props.theme.primaryTextColor};
  &:focus {
    outline-color: ${(props) => props.theme.primaryColor};
  }
  &.input-error {
    border-color: ${(props) => props.theme.primaryColor};
  }
`;
