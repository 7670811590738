import PlusRoundIcon from '@rsuite/icons/PlusRound';
import React, { useEffect, useState } from 'react';
import { Dropdown, Popover, Whisper } from 'rsuite';
import { OverlayTriggerProps } from 'rsuite/esm/Overlay/OverlayTrigger';
import styled from 'styled-components';
import { useAppMessageContext } from '../../../../../appMessagesContext';
import CustomButton from '../../../../../components/Buttons/CustomButton';
import CustomRadio from '../../../../../components/CustomRadio';
import CustomTable from '../../../../../components/CustomTable';
import ArrowSvg from '../../../../../components/SvgElements/ArrowSvg';
import EditSvg from '../../../../../components/SvgElements/EditSvg';
import { useGetSelectedDealer } from '../../../../../hooks/getDealersHook';
import useNavigateHook from '../../../../../hooks/useNavigateHook';
import Pages from '../../../../../Router/pages';
import { getMeRequest } from '../../../../../store/auth/actions';
import { SupperAdminRoles } from '../../../../../store/constants';
import {
  DeleteFbProfileRequest,
  EditFbProfileRequest,
  getFbProfilesRequest,
} from '../../../../../store/settings/actions';
import { FbProfileI } from '../../../../../store/settings/requests';
import ProfileDeleteModal from './FbProfileDeleteModal';
import { roleHasAccess } from '../../../../../helpers';

function IsDefaultCell({ item, onDefaultChange }: { item: FbProfileI, onDefaultChange: () => void }) {
  return (
    <div>
      <CustomRadio
        label=""
        value="active"
        id="active"
        onChange={() => {
          if (!item.is_default) {
            onDefaultChange();
          }
        }}
        checked={item.is_default}
      />
    </div>
  );
}

function FbProfileTabTable() {
  const navigate = useNavigateHook();
  const { showMessage } = useAppMessageContext();
  const selectedDealer = useGetSelectedDealer();
  const getMe = getMeRequest();
  const dealersIDReqLoading = (roleHasAccess(getMe?.data?.data.role, SupperAdminRoles) ? !!selectedDealer?.id : true);
  const { responseData: tableData, refetch, isFetching } = getFbProfilesRequest({ dealer_id: selectedDealer?.id }, !dealersIDReqLoading);

  const editRequest = EditFbProfileRequest();

  useEffect(() => {
    if (editRequest.isSuccess) {
      editRequest.reset();
      showMessage({ type: 'success', message: 'Profile Updated successfully' });
      refetch();
      navigate(Pages.SchedulerSetting);
    }
  }, [editRequest.isSuccess]);

  const handleDefaultChange = (profile: FbProfileI) => {
    const formData = {
      name: profile.name,
      fb_token: profile.fb_token,
      fb_login: profile.fb_login ?? '',
      fb_password: profile.fb_password ?? '',
      is_default: 1,
      ...(selectedDealer?.id && { dealer_id: Number(selectedDealer?.id) }),
    };
    editRequest.mutate({ data: formData, id: profile.id });
  };

  return (
    <div>
      <div className="header">
        <CustomButton
          className="new-profile-button"
          onClick={() => {
            navigate(`${Pages.SchedulerFbProfileFromSettingPure}/new/null`);
          }}
        >
          <PlusRoundIcon color="#ffffff" />
          <span>
            New FB Profile
          </span>
        </CustomButton>
      </div>
      <CustomTable
        loading={isFetching}
        data={tableData.map((dataRow) => ({
          ...dataRow,
          is_default: <IsDefaultCell item={dataRow} onDefaultChange={() => handleDefaultChange(dataRow)} />,
          action: <ActionCell item={dataRow} refetch={refetch} />,
        }))}
        columns={columns}
        hideSelectHeader
        selectable={false}
        displayPagination={false}
        displayTotal={false}
        fillHeight={false}
        autoHeight
        fullHeightContainer={false}
      />
    </div>
  );
}
export default FbProfileTabTable;

function ActionCell({ item, refetch }: { item: FbProfileI, refetch: () => void }) {
  const navigate = useNavigateHook();
  const deleteRequest = DeleteFbProfileRequest();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const handleDelete = () => {
    deleteRequest.mutate({ id: item.id });
  };
  useEffect(() => {
    if (deleteRequest.isSuccess) {
      deleteRequest.reset();
      refetch();
      setDeleteModal(false);
    }
  }, [deleteRequest.isSuccess]);
    type renderMenuProps = OverlayTriggerProps['speaker']
    const renderMenu: renderMenuProps = ({
      onClose, left, top, className,
    }, ref) => {
      const handleSelect = () => {
        onClose();
      };

      return (
        <Popover ref={ref} className={className} style={{ left, top }} full>
          <Dropdown.Menu onSelect={handleSelect}>
            <Dropdown.Item
              onClick={() => {
                setDeleteModal(true);
                handleSelect();
              }}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Popover>
      );
    };
    return (
      <ActionButtonsContainer>
        <IconWrapper onClick={() => navigate(`${Pages.SchedulerFbProfileFromSettingPure}/edit/${item.id}`)}>
          <EditSvg />
        </IconWrapper>
        <div>
          <Whisper placement="autoHorizontalStart" trigger="click" speaker={renderMenu}>
            <IconWrapper>
              <ArrowSvg />
            </IconWrapper>
          </Whisper>
        </div>
        {/* <ProfileDuplicateModal */}
        {/*  showModal={duplicateModal} */}
        {/*  onModalClose={() => setDuplicateModal(false)} */}
        {/* /> */}
        <ProfileDeleteModal
          showModal={deleteModal}
          setShowModal={() => setDeleteModal(false)}
          handleAcceptModal={handleDelete}
        />
      </ActionButtonsContainer>
    );
}
const IconWrapper = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.primaryColor};
  transition: all ease 0.2s;
  :hover {
    border: 2px solid ${(props) => props.theme.primaryColor};
  }
  svg {
    width: 22px;
    height: 22px;
  }
`;
const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 13px;
`;

const columns = [
  {
    label: 'Default Profile',
    key: 'is_default',
  },
  {
    label: 'Profile Name',
    key: 'name',
  },
  {
    label: 'Login',
    key: 'fb_login',
  },
  {
    label: '',
    key: 'action',
    width: 120,
  },

];
