import * as Y from 'yup';
import { CanadaZipCodePattern, USzipCodeValidation } from '../helpers/regexPatterns';

const locationFormSchema = Y.object().shape({
  name: Y.string().required('This field is required'),
  contact_first_name: Y.string().required('This field is required'),
  contact_last_name: Y.string().required('This field is required'),
  phone_number: Y.string().required('This field is required'),
  street_address: Y.string().required('This field is required'),
  city: Y.string().required('This field is required'),
  county: Y.string().required('This field is required'),
  zip_code: Y.lazy((value, options) => {
    if (options?.parent?.country?.value === 'Canada') {
      return Y.string().required('This field is required')
        .matches(new RegExp(CanadaZipCodePattern), 'invalid zip code');
    }
    return Y.string().required('This field is required')
      .matches(new RegExp(USzipCodeValidation), 'invalid zip code');
  }),
  email: Y.lazy((val) => (Array.isArray(val)
    ? Y.array().min(1, 'This field is required')
    : Y.string().required('This field is required'))),
  state: Y.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Y.object().required('This field is required'); // schema for object
      case 'string':
        return Y.string().required('This field is required');
      default:
        return Y.mixed(); // here you can decide what is the default
    }
  }),
  country: Y.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Y.object().required('This field is required'); // schema for object
      case 'string':
        return Y.string().required('This field is required');
      default:
        return Y.mixed(); // here you can decide what is the default
    }
  }),
});

export default locationFormSchema;
