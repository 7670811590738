import React from 'react';
import styled from 'styled-components';
import FileUploader, { RenderImageT } from '../../../../components/FileUploader';
import { FileFromResponse } from '../../../../store/unit/actions';

export type PDFFormInputs = {
    files: FileFromResponse[]
    filesFromResponse: FileFromResponse[]
}
interface PDFsProps {
    onChange: (files: RenderImageT[]) => void
    initialValue: PDFFormInputs
}
function PDFs({ onChange, initialValue }: PDFsProps) {
  return (
    <StyledContainer>
      <div className="uploader">
        <FileUploader title="Add PDF" subtitle="" fileType="application/pdf" onPreviewChange={onChange} filesFromResponse={initialValue.filesFromResponse} />
      </div>
    </StyledContainer>
  );
}

export default PDFs;

const StyledContainer = styled.div`
.select-wrapper {
 label {
   white-space: nowrap;
   font-weight: normal;
 } 
}
  .uploader {
    margin-top: 0;
    margin-bottom: 22px;
  }
`;
