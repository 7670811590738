import PlusRoundIcon from '@rsuite/icons/PlusRound';
import MinusRoundIcon from '@rsuite/icons/MinusRound';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Toggle } from 'rsuite';
import CustomButton from '../../../components/Buttons/CustomButton';
import CustomInput from '../../../components/inputs/CustomInput';
import CustomPhoneInput from '../../../components/inputs/CustomPhoneInput';
import CustomSelect, { SelectOption, SelectUnknownOption, SelectValue } from '../../../components/inputs/CustomSelect';
import { ApplicationTheme } from '../../../globalStyles';
import { DeepKeysArrAndObj, formSelectOptions } from '../../../helpers';
import { TFAInputPattern } from '../../../helpers/regexPatterns';
import { GenerateCreateLocationErrors } from '../../../store/dealers/actions';
import { DeleteLocationRequest } from '../../../store/settings/actions';
import { getUnits, ItemsPaginationParams } from '../../../store/unit/actions';
import createDealerFormSchema, { updateDealerFormSchema } from '../../../validation/createDealerFormSchema';
import {
  CountriesList, CountriesOptions, statesByCounty,
} from '../../SettingsSections/data';
import WarningModal from './WarningModal';
import CustomMultiSelect from '../../../components/inputs/CustomMultiSelect';

export interface DealerFromData {
  dealer_data: {
    dealership_name: string
    primary_email: string
    password?: string | undefined
    first_name: string
    last_name: string
    phone_number: string
    server_address: string
    ftp_login: string
    ftp_password: string
    filename: string
    fb_token: string
    stripe_id: string
    ksl_folder_name: string
    ksl_password: string

    factory_feed: boolean
    factory_feeds: SelectOption[]

    rj_dealer_id: string
    rj_integration: boolean
    rj_slots: SelectValue
    rj_slots_used: number
  }
  access_scheduler: boolean
  fb_profiles: boolean
  dl_customer_id?: string
  main_location: {
    id?: number
    name: string
    contact_first_name: string
    contact_last_name: string
    website: string
    phone_number: string
    email: string[]
    street_address: string
    city: string
    country: SelectValue
    state: string
    zip_code: string
    county: string
  }
  locations: {
    id?: number
    name: string
    contact_first_name: string
    contact_last_name: string
    website: string
    phone_number: string
    email: string[]
    street_address: string
    city: string
    state: string
    country: SelectValue
    zip_code: string
    county: string
  }[]
}

type DealerCreationFormProps = {
  errorsProps?: GenerateCreateLocationErrors
  onSubmit: (data: DealerFromData) => void
  editDealerMode: boolean
  editableDealerValues?: DealerFromData
}
function DealerCreationForm({
  errorsProps, onSubmit, editDealerMode, editableDealerValues,
}: DealerCreationFormProps) {
  const [locationID, setLocationID] = useState<number | null>(null);
  const [params, setParams] = useState<ItemsPaginationParams>({ limit: 1 });
  const { meta: unitsMeta } = getUnits(params, !locationID);
  const deleteLocationRequest = DeleteLocationRequest();

  const initialLocation: DealerFromData['locations'][number] = {
    name: '',
    contact_first_name: '',
    contact_last_name: '',
    website: '',
    phone_number: '',
    email: [''],
    street_address: '',
    city: '',
    state: '',
    country: null,
    zip_code: '',
    county: '',
  };

  const initialValues: DealerFromData = editableDealerValues || {
    dealer_data:
        {
          dealership_name: '',
          primary_email: '',
          password: '',
          first_name: '',
          last_name: '',
          phone_number: '',
          server_address: '',
          ftp_login: '',
          ftp_password: '',
          filename: '',
          fb_token: '',
          stripe_id: '',
          ksl_folder_name: '',
          ksl_password: '',
          factory_feed: false,
          factory_feeds: [],
          rj_dealer_id: '',
          rj_integration: false,
          rj_slots: null,
          rj_slots_used: 0,
        },
    access_scheduler: false,
    fb_profiles: false,
    dl_customer_id: '',
    main_location:
        {
          name: '',
          contact_first_name: '',
          contact_last_name: '',
          website: '',
          phone_number: '',
          email: [''],
          street_address: '',
          city: '',
          state: '',
          country: null,
          zip_code: '',
          county: '',
        },
    locations: [],
  };

  const formik = useFormik<DealerFromData>({
    initialValues,
    onSubmit,
    validationSchema: editDealerMode ? updateDealerFormSchema : createDealerFormSchema,
  });

  const {
    setFieldValue, values: formikValues, errors: formikErrors, touched,
  } = formik;

  // console.log(formik);

  // formik catch wrong type for array (errors.locations?.[0].name)
  // Partial because DealerFromData in not always contains all the keys
  const errors = formikErrors as Partial<DealerFromData>;
  type TranslationKey = DeepKeysArrAndObj<DealerFromData> | 'dealer_data.password' | 'access_scheduler' | 'fb_profiles' | 'dl_customer_id' | `locations.${number}.country` | 'main_location.country' | 'dealer_data.factory_feed' | 'dealer_data.factory_feeds' | 'dealer_data.rj_dealer_id' | 'dealer_data.rj_integration' | 'dealer_data.rj_slots' ;
  const handleFieldChange = (key: TranslationKey, value: string | SelectValue | boolean) => {
    setFieldValue(key, value);
  };

  const handleRemoveLocation = (id: number | undefined, locationIndex: number) => {
    if (id) {
      setLocationID(id);
      setParams((prevState) => ({
        ...prevState,
        location_id: [id],
      }));
    } else {
      const locations = [...formikValues.locations];
      locations.splice(locationIndex, 1);
      setFieldValue('locations', locations);
    }
  };

  const removeLocationFromForm = () => {
    const locations = [...formikValues.locations];
    const locationIndex = locations.findIndex((location) => location.id === locationID);
    if (locationIndex > -1) {
      locations.splice(locationIndex, 1);
      setFieldValue('locations', locations);
    }
    setLocationID(null);
  };
  const handleRemoveLocationApprove = () => {
    if (locationID) {
      deleteLocationRequest.mutate({ ids: [locationID] });
    } else {
      removeLocationFromForm();
    }
  };

  useEffect(() => {
    if (deleteLocationRequest.isSuccess && !deleteLocationRequest.isPending) {
      deleteLocationRequest.reset();
      removeLocationFromForm();
    }
  }, [deleteLocationRequest.isSuccess]);

  const handleModalClose = () => {
    setLocationID(null);
  };

  const currentFactoryFeeds = (formikValues.dealer_data.factory_feeds || []).map((factoryFeed, index) => ({
    value: factoryFeed.value || factoryFeed,
    label: factoryFeed.label || factoryFeed,
  })) as SelectOption[]; // Explicitly assert the type

  console.log(formikValues.dealer_data.factory_feeds);

  const packagesOption = formSelectOptions([2, 10, 20, 50, 100]);
  const factoryFeedOption = formSelectOptions([
    'Aluma',
    'Novae',
    'Loadtrail',
    'Lamar',
    'Norstar',
  ]);

  return (
    <StyledForm onSubmit={(e) => {
      e.preventDefault();
      formik.submitForm();
    }}
    >
      <div className="section">
        <div className="section-title">
          General Info
        </div>
        <div className="section-content">
          <div className="input-container">
            <CustomInput
              label="Dealership Name"
              required
              wrapperClass="input-wrapper"
              value={formikValues.dealer_data.dealership_name}
              onChange={(value) => handleFieldChange('dealer_data.dealership_name', value)}
              error={errors.dealer_data?.dealership_name && touched?.dealer_data?.dealership_name ? errors.dealer_data?.dealership_name : errorsProps?.['dealer_data.dealership_name']}
              data-testid="dealer_data.dealership_name"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="Primary Account Email"
              required
              wrapperClass="input-wrapper"
              value={formikValues.dealer_data.primary_email}
              onChange={(value) => handleFieldChange('dealer_data.primary_email', value)}
              error={errors.dealer_data?.primary_email && touched?.dealer_data?.primary_email ? errors.dealer_data?.primary_email : errorsProps?.['dealer_data.primary_email']}
              data-testid="dealer_data.primary_email"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="Password"
              required={!editDealerMode}
              wrapperClass="input-wrapper"
              type="password"
              value={formikValues.dealer_data.password}
              onChange={(value) => handleFieldChange('dealer_data.password', value)}
              error={errors.dealer_data?.password && touched?.dealer_data?.password ? errors.dealer_data?.password : errorsProps?.['dealer_data.password']}
              data-testid="dealer_data.password"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="POC First Name"
              required
              wrapperClass="input-wrapper"
              value={formikValues.dealer_data.first_name}
              onChange={(value) => handleFieldChange('dealer_data.first_name', value)}
              error={errors.dealer_data?.first_name && touched?.dealer_data?.first_name ? errors.dealer_data?.first_name : errorsProps?.['dealer_data.first_name']}
              data-testid="dealer_data.first_name"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="POC Last Name"
              required
              wrapperClass="input-wrapper"
              value={formikValues.dealer_data.last_name}
              onChange={(value) => handleFieldChange('dealer_data.last_name', value)}
              error={errors.dealer_data?.last_name && touched?.dealer_data?.last_name ? errors.dealer_data?.last_name : errorsProps?.['dealer_data.last_name']}
              data-testid="dealer_data.last_name"
            />
          </div>
          <div className="input-container phone-input">
            <CustomPhoneInput
              label="POC Phone Number"
              required
              specialLabel=""
              alwaysDefaultMask
              placeholder="  (  )    -  "
              value={formikValues.dealer_data.phone_number}
              onChange={(value) => handleFieldChange('dealer_data.phone_number', `+${value}`)}
              error={errors.dealer_data?.phone_number && touched?.dealer_data?.phone_number ? errors.dealer_data?.phone_number : errorsProps?.['dealer_data.phone_number']}
              inputProps={{
                'data-testid': 'dealer_data.phone_number',
              }}
            />
          </div>
          {formikValues?.fb_profiles !== true && (
            <div className="input-container">
              <CustomInput
                label="Facebook 2FA"
                wrapperClass="input-wrapper"
                pattern={TFAInputPattern}
                value={formikValues.dealer_data?.fb_token}
                onChange={(value) => handleFieldChange('dealer_data.fb_token', value)}
                error={errors.dealer_data?.fb_token && touched?.dealer_data?.fb_token ? errors.dealer_data?.fb_token : errorsProps?.['dealer_data.fb_token']}
                data-testid="dealer_data.fb_token"
              />
            </div>
          )}
          <div className="input-container">
            <CustomInput
              label="Stripe ID"
              wrapperClass="input-wrapper"
              value={formikValues.dealer_data?.stripe_id}
              onChange={(value) => handleFieldChange('dealer_data.stripe_id', value)}
              error={errors.dealer_data?.stripe_id && touched?.dealer_data?.stripe_id ? errors.dealer_data?.stripe_id : errorsProps?.['dealer_data.stripe_id']}
              data-testid="dealer_data.stripe_id"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="DL Customer ID"
              wrapperClass="input-wrapper"
              value={formikValues?.dl_customer_id}
              onChange={(value) => handleFieldChange('dl_customer_id', value)}
            />
          </div>
          <div className="input-container">
            <div className="input-wrapper">
              <label htmlFor="marketing_tools" className="toggle-label">Marketing Tools</label>
              <Toggle
                id="marketing_tools"
                arial-label="Switch"
                onChange={(value: boolean) => {
                  handleFieldChange('access_scheduler', value);
                }}
                checked={formikValues?.access_scheduler}
              />
            </div>
          </div>
          <div className="input-container">
            <div className="input-wrapper">
              <label htmlFor="marketing_tools" className="toggle-label">Facebook Profiles</label>
              <Toggle
                id="facebook_profiles"
                arial-label="Switch"
                onChange={(value: boolean) => {
                  handleFieldChange('fb_profiles', value);
                }}
                checked={formikValues?.fb_profiles}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="section-title">
          Main Location Specifics
        </div>
        <div className="section-content">
          <div className="input-container with-id">
            <div>
              <CustomInput
                label="Location Name"
                required
                wrapperClass="input-wrapper"
                value={formikValues.main_location.name}
                onChange={(value) => handleFieldChange('main_location.name', value)}
                error={errors.main_location?.name && touched?.main_location?.name ? errors.main_location?.name : errorsProps?.['main_location.name']}
                data-testid="main_location.name"
              />
            </div>
            <div>
              ID:
              {formikValues.main_location?.id}
            </div>
          </div>
          <div className="input-container double-inputs">
            <CustomInput
              label="Location Contact"
              required
              wrapperClass="input-wrapper with-label"
              value={formikValues.main_location.contact_first_name}
              onChange={(value) => handleFieldChange('main_location.contact_first_name', value)}
              error={errors.main_location?.contact_first_name && touched?.main_location?.contact_first_name ? errors.main_location?.contact_first_name : errorsProps?.['main_location.contact_first_name']}
              data-testid="main_location.contact_first_name"
            />
            <CustomInput
              label=""
              wrapperClass="input-wrapper"
              value={formikValues.main_location.contact_last_name}
              onChange={(value) => handleFieldChange('main_location.contact_last_name', value)}
              error={errors.main_location?.contact_last_name && touched?.main_location?.contact_last_name ? errors.main_location?.contact_last_name : errorsProps?.['main_location.contact_last_name']}
              data-testid="main_location.contact_last_name"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="Website"
              wrapperClass="input-wrapper"
              value={formikValues.main_location.website}
              onChange={(value) => handleFieldChange('main_location.website', value)}
              error={errors.main_location?.website && touched?.main_location?.website ? errors.main_location?.website : errorsProps?.['main_location.website']}
              data-testid="main_location.website"
            />
          </div>
          <div className="input-container phone-input">
            <CustomPhoneInput
              label="Phone Number"
              required
              specialLabel=""
              alwaysDefaultMask
              placeholder="  (  )    -  "
              value={formikValues.main_location.phone_number}
              onChange={(value) => handleFieldChange('main_location.phone_number', `+${value}`)}
              error={errors.main_location?.phone_number && touched?.main_location?.phone_number ? errors.main_location?.phone_number : errorsProps?.['main_location.phone_number']}
              inputProps={{
                'data-testid': 'main_location.phone_number',
              }}
            />
          </div>
          {formikValues.main_location.email.map((field, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="input-container with-button" key={`main_location_email${index}`}>
              <CustomInput
                label="Email"
                required
                wrapperClass="input-wrapper"
                value={formikValues.main_location.email[index]}
                onChange={(value) => handleFieldChange(`main_location.email.${index}`, value)}
                error={errors.main_location?.email?.[index] && touched?.main_location?.email ? errors.main_location?.email[index] : errorsProps?.[`main_location.email.${index}`]}
                data-testid="main_location.email"
              />
              {index === formikValues.main_location.email.length - 1 && (
              <button
                type="button"
                className="add-button"
                onClick={() => {
                  const mainLocationEmails = [...formikValues.main_location.email];
                  mainLocationEmails.push('');
                  setFieldValue('main_location.email', mainLocationEmails);
                }}
              >
                <PlusRoundIcon color={ApplicationTheme.primaryColor} width={24} height={24} />
              </button>
              )}
            </div>
          ))}
          <div className="input-container">
            <CustomInput
              label="Street Address"
              required
              wrapperClass="input-wrapper"
              value={formikValues.main_location.street_address}
              onChange={(value) => handleFieldChange('main_location.street_address', value)}
              error={errors.main_location?.street_address && touched?.main_location?.street_address ? errors.main_location?.street_address : errorsProps?.['main_location.street_address']}
              data-testid="main_location.street_address"
            />
          </div>
          <div className="input-container">
            <CustomSelect
              label="Country"
              required
              options={CountriesOptions}
              value={formikValues.main_location.country}
              onChange={(value) => {
                if (formikValues?.main_location?.country?.value !== value?.value) {
                  handleFieldChange('main_location.state', '');
                  handleFieldChange('main_location.zip_code', '');
                }
                handleFieldChange('main_location.country', value);
              }}
              error={errors.main_location?.country && touched?.main_location?.country ? String(errors.main_location?.country?.label) : ''}
              ariaLabel="main_location.state"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="City"
              required
              wrapperClass="input-wrapper"
              value={formikValues.main_location.city}
              onChange={(value) => handleFieldChange('main_location.city', value)}
              error={errors.main_location?.city && touched?.main_location?.city ? errors.main_location?.city : errorsProps?.['main_location.city']}
              data-testid="main_location.city"
            />
          </div>
          <div className="input-container">
            <CustomSelect
              label="State"
              required
              options={statesByCounty[formikValues?.main_location?.country?.label as typeof CountriesList[number]]}
              value={formikValues.main_location.state}
              onChange={(value) => handleFieldChange('main_location.state', value?.value ? String(value?.value) : '')}
              error={errors.main_location?.state && touched?.main_location?.state ? errors.main_location?.state : errorsProps?.['main_location.state']}
              ariaLabel="main_location.state"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="Zip Code"
              required
              wrapperClass="input-wrapper"
              value={formikValues.main_location.zip_code}
              onChange={(value) => handleFieldChange('main_location.zip_code', value)}
              error={errors.main_location?.zip_code && touched?.main_location?.zip_code ? errors.main_location?.zip_code : errorsProps?.['main_location.zip_code']}
              data-testid="main_location.zip_code"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="County"
              required
              wrapperClass="input-wrapper"
              value={formikValues.main_location.county}
              onChange={(value) => handleFieldChange('main_location.county', value)}
              error={errors.main_location?.county && touched?.main_location?.county ? errors.main_location?.county : errorsProps?.['main_location.county']}
              data-testid="main_location.county"
            />
          </div>
        </div>
      </div>

      {formikValues.locations.map((location, locationIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="section" key={`locations_${locationIndex}`}>
          <div className="section-title-container">
            <div className="section-title">
              Location
            </div>
            <button
              className="remove-location-btn"
              type="button"
              onClick={() => handleRemoveLocation(location.id, locationIndex)}
            >
              <MinusRoundIcon color={ApplicationTheme.primaryColor} width={24} height={24} />
            </button>
          </div>
          <div className="section-content">
            <div className="input-container with-id">
              <div>
                <CustomInput
                  label="Location Name"
                  required
                  wrapperClass="input-wrapper"
                  value={formikValues.locations[locationIndex].name}
                  onChange={(value) => handleFieldChange(`locations.${locationIndex}.name`, value)}
                  error={errors?.locations?.[locationIndex]?.name && touched?.locations?.[locationIndex]?.name ? errors.locations?.[locationIndex]?.name : errorsProps?.[`locations.${locationIndex}.name`]}
                />
              </div>
              <div>
                ID:
                {formikValues.locations[locationIndex]?.id}
              </div>
            </div>
            <div className="input-container double-inputs">
              <CustomInput
                label="Location Contact"
                required
                wrapperClass="input-wrapper with-label"
                value={formikValues.locations[locationIndex].contact_first_name}
                onChange={(value) => handleFieldChange(`locations.${locationIndex}.contact_first_name`, value)}
                error={errors?.locations?.[locationIndex]?.contact_first_name && touched?.locations?.[locationIndex]?.contact_first_name ? errors.locations?.[locationIndex]?.contact_first_name : errorsProps?.[`locations.${locationIndex}.contact_first_name`]}
              />
              <CustomInput
                label=""
                wrapperClass="input-wrapper"
                value={formikValues.locations[locationIndex].contact_last_name}
                onChange={(value) => handleFieldChange(`locations.${locationIndex}.contact_last_name`, value)}
                error={errors?.locations?.[locationIndex]?.contact_last_name && touched?.locations?.[locationIndex]?.contact_last_name ? errors.locations?.[locationIndex]?.contact_last_name : errorsProps?.[`locations.${locationIndex}.contact_last_name`]}
              />
            </div>
            <div className="input-container">
              <CustomInput
                label="Website"
                wrapperClass="input-wrapper"
                value={formikValues.locations[locationIndex].website}
                onChange={(value) => handleFieldChange(`locations.${locationIndex}.website`, value)}
                error={errors?.locations?.[locationIndex]?.website && touched?.locations?.[locationIndex]?.website ? errors.locations?.[locationIndex]?.website : errorsProps?.[`locations.${locationIndex}.website`]}
              />
            </div>
            <div className="input-container phone-input">
              <CustomPhoneInput
                label="Phone Number"
                required
                specialLabel=""
                alwaysDefaultMask
                placeholder="  (  )    -  "
                value={formikValues.locations[locationIndex].phone_number}
                onChange={(value) => handleFieldChange(`locations.${locationIndex}.phone_number`, `+${value}`)}
                error={errors.locations?.[locationIndex]?.phone_number && touched?.locations?.[locationIndex]?.phone_number ? errors.locations?.[locationIndex]?.phone_number : errorsProps?.[`locations.${locationIndex}.phone_number`]}
                inputProps={{
                  'data-testid': 'locations.phone_number',
                }}
              />
            </div>
            {formikValues.locations[locationIndex].email.map((field, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="input-container with-button" key={`location${index}`}>
                <CustomInput
                  label="Email"
                  required
                  wrapperClass="input-wrapper"
                  value={formikValues.locations[locationIndex].email[index]}
                  onChange={(value) => handleFieldChange(`locations.${locationIndex}.email.${index}`, value)}
                  error={errors?.locations?.[locationIndex]?.email?.[index] && touched?.locations?.[locationIndex]?.email ? errors.locations?.[locationIndex]?.email?.[index] : errorsProps?.[`locations.${locationIndex}.email.${index}`]}
                />
                {index === formikValues.locations[locationIndex].email.length - 1 && (
                  <button
                    type="button"
                    className="add-button"
                    onClick={() => {
                      const locationEmails = [...formikValues.locations[locationIndex].email];
                      locationEmails.push('');
                      setFieldValue(`locations.${locationIndex}.email`, locationEmails);
                    }}
                  >
                    <PlusRoundIcon color={ApplicationTheme.primaryColor} width={24} height={24} />
                  </button>
                )}
              </div>
            ))}
            <div className="input-container">
              <CustomInput
                label="Street Address"
                required
                wrapperClass="input-wrapper"
                value={formikValues.locations[locationIndex].street_address}
                onChange={(value) => handleFieldChange(`locations.${locationIndex}.street_address`, value)}
                error={errors.locations?.[locationIndex]?.street_address && touched?.locations?.[locationIndex]?.street_address ? errors.locations?.[locationIndex]?.street_address : errorsProps?.[`locations.${locationIndex}.street_address`]}
              />
            </div>
            <div className="input-container">
              <CustomSelect
                label="Country"
                required
                options={CountriesOptions}
                value={formikValues.locations[locationIndex].country}
                onChange={(value) => {
                  if (formikValues?.locations[locationIndex]?.country?.value !== value?.value) {
                    handleFieldChange(`locations.${locationIndex}.state`, '');
                    handleFieldChange(`locations.${locationIndex}.zip_code`, '');
                  }
                  handleFieldChange(`locations.${locationIndex}.country`, value);
                }}
                error={errors.locations?.[locationIndex]?.country?.label && touched?.locations?.[locationIndex]?.country ? String(errors.locations?.[locationIndex]?.country?.label) : ''}
              />
            </div>
            <div className="input-container">
              <CustomInput
                label="City"
                required
                wrapperClass="input-wrapper"
                value={formikValues.locations[locationIndex].city}
                onChange={(value) => handleFieldChange(`locations.${locationIndex}.city`, value)}
                error={errors.locations?.[locationIndex]?.city && touched?.locations?.[locationIndex]?.city ? errors.locations?.[locationIndex]?.city : errorsProps?.[`locations.${locationIndex}.city`]}
              />
            </div>
            <div className="input-container">
              <CustomSelect
                label="State"
                required
                options={statesByCounty[formikValues?.locations[locationIndex]?.country?.label as typeof CountriesList[number]]}
                value={formikValues.locations[locationIndex].state}
                onChange={(value) => handleFieldChange(`locations.${locationIndex}.state`, value?.value ? String(value?.value) : '')}
                error={errors.locations?.[locationIndex]?.state && touched?.locations?.[locationIndex]?.state ? errors.locations?.[locationIndex]?.state : errorsProps?.[`locations.${locationIndex}.state`]}
              />
            </div>
            <div className="input-container">
              <CustomInput
                label="Zip Code"
                required
                wrapperClass="input-wrapper"
                value={formikValues.locations[locationIndex].zip_code}
                onChange={(value) => handleFieldChange(`locations.${locationIndex}.zip_code`, value)}
                error={errors.locations?.[locationIndex]?.zip_code && touched?.locations?.[locationIndex]?.zip_code ? errors.locations?.[locationIndex]?.zip_code : errorsProps?.[`locations.${locationIndex}.zip_code`]}
              />
            </div>
            <div className="input-container">
              <CustomInput
                label="County"
                required
                wrapperClass="input-wrapper"
                value={formikValues.locations[locationIndex].county}
                onChange={(value) => handleFieldChange(`locations.${locationIndex}.county`, value)}
                error={errors.locations?.[locationIndex]?.county && touched?.locations?.[locationIndex]?.county ? errors.locations?.[locationIndex]?.county : errorsProps?.[`locations.${locationIndex}.county`]}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="section">
        <div className="section-title">
          Racing Junk
        </div>
        <div className="section-content">
          <div className="input-container">
            <div className="input-wrapper">
              <label htmlFor="racing_junk" className="toggle-label">Racing Junk</label>
              <Toggle
                id="racing_junk"
                arial-label="Switch"
                onChange={(value: boolean) => {
                  handleFieldChange('dealer_data.rj_integration', value);
                }}
                checked={formikValues?.dealer_data?.rj_integration}
              />
            </div>
          </div>
          {formikValues?.dealer_data?.rj_integration && (
            <>
              <div className="input-container">
                <CustomInput
                  label="Racing Junk ID"
                  required={formikValues?.dealer_data?.rj_integration}
                  wrapperClass="input-wrapper"
                  value={formikValues.dealer_data.rj_dealer_id}
                  onChange={(value) => handleFieldChange('dealer_data.rj_dealer_id', value)}
                  error={errors.dealer_data?.rj_dealer_id && touched?.dealer_data?.rj_dealer_id ? errors.dealer_data?.rj_dealer_id : errorsProps?.['dealer_data.rj_dealer_id']}
                />
              </div>
              <div className="input-container">
                <CustomSelect
                  label="Package"
                  options={packagesOption}
                  onChange={(val) => handleFieldChange('dealer_data.rj_slots', val)}
                  value={formikValues.dealer_data.rj_slots}
                  error={errors.dealer_data?.rj_slots?.value as string && touched?.dealer_data?.rj_slots ? errors.dealer_data?.rj_slots?.value as string : errorsProps?.['dealer_data.rj_slots']}
                  isOptionDisabled={(option: SelectUnknownOption) => (formikValues.dealer_data.rj_slots_used > Number(option.value))}
                />
                <p className="input-description">
                  Some options might be disabled based on the current amount of integrated
                  units.
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="section">
        <div className="section-title">
          Factory Feed
        </div>
        <div className="section-content">
          <div className="input-container">
            <div className="input-wrapper">
              <label htmlFor="racing_junk" className="toggle-label">Factory Feed</label>
              <Toggle
                id="factory_feed"
                arial-label="Switch"
                onChange={(value: boolean) => {
                  handleFieldChange('dealer_data.factory_feed', value);
                }}
                checked={formikValues?.dealer_data?.factory_feed}
              />
            </div>
          </div>
          {formikValues?.dealer_data?.factory_feed && (

          <div className="input-container">
            <CustomMultiSelect
              label="Factory Feeds"
              value={currentFactoryFeeds}
              options={factoryFeedOption}
              isMulti
              onChange={(value) => handleFieldChange('dealer_data.factory_feeds', value)}
            />
          </div>

          )}
        </div>
      </div>
      <div className="section">
        <div className="section-title">
          FTP Settings
        </div>
        <div className="section-content">
          <div className="input-container">
            <CustomInput
              label="Server Address"
              wrapperClass="input-wrapper"
              value={formikValues.dealer_data.server_address}
              onChange={(value) => handleFieldChange('dealer_data.server_address', value)}
              error={errors.dealer_data?.server_address && touched?.dealer_data?.server_address ? errors.dealer_data?.server_address : errorsProps?.['dealer_data.server_address']}
              data-testid="dealer_data.ftp_address"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="Login"
              wrapperClass="input-wrapper"
              value={formikValues.dealer_data.ftp_login}
              onChange={(value) => handleFieldChange('dealer_data.ftp_login', value)}
              error={errors.dealer_data?.ftp_login && touched?.dealer_data?.ftp_login ? errors.dealer_data?.ftp_login : errorsProps?.['dealer_data.ftp_login']}
              data-testid="dealer_data.ftp_login"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="Password"
              wrapperClass="input-wrapper"
              type="password"
              value={formikValues.dealer_data.ftp_password}
              onChange={(value) => handleFieldChange('dealer_data.ftp_password', value)}
              error={errors.dealer_data?.ftp_password && touched?.dealer_data?.ftp_password ? errors.dealer_data?.ftp_password : errorsProps?.['dealer_data.ftp_password']}
              data-testid="dealer_data.ftp_password"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="File Name"
              wrapperClass="input-wrapper"
              value={formikValues.dealer_data.filename}
              onChange={(value) => handleFieldChange('dealer_data.filename', value)}
              error={errors.dealer_data?.filename && touched?.dealer_data?.filename ? errors.dealer_data?.filename : errorsProps?.['dealer_data.filename']}
              data-testid="dealer_data.filename"
            />
          </div>
        </div>
      </div>
      <div className="section">
        <div className="section-title">
          KSL Settings
        </div>
        <div className="section-content">
          <div className="input-container">
            <CustomInput
              label="Folder name"
              wrapperClass="input-wrapper"
              value={formikValues.dealer_data.ksl_folder_name}
              onChange={(value) => handleFieldChange('dealer_data.ksl_folder_name', value)}
              error={errors.dealer_data?.ksl_folder_name && touched?.dealer_data?.ksl_folder_name ? errors.dealer_data?.ksl_folder_name : errorsProps?.['dealer_data.ksl_folder_name']}
              data-testid="dealer_data.ksl_folder_name"
            />
          </div>
          <div className="input-container">
            <CustomInput
              label="KSL Password"
              wrapperClass="input-wrapper"
              type="password"
              value={formikValues.dealer_data.ksl_password}
              onChange={(value) => handleFieldChange('dealer_data.ksl_password', value)}
              error={errors.dealer_data?.ksl_password && touched?.dealer_data?.ksl_password ? errors.dealer_data?.ksl_password : errorsProps?.['dealer_data.ksl_password']}
              data-testid="dealer_data.ksl_password"
            />
          </div>
        </div>
      </div>

      <div className="buttons-container">
        <button
          className="add-location-btn"
          type="button"
          onClick={() => {
            const locations = [...formikValues.locations];
            locations.push(initialLocation);
            setFieldValue('locations', locations);
          }}
        >
          <PlusRoundIcon color={ApplicationTheme.primaryColor} width={24} height={24} />
          <span>Add Another Location</span>
        </button>
        <div className="save-button">
          <CustomButton type="submit">
            {editDealerMode ? 'Update Dealer' : 'Create Dealer'}
          </CustomButton>
        </div>
      </div>
      <WarningModal
        open={!!locationID}
        onClose={handleModalClose}
        onApproveClose={handleRemoveLocationApprove}
        locationsQuantity={unitsMeta?.total}
      />
    </StyledForm>
  );
}

export default DealerCreationForm;

const StyledForm = styled.form`
  padding-left: 61px;
  padding-right: 39px;

  .section {
    padding-top: 31px;

    .section-title-container {
      display: flex;
      justify-content: space-between;
    }

    .section-title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    .section-content {
      background-color: #fff;
      border-radius: 8px;
      padding: 8px;
      padding-left: 0;
      padding-top: 1px;
      box-shadow: 0 5px 5px rgba(23, 23, 23, 0.31);

      .input-container {
        padding-top: 18px;
        padding-bottom: 18px;

        :not(:last-child) {
          border-bottom: 0.5px solid #c1c1c1;
        }

        label {
          max-width: 198px;
          color: ${(props) => props.theme.secondaryTextColor};
          font-size: 14px;
        }

        input {
          width: 100%;
          max-width: 315px;
        }

        .input-description {
          padding-top: 10px;
          padding-left: 36px;
          width: 100%;
          font-style: italic;
          font-size: 11px;
        }

        .input-wrapper {
          padding-left: 36px;
          display: grid;
          grid-template-columns: clamp(150px, 40%, 198px) clamp(215px, 40%, 315px);
        }

        .select-container {
          padding-left: 36px;
          display: grid;
          grid-template-columns: clamp(150px, 40%, 198px) clamp(215px, 40%, 315px);

          label {
            color: ${(props) => props.theme.secondaryTextColor};
            font-size: 14px;
            font-weight: normal;
          }

          .select-wrap {
            max-width: 315px;
            height: 39px;
          }
        }

        &.phone-input {
          padding-left: 36px;

          input {
            height: 39px;
            max-width: 315px;
            margin-left: 0;
          }

          > div {
            display: grid;
            grid-template-columns: clamp(150px, 40%, 198px) clamp(215px, 40%, 315px);
          }
        }

        &.double-inputs {
          display: flex;
          padding-left: 0;

          .input-wrapper {
            display: flex;
            flex-direction: row;
          }

          label {
            display: none;
          }
          .with-label {
            label {
              display: block;
              width: 197px;
            }
          
          }
          .input-wrapper {
            &:not(.with-label) {
              padding-left: 0;
              margin-left: 13px;
            }  
          }
          .symbol-input-wrapper {
            max-width: 151px;
          }
          input {
            width: 151px;
          }
        }
        
        &.with-button {
          display: flex;
          align-items: center;
          .input-wrapper {
            display: grid;
            min-width: 513px;
            grid-template-columns: clamp(150px, 60%, 198px) clamp(215px, 60%, 315px);
            label {
              max-width: 198px;
            }
            input {
              width: 100%;
            }
          }
          .add-button {
            margin-left: 18px;
          }
        }
        
        &.with-id {
          display: flex;
          justify-content: space-between;
          padding-right: 15px;
          .input-wrapper {
            width: 500px;
            input {
              width: 320px;
              margin-left: 10px;
            }
          }
        }
        
        
      }
    }
  }

  .buttons-container {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .add-location-btn {
      color: ${(props) => props.theme.primaryColor};
      display: flex;
      align-items: center;
      span {
        margin-left: 7px;
        font-size: 14px;
      }
    }
    .save-button {
      position: fixed;
      bottom: 0;
      right: 0;
      transform: translate(-28%, -25%);
      
      .primary {
        border-radius: 4px;
      }
    }
  }           
`;
