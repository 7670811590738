import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import { StyledError } from '../../StyledComponents';

interface CustomPhoneInputProps{
    label: string
    error?: string
    required?: boolean
    className?: boolean
    value?: string | null;
    specialLabel?: string;
    onChange: (value: string) => void
    defaultMask?: string;
    alwaysDefaultMask?: boolean;
    placeholder?: string;
    inputProps?: object
}
function CustomPhoneInput({
  label, required, error, ...props
}: CustomPhoneInputProps) {
  return (
    <InputWrapper>
      <label htmlFor="phone">
        <span>
          {label}
        </span>
        {required
              && <span className="required">*</span>}
      </label>
      <div>
        <PhoneInput
          inputProps={{ ...props.inputProps }}
          containerClass={`${props.className} ${error ? 'input-error' : ''}`}
          defaultMask={props.defaultMask || '(...) ...-....'}
          {...props}
        />
        {error
            && <StyledError className="error">{error}</StyledError>}
      </div>
    </InputWrapper>
  );
}

export default CustomPhoneInput;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  label {
    color: #232323;
    width: unset;
  }
  .required {
    color: ${(props) => props.theme.primaryColor};
  }
  .error {
    margin-left: 10px;
  }
  input {
    border-width: 0.5px;
    outline-color: #d2cece;
    border-color: #d2cece;
    color:${(props) => props.theme.primaryTextColor};
    border-radius: 4px;
    border-style: solid;
    width: 120px;
    margin-left: 6px;

    &::placeholder {
      color:${(props) => props.theme.primaryTextColor};
    }

    &:focus {
      outline-color: ${(props) => props.theme.primaryColor};
    }
  }
  .input-error {
    input {
      border-color: ${(props) => props.theme.primaryColor};
    }
  }
`;
