import { useEffect, useState } from 'react';
import { Loader } from 'rsuite';
import { useAppMessageContext } from '../../../../appMessagesContext';
import uploadIco from '../../../../assets/upload-icon.png';
import CustomButton from '../../../../components/Buttons/CustomButton';
import ExportInventoryModal from '../../../../components/ExportInventoryModal';
import FileUploader, { UploadedFile } from '../../../../components/FileUploader';
import { useGetSelectedDealer } from '../../../../hooks/getDealersHook';
import { getMeRequest } from '../../../../store/auth/actions';
import {
  CSVUploadRequestData,
  UploadCSVRequest, CSVErrors, CSVDuplicate,
} from '../../../../store/settings/actions';
import { UnitRequests } from '../../../../store/unit/requests';
import { ExportModes, ExportUnitRequestParams } from '../../../../store/unit/types';
import CSVDuplicateModal from './CSVDuplicateModal';
import CSVErrorsModal from './CSVErrorsModal';

function ImportTab() {
  const { showMessage } = useAppMessageContext();

  const [errors, setErrors] = useState<CSVErrors[] | null>(null);
  const [duplicate, setDuplicates] = useState<CSVDuplicate | null>(null);
  const user = getMeRequest()?.data?.data;
  const selectedDealer = useGetSelectedDealer();
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const uploadCSV = UploadCSVRequest();

  const handleForm = (csv: UploadedFile[]) => {
    const formData: CSVUploadRequestData = {
      file: csv[0],
      dealer_id: user?.dealer_id || selectedDealer?.id,
    };
    if (csv.length) {
      uploadCSV.mutate({ data: formData });
    }
  };

  useEffect(() => {
    if (uploadCSV.isSuccess) {
      if (Array.isArray(uploadCSV?.data?.data) && uploadCSV?.data?.data?.[0]?.error_messages) {
        setErrors(uploadCSV?.data?.data);
      }
    }
    if (uploadCSV.isError && uploadCSV.error.response?.data.message) {
      showMessage({ type: 'error', message: uploadCSV.error.response?.data.message });
    }
  }, [uploadCSV.isSuccess, uploadCSV.isError]);

  const dropzoneContent = () => (
    <div className="custom-content">
      <img src={uploadIco} alt="upload" />
      <div className="label">Drag and drop File here</div>
      <div>or</div>
      <div className="button-wrap">
        <CustomButton variant="primary">Browse files</CustomButton>
      </div>
    </div>
  );
  const handleDownload = () => {
    UnitRequests.sampleDownload();
  };
  const handleDownloadCheatsheet = () => {
    UnitRequests.csvCheatsheetDownload();
  };

  const handleExport = (mode: ExportModes) => {
    const exportParams: ExportUnitRequestParams = {
      ...(selectedDealer?.id ? { dealer_id: selectedDealer?.id ? [Number(selectedDealer?.id)] : undefined } : { dealer_id: undefined }),
      inventory: mode,
    };
    UnitRequests.exportInventory(mode, exportParams);
    setShowExportModal(false);
  };

  return (
    <div className="content">
      <div className="section-top">
        <div className="label">CSV File Upload</div>
        <div className="download-buttons">
          <div className="download-btn">
            <CustomButton
              variant="primary"
              onClick={handleDownload}
            >
              Download Sample CSV
            </CustomButton>
          </div>
          <div className="download-btn">
            <CustomButton variant="primary" onClick={() => setShowExportModal(true)}>
              Download Inventory
            </CustomButton>
          </div>
          <div
            className="download-btn"
            style={{
              display: 'none', // TODO: show button when Cheatsheet is ready
            }}
          >
            <CustomButton
              variant="primary"
              onClick={handleDownloadCheatsheet}
            >
              Download CSV Cheatsheet
            </CustomButton>
          </div>
        </div>
      </div>
      <div className="text">
        <p>
          Products can be imported and exported from your inventory using CSV file. A CSV file enables the
          simultaneous import and export of a sizable number of products and their details.
        </p>
        <p>
          Please ensure each CSV file is formatted in proper HTML/Markdown in order to successfully upload the file.
          {/* For more information regarding HTML/Markdown formatting */}
          {/* <span className="click-here">click here.</span> */}
        </p>
      </div>
      <div className="input-label">
        <span>
          CSV File
        </span>
        <span className="required">*</span>
      </div>
      <div className="dropzone-container">
        {uploadCSV.isPending
        && (
        <div className="loader">
          <Loader center size="lg" color="red" />
        </div>
        )}
        <FileUploader
          onChange={handleForm}
          fileType=".csv"
          initialFiles={[]}
          customDragZoneContent={dropzoneContent()}
          multiple={false}
        />
      </div>
      <CSVErrorsModal
        open={!!errors}
        onClose={() => setErrors(null)}
        errors={errors}
      />
      <CSVDuplicateModal
        open={!!duplicate}
        onClose={() => setDuplicates(null)}
        duplicates={duplicate}
      />
      <ExportInventoryModal showModel={showExportModal} onClose={() => setShowExportModal(false)} handleExport={handleExport} />
    </div>
  );
}

export default ImportTab;
