import { AxiosError } from 'axios';
import { queryClient } from '../App';
import { AppMessageType } from '../appMessagesContext';
import { IntegrationMockType, locationTableData } from '../pages/SettingsSections/data';
import { RequestKeys } from './keys';
import { LocationReqError } from './settings/actions';

declare global {
  interface Window { EXTENSION_ID?: string; }
}

export const requestOptions = {
  cacheTime: Infinity,
  retry: false,
  keepPreviousData: false,
  refetchOnWindowFocus: false,
  gcTime: 1000 * 60 * 60 * 24, // 1day / persisted storage time
  refetchOnReconnect: true,
};
export interface FundsState {
  funds: string
}

interface StoreInitializationProps {
  showMessage: (val: AppMessageType) => void
}
export const StoreInitialization = ({ showMessage }: StoreInitializationProps) => {
  queryClient.setQueryData(
    [RequestKeys.LOCATION_DATA],
    locationTableData,
  );
  queryClient.setQueryData(
    [RequestKeys.INTEGRATION_STORE],
    (oldVal: IntegrationMockType) => ({
      ...oldVal,
      facebook: {
        isConnected: true,
      },
    }),
  );

  document.addEventListener('extensionEvent', () => {
    const extensionID = document.getElementById('extension-id')?.getAttribute('data-ex-id');
    queryClient.setQueryData(
      [RequestKeys.INTEGRATION_STORE],
      (oldVal: IntegrationMockType) => ({
        ...oldVal,
        craigsList: {
          extensionId: extensionID || window?.EXTENSION_ID || '',
        },
      }),
      {
        updatedAt: Infinity,
      },
    );
  });
  queryClient.setDefaultOptions({
    queries: {
      ...requestOptions,
      // throwOnError: (error: AxiosError<{ errors: LocationReqError, message: string }>) => {
      //   if (error.response?.status?.toString()?.startsWith('5')) {
      //     showMessage({ type: 'error', message: error?.response?.data?.message || 'Something Went Wrong' });
      //   }
      //   if (error?.response?.status === 429) {
      //     clearLocalStorage();
      //     showMessage({ type: 'error', message: 'To many attempts' });
      //   }
      //   return false;
      // },
    },
    mutations: {
      ...requestOptions,
      onError: (error: AxiosError<{ errors: LocationReqError, message: string }>) => {
        if (error.response?.status?.toString()?.startsWith('5')) {
          showMessage({ type: 'error', message: error?.response?.data?.message || 'Something Went Wrong' });
        }
      },
    },
  });
};
