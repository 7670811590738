import { useEffect, useState } from 'react';
import { useAppMessageContext } from '../../../appMessagesContext';
import { formSelectOptions } from '../../../helpers';
import { useGetSelectedDealer } from '../../../hooks/getDealersHook';
import { getMeRequest } from '../../../store/auth/actions';
import {
  getCustomStatusOverlaysRequest, getDealerSettingsRequest,
  GetOverlayRequest,
  UpdateOverlayRequest,
  UpdateOverlayRequestData,
} from '../../../store/settings/actions';
import OverlaySectionForm, { OverlaySectionFormI } from './OverlaySectionForm';
import { FileFromResponse } from '../../../store/unit/actions';

function OverlaySection(
  { dealerId }: { dealerId: string | undefined },
) {
  const { showMessage } = useAppMessageContext();
  const selectedDealer = useGetSelectedDealer();

  const user = getMeRequest()?.data?.data;
  const {
    responseData: overlayData,
    isLoading: overlayLoading,
    refetch: refetchOverlay,
  } = GetOverlayRequest(user?.ovelay_id || selectedDealer?.overlay_id);
  const updateOverlayReq = UpdateOverlayRequest();

  const [loggedDealerId, setloggedDealerId] = useState<number>(0);

  useEffect(() => {
    if (selectedDealer?.id) {
      setloggedDealerId(selectedDealer.id);
    }

    if (user?.dealer_id) {
      setloggedDealerId(user.dealer_id);
    }
  }, [selectedDealer?.id, user?.dealer_id]);

  const { responseData: defaultStatusOverlays, refetch: refetchDefaultStatusOverlays } = getCustomStatusOverlaysRequest({ dealer_id: loggedDealerId, is_default: 1 });
  const { responseData: customStatusOverlays, refetch: refetchCustomStatusOverlays } = getCustomStatusOverlaysRequest({ dealer_id: loggedDealerId, is_default: 0 });

  const handleOverlayFormSubmit = (data: UpdateOverlayRequestData) => {
    updateOverlayReq.mutate({
      data,
      id: selectedDealer?.overlay_id ? selectedDealer?.overlay_id : Number(user?.ovelay_id),
    });
  };

  const defaultOverlayOptions = formSelectOptions(['All', 'None', 'Primary']);
  const logoPlacementOptions = formSelectOptions(['Disabled', 'Lower Left', 'Lower Right', 'Upper Left', 'Upper Right']);
  const textOptions = formSelectOptions(['Disabled', 'Unit\'s Location Name', 'Unit\'s Location Phone Number']);

  const overlayInitialValues: OverlaySectionFormI = {
    logoSize: overlayData?.logo_size ? String(overlayData?.logo_size) : '25',
    logo: null,
    logo_url: overlayData?.logo_url || '',
    defaultOverlay: overlayData?.default_setting ? formSelectOptions([overlayData?.default_setting])[0] : defaultOverlayOptions[1],
    logoPlacement: overlayData?.logo_placement ? formSelectOptions([overlayData?.logo_placement])[0] : logoPlacementOptions[0],
    statusOverlay: overlayData?.status_overlay || '',
    statusOverlaySize: overlayData?.status_overlay_size ? String(overlayData.status_overlay_size) : '25',
    statusOverlayUrl: overlayData?.status_overlay_url || '',
    statusOverlayFile: null,
    statusOverlayPlacement: overlayData?.status_overlay_placement ? formSelectOptions([overlayData?.status_overlay_placement])[0] : logoPlacementOptions[0],
    removeStatusOverlay: false,
    upperText: overlayData?.upper_text ? formSelectOptions([overlayData?.upper_text])[0] : textOptions[0],
    upperBannerColor: overlayData?.upper_background_color || '#ffffff',
    upperTextColor: overlayData?.upper_text_color || '#000000',
    lowerText: overlayData?.lower_text ? formSelectOptions([overlayData?.lower_text])[0] : textOptions[0],
    lowerBannerColor: overlayData?.lower_background_color || '#ffffff',
    lowerTextColor: overlayData?.lower_text_color || '#000000',
    customStatus: customStatusOverlays,
    defaultStatus: defaultStatusOverlays,
  };
  useEffect(() => {
    if (updateOverlayReq.isSuccess) {
      showMessage({ type: 'success', message: 'Overlay Saved successfully' });
      updateOverlayReq.reset();
      refetchOverlay();
      refetchDefaultStatusOverlays();
      refetchCustomStatusOverlays();
    }
  }, [updateOverlayReq.isSuccess]);

  if (overlayLoading) {
    return null;
  }
  return (
    <OverlaySectionForm
      onFormSubmit={handleOverlayFormSubmit}
      initialValues={overlayInitialValues}
      defaultOverlayOptions={defaultOverlayOptions}
      defaultStatusOverlays={defaultStatusOverlays}
      customStatusOverlays={customStatusOverlays}
      statusOverlayPlacementOptions={logoPlacementOptions}
      logoPlacementOptions={logoPlacementOptions}
      textOptions={textOptions}
    />
  );
}

export default OverlaySection;
