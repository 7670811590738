import {
  AxiosError, AxiosResponse,
} from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../../App';
import { AxiosResponseInterface } from '../../config/axios';
import {
  clearLocalStorage, setLocalStorage,
} from '../../helpers';
import { LoginFormData } from '../../pages/Login/loginForm';
import { RegistrationErrorsType } from '../../pages/Registration';
import Pages from '../../Router/pages';
import { LocalStorageKeys, RequestKeys } from '../keys';
import { AuthRequests } from './requests';
import { MeType, MFAType } from './types';

export const RegistrationRequest = () => {
  const mutation = useMutation(
    {
      mutationKey: [RequestKeys.REGISTRATION],
      mutationFn: AuthRequests.Registration,
      onSuccess: (res: AxiosResponse<{token: string}>) => {
        setLocalStorage(LocalStorageKeys.TOKEN, res.data.token);
      },
      onError: (error: AxiosError<RegistrationErrorsType>) => {
        if (error?.response?.status && error?.response?.status !== 200) {
          queryClient.setQueryData([RequestKeys.REGISTRATION_ERROR], error.response.data);
        }
      },
    },
  );
  return { ...mutation };
};
export const LoginRequest = () => {
  const mutation = useMutation(
    {
      mutationKey: [RequestKeys.LOGIN],
      mutationFn: AuthRequests.Login,
      onSuccess: (res: AxiosResponse<{token: string}>) => {
        setLocalStorage(LocalStorageKeys.TOKEN, res.data.token);
      },
      onError: (error: AxiosError<LoginFormData>) => {
        if (error?.response?.status && error?.response?.status !== 200) {
          queryClient.setQueryData([RequestKeys.LOGIN_ERROR], error?.response?.data);
        }
      },
    },
  );
  return { ...mutation };
};
export const AdminLoginRequest = () => {
  const mutation = useMutation(
    {
      mutationKey: [RequestKeys.ADMIN_LOGIN],
      mutationFn: AuthRequests.AdminLogin,
      onSuccess: (res: AxiosResponse<{token: string}>) => {
        setLocalStorage(LocalStorageKeys.TOKEN, res.data.token);
      },
      onError: (error: AxiosError<LoginFormData>) => {
        if (error?.response?.status && error?.response?.status !== 200) {
          queryClient.setQueryData([RequestKeys.LOGIN_ERROR], error?.response?.data);
        }
      },
    },
  );
  return { ...mutation };
};

type LogoutSuccess = { message: string }
type LogoutError = { message: string }
export const LogoutRequest = (isAdminLogout?: boolean) => {
  const mutation = useMutation(
    {
      mutationKey: [RequestKeys.LOGOUT],
      mutationFn: AuthRequests.Logout,
      onSuccess: (res: AxiosResponseInterface<LogoutSuccess, LogoutError>) => {
        if (!(res instanceof AxiosError)) {
          clearLocalStorage();
          window.location.href = isAdminLogout ? Pages.adminLogin : Pages.login;
        }
      },
    },
  );
  return { ...mutation };
};

export const getMeRequest = (enabled = true) => {
  const resp = useQuery<AxiosResponse<MeType>>(
    {
      queryKey: [RequestKeys.ME],
      queryFn: AuthRequests.Me,
      refetchOnMount: true,
      retry: false,
      enabled,
    },
  );
  return resp;
};

export const getMfaCodeRequest = (id: number, enabled?: boolean) => {
  const route = process.env.REACT_APP_MFA_PROFILES_URL;
  const request = useQuery<AxiosResponse<MFAType>>(
    {
      queryKey: [RequestKeys.MFA_CODE, id],
      enabled: !!(!!id && route) && enabled,
      staleTime: 2000,
      refetchInterval: 30000,
      queryFn: () => AuthRequests.MfaCode(id, route as string),
    },
  );
  return { ...request, data: request?.data?.data?.token };
};

export const ResetPasswordRequest = () => {
  const mutation = useMutation<AxiosResponse, AxiosError<{exception: string, message: string}>, unknown, unknown>(
    {
      mutationKey: [RequestKeys.RESET_PASSWORD],
      mutationFn: AuthRequests.ResetPassword,
    },
  );
  return { ...mutation };
};

export const ForgotPasswordRequest = () => {
  const mutation = useMutation(
    { mutationKey: [RequestKeys.FORGOT_PASSWORD], mutationFn: AuthRequests.ForgotPassword },
  );
  return { ...mutation };
};

export type NotificationT = {
    id: string
    type: string
    read_at: null | string
    created_at: string
    updated_at: string
    filename: string
    message: string
}
export const getNotificationsRequest = () => {
  const resp = useQuery<AxiosResponse<NotificationT[]>>(
    {
      queryKey: [RequestKeys.NOTIFICATIONS],
      queryFn: AuthRequests.Notifications,
      refetchOnMount: true,
      refetchInterval: 1000 * 60 * 5,
    },
  );
  return { ...resp, respData: resp?.data?.data };
};
export const deleteNotificationsRequest = () => {
  const mutation = useMutation<AxiosResponse, AxiosError<{exception: string, message: string}>, { ids: string[] }, unknown>(
    {
      mutationKey: [RequestKeys.NOTIFICATIONS_DELETE],
      mutationFn: AuthRequests.NotificationsDelete,
    },
  );
  return { ...mutation };
};

export const markNotificationsReadRequest = () => {
  const mutation = useMutation<AxiosResponse, AxiosError<{exception: string, message: string}>, { ids: string[] }, unknown>(
    {
      mutationKey: [RequestKeys.NOTIFICATIONS_READ],
      mutationFn: AuthRequests.MarkNotificationsRead,
    },
  );
  return { ...mutation };
};
