import { ApplicationTheme } from '../../../globalStyles';

interface SVGProps {
    fill?: string
    stroke?: string
}

function ArrowSvg(props: SVGProps): JSX.Element {
  const { fill, stroke } = props;
  return (
    <svg
      fill={fill || ApplicationTheme.iconsColor}
      viewBox="7 11 10.171 1"
    >
      <path stroke={stroke} d="M 8.7 11.7 L 11.3 14.3 C 11.7 14.7 12.3 14.7 12.7 14.3 L 15.3 11.7 C 15.9 11.1 15.5 10 14.6 10 H 9.4 C 8.5 10 8.1 11.1 8.7 11.7 Z" />
    </svg>
  );
}

export default ArrowSvg;
