import styled from 'styled-components';
import CustomButton from '../../../../components/Buttons/CustomButton';
import CustomTable from '../../../../components/CustomTable';
import { niceBytes } from '../../../../helpers';
import { useGetSelectedDealer } from '../../../../hooks/getDealersHook';
import { CSVFile, getCSVRequest } from '../../../../store/settings/actions';

function HistoryTab() {
  const selectedDealer = useGetSelectedDealer();
  const { responseData: csvFiles, isFetching } = getCSVRequest({
    ...(selectedDealer?.id && { dealer_id: selectedDealer?.id }),
  });
  return (
    <Container>
      <CustomTable
        loading={isFetching}
        data={csvFiles.map((dataRow) => ({
          ...dataRow,
          action: <ActionCell item={dataRow} />,
          size: <SizeCell item={dataRow} />,
          created_at: <CreatedAtCell item={dataRow} />,
        }))}
        columns={columns}
        selectable={false}
        displayPagination={false}
        displayTotal={false}
      />
    </Container>
  );
}

export default HistoryTab;
function ActionCell({ item }: {item: CSVFile }) {
  return (
    <div className="action-cell">
      <CustomButton variant="primary">
        <a href={item.url} target="_blank" rel="noreferrer">Download</a>
      </CustomButton>
    </div>
  );
}
function SizeCell({ item }: {item: CSVFile}) {
  return (
    <div className="size-cell">
      {niceBytes(item.size)}
    </div>
  );
}
function CreatedAtCell({ item }: {item: CSVFile}) {
  const createdAt = new Date(item.created_at);
  const hours = createdAt.toLocaleDateString('en-US', {
    hour: 'numeric', minute: 'numeric', hour12: true,
  });
  return (
    <div className="created_at-cell">
      {hours}
    </div>
  );
}

type CSVTAbleColumns = {
  label: string
  key: keyof CSVFile | 'action'
  width?: number
}

const columns: CSVTAbleColumns[] = [
  {
    label: 'Uploaded On',
    key: 'created_at',
  },
  {
    label: 'File Name',
    key: 'name',
  },
  {
    label: 'File Size',
    key: 'size',
  },
  {
    label: '',
    key: 'action',
    width: 150,
  },

];

const Container = styled.div`
  .fullHeightContainer {
    height: calc(100vh - 304px);
  }
  .action-cell {
    width: 114px;
    button {
      text-transform: unset;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }
`;
