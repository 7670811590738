import { ApplicationTheme } from '../../../globalStyles';

interface SVGProps {
    fill?: string
    stroke?: string
}

function InventorySvg(props: SVGProps): JSX.Element {
  const { fill, stroke } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={fill || ApplicationTheme.iconsColor}
      viewBox="0 0 24 24"
    >
      <path stroke={stroke} d="M22,15V17H10A3,3 0 0,1 7,20A3,3 0 0,1 4,17H2V6A2,2 0 0,1 4,4H17A2,2 0 0,1 19,6V15H22M7,16A1,1 0 0,0 6,17A1,1 0 0,0 7,18A1,1 0 0,0 8,17A1,1 0 0,0 7,16Z" />
    </svg>
  );
}

export default InventorySvg;
