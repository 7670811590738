import React, { useMemo } from 'react';
import styled from 'styled-components';
import CustomSelect, { SelectOption, SelectValue } from '../../../../components/inputs/CustomSelect';
import { CategoryFilters, LocationFilters } from '../../../../store/unit/actions';
import { useInventoryManagerContext } from '../../inventoryManagerContext';

export type ManufacturerFilterT = SelectOption & {
    is_placeholder?: boolean
} | null

export type CategoryFilterT = Omit<CategoryFilters, 'id'> & {
    is_placeholder?: boolean
    id?: number
} | null

export type StatusFilterT = SelectOption & {
    is_placeholder?: boolean
} | null
export type ConditionFilterT = SelectOption & {
    is_placeholder?: boolean
} | null

export type LocationsFilterT = Omit<LocationFilters, 'id'> & {
    is_placeholder?: boolean
    id?: number
} | null
export type inventoryTypeFilterT = SelectValue & {
    is_active: boolean
}
export type FiltersType = {
    location: LocationsFilterT
    manufacturer: ManufacturerFilterT
    category: CategoryFilterT
    status: StatusFilterT
    conditions: ConditionFilterT
    imageCount: ConditionFilterT
    inventoryType: inventoryTypeFilterT
}

interface FiltersProps {
    locationOptions: LocationFilters[]
    manufacturerOptions: SelectOption[]
    categoryOptions: CategoryFilters[]
    statusOptions: SelectOption[]
    conditionOptions: SelectOption[]
    className?: string
}

function Filters({
  locationOptions, manufacturerOptions, categoryOptions, statusOptions, conditionOptions, className,
}: FiltersProps) {
  const formLocationOption = [{ name: 'All', is_placeholder: true }, ...locationOptions];
  const formCategoryOption = useMemo(() => [{ name: 'All', is_placeholder: true }, ...categoryOptions], [categoryOptions]);
  const {
    locationFilter,
    setLocationFilter,
    manufacturerFilter,
    setManufacturerFilter,
    categoryFilter,
    setCategoryFilter,
    statusFilter,
    setStatusFilter,
    conditionFilter,
    setConditionFilter,
    setPage,
  } = useInventoryManagerContext();
  const locationLabel = (data: LocationFilters) => (
    <div>{data.name}</div>
  );
  const categoryLabel = (data: CategoryFilters) => (
    <div>{data.name}</div>
  );
  const handleFiltersChange = () => {
    setPage(1);
  };
  return (
    <FiltersContainer className={className}>
      <div className="select-wrapper">
        <CustomSelect
          options={formLocationOption}
          label="Location"
          name="location"
          value={locationFilter}
          formatOptionLabel={(data: LocationFilters) => locationLabel(data)}
          isOptionSelected={(data: LocationFilters) => data.id === locationFilter?.id}
          onChange={(val) => {
            setLocationFilter(val as LocationFilters);
            handleFiltersChange();
          }}
          controlStyles={{ border: 'none', width: '195px' }}
        />
      </div>
      <div className="select-wrapper">
        <CustomSelect
          options={manufacturerOptions}
          label="Manufacturer"
          name="manufacturer"
          value={manufacturerFilter}
          onChange={(val) => {
            setManufacturerFilter(val);
            handleFiltersChange();
          }}
          controlStyles={{ border: 'none', width: '195px' }}
        />
      </div>
      <div className="select-wrapper">
        <CustomSelect
          options={formCategoryOption}
          label="Category"
          name="category"
          value={categoryFilter}
          formatOptionLabel={(data: CategoryFilters) => categoryLabel(data)}
          isOptionSelected={(data: CategoryFilters) => data.id === categoryFilter?.id}
          onChange={(val) => {
            setCategoryFilter(val as FiltersType['category']);
            handleFiltersChange();
          }}
          controlStyles={{ border: 'none', width: '195px' }}
        />
      </div>
      <div className="select-wrapper">
        <CustomSelect
          options={statusOptions}
          label="Status"
          name="status"
          value={statusFilter}
          onChange={(val) => {
            setStatusFilter(val);
            handleFiltersChange();
          }}
          controlStyles={{ border: 'none', width: '195px' }}
        />
      </div>
      <div className="select-wrapper">
        <CustomSelect
          options={conditionOptions}
          label="Condition"
          name="condition"
          value={conditionFilter}
          onChange={(val) => {
            setConditionFilter(val);
            handleFiltersChange();
          }}
          controlStyles={{ border: 'none', width: '195px' }}
        />
      </div>
    </FiltersContainer>
  );
}

export default Filters;

const FiltersContainer = styled.div`
  margin-top: 26px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //max-width: 1080px;
  //margin-left: 64px;
  //margin-left: 3rem;

  > :not(:nth-child(1)) {
    margin-left: 3rem;
  }
  
  @media (max-width: 600px) {
    > {
      margin-left: 0;
    }
  } 

  //> :not(:nth-child(1)) {
  //  margin-right: 3rem;
  //}

  &.drawer-filters {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    margin-left: 0;

    > :not(:nth-child(1)) {
      margin-left: 0;
    }

    .select-wrapper {
      margin-top: 10px;
    }

    .select-container {
      display: flex;
      flex-direction: column;
      min-width: 147px;

      .select-wrap {
        border: 1px solid #747474;
        border-radius: 4px;

        div {
          max-width: 100%;
        }
      }

      label {
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
        margin-top: 5px;
      }
    }
  }

  .select-container {
    flex-direction: column;
    align-items: baseline;
  }
  @media (max-width: 1230px) {
    justify-content: start;
  }
`;
