import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge, Dropdown, Popover, Whisper,
} from 'rsuite';
import { OverlayTriggerProps } from 'rsuite/esm/Overlay/OverlayTrigger';
import styled from 'styled-components';
import Tooltip from 'rsuite/Tooltip';
import imagePlaceholder from '../../../assets/image_placeholder.png';
import ArrowSvg from '../../../components/SvgElements/ArrowSvg';
import EditSvg from '../../../components/SvgElements/EditSvg';
import {
  formDate, getLogoPosition, isEditDisabled,
} from '../../../helpers';
import Pages from '../../../Router/pages';
import { FileFromResponse, UnitPrintType, UnitType } from '../../../store/unit/actions';
import { FiltersType } from '../ControlPanel/Filters';
import { useInventoryManagerContext } from '../inventoryManagerContext';
import InventoryManagerDeleteModal, { InventoryManagerDeleteModalProps } from '../InventoryManagerDeleteModal';
import InventoryManagerDuplicateUnitModal from '../InventoryManagerDuplicateUnitModal';
import QuickEditModal from '../QuickEditModal';

export type RenderPrimaryImageCellType = {
  image: FileFromResponse,
  placeholder?: string,
  userOverlaySettings: UnitType['user_overlay_settings']
  imagesCount: UnitType['images_count']
  overlaySetting: UnitType['overlay_setting']
}
function RenderImage({
  image, placeholder, userOverlaySettings, imagesCount, overlaySetting,
}:RenderPrimaryImageCellType) {
  const url = image?.thumb_uri || image?.url || '';
  const thumbUrl = image?.thumb_uri || '';
  const originalUrl = image?.url || '';
  const splittedName = url.split('/') || '';
  return (
    <ImageCellContainer>
      {image?.url
        ? (
          <div className={`thumb-wrapper ${getLogoPosition(String(userOverlaySettings?.logo_placement))}`}>
            {(userOverlaySettings?.upper_text && userOverlaySettings?.upper_text !== 'Disabled')
                      && overlaySetting !== 'None'
                      && (
                      <div
                        className="upper-text-overlay"
                        style={{
                          color: userOverlaySettings?.upper_text_color || '',
                          backgroundColor: userOverlaySettings?.upper_background_color || '',
                        }}
                      >
                        {userOverlaySettings?.upper_text}
                      </div>
                      )}
            {(userOverlaySettings?.lower_text && userOverlaySettings?.lower_text !== 'Disabled')
                      && overlaySetting !== 'None'
                      && (
                      <div
                        className="lower-text-overlay"
                        style={{
                          color: userOverlaySettings?.lower_text_color || '',
                          backgroundColor: userOverlaySettings?.lower_background_color || '',
                        }}
                      >
                        {userOverlaySettings?.lower_text}
                      </div>
                      )}
            <img
              src={thumbUrl}
              alt={splittedName[splittedName.length - 1] || 'primary'}
              className="main-img"
              width="100%"
              loading="lazy"
              onError={({ currentTarget }) => {
                // eslint-disable-next-line no-param-reassign
                currentTarget.onerror = null; // prevents looping
                // eslint-disable-next-line no-param-reassign
                if (currentTarget?.src !== originalUrl) {
                  // eslint-disable-next-line no-param-reassign
                  currentTarget.src = originalUrl;
                } else {
                  // eslint-disable-next-line no-param-reassign
                  currentTarget.src = imagePlaceholder;
                }
              }}
            />
            {userOverlaySettings
                      && overlaySetting !== 'None'
                      && userOverlaySettings.logo_placement !== 'Disabled'
                      && (
                      <img
                        alt="preview"
                        src={userOverlaySettings?.logo_url}
                        className="overlay-image"
                        height={`${userOverlaySettings?.logo_size}%`}
                      />
                      )}
          </div>
        )
        : (
          <div className="thumb-wrapper">
            <div className="no-image">
              { placeholder ? (
                <img
                  src={placeholder}
                  alt="primary"
                  className="main-img"
                  width="100%"
                  loading="lazy"
                />
              ) : 'No Image'}
            </div>
          </div>
        )}
      {imagesCount > 0
            && (
            <span className="image-count">
              <Badge
                content={imagesCount}
                maxCount={99}
                style={{
                  background: '#c0c0c0',
                  border: '1px solid #d6d6d6',
                  color: '#000',
                }}
              />
            </span>
            )}
    </ImageCellContainer>
  );
}

export function PrimeImageCell({ item }: { item: UnitPrintType }) {
  const image = item.primary_image;
  return (
    <RenderImage
      image={image}
      placeholder={item?.placeholder_image_url}
      userOverlaySettings={item?.user_overlay_settings}
      imagesCount={item?.images_count}
      overlaySetting={item?.overlay_setting}
    />
  );
}

export function ImageCell({ item }: { item: UnitType }) {
  const image = item.images.find((file) => file.order === 0) || item.images[0];
  return (
    <RenderImage image={image} placeholder={item?.placeholder_image_url} userOverlaySettings={item?.user_overlay_settings} imagesCount={item?.images_count} overlaySetting={item?.overlay_setting} />
  );
}
const ImageCellContainer = styled.div`
  position: relative;
  .thumb-wrapper {
    overflow: hidden;
    text-align: center;
    position: relative;
    width: 100px;
    height: 80px;
    display: flex;
    min-width: 0;
    border-radius: 4px;
    .no-image {
      color: gray;
      width: 100%;
      text-align: center;
      vertical-align: middle;
      line-height: 80px;
      border-radius: 4px;
      border: 1px solid gray;
      background-color: #fff;
    }
    .overlay-image {
      width: auto;
      z-index: 7;
      position: absolute;
      max-width: calc(100% - 10px); //calculate width with padding
      max-height: calc(100% - 10px);
      object-fit: initial;
    }
    .upper-text-overlay,
    .lower-text-overlay {
      overflow: hidden;
      position: absolute;
      text-align: center;
      font-size: 10px;
      width: 100%;
      left: 0;
      padding-top: 2px;
      z-index: 6;
      max-height: 16px;
    }
    .upper-text-overlay {
      top: 0;
    }
    .lower-text-overlay {
      bottom: 0;
    }
    &.ll {
      align-items: flex-end;
    }
    &.lr {
      justify-content: flex-end;
      align-items: flex-end;
    }
    &.ul {
      justify-content: flex-start;
      align-items: flex-start;
    }
    &.ur {
      justify-content: flex-end;
      align-items: flex-start;
    }
  }
  .main-img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border: 1px solid ${(props) => props.theme.primaryColor};
    border-radius: 8px;
    &.placeholder {
      max-height: 100%;
    }
  }
  .image-count {
    position: absolute;
    top: 37%;
    right: -9px;
    z-index: 2;
    //background-color: #c0c0c0;
    //border:  1px solid #d6d6d6;
    //border-radius: 50%;
    //min-width: 18px;
    //height: 18px;
  }
`;

export function NotesCell({ item }: {item: UnitType | UnitPrintType}) {
  return (
    <NotesContainer className={`${item.details?.notes && 'with-note'}`}>
      <div>
        {item.details?.notes || ''}
      </div>
    </NotesContainer>
  );
}
const NotesContainer = styled.div`
  width: 100%;
  max-height: 100px;
  overflow: auto;
  padding: 8px;
  border-radius: 1px;
  &.with-note {
    box-shadow: -1px 1px 2px rgba(250, 165, 29, 0.49);
    background-color: #fef4e2;
  }
`;
export function LocationCell({ item }: {item: UnitType | UnitPrintType}) {
  return (
    <div>
      {item.dealership_location.name}
    </div>
  );
}

const StatusContainer = styled.div`
  .status {
    margin-right: 5px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.Available {
      background-color: #629c44;
    }
    &.Sold {
      background-color: #668ae9;
    }
    &.Order,
    &.Pending {
      background-color: #ffa834;
    }
  }
`;

export function StatusCell({ item }: {item: UnitType | UnitPrintType}) {
  return (
    <Whisper placement="top" controlId={`${item?.stock}`} trigger={['hover']} speaker={statusTooltip({ item })}>
      <StatusContainer className="status-wrap">
        <div className={`status ${item?.status}`} />
      </StatusContainer>
    </Whisper>
  );
}

const StyledTooltip = styled(Tooltip)`
  max-width: 241px;
  padding: 10px;
  text-align: center;
  font-size: 13px;
  background-color: ${(props) => props.theme.primaryBGColor};
  border-radius: 3px;
  border: 1px solid #f7f6f6;
  color: ${(props) => props.theme.primaryTextColor};
  .highlighted {
    color: ${(props) => props.theme.primaryColor};
    font-weight: bold;
    line-height: 1.3rem;
  }
`;

const statusTooltip = ({ item }: {item: UnitType | UnitPrintType}) => (
  <StyledTooltip>
    <div className="tooltip">
      <span className={`status ${item?.status}`}>
        {item?.status}
      </span>
    </div>
  </StyledTooltip>
);

export function PriceCell({ item }: {item: UnitType | UnitPrintType}) {
  return (
    <div>
      {(item?.details?.price !== '0.00' ? item?.details?.price : item?.details?.msrp) || '0.00'}
    </div>
  );
}

export function SalesPriceCell({ item }: {item: UnitType | UnitPrintType}) {
  return (
    <div>
      {item?.details?.sales_price || '0.00'}
    </div>
  );
}

export function CategoryCell({ item }: {item: UnitType | UnitPrintType}) {
  return (
    <div>
      {item.item_category?.name}
    </div>
  );
}

export function CreatedAtCell({ item }: {item: UnitType | UnitPrintType}) {
  const createdAt = new Date(item.created_at);
  const hours = createdAt.toLocaleDateString('en-US', {
    hour: 'numeric', minute: 'numeric', hour12: true,
  }).split(',')[1];
  const date = createdAt.toLocaleDateString('en-US', {
    day: 'numeric', month: 'numeric', year: '2-digit',
  });
  return (
    <StyledDateCell>
      {hours}
      {' '}
      {date}
    </StyledDateCell>
  );
}
export function UpdatedAtCell({ item }: {item: UnitType | UnitPrintType}) {
  return (
    <StyledDateCell>
      {formDate(item.updated_at)?.time}
      {' '}
      {formDate(item.updated_at)?.date}
    </StyledDateCell>
  );
}

const StyledDateCell = styled.div`
  max-width: 70px;
`;

export type ActionCellProps = {
  item: UnitType,
  onModeChange?: (id: number, newMode: InventoryManagerDeleteModalProps['mode']) => void,
  inventoryType?: FiltersType['inventoryType']
  refetchUnits?: () => void
  hideExtra?: boolean
}
export function ActionCell({
  item, onModeChange, inventoryType, refetchUnits, hideExtra,
}: ActionCellProps) {
  const { setPermanentDelete } = useInventoryManagerContext();
  const [showModel, setShowModal] = useState<{mode: InventoryManagerDeleteModalProps['mode']} | null>(null);
  const [showDuplicateModal, setShowDuplicateModal] = useState<UnitType | null>(null);
  const [showEditModal, setShowEditModal] = useState<UnitType | null>(null);
  type renderMenuProps = OverlayTriggerProps['speaker']
  const disabledEdit = isEditDisabled(item?.dealer_schedules);
  const renderMenu: renderMenuProps = ({
    onClose, left, top, className,
  }, ref) => {
    const handleSelect = () => {
      onClose();
    };
    const isActiveInventory = inventoryType?.value === 'Active Inventory';

    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu onSelect={handleSelect}>
          {!disabledEdit
          && (
          <Dropdown.Item
            onClick={() => {
              setShowEditModal(item);
              handleSelect();
            }}
          >
            Quick Edit
          </Dropdown.Item>
          )}
          {!hideExtra
                && (
                <>
                  {isActiveInventory
                    ? (
                      <Dropdown.Item
                        onClick={() => {
                          setShowModal({ mode: 'Archive' });
                          handleSelect();
                        }}
                      >
                        Archive
                      </Dropdown.Item>
                    )
                    : (
                      <Dropdown.Item
                        onClick={() => {
                          setShowModal({ mode: 'Unarchive' });
                          handleSelect();
                        }}
                      >
                        Unarchive
                      </Dropdown.Item>
                    )}
                  <Dropdown.Item
                    onClick={() => {
                      setShowDuplicateModal(item);
                      handleSelect();
                    }}
                  >
                    Duplicate
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setPermanentDelete(true);
                      setShowModal({ mode: 'Delete' });
                      handleSelect();
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                </>
                )}
        </Dropdown.Menu>
      </Popover>
    );
  };
  const handleAcceptModal = () => {
    onModeChange?.(item.id, showModel?.mode);
    setShowModal(null);
  };
  return (
    <ActionButtonsContainer>
      <Whisper
        controlId="edit-tooltip-container"
        preventOverflow
        trigger={disabledEdit ? 'hover' : 'none'}
        speaker={(
          <Tooltip>
            Unit is unable to be edited while being Posted
          </Tooltip>
            )}
        placement="top"
      >
        <Link
          to={`${Pages.editUnitPure}/${item.id}`}
          onClick={(e) => {
            if (disabledEdit) {
              e?.preventDefault();
            }
          }}
        >
          <IconWrapper className={`${disabledEdit ? 'disabled' : ''}`}>
            <EditSvg
              {...(disabledEdit && { stroke: '#adadad' })}
            />
          </IconWrapper>
        </Link>
      </Whisper>
      <div>
        <Whisper placement="autoHorizontal" trigger="click" speaker={renderMenu}>
          <IconWrapper>
            <ArrowSvg />
          </IconWrapper>
        </Whisper>
      </div>
      <InventoryManagerDeleteModal
        showModel={!!showModel}
        setShowModal={() => setShowModal(null)}
        handleAcceptModal={handleAcceptModal}
        mode={showModel?.mode}
      />
      <InventoryManagerDuplicateUnitModal
        showModal={showDuplicateModal}
        onModalClose={() => setShowDuplicateModal(null)}
        onSuccessDuplicate={() => {
          setShowDuplicateModal(null);
          refetchUnits?.();
        }}
      />
      <QuickEditModal
        showModel={showEditModal}
        setShowModal={() => setShowEditModal(null)}
        handleSuccessClose={() => {
          setShowEditModal(null);
          refetchUnits?.();
        }}
      />
    </ActionButtonsContainer>
  );
}
const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 13px;
  img {
    aspect-ratio: 1/0;
  }
  .intercom-namespace {
    div {
      position: relative;
      top: -37px;
      left: 25px;
    }
  }
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.primaryColor};
  transition: all ease 0.2s;
  :hover {
    border: 2px solid ${(props) => props.theme.primaryColor};
  }
  &.disabled {
    border: 1px solid #adadad;
    cursor: not-allowed;
    :hover {
      border: 2px solid #7a7a7a;
    } 
    svg {
      fill: #adadad;
    }
  }
  svg {
    width: 22px;
    height: 22px;
  }
`;
export function MobileViewCell({ item, refetchUnits }: {item: UnitType, refetchUnits: () => void}) {
  const [showEditModal, setShowEditModal] = useState<UnitType | null>(null);
  return (
    <>
      <StyledMobileViewCell
        onClick={() => { setShowEditModal(item); }}
      >
        <div className="row">
          <div className="main-content">
            {item.title}
          </div>
          <div className="price price-item">
            Price:
            {' '}
            {item.details?.price}
          </div>
        </div>
        <div className="row">
          <div className="main-content">
            Stock #:
            {item.stock}
          </div>
          <div className="sales-price price-item">
            Sales Price:
            {' '}
            {item.details?.sales_price}
          </div>
        </div>
      </StyledMobileViewCell>
      <QuickEditModal
        showModel={showEditModal}
        setShowModal={() => {
          setShowEditModal(null);
        }}
        handleSuccessClose={() => {
          setShowEditModal(null);
          refetchUnits();
        }}
      />
    </>
  );
}
const StyledMobileViewCell = styled.div`
  text-align: initial;
  width: 100%;
  @media screen and (max-width: 520px) {
    overflow: auto;
    max-width: 60vw;
  }
  @media screen and (max-width: 440px) {
    overflow: auto;
    max-width: 56vw;
  }
    .row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    width: 66vw;
    .price-item {
      color: ${(props) => props.theme.primaryColor};
      font-weight: bold;
    }
    .main-content {
      max-width: 55%;
    }
  }
`;
