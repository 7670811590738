import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAppMessageContext } from '../../../../appMessagesContext';
import { SelectedDealer, useGetSelectedDealer } from '../../../../hooks/getDealersHook';
import useNavigateHook from '../../../../hooks/useNavigateHook';
import Pages from '../../../../Router/pages';
import {
  CreateFbProfileRequest,
  EditFbProfileRequest,
  getFbProfileByIdRequest,
} from '../../../../store/settings/actions';
import FbProfileTabForm, { FbProfileTabFormT, LocationT } from './FbProfileTabForm';
import FbProfileTabTable from './FbProfileTabTable';

export type CLProfilesPageMode = {
  mode: 'new' | 'edit' | 'duplicate'
  id: string
}
export const formFbProfileData = (data: FbProfileTabFormT, selectedDealer: SelectedDealer) => ({
  name: data.name,
  fb_token: data.fb_token,
  fb_login: data.fb_login,
  fb_password: data.fb_password,
  locations: data.locations.map((location: LocationT) => location?.value || location?.id),
  ...(selectedDealer?.id && { dealer_id: Number(selectedDealer?.id) }),
});

function FbProfilesTab() {
  const params = useParams<CLProfilesPageMode>();
  const { responseData } = getFbProfileByIdRequest(Number(params.id));
  const navigate = useNavigateHook();
  const { showMessage } = useAppMessageContext();

  const formInitialValues: FbProfileTabFormT = {
    name: responseData?.name || '',
    fb_token: responseData?.fb_token || '',
    fb_login: responseData?.fb_login || '',
    fb_password: responseData?.fb_password || '',
    locations: responseData?.locations || [],
    autoposting_configuration: responseData?.autoposting_configuration || undefined,
  };

  const saveRequest = CreateFbProfileRequest();
  const editRequest = EditFbProfileRequest();
  const requestErrors = saveRequest?.error?.response?.data.errors || editRequest?.error?.response?.data.errors;
  const selectedDealer = useGetSelectedDealer();

  useEffect(() => {
    if (saveRequest.isSuccess) {
      saveRequest.reset();
      showMessage({ type: 'success', message: 'Profile Saved successfully' });
      navigate(Pages.SchedulerSetting);
    }
  }, [saveRequest.isSuccess]);
  useEffect(() => {
    if (editRequest.isSuccess) {
      editRequest.reset();
      showMessage({ type: 'success', message: 'Profile Updated successfully' });
    }
  }, [editRequest.isSuccess]);
  useEffect(() => {
    if (editRequest.isError) {
      editRequest.reset();
      showMessage({ type: 'error', message: String(editRequest.error?.response?.data.message) });
    }
  }, [editRequest.isError]);

  const handleSubmit = (data: FbProfileTabFormT) => {
    saveRequest.mutate(formFbProfileData(data, selectedDealer));
  };
  const handleEdit = (data: FbProfileTabFormT) => {
    if (params.id) {
      editRequest.mutate({ data: formFbProfileData(data, selectedDealer), id: params.id });
    }
  };

  const renderTab = () => {
    switch (params.mode) {
      case 'edit':
        return <FbProfileTabForm initialValueProps={{ ...formInitialValues, id: params.id }} onSubmit={handleEdit} requestErrors={requestErrors} />;
      case 'duplicate':
        return <FbProfileTabForm onSubmit={handleSubmit} requestErrors={requestErrors} initialValueProps={{ ...formInitialValues, name: '' }} />;
      case 'new':
        return <FbProfileTabForm onSubmit={handleSubmit} requestErrors={requestErrors} />;
      default:
        return <FbProfileTabTable />;
    }
  };

  return (
    <Container>
      {renderTab()}
    </Container>
  );
}

export default FbProfilesTab;

const Container = styled.div`
  text-align: start;
  .header {
    text-align: end;
    margin-top: 10px;
  }
    .new-profile-button {
      width: 200px;
      margin-right: 20px;
      span {
        margin-left: 10px;
      }
    }
`;
