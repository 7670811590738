import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { Loader } from 'rsuite';
import styled from 'styled-components';
import { queryClient } from '../../../App';
import { useAppMessageContext } from '../../../appMessagesContext';
import CustomButton from '../../../components/Buttons/CustomButton';
import CustomRadio from '../../../components/CustomRadio';
import CustomInput from '../../../components/inputs/CustomInput';
import SettingsLayout from '../../../components/SettingsLayout';
import { SidebarItem } from '../../../components/SideBar';
import { useGetSelectedDealer } from '../../../hooks/getDealersHook';
import useNavigateHook from '../../../hooks/useNavigateHook';
import Pages from '../../../Router/pages';
import { FundsState } from '../../../store';
import { getMeRequest } from '../../../store/auth/actions';
import { RequestKeys } from '../../../store/keys';
import {
  checkoutRequest,
  getPaymentMethodRequest,
  removePaymentMethodRequest,
  setPaymentMethodRequest,
} from '../../../store/settings/actions';
import BillingCard from '../BillingCard';
import Stripe from '../Stripe';
import { roleHasAccess } from '../../../helpers';
import { BillingRoles } from '../../../store/constants';

export enum BillingSectionsEnum {
  BillingSection = 'Billing',
  HistorySection = 'History',
}

export const billingSections = [
  { label: BillingSectionsEnum.BillingSection, key: 'add-funds' },
  { label: BillingSectionsEnum.HistorySection, key: 'history' },
] as const;

export interface BillingSideBar extends SidebarItem {
  label: typeof billingSections[number]['label']
  key: typeof billingSections[number]['key']
  hidden: boolean
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

function AddFund() {
  const navigate = useNavigateHook();
  const user = getMeRequest()?.data?.data;
  const selectedDealer = useGetSelectedDealer();
  const { showMessage } = useAppMessageContext();
  const fundsState = queryClient.getQueryData<FundsState>([RequestKeys.ADD_FUNDS_STATE]);
  const { responseData: paymentMethod, refetch: refetchPaymentMethod } = getPaymentMethodRequest(
    { dealer_id: selectedDealer?.id || user?.dealer_id },
    (user?.role?.includes('admin') ? !!selectedDealer?.id : roleHasAccess(user?.role, BillingRoles)),
  );
  const setPaymentMethod = setPaymentMethodRequest();
  const removePaymentMethod = removePaymentMethodRequest();
  const checkout = checkoutRequest();
  const [activeSection, setActiveSection] = useState<typeof billingSections[number]['key']>(billingSections.find((item) => window.location.pathname.includes(item.key))?.key || 'add-funds');
  const [amount, setAmount] = useState<string>(fundsState?.funds || '');
  const [errors, setErrors] = useState<string>('');
  useEffect(() => {
    if (paymentMethod?.message && (user?.dealer_id || selectedDealer?.id)) {
      setPaymentMethod.mutate({ dealer_id: selectedDealer?.id || user?.dealer_id });
    }
  }, [paymentMethod?.message, selectedDealer?.id, user?.dealer_id]);

  useEffect(() => {
    if (checkout.isError) {
      showMessage({ type: 'error', message: checkout.error.response?.data.message || checkout.error.message });
    }
    if (setPaymentMethod.isError) {
      showMessage({ type: 'error', message: setPaymentMethod.error.response?.data.message || setPaymentMethod.error.message });
    }
  }, [checkout.isError, setPaymentMethod.isError]);

  const handleSidebarItemClick = (item: BillingSideBar) => {
    navigate(`${Pages.billing}/${item.key}`);
    setActiveSection(item.key);
  };

  const defaultSideBarItems = billingSections.map((section) => ({
    label: section.label, onClick: handleSidebarItemClick, key: section.key, page: Pages.billing,
  }));

  const options = {
    clientSecret: setPaymentMethod?.data?.data.client_secret,
    appearance: {/* ... */},
  };

  const handlePay = () => {
    if (Number(amount)) {
      if (Number(amount) < 5) {
        setErrors('Minimum amount is 5$');
      } else {
        const cents = Number(amount) * 100;
        checkout.mutate({ dealer_id: selectedDealer?.id || user?.dealer_id, amount: cents });
      }
    } else {
      setErrors('This field is required');
    }
  };

  useEffect(() => {
    if (checkout.isSuccess) {
      showMessage({ type: 'success', message: 'Payment successful' });
      navigate(`${Pages.billingHistory}`);
      checkout.reset();
    }
  }, [checkout.isSuccess]);

  useEffect(() => {
    if (setPaymentMethod.isSuccess || removePaymentMethod.isSuccess) {
      refetchPaymentMethod();
    }
  }, [setPaymentMethod.isSuccess, removePaymentMethod.isSuccess]);

  const handlePaymentChange = () => {
    removePaymentMethod.mutate({ dealer_id: selectedDealer?.id || user?.dealer_id });
  };

  return (
    <SettingsLayout navigateOnBack={Pages.inventoryManager} sideBarItems={defaultSideBarItems} headerTitle="Billing" activeSectionProp={activeSection}>
      <>
        {paymentMethod?.id
        && (
        <BillingContainer>
          <div className="amount-section">
            <CustomInput
              label="Amount"
              wrapperClass="input-wrapper price"
              value={amount}
              onChange={(value) => setAmount(value)}
              inputSymbol="$"
              pattern="numeric"
              error={errors}
            />
          </div>
          <hr />
          <div className="second-section">
            <div className="card-section">
              <CustomRadio
                value="active"
                id="active"
                checked
              />
              <BillingCard cardType={paymentMethod.card.brand} lastNumbers={paymentMethod?.card?.last4} />
            </div>
            <div className="change-payment">
              <CustomButton onClick={handlePaymentChange}>Change Payment Method</CustomButton>
              <p>This action will remove your current billing information.</p>
            </div>
          </div>
          <hr />
          <div className="checkout">
            <CustomButton onClick={handlePay} disabled={checkout.isPending}>Checkout</CustomButton>
          </div>
        </BillingContainer>
        )}
        {!setPaymentMethod?.data?.data.client_secret && !paymentMethod?.id
              && <Loader center />}
        {!paymentMethod?.id && setPaymentMethod?.data?.data.client_secret
            && (
            <StripeFormContainer>
              <Elements stripe={stripePromise} options={options}>
                <Stripe />
              </Elements>
            </StripeFormContainer>
            )}
      </>
    </SettingsLayout>
  );
}

export default AddFund;
const StripeFormContainer = styled.div`
  max-width: 600px;
  margin-left: 20px;
  margin-top: 20px;
`;

const BillingContainer = styled.div`
  padding-right: 20px;
  padding-left: 20px;
  max-width: 800px;
  .second-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .amount-section {
    .input-wrapper {
      display: flex;
      flex-direction: row;
      label {
        margin-right: 10px;
      }
      .input-symbol {
        top: 10px;
      }
    }
  }
  .card-section {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .primary {
    @media screen and (max-width: 900px) {
      font-size: 12px;
    }
  }
  .change-payment {
    margin-left: 40px;
    max-width: 300px;
    @media screen and (max-width: 900px) {
      margin-top: 20px;
    }
    p {
      margin-top: 10px;
      font-style: italic;
      color: #ff9b41;
      font-size: 11px;
    }
  }
  .checkout {
    max-width: 200px;
    margin-top: 30px;
    margin-left: 40px;
  }
  
`;
