import { useEffect, useState } from 'react';
import { queryClient } from '../App';
import { getLocalStorage, roleHasAccess } from '../helpers';
import { getMeRequest } from '../store/auth/actions';
import { SupperAdminRoles } from '../store/constants';
import { DealerType, GetDealersById } from '../store/dealers/actions';
import { LocalStorageKeys, RequestKeys } from '../store/keys';

export type SelectedDealer = DealerType | undefined

export const useGetSelectedDealer = (): SelectedDealer => {
  const idFromStorage = getLocalStorage(LocalStorageKeys.DEALER_ID);
  const [selectedDealer, setSelectedDealer] = useState<SelectedDealer>(undefined);
  const selectedDealerQuery = queryClient.getQueryData<SelectedDealer>([RequestKeys.SELECTED_DEALER_ID]);
  const user = getMeRequest()?.data?.data;
  const getDealerRequest = GetDealersById(idFromStorage, !!(idFromStorage && roleHasAccess(user?.role, SupperAdminRoles))); // get specific dealer is available only for "admin"

  useEffect(() => {
    if (getDealerRequest.isSuccess) {
      queryClient.setQueryData([RequestKeys.SELECTED_DEALER_ID], getDealerRequest.data);
      setSelectedDealer(getDealerRequest.data);
    }
  }, [getDealerRequest.isSuccess]);

  useEffect(() => {
    if (selectedDealerQuery) {
      setSelectedDealer(getDealerRequest.data as DealerType);
    }
  }, [selectedDealerQuery]);
  return selectedDealer;
};
