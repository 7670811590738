import * as Y from 'yup';

const loginFormSchema = Y.object().shape({
  email: Y.string().required('Email is required field').email().min(9)
    .max(255),
  password: Y.string()
    .required('No password provided.'),
});

export default loginFormSchema;

export const passwordConfirmationFormSchema = Y.object().shape({
  password: Y.string().required('This field is required'),
  password_confirmation: Y.string()
    .required('No confirm password provided.')
    .oneOf([Y.ref('password')], 'Passwords must match'),
});
