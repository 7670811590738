import { RowDataType } from 'rsuite-table/src/@types/common';
import { SelectOption, SelectValue } from '../../components/inputs/CustomSelect';
import { formSelectOptions } from '../../helpers';

export const USAstateOptions = formSelectOptions([
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]);

export const CanadaStateOptions = formSelectOptions([
  'Alberta',
  'Manitoba',
  'New Brunswick',
  'Nova Scotia',
  'British Columbia',
  'Ontario',
  'Newfoundland and Labrador',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
]);

export const CountriesList = ['USA', 'Canada'] as const;
export const CountriesOptions = [
  { label: 'USA', value: 'US' },
  { label: 'Canada', value: 'CA' },
];

export type StateByCountryOptionsT = {
  [key in typeof CountriesList[number]]: SelectOption[]
}

export const statesByCounty: StateByCountryOptionsT = {
  USA: USAstateOptions,
  Canada: CanadaStateOptions,
};
export interface LocationTableInterface extends RowDataType {
    id: string
    main_location: boolean
    location_name: string
    phone: string
    email: string[]
    contactFirstName: string
    contactLastName: string
    address?: {
        street: string
        city: string
        county: string
        state: SelectValue
        zipCode: string
    }
}

export type IntegrationMockType = {
  craigsList: {
    extensionId: string | null
  },
  facebook: {
    isConnected: boolean,
  },
}

export const locationTableData: LocationTableInterface[] = [
  {
    id: '1',
    main_location: true,
    location_name: 'Judson\'s Trailer Dealership, Armarillo',
    phone: '(111) 111-1111',
    email: ['test1@gmai.com'],
    contactFirstName: '',
    contactLastName: '',
    address: {
      street: '123 Jud Street West Palm',
      city: 'Judsonville',
      county: 'Sarasota',
      state: USAstateOptions[1],
      zipCode: '12345',
    },
  },
  {
    id: '2',
    main_location: false,
    location_name: '688 E 5th Ave, Columbus',
    phone: '(2222) 333-4444',
    email: ['placeholderMail@gmai.com', 'secondMail@some.com'],
    contactFirstName: '',
    contactLastName: '',
    address: {
      street: '688 E 5th Ave',
      city: 'Columbus',
      county: 'Armarillo',
      state: USAstateOptions[7],
      zipCode: '43201',
    },
  },
];
