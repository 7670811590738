import * as Y from 'yup';

const changePasswordFormSchema = Y.object().shape({
  password: Y.string()
    .required('No password provided.')
    .min(8, 'Password must be at least 8 characters')
    .max(255, 'Password must not be more than 255 characters')
    .oneOf([Y.ref('passwordConfirmation')], 'Passwords must match'),
  passwordConfirmation: Y.string()
    .required('No confirm password provided.')
    .oneOf([Y.ref('password')], 'Passwords must match'),
});

export default changePasswordFormSchema;
