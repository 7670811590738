import { useFormik } from 'formik';
import styled from 'styled-components';
import CustomButton from '../../components/Buttons/CustomButton';
import CustomInput from '../../components/inputs/CustomInput';
import { passwordConfirmationFormSchema } from '../../validation/loginFormSchema';

export interface ResetPasswordFormData {
    password: string
    password_confirmation: string
    email: string
    token: string
}
export type ResetPasswordFormT = Pick<ResetPasswordFormData, 'password' | 'password_confirmation'>
interface ResetPasswordFormProps {
    handleSubmit: (data: ResetPasswordFormT) => void
    loading: boolean
    error?: string
}

function ResetPasswordForm({
  handleSubmit, loading, error,
}: ResetPasswordFormProps) {
  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: passwordConfirmationFormSchema,
    onSubmit: handleSubmit,
  });
  const {
    setFieldValue, values: formikValues, errors, touched,
  } = formik;

  const passwordError = errors.password && touched.password ? errors.password : '';
  const passwordConfirmationError = errors.password_confirmation && touched.password_confirmation ? errors.password_confirmation : '';

  return (
    <form onSubmit={(event) => {
      event.preventDefault();
      formik.submitForm();
    }}
    >
      <InputsContainer>
        <CustomInput
          value={formikValues.password}
          placeholder="Password"
          name="password"
          type="password"
          onChange={(val) => setFieldValue('password', val)}
          wrapperClass="input-wrapper"
          error={passwordError || error}
        />
        <CustomInput
          placeholder="Confirm password"
          type="password"
          name="password_confirmation"
          value={formikValues.password_confirmation}
          onChange={(val) => setFieldValue('password_confirmation', val)}
          wrapperClass="input-wrapper"
          error={passwordConfirmationError}
        />
        <PrimaryButtonWrapper>
          <CustomButton type="submit" disabled={loading}>Reset password</CustomButton>
        </PrimaryButtonWrapper>
      </InputsContainer>
    </form>
  );
}

export default ResetPasswordForm;

const InputsContainer = styled.div`
display: flex;   
flex-direction: column;
  margin-top: 34px;
  .input-wrapper {
    width: 283px;
    margin-top: 30px;
  }
  input {
    width: 100%;
  }
  button {
    margin-top: 8px;
    min-width: 14rem;
    &.primary {
    margin-top: 30px;
    }
    &.text {
    margin-top: 30px;
    }
  }
`;

const PrimaryButtonWrapper = styled.div`
  button {
    height: 44px;
    width: 190px;
    min-width: unset;
    font-size: 16px; 
  }
`;
