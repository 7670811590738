import { Modal } from 'rsuite';
import styled from 'styled-components';
import clearIco from '../../../../../../assets/clear.png';
import CustomButton from '../../../../../../components/Buttons/CustomButton';
import BinSvg from '../../../../../../components/SvgElements/BinSvg';

export type ProfileDeleteModalProps = {
    showModal: boolean
    setShowModal: (val: boolean) => void
    handleAcceptModal?: () => void
}

function FbProfileDeleteModal({
  setShowModal, showModal, handleAcceptModal,
}: ProfileDeleteModalProps) {
  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
    >
      <StyledModalHeader>
        <button type="button" onClick={() => setShowModal(false)}>
          <img src={clearIco} alt="clear" />
        </button>
      </StyledModalHeader>
      <Modal.Body>
        <ModalContainer>
          <IconWrapper className="remove-ico">
            <BinSvg />
          </IconWrapper>
          <h3>
            Delete
          </h3>
          <div className="text">
            Are you sure you would like
            {' '}
            <br />
            to
            {' '}
            Delete
            {' '}
            this profile?
          </div>
          <CustomButton
            wrapperClassname="archive-btn"
            variant="primary"
            onClick={handleAcceptModal}
          >
            Delete
          </CustomButton>
        </ModalContainer>
      </Modal.Body>
    </Modal>
  );
}

export default FbProfileDeleteModal;

const StyledModalHeader = styled.div`
display: flex;
justify-content: flex-end;
  button {
    padding: 0;
    margin: 0;
    background: transparent;
  }
  img {
    cursor: pointer;
    transition: all ease 0.2s;
    :hover {
      transform: scale(1.2);
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    margin-top: 10px;
  }
  .text {
    margin-top: 11px;
    font-size: 18px;
    color: ${(props) => props.theme.secondaryTextColor};
  }
  .archive-btn {
    width: 121px;
    height: 35px;
    margin-top: 32px;
  }
`;

const IconWrapper = styled.div`
  svg {
    height: 52px;
    width: 52px;
  }
`;
